import { MARKET_SEGMENTS } from "@/utils/areas";
import PropTypes from "prop-types";

const RegionFilter = (props) => {
  const { selection, setSelection } = props;

  const onSelectRegion = (e, region) => {
    const checked = e.target.checked;
    const newSelection = { ...selection, [region.label]: checked };
    setSelection(newSelection);
  }

  return (
    <>
      {MARKET_SEGMENTS.map((region, index) => (
        <div className="row y-gap-10 items-center justify-between" key={index}>
          <div className="col-auto">
            <div className="form-checkbox d-flex items-center">
              <input
                type="checkbox"
                name={"region-" + region.label}
                checked={!!selection?.[region.label]}
                onChange={e => onSelectRegion(e, region)}
              />
              <div className="form-checkbox__mark">
                <div className="form-checkbox__icon icon-check" />
              </div>
              <div className="text-15 ml-10">{region.label}</div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

RegionFilter.propTypes = {
  selection: PropTypes.object.isRequired,
  setSelection: PropTypes.func.isRequired,
};

export default RegionFilter;
