import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';

const Header = ({
  name,
  column,
  isSortable,
  customSort,
  onCustomSort,
  onHeaderClick
}) => (
  <span
    className="react-table__column-header"
    onClick={() => {
      if (onCustomSort && column.pageLevelSort) {
        onCustomSort(column);
      }
      onHeaderClick?.(column);
    }}
  >
    <span className={(isSortable || column.pageLevelSort) ? 'react-table__column-header sortable' : ''}>
      {column.render('Header')}
    </span>
    {((isSortable && column.canSort) || column.pageLevelSort)
      && <Sorting
        name={name}
        column={column}
        tooltip={column.tooltip}
        customSort={customSort}
      />
    }
  </span>
);

Header.propTypes = {
  column: PropTypes.shape({
    Header: PropTypes.string,
    disableGlobalFilter: PropTypes.bool,
    accessor: PropTypes.func,
    render: PropTypes.func,
    canSort: PropTypes.bool,
    tooltip: PropTypes.string,
    pageLevelSort: PropTypes.bool,
  }).isRequired,
  isSortable: PropTypes.bool.isRequired,
  customSort: PropTypes.object,
  onCustomSort: PropTypes.func,
  name: PropTypes.string,
};

const checkCustomUnsorted = (id, customSort) => {
  if (customSort === null || customSort === undefined) {
    return true;
  }
  return customSort[id] === null || customSort[id] === undefined;
};

const checkCustomSortDesc = (id, customSort) => customSort[id] === 'desc';

const Sorting = ({ name, column, tooltip, customSort }) => (
  <span className="react-table__column-header sortable">
    {tooltip
      && <>
        <a
          data-tooltip-id={`${name}-th-tooltip`}
          data-tooltip-content={tooltip}
          data-tooltip-variant="dark"
          onClick={e => e.stopPropagation()}
        >
          <p className="text-15 mt-5 mr-5">ⓘ</p>
        </a>
        <Tooltip id={`${name}-th-tooltip`} />
      </>
    }
    {column.isSortedDesc === undefined && checkCustomUnsorted(column.id, customSort) ? (
      <i className="icon-up-down text-12 mt-5" />
    ) : (
      <span>
        {!customSort && (column.isSortedDesc
          ? <span className="text-12">↓</span>
          : <span className="text-12">↑</span>)
        }
        {customSort && (checkCustomSortDesc(column.id, customSort)
          ? <span className="text-12">↓</span>
          : <span className="text-12">↑</span>)
        }
      </span>
    )}
  </span>
);

Sorting.propTypes = {
  column: PropTypes.shape({
    Header: PropTypes.string,
    disableGlobalFilter: PropTypes.bool,
    accessor: PropTypes.func,
    isSorted: PropTypes.bool,
    isSortedDesc: PropTypes.bool,
    id: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
  tooltip: PropTypes.string,
  customSort: PropTypes.object,
};

const getStylesResizable = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
];

const ReactTableHeader = ({
  name,
  headerGroups,
  isResizable,
  isSortable,
  customSort,
  onCustomSort,
  onHeaderClick
}) => {
  const headerPropsSortable = (props, { column }) => {
    if (column.getSortByToggleProps && isSortable) {
      return column.getSortByToggleProps;
    }
    return [props];
  };
  const headerPropsResize = (props, { column }) => {
    if (column.getResizerProps && isResizable) {
      return getStylesResizable(props, column.align);
    }
    return [props];
  };

  return (
    <thead>
      {/* <p className="md:d-none text-15 ml-10 mb-20 text-green-2">Hover on column header with <i className="icon-transmission text-10" /> for more details on the column</p> */}
      {headerGroups.map((headerGroup, i) => (
        <tr {...headerGroup.getHeaderGroupProps()} key={`tx-t${i}-h`} className="react-table thead tr">
          {headerGroup.headers.map((column, j) => (
            <th
              {...column.getHeaderProps(headerPropsSortable)}
              {...column.getHeaderProps(headerPropsResize)}
              {...column.getHeaderProps()}
              key={`${name}-t${j}-col`}
            >
              <Header
                name={name}
                column={column}
                isSortable={isSortable}
                customSort={customSort}
                onCustomSort={onCustomSort}
                onHeaderClick={onHeaderClick}
              />
              {isResizable && (
                <div {...column.getResizerProps()} className={`resizer ${column.isResizing && 'isResizing'}`} />
              )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

ReactTableHeader.propTypes = {
  headerGroups: PropTypes.arrayOf(PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.shape()),
    getHeaderGroupProps: PropTypes.func,
    getFooterGroupProps: PropTypes.func,
  })).isRequired,
  isResizable: PropTypes.bool.isRequired,
  isSortable: PropTypes.bool.isRequired,
  name: PropTypes.string,
  customSort: PropTypes.object,
  onCustomSort: PropTypes.func,
};

export default ReactTableHeader;
