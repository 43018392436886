import { useState } from "react";
import CompareView from "./CompareView";

const CompareTab = ({
  user,
  data,
  target,
  screenDim,
  isMaximized,
  goToProperty,
  setFocusOn,
  compareList,
  setCompareList,
  viewComparePro,
  getProjectLabel,
  onCompareSearch,
  unfadedProps,
  setUnfadedProps,
  setFocus
}) => {
  const [compData, setCompData] = useState(data.comparables);

  return (
    <CompareView
      user={user}
      data={data}
      target={target}
      compData={compData}
      setCompData={setCompData}
      screenDim={screenDim}
      isMaximized={isMaximized}
      goToProperty={goToProperty}
      setFocusOn={setFocusOn}
      compareList={compareList}
      setCompareList={setCompareList}
      viewComparePro={viewComparePro}
      getProjectLabel={getProjectLabel}
      onCompareSearch={onCompareSearch}
      unfadedProps={unfadedProps}
      setUnfadedProps={setUnfadedProps}
      setFocus={setFocus}
    />
  );
};

export default CompareTab;
