import { Marker } from "react-map-gl/maplibre";

const UserMarker = ({
  target
}) => {
  return (
    <Marker
      latitude={target.lat}
      longitude={target.lng}
      anchor="bottom"
    >
      <img
        src="/img/general/navi.png"
        height={20}
        width={20}
        style={{
          boxShadow: '0 0 20px 10px rgba(52, 152, 219, 0.9)',
          borderRadius: '10px'
        }}
      />
    </Marker>
  );
};

export default UserMarker;
