import { Link } from "react-router-dom";

import MetaComponent from "@/components/common/MetaComponent";
import { useEffect } from "react";
import { logPage } from "@/utils/api";

const metadata = {
  title: "Superpowered! | REALSMART.SG | Supercharge your property search",
  description: "REALSMART.SG - Supercharge your property search",
};

const ErrorOccurredPage = ({ session }) => {
  useEffect(() => {
    logPage('ERROR_PAGE', session, {});
  }, []);

  return (
    <>
      <MetaComponent meta={metadata} />

      <section className="layout-pt-lg layout-pb-lg noselect">
          <div className="container">
            <div className="row y-gap-30 justify-between items-center">
              <div className="col-lg-6">
                <img src="/img/general/404.svg" alt="image" />
              </div>
              <div className="col-lg-5">
                <div className="no-page">
                  <h2 className="text-30 fw-600">Page under <span className="text-blue-1">MAINTENANCE</span></h2>
                </div>
                <div className="mt-5 text-14 fw-600">
                  Try searching other projects or come back soon!
                </div>
                <Link
                  to="/map"
                  className="button -md -dark-1 bg-blue-1 text-white confirm-btn mt-20"
                >
                  Search other projects
                </Link>
              </div>
            </div>
          </div>
        </section>
    </>
  );
};

export default ErrorOccurredPage;
