import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';

import {
  getFunctions,
  httpsCallable
} from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import {
  getStorage,
  ref,
  getBlob,
  uploadBytesResumable
} from "firebase/storage";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider
} from "firebase/app-check";
import {
  connectDataConnectEmulator,
  executeQuery,
  executeMutation,
  getDataConnect
} from 'firebase/data-connect';
import {
  getFirestore,
  doc,
  addDoc,
  collection
} from "firebase/firestore";
import { ungzip } from 'pako';
import { v4 as uuidv4 } from 'uuid';

import {
  connectorConfig,
  listPropertyLikesRef,
  getPropertyLikeByIdRef,
  createPropertyLikesRef,
  deletePropertyLikesRef,
  getNewsfeedRef,
  getPublicFeedsRef,
  getPostRef,
  getCommentsRef,
  getUnreadNotificationsRef,
  getFollowsRef,
  getFollowersRef,
  getUserPostsRef,
  getLikedPostsRef,
  getAllNotificationsRef,
} from '../../dataconnect-generated/js/default-connector';

import { isDevMode } from './dev';
import { getUserDisplayName, getUserPhotoUrl, randomize } from './user';

export const DEBOUNCE_TIMING = 100;

const REGION = 'asia-southeast1';

// setup firebase
const firebaseConfig = {
  apiKey: "AIzaSyA5I7E4wezPbv-133OrRf4iSjFO0HHKoM4",
  authDomain: "realsmart.sg",
  projectId: "realsmart-main",
  storageBucket: "realsmart-main.appspot.com",
  messagingSenderId: "1010308007737",
  appId: "1:1010308007737:web:9f349bdd74d0ebe56e8ae8",
  measurementId: "G-H9XW5H3Y3M"
};

// initialize firebase app
const app = firebase.initializeApp(firebaseConfig);

// initialize storage
const storage = getStorage(app);

// initialize firestore
const firestoreDb = getFirestore(app);

// setup dev env for firebase
if (isDevMode) {
  // set functions to use local emulator
  // const functions = firebase.app().functions(REGION);
  // functions.useEmulator('127.0.0.1', '5001');

  // set app check with debug provider
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

  // use local emulator for data connect
  // const dataConnect = getDataConnect(connectorConfig);
  // connectDataConnectEmulator(dataConnect, 'localhost', 9399);

  // disable Umami analytics for dev if not already set
  // if (!localStorage.getItem('umami.disabled')) {
  //   localStorage.setItem('umami.disabled', 1);
  // }
} else {
  // initialize analytics for non-dev
  getAnalytics(app);
}

// initialize app check
initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider('6Lf8dgMqAAAAAONjse8fQO-z_GB8cj1PzkiOykHg'),
  isTokenAutoRefreshEnabled: true
});

// api for posts create

export const createPost = async (user, data, callback) => {
  const docRef = await addDoc(collection(firestoreDb, 'posts', user.claims.user_id, 'p'), {
    ...data,
    uid: user.claims.user_id,
    author: getUserDisplayName(user),
    avatar: getUserPhotoUrl(user)
  });
  callback?.(docRef.id);
};

export const createComment = async (user, data, callback) => {
  const docRef = await addDoc(collection(firestoreDb, 'posts', user.claims.user_id, 'p'), {
    ...data,
    type: 'comment',
    uid: user.claims.user_id,
    author: getUserDisplayName(user),
    avatar: getUserPhotoUrl(user)
  });
  callback?.(docRef.id);
};

// api for likes

export const getPropertyLikes = async (user, callback, limit, offset = 0) => {
  const ref = listPropertyLikesRef({ uid: user.claims.user_id, offset, limit });
  const { data } = await executeQuery(ref);
  callback?.(data.propertylikes);
};

export const getPropertyLike = async (pid, callback) => {
  const ref = getPropertyLikeByIdRef({ pid });
  const { data } = await executeQuery(ref);
  callback?.(data.propertylike);
};

export const likeProperty = async (pid) => {
  const ref = createPropertyLikesRef({ pid });
  await executeMutation(ref);
};

export const unlikeProperty = async (pid) => {
  const ref = deletePropertyLikesRef({ pid });
  await executeMutation(ref);
};

export const getGuestFeeds = async (maxSysTs, limit, callback) => {
  const ref = getPublicFeedsRef({ maxSysTs, limit });
  const { data } = await executeQuery(ref);
  callback?.(data.feeds);
};

export const getLatestGuestFeeds = async (minSysTs, limit, callback) => {
  const ref = getPublicFeedsRef({ minSysTs, limit });
  const { data } = await executeQuery(ref);
  callback?.(data.feeds);
};

// export const getUserFeeds = async (uid, minUserTs, maxUserTs, minSysTs, maxSysTs, callback) => {
//   const ref = getNewsfeedRef({ uid, minUserTs, maxUserTs, minSysTs, maxSysTs, limit: 50 });
//   const { data } = await executeQuery(ref);
//   callback?.(data.feeds);
// };

export const getPost = async (pid, callback) => {
  const ref = getPostRef({ id: pid });
  const { data } = await executeQuery(ref);
  callback?.(data.post);
};

export const getComments = async (pid, maxSysTs, limit, callback) => {
  const ref = getCommentsRef({ pid, maxSysTs, limit });
  const { data } = await executeQuery(ref);
  callback?.(data.comments);
};

export const getUnreadNotifications = async (uid, offset, limit, callback) => {
  const ref = getUnreadNotificationsRef({ uid, offset, limit });
  const { data } = await executeQuery(ref);
  callback?.(data.notifications);
};

export const getAllNotifications = async (uid, offset, limit, callback) => {
  const ref = getAllNotificationsRef({ uid, offset, limit });
  const { data } = await executeQuery(ref);
  callback?.(data.notifications);
};

export const getFollows = async (user, offset, limit, callback) => {
  const ref = getFollowsRef({ uid: user.claims.user_id, offset, limit });
  const { data } = await executeQuery(ref);
  callback?.(data.follows);
};

export const getFollowers = async (user, offset, limit, callback) => {
  const ref = getFollowersRef({ uid: user.claims.user_id, offset, limit });
  const { data } = await executeQuery(ref);
  callback?.(data.follows);
};

export const getUserPosts = async (uid, offset, limit, callback) => {
  const ref = getUserPostsRef({ uid, offset, limit });
  const { data } = await executeQuery(ref);
  callback?.(data.posts);
};

export const getLikedPosts = async (uid, offset, limit, callback) => {
  const ref = getLikedPostsRef({ uid, offset, limit });
  const { data } = await executeQuery(ref);
  callback?.(data.postlikes);
};

/* analytics */

export const trackPageView = (title, data = {}) => {
  if (window.umami && typeof window.umami.track === 'function') {
    const { pathname, search } = window.location;
    const fullPath = `${pathname}${search}`;
    window.umami.track({
      ...data,
      title,
      url: fullPath,
      website: '03ca7ff0-525b-41fd-aef7-f37472bd118c'
    });
  }
};

export const trackEvent = (title, data = {}) => {
  if (window.umami && typeof window.umami.track === 'function') {
    window.umami.track(title, {
      ...data,
      website: '03ca7ff0-525b-41fd-aef7-f37472bd118c'
    });
  }
};

/**
 * File fetch
 */

export const getFile = (dir, filename, onSuccess, onError) => {
  fetch(`https://realsmart.global.ssl.fastly.net/s/${dir}/${filename}`)
  .then(resp => {
    return resp.arrayBuffer();
  }).then(data => {
    onSuccess(ungzip(new Uint8Array(data), { to: "string" }));
  }).catch(err => {
    console.log('Error', err);
    onError?.(err);
  });
};

/* autocomplete */

const TS_HOST = 'realsense.global.ssl.fastly.net';

const TS_HEADER = {
  'X-TYPESENSE-API-KEY': '5XRXUw4HPHlAJ4EmJWVsqryAeGH4glsP'
};

export const searchQuery = (term, limit, onSuccess, onError, specificType = null, additionalFilters = null) => {
  const sanitizedTerm = term.replace('#', '');
  const typeFilter = specificType
    ? (additionalFilters ? `&filter_by=type:=${specificType}&&${additionalFilters}` : `&filter_by=type:=${specificType}`)
    : (additionalFilters ? `&filter_by=${additionalFilters}` : '');
  // const typeFilter = specificType ? `&filter_by=type:=${specificType}&&subtype:!=HDB` : '&filter_by=subtype:!=HDB';
  const url = `https://${TS_HOST}/collections/searchv3/documents/search?q=${sanitizedTerm}&query_by=marker,names,street,postal&sort_by=_text_match:desc&per_page=${limit}&prefix=true&num_typos=3&split_join_tokens=always${typeFilter}&include_fields=names,name,street,store,marker,type,subtype,landed,postal`;
  fetch(url, { headers: TS_HEADER }).then(response => {
    return response.json();
  }).then(data => {
    onSuccess?.(data.hits?.map(v => v['document']) ?? []);
  }).catch(err => {
    onError?.(err);
  });
};

const getNearby = (type, subtype, lat, lng, radius, limit, onSuccess, onError) => {
  const url = `https://${TS_HOST}/collections/searchv3/documents/search?q=${type}&query_by=type&sort_by=coords(${lat},${lng}):asc&per_page=${limit}&filter_by=subtype:=${subtype}&&coords:(${lat},${lng},${radius})`;
  fetch(url, { headers: TS_HEADER }).then(response => {
    return response.json();
  }).then(data => {
    onSuccess?.(data.hits?.map(v => v['document']) ?? []);
  }).catch(err => {
    onError?.(err);
  });
};

export const TS_HDB_FILTER = "HDB";

export const TS_CONDO_FILTER = "['Apartment','Condominium','Executive Condominium']";

export const TS_LANDED_FILTER = "['Semi-Detached House','Detached House','Terrace House']";

export const getNearbyHdbs = (lat, lng, radius, limit, onSuccess, onError) => {
  getNearby('property', TS_HDB_FILTER, lat, lng, radius, limit, onSuccess, onError);
};

export const getNearbyCondos = (lat, lng, radius, limit, onSuccess, onError) => {
  getNearby('property', TS_CONDO_FILTER, lat, lng, radius, limit, onSuccess, onError);
};

export const getNearbyLanded = (lat, lng, radius, limit, onSuccess, onError) => {
  getNearby('property', TS_LANDED_FILTER, lat, lng, radius, limit, onSuccess, onError);
};

export const getUpcomingPropertyData = (name, lat, lng, onSuccess, onError) => {
  const url = `https://${TS_HOST}/collections/searchv3/documents/search?q=${name}&query_by=marker&sort_by=coords(${lat},${lng}):asc&per_page=1&filter_by=type:=upcoming`;
  fetch(url, { headers: TS_HEADER }).then(response => {
    return response.json();
  }).then(data => {
    onSuccess?.(data?.hits?.[0]?.document);
  }).catch(err => {
    onError?.(err);
  });
};

export const getTopProperties = (subtypes, models, field, limit, onSuccess, onError) => {
  const url = `https://${TS_HOST}/collections/searchv3/documents/search?q=property&query_by=type&sort_by=${field}&per_page=${limit}&filter_by=subtype:=${subtypes}${models && models.length > 0 ? `&&models:=[${models.map(m => `'${m}'`)}]` : ''}`;
  fetch(url, { headers: TS_HEADER }).then(response => {
    return response.json();
  }).then(data => {
    onSuccess?.(data.hits?.map(v => v['document']) ?? []);
  }).catch(err => {
    onError?.(err);
  });
};

/**
 * API Functions
 */

const requestFunc = (func, params, onSuccess, onError) => {
  const request = httpsCallable(getFunctions(app, REGION), func);
  request(params)
  .then((result) => {
    let err = result.err ?? result.error;
    if (err) {
      onError?.(err);
    } else {
      const data = result.data;
      err = data.err ?? data.error;
      if (err) {
        onError?.(err);
      } else {
        onSuccess?.(data);
      }
    }
  }).catch((err) => {
    if (onError) {
      let errStr = err.toString();
      if (errStr.toLowerCase() === 'FirebaseError: internal'.toLowerCase()) {
        errStr = 'Internal error'
      }
      onError?.(errStr);
    } else {
      console.log('Error', err);
      onError?.(err);
    }
  });
};

export const editPost = (id, user, data, onSuccess, onError) => {
  requestFunc('user_edit', { id, t: 'p', d: JSON.stringify({
    ...data,
    id,
    uid: user.claims.user_id,
    author: getUserDisplayName(user),
    avatar: getUserPhotoUrl(user)
  }) }, onSuccess, onError);
};

export const editComment = (id, data, onSuccess, onError) => {
  requestFunc('user_edit', { id, t: 'c', d: data }, onSuccess, onError);
};

export const deletePost = (id, onSuccess, onError) => {
  requestFunc('user_delete', { id, t: 'p' }, onSuccess, onError);
};

export const deleteComment = (id, postId, onSuccess, onError) => {
  requestFunc('user_delete', { id, s: postId, t: 'c' }, onSuccess, onError);
};

export const likePost = (id, onSuccess, onError) => {
  requestFunc('user_action', { id, t: 'lp', v: 1 }, onSuccess, onError);
};

export const unlikePost = (id, onSuccess, onError) => {
  requestFunc('user_action', { id, t: 'lp', v: -1 }, onSuccess, onError);
};

export const votePost = (id, value, onSuccess, onError) => {
  requestFunc('user_action', { id, t: 'vp', v: value }, onSuccess, onError);
};

export const likeComment = (id, onSuccess, onError) => {
  requestFunc('user_action', { id, t: 'lc', v: 1 }, onSuccess, onError);
};

export const unlikeComment = (id, onSuccess, onError) => {
  requestFunc('user_action', { id, t: 'lc', v: -1 }, onSuccess, onError);
};

export const voteComment = (id, cid, value, onSuccess, onError) => {
  requestFunc('user_action', { id, t: 'vc', s: cid, v: value }, onSuccess, onError);
};

export const followAuthor = (id, onSuccess, onError) => {
  requestFunc('user_action', { id, t: 'fa' }, onSuccess, onError);
};

export const unfollowAuthor = (id, onSuccess, onError) => {
  requestFunc('user_action', { id, t: 'ua' }, onSuccess, onError);
};

export const reportPost = (id, data, onSuccess, onError) => {
  requestFunc('user_report', { id, t: 'p', c: data }, onSuccess, onError);
};

export const reportComment = (id, data, onSuccess, onError) => {
  requestFunc('user_report', { id, t: 'c', c: data }, onSuccess, onError);
};

export const viewPost = (id, onSuccess, onError) => {
  requestFunc('user_view', { id, t: 'p' }, onSuccess, onError);
};

export const viewComment = (id, onSuccess, onError) => {
  requestFunc('user_view', { id, t: 'c' }, onSuccess, onError);
};

export const scrollFeeds = (ids, onSuccess, onError) => {
  requestFunc('user_scroll_feeds', { ps: ids }, onSuccess, onError);
};

export const readNotifications = (ids, onSuccess, onError) => {
  requestFunc('user_read_notification', { ids }, onSuccess, onError);
};

export const deleteNotifications = (ids, onSuccess, onError) => {
  requestFunc('user_read_notification', { ids, delete: true }, onSuccess, onError);
};

export const hasLikedProperty = (id, onSuccess, onError) => {
  // Check if user has followed property
  requestFunc('get_meta_data', { t: 'fp', i: id }, data => {
    const state = !!data.data;
    onSuccess(state);
  }, onError);
};

export const hasLikedAuthor = (id, onSuccess, onError) => {
  // Check if user has followed author
  requestFunc('get_meta_data', { t: 'fa', i: id }, data => {
    const state = !!data.data;
    onSuccess(state);
  }, onError);
};

export const hasLikedPost = (id, onSuccess, onError) => {
  // Check if user has liked/voted post and any of the comments
  requestFunc('get_meta_data', { t: 'lp', i: id }, data => {
    const state = !!data.data;
    const comments = data.comments;
    onSuccess(state, comments);
  }, onError);
};

// export const debugCall = (params, onSuccess, onError) => {
//   requestFunc('debug_user', params, onSuccess, onError);
// }

// export const addSuperUser = (email, onSuccess, onError) => {
//   requestFunc('debug_user', {
//     e: email,
//   }, onSuccess, onError);
// }

// export const getUserInfo = (onSuccess, onError) => {
//   requestFunc('get_user', {}, onSuccess, onError);
// };

// export const getProfitsTable = (params, onSuccess, onError) => {
//   requestFunc('query_profits', params, onSuccess, onError);
// };

// export const getTransactionsTable = (params, onSuccess, onError) => {
//   requestFunc('query_transactions', params, onSuccess, onError);
// };

// export const getRentalsTable = (params, onSuccess, onError) => {
//   requestFunc('query_rentals', params, onSuccess, onError);
// };

// export const getUserHistory = (type, onSuccess) => {
//   requestFunc('get_user_config', {
//     t: type,
//   }, onSuccess);
// };

// export const saveUserHistory = (type, newConfig) => {
//   requestFunc('set_user_config', {
//     t: type,
//     c: newConfig,
//   });
// };

// export const getUserPaymentId = (onSuccess, onError) => {
//   requestFunc('get_stripe_session', {}, onSuccess, onError);
// };

// export const refreshPaymentStatus = (params, onSuccess, onError) => {
//   requestFunc('refresh_payment_status', params, onSuccess, onError);
// };

// export const getBillingPortalLink = (onSuccess, onError) => {
//   requestFunc('get_billing_portal_link', {}, onSuccess, onError);
// };

/* social */

// export const getPostsAPI = (propertyId, params, onSuccess, onError) => {
//   requestFunc('get_posts', {
//     ...params,
//     id: propertyId,
//     type: 'p',
//   }, onSuccess, onError);
// };

// export const getRepliesAPI = (propertyId, postId, params, onSuccess, onError) => {
//   requestFunc('get_posts', {
//     ...params,
//     id: propertyId,
//     type: 'p',
//     post: postId,
//   }, onSuccess, onError);
// };

// export const createPostAPI = (propertyId, content, onSuccess, onError) => {
//   requestFunc('create_post', {
//     id: propertyId,
//     type: 'p',
//     content,
//   }, onSuccess, onError);
// };

// export const createReplyAPI = (propertyId, postId, content, onSuccess, onError) => {
//   requestFunc('create_post', {
//     id: propertyId,
//     type: 'r',
//     content,
//     post: postId,
//   }, onSuccess, onError);
// };

// export const deletePostAPI = (postId, onSuccess, onError) => {
//   requestFunc('delete_post', {
//     id: postId,
//     type: 'p',
//   }, onSuccess, onError);
// };

// export const deleteReplyAPI = (replyId, onSuccess, onError) => {
//   requestFunc('delete_post', {
//     id: replyId,
//     type: 'r',
//   }, onSuccess, onError);
// };

// export const votePostAPI = (postId, score, onSuccess, onError) => {
//   requestFunc('vote', {
//     id: postId,
//     type: 'p',
//     score,
//   }, onSuccess, onError);
// };

// export const voteReplyAPI = (replyId, score, onSuccess, onError) => {
//   requestFunc('vote', {
//     id: replyId,
//     type: 'r',
//     score,
//   }, onSuccess, onError);
// };

/* analytics */

export const logAnalytics = (event, session, params) => {
  if (isDevMode) {
    console.log('LOG', event, session, params);
    return;  // do not log for development
  }
  requestFunc('log_analytics', {
    params,
    event,
    session,
  });
};

export const logPropSearchFilter = (session, params) => {
  logAnalytics('PROPERTY_FILTER', session, params);
};

export const logNameSearch = (page, session, input) => {
  logAnalytics(`${page}_NAME_SEARCH`, session, { input });
};

export const logLanding = (session, user) => {
  logAnalytics('LANDING', session, Object.keys(user).length > 0 ? {
    user: {
      id: user?.claims?.user_id,
      name: user?.claims?.name,
      email: user?.claims?.email,
    },
    type: 'member'
  } : {
    type: 'guest'
  });
};

export const logLogin = (session, user) => {
  logAnalytics('LOGIN', session, {
    user: {
      id: user?.multiFactor?.user?.uid,
      name: user?.multiFactor?.user?.displayName,
      email: user?.multiFactor?.user?.email,
    },
    type: 'member'
  });
};

export const logSignup = (session, user, createdAt) => {
  logAnalytics('SIGNUP', session, {
    user: {
      id: user?.multiFactor?.user?.uid,
      name: user?.multiFactor?.user?.displayName,
      email: user?.multiFactor?.user?.email,
      created: createdAt
    },
    type: 'member'
  });
};

export const logPageView = (page, session) => {
  logAnalytics(`${page}_VIEW`, session, null);
};

export const logPage = (page, session, params) => {
  logAnalytics(page, session, params);
};

export const logReferred = (origin, session) => {
  logAnalytics(`REFERRED_${origin.toUpperCase()}`, session, null);
};

export const logPropertyTabView = (tab, session, marker, project) => {
  logAnalytics(`DETAIL_TAB_${tab}`, session, { project });
};

export const logProfitSearchFilter = (session, params) => {
  logAnalytics('PROFIT_FILTER', session, params);
};

export const logTransactionSearchFilter = (session, params) => {
  logAnalytics('TRANSACTION_FILTER', session, params);
};

export const logRentalSearchFilter = (session, params) => {
  logAnalytics('RENTAL_FILTER', session, params);
};

export const logMapMarkerSelect = (session, params) => {
  logAnalytics('MAP_MARKER', session, params);
};

export const logComparePro = (session, params) => {
  logAnalytics('MAP_COMPARE_PRO', session, params);
};

/**
 * Storage
 */

// export const downloadFileUrl = (folder, filekey, extension, onSuccess, onError) => {
//   const fileRef = ref(storage, `${folder}/${filekey}${extension ? `.${extension}` : ''}`);
//   getBlob(fileRef)
//     .then((blob) => {
//       const url = URL.createObjectURL(blob);
//       onSuccess(url);
//     })
//     .catch((err) => {
//       return onError('Property does not exist');
//     });
// };

const uploadFile = (idx, generatedPostId, file, onProgress, onSuccess, onError) => {
  const ext = file.name.split('.').pop();
  const filename = `${generatedPostId}_${uuidv4()}.${ext}`;
  const storageRef = ref(storage, filename);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on("state_changed",
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      onProgress?.(idx, progress);
    },
    (error) => {
      onError?.(idx, error);
    },
    () => {
      onSuccess?.(idx, filename);
    }
  );
};

const MAX_FILE_UPLOAD_SIZE = 100 * 1024 * 1024; // 100MB

export const uploadFiles = (generatedPostId, selectedFiles, onProgress, onSuccess, onError) => {
  selectedFiles
    .forEach(f => {
      if (!f?.file) onError?.(f.name, "Invalid file selected");
      else if (f.file.size > MAX_FILE_UPLOAD_SIZE) onError?.(f.name, "File too large, exceeded 5MB");
      else if (!f.file.type.startsWith("image/") && !f.file.type.startsWith("video/")) onError?.(f.name, "Invalid file type selected");
      else uploadFile(f.name, generatedPostId, f.file, onProgress, onSuccess, onError);
    });
};

const ATTACHMENT_MEDIA_IMG = new Set(['jpg', 'jpeg', 'heic', 'gif', 'bmp', 'webp']);

const ATTACHMENT_MEDIA_VIDEO = new Set(['mp4', 'mov', 'webm', 'avi', 'mkv']);

export const getAttachmentMediaType = (v) => {
  if (!v) return 'unknown';
  const c = v.split('.');
  const ext = c[c.length - 1].toLowerCase();
  if (ATTACHMENT_MEDIA_IMG.has(ext)) return 'image';
  if (ATTACHMENT_MEDIA_VIDEO.has(ext)) return 'video';
  return 'unknown';
};

export const getPostImgUrl = (img, thumbnail) => {
  const imgId = img.split('.')[0];
  const type = getAttachmentMediaType(img);
  if (type === 'image') {
    return `https://realsmart.global.ssl.fastly.net/n/${thumbnail ? 't' : 'o'}/${imgId}.jpg`;
  } else if (type === 'video') {
    return `https://realsmart.global.ssl.fastly.net/n/vt/${imgId}.gif`;
  }
  return '/img/general/animhome2.gif';
};

export const getPlaybackVideoUrl = (img) => {
  const imgId = img.split('.')[0];
  return `https://realsmart.global.ssl.fastly.net/n/v/${imgId}.mp4`;
};
