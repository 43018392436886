import { trackEvent } from "@/utils/api";
import { NA } from "@/utils/convert";
import {
  ICON,
  getPropertyBgColor,
  getPropertyTextColor
} from "@/utils/map";
import { Marker } from "react-map-gl/maplibre";

const PropertyMarker = ({
  idx,
  target,
  focus,
  property,
  goToMarker,
  onMouseEnter,
  onMouseLeave,
  mediaMatches,
  unfadedProps,
  scheme
}) => {
  const data = property.properties;

  // check if the marker on map is selected
  // any property within this marker selected will mean that this marker is selected
  // render the roof to indicate marker selected
  // const isSelectedMarker = target?.id === data.name;
  const isSelectedMarker = focus?.target?.marker === data.name || target?.marker === data.name;

  const markerOpacity = unfadedProps.size === 0 || data.properties.some(p => unfadedProps.has(`${p.marker}_${p.projectId}`)) ? 1 : 0.3;

  return (
    <Marker
      key={`propm-${idx}`}
      latitude={data.lat}
      longitude={data.lng}
      anchor="bottom"
      onClick={e => {
        e.originalEvent.stopPropagation();
        goToMarker(data.name);
        trackEvent(`private_marker_${data.name}`);
      }}
      {...(mediaMatches ? {
        offset: [0, 20]
      } : {})}
    >
      <div
        style={{
          opacity: markerOpacity,
          transform: `scale(${data.markerScale})`
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-bs-toggle="offcanvas"
        data-bs-target="#propSidebar"
        {...(mediaMatches ? {} : {
          'data-tooltip-id': "prop-tooltip",
          'data-tooltip-html': mediaMatches ? `<div class="map-tooltip-content"><span class="fw-500">${data.name}</span></div>` : `
            <div class="map-tooltip-content">
            <span class="fw-500">${data.name}</span>
            <div class="map-tooltip-l-content">
            Profitable %: <span class="${getPropertyTextColor(scheme, data, 'profitable')}">${data.profitable === null || data.profitable < 0 ? NA : `${data.profitable.toFixed(0)}%`}</span>
            <br />
            Total Transactions: ${data.totalTx?.toLocaleString()}
            <br />
            Property Types: ${data.propTypes.join(', ')}
            </div>
            <span class="map-tooltip-hint">${isSelectedMarker ? 'Current property' : 'Click to view details'}</span>
            </div>
          `,
          'data-tooltip-variant': "dark",
          'data-tooltip-place': "bottom"
        })}
      >
        {isSelectedMarker
          && <div className={`prop-marker-roof ${getPropertyBgColor(scheme, data, 'profitable')}`}></div>
        }
        <svg
          height={60}
          viewBox="0 0 24 24"
          style={{
            cursor: 'pointer',
            fill: data.markerColor[scheme],
            stroke: 'none',
          }}
        >
          <path d={ICON} />
          <circle cx="12" cy="10" r="8" fill="#fff" />
          <image
            href={`/img/general/${data.iconType}.png`}
            x={data.hasText ? "8" : "7"} 
            y={data.hasText ? "3" : "5"}
            width={data.hasText ? "9" : "10"}
            height={data.hasText ? "9" : "10"}
          />
          {data.hasText
            && <text
              x="12"
              y={data.iconType === 'flat' ? "14" : "15"}
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize={data.iconType === 'flat' ? "3" : "4"}
              fill="#000"
              fontWeight="500"
              fontFamily="'Arial Black', sans-serif"
            >
              {data.iconText}
            </text>
          }
        </svg>
      </div>
    </Marker>
  );
};

export default PropertyMarker;
