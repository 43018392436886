import { useState } from 'react';
import CustomTable from '@/components/transactions/CustomTable';
import { NA, formatDistanceLabel } from '@/utils/convert';
import { STANDARD_PAGE_CONFIG } from '@/utils/table';
import { MAP_MODE_HDB, getProfitableTextClass } from '@/utils/map';

const AmenityTable = ({
  data,
  userConfig,
  goToProperty,
  goToBlock,
  mode
}) => {
  const DEFAULT_COLUMNS = mode === MAP_MODE_HDB
    ? [
        {
          Header: 'HDB',
          accessor: 'marker',
          disableGlobalFilter: true,
          Cell: (row) =>
            <a
              className="text-blue-1 cursor-pointer"
              onClick={() => goToProperty(row)}
            >
              {row.cell.value}
            </a>
        },
        {
          Header: 'Postal Code',
          accessor: 'postal',
          disableGlobalFilter: true,
          Cell: (row) =>
            <a
              className="text-blue-1 cursor-pointer"
              onClick={() => goToProperty(row)}
            >
              {row.cell.value}
            </a>
        },
        {
          Header: 'Distance',
          accessor: 'distance',
          disableGlobalFilter: true,
          width: 60,
          Cell: (row) => formatDistanceLabel(row.cell.value)
        },
      ]
    : [
        {
          Header: 'Project',
          accessor: 'name',
          disableGlobalFilter: true,
          Cell: (row) =>
            <a
              className="text-blue-1 cursor-pointer"
              onClick={() => goToProperty(row)}
            >
              {row.cell.value}
            </a>
        },
        {
          Header: 'Street / Address',
          accessor: 'addr',
          disableGlobalFilter: true,
          // Cell: (row) => <a
          //   className="text-blue-1 cursor-pointer"
          //   onClick={() => goToBlock(row)}
          // >
          //   {row.cell.value}
          // </a>
          Cell: (row) => <div>{row.cell.value}</div>
        },
        {
          Header: 'Distance',
          accessor: 'distance',
          disableGlobalFilter: true,
          width: 60,
          Cell: (row) => formatDistanceLabel(row.cell.value)
        },
        {
          Header: 'Property Type',
          accessor: 'subtype',
          disableGlobalFilter: true,
          disableSortBy: true,
          Cell: (row) => row.cell.value.join(', ')
        },
        // {
        //   Header: 'Profitable (%)',
        //   accessor: 'profitable',
        //   disableGlobalFilter: true,
        //   width: 60,
        //   Cell: (row) => <div className={getProfitableTextClass(userConfig.profitableColor, row.cell.value)}>
        //     {isNaN(parseFloat(row.cell.value)) || row.cell.value === -1 ? NA : `${row.cell.value.toFixed(1)}%`}
        //   </div>
        // },
      ];

  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  return (
    <CustomTable
      data={data}
      tableConfig={STANDARD_PAGE_CONFIG}
      defaultColumns={DEFAULT_COLUMNS}
      fullColumns={fullColumns}
      setFullColumns={setFullColumns}
      columns={columns}
      setColumns={setColumns}
      hideSearchInput
      hidePageButton
      hidePage
      pageLimit={1000}
      disableCustomColumns={true}
    />
  );
};

export default AmenityTable;
