import { ReactSVG } from 'react-svg';
import Checkbox from "@/components/common/sidebar/Checkbox";
import { MAP_THEMES } from "@/utils/areas";
import {
  getMapOverlayColor,
  MAP_MODES,
  OVERLAY_GROUPS,
  PROFITABLE_SCHEMES,
  PROFITABLE_SCHEMES_MAP
} from "@/utils/map";
import { checkMediaQuery, checkMobile } from "@/utils/user";
import { trackEvent } from '@/utils/api';

const MapButtons = ({
  theme,
  setTheme,
  onSelectFilter,
  // markerSchemeOptions,
  // markerScheme,
  // setMarkerScheme,
  onSelectCompare,
  overlayOpacity,
  setOverlayOpacity,
  hiddenOverlayIds,
  setHiddenOverlayIds,
  numFilters,
  userConfig,
  setProfitableColorScheme,
  mode,
  setMode,
  showSchoolMarkers,
  setShowSchoolMarkers,
  showStationMarkers,
  setShowStationMarkers,
  showUpcomingMarkers,
  setShowUpcomingMarkers,
  allowModeSwitch,
  enableShowAmenity,
  showSchoolMarkersInPage,
  setShowSchoolMarkersInPage,
  showStationMarkersInPage,
  setShowStationMarkersInPage,
  showUpcomingMarkersInPage,
  setShowUpcomingMarkersInPage
}) => {
  const isSmallScreen = window.innerWidth < 1000;

  // const isXSmallScreen = window.innerWidth < 600;
  const isXSmallScreen = false;

  const mediaQuery = checkMediaQuery();

  return (
    <>
      <div className="d-flex d-row justify-content-between noselect">
        {!isXSmallScreen
          && <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
            <div
              className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-5 py-5 text-12 lh-12"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
              aria-expanded="false"
              data-bs-offset="0,10"
              data-tooltip-id="map-tooltip"
              data-tooltip-html={`
                <div class="map-tooltip-content">
                <span class="fw-500">${theme} map</span>
                <br />
                <span class="map-tooltip-hint">Click to change map style</span>
                </div>
              `}
              data-tooltip-variant="dark"
              data-tooltip-place="bottom"
            >
              <span className="js-dropdown-map">
                <img id="map-mode-ic" className="map-mode-icon" height={25} width={25} src={MAP_THEMES.find(t => t.label === theme).icon} />
              </span>
              {!mediaQuery && <i className="icon icon-chevron-sm-down text-7 ml-10" />}
            </div>
            <div className="toggle-element -dropdown dropdown-menu dropdown-top">
              <div className="text-12 y-gap-20 js-dropdown-list">
                {MAP_THEMES.map((option, index) => (
                  <div key={index}>
                    <button
                      className={`d-block js-dropdown-link ${
                        theme === option.label ? "text-blue-1 " : ""
                      }`}
                      onClick={() => {
                        setTheme(option.label);
                        document.getElementById('map-mode-ic').src = option.icon;

                        trackEvent(`map_change_theme_${option.label}`);
                      }}
                    >
                      <img className="map-mode-icon mr-20" height={25} width={25} src={option.icon} />
                      {option.label}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }

        <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
          <div
            className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5 text-12 lh-12 "
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,10"
            {...(
              mediaQuery
                ? {}
                : {
                  'data-tooltip-id': "map-tooltip",
                  'data-tooltip-html': `
                    <div class="map-tooltip-content">
                    <span class="fw-500">Overlay Setting</span>
                    <br />
                    <span class="map-tooltip-hint">Click to configure map overlays</span>
                    </div>
                  `,
                  'data-tooltip-variant': "dark",
                  'data-tooltip-place': "bottom"
                }
            )}
          >
            <span className="js-dropdown-map map-btn-icon">
              <ReactSVG
                src="/img/general/overlay.svg"
                beforeInjection={(svg) => {
                  svg.querySelectorAll('[fill]').forEach((element) => element.removeAttribute('fill'));
                }}
              />
            </span>
            {!mediaQuery && <i className="icon icon-chevron-sm-down text-7 ml-5" />}
          </div>
          <div className="toggle-element -dropdown dropdown-menu overlay-menu">
            <div className="text-12 y-gap-5 js-dropdown-list">
              <div className="fw-600 text-blue-1">LAND USE OVERLAY OPACITY {(overlayOpacity * 100).toFixed(0)}%</div>
              <input
                type="range"
                min={0}
                max={1}
                value={overlayOpacity}
                step={0.05}
                style={{ width: '100%' }}
                onChange={e => setOverlayOpacity(parseFloat(e.target.value))}
              />
              <hr />
              <div className="fw-600 text-blue-1">SHOW / HIDE OVERLAY</div>
              {OVERLAY_GROUPS.map((option, index) => (
                <div key={index}>
                  <Checkbox
                    label={option.label}
                    value={!option.ids.some(id => hiddenOverlayIds.indexOf(id) >= 0)}
                    setValue={v => {
                      if (v) setHiddenOverlayIds(hiddenOverlayIds.filter(id => option.ids.indexOf(id) < 0));
                      else setHiddenOverlayIds([...new Set([...hiddenOverlayIds, ...option.ids])]);

                      trackEvent(`map_overlay_change_${v ? 'enable' : 'disable'}_${option.label}`);
                    }}
                    textSize="14"
                    colorHints={option.ids.map(id => getMapOverlayColor(id))}
                  />
                </div>
              ))}
              <button
                className="button mr-10 mb-5 -dark-1 bg-blue-1 text-white h-30 px-10 rounded-100 text-12 mt-10"
                onClick={() => {
                  setHiddenOverlayIds([]);
                  setOverlayOpacity(0.5);

                  trackEvent('map_overlay_reset');
                }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>

        {!checkMobile()
          && <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
            <div
              className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
              aria-expanded="false"
              data-bs-offset="0,10"
              {...(mediaQuery
                ? {}
                : {
                  'data-tooltip-id': "map-tooltip",
                  'data-tooltip-html': `
                    <div class="map-tooltip-content">
                    <span class="fw-500">Color by Profitable %</span>
                    <div class="map-tooltip-l-content text-12">
                    <span class="text-14">Color range for % transactions
                    <br />
                    which are profitable:</span>
                    <br />
                    <span class="map-marker-color-${PROFITABLE_SCHEMES_MAP[userConfig.profitableColor].texts.top}">${PROFITABLE_SCHEMES_MAP[userConfig.profitableColor].texts.top.toUpperCase()}: > 90%</span>
                    <br />
                    <span class="map-marker-color-${PROFITABLE_SCHEMES_MAP[userConfig.profitableColor].texts.high}">${PROFITABLE_SCHEMES_MAP[userConfig.profitableColor].texts.high.toUpperCase()}: > 70%</span>
                    <br />
                    <span class="map-marker-color-${PROFITABLE_SCHEMES_MAP[userConfig.profitableColor].texts.mid}">${PROFITABLE_SCHEMES_MAP[userConfig.profitableColor].texts.mid.toUpperCase()}: > 50%</span>
                    <br />
                    <span class="map-marker-color-${PROFITABLE_SCHEMES_MAP[userConfig.profitableColor].texts.low}">${PROFITABLE_SCHEMES_MAP[userConfig.profitableColor].texts.low.toUpperCase()}: < 50%</span>
                    <br />
                    <span>GREY: No Data</span>
                    </div>
                  `,
                  'data-tooltip-variant': "dark",
                  'data-tooltip-place': "bottom"
                }
              )}
              style={{
                padding: '0 8px'
              }}
            >
              <div className={`map-color-legend map-color-grad-${userConfig.profitableColor}`}>
                <span className="low">HIGH</span>
                <span className="high">LOW</span>
              </div>
              <div className="map-color-legend map-color-na ml-5">
                <span className="">NA</span>
              </div>
              {!mediaQuery && <i className="icon icon-chevron-sm-down text-7 ml-5" />}
            </div>
            <div className="toggle-element -dropdown dropdown-menu dropdown-top">
              <div className="text-12 y-gap-20 js-dropdown-list">
                {PROFITABLE_SCHEMES.map((option, index) => (
                  <div key={index}>
                    <button
                      className={`d-block js-dropdown-link ${
                        userConfig.profitableColor === option.id ? "text-blue-1 " : ""
                      }`}
                      onClick={() => {
                        setProfitableColorScheme(option.id);

                        trackEvent(`map_change_color_scheme_${option.id}`);
                      }}
                      data-tooltip-id="map-tooltip"
                      data-tooltip-html={`
                        <div class="map-tooltip-content">
                        ${PROFITABLE_SCHEMES_MAP[userConfig.profitableColor].hint}
                        </div>
                      `}
                      data-tooltip-variant="dark"
                      data-tooltip-place="bottom"
                    >
                      <span className={`map-marker-color-${option.texts.top}`}>{option.label}</span>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }

        {/* handle different types */}
        {allowModeSwitch
          && <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
            <div
              className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5 text-12 lh-12 text-blue-1"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
              aria-expanded="false"
              {...(
                mediaQuery
                  ? {}
                  : {
                    'data-tooltip-id': "map-tooltip",
                    'data-tooltip-html': `
                      <div class="map-tooltip-content">
                      <span class="fw-500">Display by Property Types</span>
                      </div>
                    `,
                    'data-tooltip-variant': "dark",
                    'data-tooltip-place': "bottom"
                  }
                )
              }
            >
              <span className="js-dropdown-title text-12">
                <span className="fw-600">{MAP_MODES.find(m => m.id === mode).label}</span>
                {!mediaQuery && <i className="icon icon-chevron-sm-down text-7 ml-5" />}
              </span>
            </div>
            <div className="toggle-element -dropdown dropdown-menu dropdown-top">
              <div className="text-12 y-gap-20 js-dropdown-list">
                {MAP_MODES.map((option, index) => (
                  <div key={index}>
                    <button
                      className={`d-block js-dropdown-link ${
                        mode === option.id ? "text-blue-1 " : ""
                      }`}
                      onClick={() => {
                        setMode(option.id);
                        trackEvent(`map_change_mode_${option.label}`);
                      }}
                      // data-tooltip-id="map-tooltip"
                      // data-tooltip-html={`
                      //   <div class="map-tooltip-content">
                      //   ${option.hint}
                      //   </div>
                      // `}
                      // data-tooltip-variant="dark"
                      // data-tooltip-place="bottom"
                    >
                      {option.label}
                    </button>
                  </div>
                ))}
                <div className=" mt-10 border-top-light">
                  <Checkbox
                    label="Show schools"
                    value={enableShowAmenity ? showSchoolMarkersInPage : showSchoolMarkers}
                    setValue={v => {
                      if (!enableShowAmenity) setShowSchoolMarkers(v);
                      else setShowSchoolMarkersInPage(v);
                      trackEvent(`map_toggle_school_${v ? 'enable' : 'disable'}`);
                    }}
                    textSize="12"
                    img="/img/general/icons/ic_edu.png"
                  />
                  <Checkbox
                    label="Show stations"
                    value={enableShowAmenity ? showStationMarkersInPage : showStationMarkers}
                    setValue={v => {
                      if (!enableShowAmenity) setShowStationMarkers(v);
                      else setShowStationMarkersInPage(v);
                      trackEvent(`map_toggle_station_${v ? 'enable' : 'disable'}`);
                    }}
                    textSize="12"
                    img="/img/general/ic_mrt.png"
                  />
                  <Checkbox
                    label="Show upcoming"
                    value={enableShowAmenity ? showUpcomingMarkersInPage : showUpcomingMarkers}
                    setValue={v => {
                      if (!enableShowAmenity) setShowUpcomingMarkers(v);
                      else setShowUpcomingMarkersInPage(v);
                      trackEvent(`map_toggle_upcoming_${v ? 'enable' : 'disable'}`);
                    }}
                    textSize="12"
                  />
                </div>
              </div>
            </div>
          </div>
        }

        {/* <div className="p-2 flex-grow-1"></div> */}

        <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
          <div
            className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5 text-12 lh-12 text-blue-1"
            data-bs-toggle="offcanvas"
            data-bs-target="#mapSidebar"
            onClick={() => {
              onSelectFilter({ type: 'filter' });
              trackEvent('map_filter_click');
            }}
            {...(
              mediaQuery
                ? {}
                : {
                  'data-tooltip-id': "map-tooltip",
                  'data-tooltip-html': `
                    <div class="map-tooltip-content">
                    <span class="fw-500">Filter Properties</span>
                    ${numFilters > 0
                      ? `<br /><span class="map-tooltip-hint">${numFilters} filter${numFilters > 1 ? 's' : ''} selected</span>`
                      : ''
                    }
                    </div>
                  `,
                  'data-tooltip-variant': "dark",
                  'data-tooltip-place': "bottom"
                }
            )}
          >
            <span className="js-dropdown-title map-btn-icon-2" style={{ height: '20px', width: '20px' }}>
              <ReactSVG
                src="/img/general/filter2.svg"
                beforeInjection={(svg) => {
                  svg.querySelectorAll('[fill]').forEach((element) => element.removeAttribute('fill'));
                }}
              />
            </span>
            {numFilters > 0
              && <span
                  className="rounded-100 bg-blue-1 text-white text-10 px-5 fw-600"
                >
                  {numFilters}
                </span>
            }
          </div>
        </div>

        {/* <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
          <div
            className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5 text-12 lh-12 text-blue-1"
            onClick={() => onSelectCompare()}
            {...(
              mediaQuery
                ? {}
                : {
                  'data-tooltip-id': "map-tooltip",
                  'data-tooltip-html': `
                    <div class="map-tooltip-content">
                    <span class="fw-500">Realsmart Compare</span>
                    </div>
                  `,
                  'data-tooltip-variant': "dark",
                  'data-tooltip-place': "bottom"
                }
            )}
          >
            <span className="js-dropdown-title map-btn-icon">
              <ReactSVG
                src="/img/general/compare.svg"
                beforeInjection={(svg) => {
                  svg.querySelectorAll('[fill]').forEach((element) => element.removeAttribute('fill'));
                }}
              />
            </span>
          </div>
        </div> */}

        <div className="p-2 flex-grow-1"></div>
      
      </div>
    </>
  );
};

export default MapButtons;
