import { trackEvent } from "@/utils/api";
import { NA } from "@/utils/convert";
import {
  ICON,
  getMarkerScaleByTx,
  getPropertyBgColor,
  getPropertyMarkerColor,
  getPropertyTextColor
} from "@/utils/map";
import { Marker } from "react-map-gl/maplibre";

const HdbMarker = ({
  idx,
  target,
  property,
  goToHdbBlock,
  onMouseEnter,
  onMouseLeave,
  matchMedia,
  unfadedProps,
  scheme
}) => {
  const data = property.properties;
  const markerScale = getMarkerScaleByTx((data.totalTx ?? 0), 0.5, 1.5, 10, 1000);
  const markerColor = getPropertyMarkerColor(scheme, data, 'profitable');

  // check if the marker on map is selected
  // any property within this marker selected will mean that this marker is selected
  // render the roof to indicate marker selected
  const isSelectedMarker = target?.id === data.postal;

  const markerOpacity = unfadedProps.size === 0 || unfadedProps.has(data.postal) ? 1 : 0.3;

  return (
    <Marker
      key={`propm-${idx}`}
      latitude={data.lat}
      longitude={data.lng}
      anchor="bottom"
      onClick={e => {
        e.originalEvent.stopPropagation();
        goToHdbBlock(data.postal);
        trackEvent(`hdb_marker_${data.postal}`);
      }}
      {...(matchMedia ? {
        offset: [0, 20]
      } : {})}
    >
      <div
        style={{
          opacity: markerOpacity,
          transform: `scale(${markerScale})`
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-bs-toggle="offcanvas"
        data-bs-target="#propSidebar"
        {...(matchMedia ? {} : {
          'data-tooltip-id': "prop-tooltip",
          'data-tooltip-html': matchMedia ? `<div class="map-tooltip-content"><span class="fw-500">${data.name}</span></div>` : `
            <div class="map-tooltip-content">
            <span class="fw-500">${data.name}</span>
            <div class="map-tooltip-l-content">
            Profitable %: <span class="${getPropertyTextColor(scheme, data, 'profitable')}">${data.profitable === null || data.profitable < 0 ? NA : `${data.profitable.toFixed(0)}%`}</span>
            <br />
            Total Transactions: ${data.totalTx?.toLocaleString()}
            <br />
            Types: ${data.flatTypes.join(', ')}
            </div>
            <span class="map-tooltip-hint">${isSelectedMarker ? 'Current property' : 'Click to view details'}</span>
            </div>
          `,
          'data-tooltip-variant': "dark",
          'data-tooltip-place': "bottom"
        })}
      >
        {isSelectedMarker && <div className={`prop-marker-roof ${getPropertyBgColor(scheme, data, 'profitable')}`}></div>}
        <svg
          height={60}
          viewBox="0 0 24 24"
          style={{
            cursor: 'pointer',
            fill: markerColor,
            stroke: 'none',
          }}
        >
          <path d={ICON} />
          <circle cx="12" cy="10" r="8" fill="#fff" />
          <image
            href="/img/general/icons/ic_hdb.png"
            x="8" 
            y="4"
            width="9"
            height="9"
          />
          <text
            x="12.5"
            y="15"
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize="3"
            fill="#000"
            fontWeight="500"
            fontFamily="'Arial Black', sans-serif"
          >
            HDB
          </text>
        </svg>
      </div>
    </Marker>
  );
};

export default HdbMarker;
