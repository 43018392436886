import { trackPageView } from "@/utils/api";
import { useEffect } from "react";

const Block1 = () => {
  useEffect(() => {
    trackPageView('about');
  }, []);

  return (
    <>
      <div className="col-lg-6">
        <section className="max-w-3xl mx-auto px-4 py-12 text-base leading-relaxed about-t">
          <h1 className="text-26 fw-600">Why Realsmart Exists</h1>
          <br />
          <p>
          Buying property is one of life’s biggest decisions — and getting it wrong can cost you your life savings.
          <br />
          <br />
          If you’re buying, you’re the one emptying your bank account for the purchase.
          <br />
          If you’re selling, you risk missing out on a fair price, maybe even an extra $100K.
          <br />
          <br />
          Instead of expecting agents or paid gurus with no skin in the game to give you the best advice, take control with data so you know exactly what your options are.
          </p>

          <br />
          <p>
          Too many people rely on:
          </p>

          <ul className="list-none mt-4 space-y-1">
            <li className="pl-20">🤐 Biased advice</li>
            <li className="pl-20">🤷‍♂️ Vague opinions</li>
            <li className="pl-20">🎲 Blind guesswork</li>
          </ul>

          <br />
          <p className="text-lg fw-600">
          Realsmart helps you cut through the noise and make smarter choices — based on data, not sales talk.
          </p>
          <br />
          <p className="text-lg">
          And the best part? It’s completely free.
          <br />
          Because everyone deserves access to the truth — not just insiders.
          </p>

          <br />
          <div className="border-top-light"></div>
          <br />
          <h1 className="text-26 fw-600">Who We Are</h1>
          <br />

          <p>
          We’re not agents. We’ve never worked in real estate. We’re a team of Big Tech engineers who’ve built software used by billions. We also invest in real estate.
          <br />
          <br />
          Now, we’re bringing our experience to everyone via Realsmart — making the industry Transparent, Data-driven and Fair.
          <br />
          <br />
          Like any engineer, we enjoy building things that make people’s lives better. We are not looking to earn any commissions or sell any courses.
          </p>

          <br />
          <div className=" border-top-light py-20"></div>
          <p className="">
            📩 Got a feature idea or want to say hi?
            <br />
            <a href="mailto:hello@realsmart.sg" className="post-btn text-blue-1 fw-600">
              Email us at hello@realsmart.sg
            </a>
            <br />
            <br />
          </p>

          <div className=" border-top-light py-20"></div>
          <h1 className="text-26 fw-600">Keep Realsmart Running</h1>

          <br />
          <h2 className="text-20 fw-600">Ship Impact with Us</h2>

          <br />
          <p>
          We’re on a mission to build the most powerful and user-friendly real estate platform in Singapore, and we want to do it with you.
          </p>
          <br />
          <p>
          We want to partner with driven, good-natured individuals for the following roles:
          </p>
          <ul className="list-none mt-4 space-y-1">
            <li className="pl-20">- Software Engineer</li>
            <li className="pl-20">- AI/Machine Learning Engineer</li>
            <li className="pl-20">- Data Analyst</li>
            <li className="pl-20">- Product Designer</li>
            <li className="pl-20">- Social Media Manager</li>
          </ul>

          <br />
          <p>
          Join forces with seasoned industry veterans and help shape something fresh, ambitious, and impactful.
          <br />
          Heads up: We’re a non-profit. That means your contribution might start off as volunteer work, but the experience, community, and impact are real.
          </p>

          <br />
          <br />
          <h2 className="text-20 fw-600">We Welcome Funding</h2>
          <br />
          <p>
          As a non-profit, we personally cover server and API costs. As site usage continues to grow, so do the expenses.
          <br />
          We pride ourselves on building lean, lightning-fast code, but every bit of support helps.
          <br />
          We wouldn’t say no to small, token donations. All proceeds will go on to keep our servers running.
          </p>

          <br />
          <p>
          <a href="mailto:hello@realsmart.sg" className="post-btn text-blue-1 fw-600">Email us at hello@realsmart.sg</a>, we would love to hear from you.
          </p>
          <br />
        </section>
      </div>

      <div className="col-lg-6">
        <img
          src="/img/general/skyline.png"
          alt="image"
          className="rounded-4 w-100 mt-10 mb-50"
        />
      </div>
    </>
  );
};

export default Block1;
