import { queryRef, executeQuery, mutationRef, executeMutation, validateArgs } from 'firebase/data-connect';

export const connectorConfig = {
  connector: 'default',
  service: 'Realsmart',
  location: 'asia-southeast1'
};

export function listPropertyLikesRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'ListPropertyLikes', inputVars);
}

export function listPropertyLikes(dcOrVars, vars) {
  return executeQuery(listPropertyLikesRef(dcOrVars, vars));
}

export function getPropertyLikeByIdRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'GetPropertyLikeById', inputVars);
}

export function getPropertyLikeById(dcOrVars, vars) {
  return executeQuery(getPropertyLikeByIdRef(dcOrVars, vars));
}

export function getPostRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'GetPost', inputVars);
}

export function getPost(dcOrVars, vars) {
  return executeQuery(getPostRef(dcOrVars, vars));
}

export function getCommentsRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'GetComments', inputVars);
}

export function getComments(dcOrVars, vars) {
  return executeQuery(getCommentsRef(dcOrVars, vars));
}

export function getPublicFeedsRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'GetPublicFeeds', inputVars);
}

export function getPublicFeeds(dcOrVars, vars) {
  return executeQuery(getPublicFeedsRef(dcOrVars, vars));
}

export function getLatestPublicFeedsRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'GetLatestPublicFeeds', inputVars);
}

export function getLatestPublicFeeds(dcOrVars, vars) {
  return executeQuery(getLatestPublicFeedsRef(dcOrVars, vars));
}

export function getNewsfeedRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'GetNewsfeed', inputVars);
}

export function getNewsfeed(dcOrVars, vars) {
  return executeQuery(getNewsfeedRef(dcOrVars, vars));
}

export function getUserPostsRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'GetUserPosts', inputVars);
}

export function getUserPosts(dcOrVars, vars) {
  return executeQuery(getUserPostsRef(dcOrVars, vars));
}

export function getLikedPostsRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'GetLikedPosts', inputVars);
}

export function getLikedPosts(dcOrVars, vars) {
  return executeQuery(getLikedPostsRef(dcOrVars, vars));
}

export function getFollowsRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'GetFollows', inputVars);
}

export function getFollows(dcOrVars, vars) {
  return executeQuery(getFollowsRef(dcOrVars, vars));
}

export function getFollowersRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'GetFollowers', inputVars);
}

export function getFollowers(dcOrVars, vars) {
  return executeQuery(getFollowersRef(dcOrVars, vars));
}

export function getUnreadNotificationsRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'GetUnreadNotifications', inputVars);
}

export function getUnreadNotifications(dcOrVars, vars) {
  return executeQuery(getUnreadNotificationsRef(dcOrVars, vars));
}

export function getAllNotificationsRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  dcInstance._useGeneratedSdk();
  return queryRef(dcInstance, 'GetAllNotifications', inputVars);
}

export function getAllNotifications(dcOrVars, vars) {
  return executeQuery(getAllNotificationsRef(dcOrVars, vars));
}

export function createPropertyLikesRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  dcInstance._useGeneratedSdk();
  return mutationRef(dcInstance, 'CreatePropertyLikes', inputVars);
}

export function createPropertyLikes(dcOrVars, vars) {
  return executeMutation(createPropertyLikesRef(dcOrVars, vars));
}

export function deletePropertyLikesRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  dcInstance._useGeneratedSdk();
  return mutationRef(dcInstance, 'DeletePropertyLikes', inputVars);
}

export function deletePropertyLikes(dcOrVars, vars) {
  return executeMutation(deletePropertyLikesRef(dcOrVars, vars));
}

