import { ReactSVG } from "react-svg";
import {
  PROFITABLE_SCHEMES_MAP,
  getProfitableTextClass
} from "@/utils/map";
import { checkMediaQuery } from "@/utils/user";
import { trackEvent } from "@/utils/api";


const MOBILE_GESTURE_LIST = [
  {
    img: '/img/general/tutorial/g01.png',
    tips: 'Touch and drag to pan the map'
  },
  {
    img: '/img/general/tutorial/g02.png',
    tips: 'Spread to zoom in'
  },
  {
    img: '/img/general/tutorial/g03.png',
    tips: 'Pinch to zoom out'
  },
  {
    img: '/img/general/tutorial/g04.png',
    tips: 'Circular rotation with 2 fingers to rotate map'
  },
  {
    img: '/img/general/tutorial/g05.png',
    tips: 'Slide up/down with 2 fingers to pitch'
  },
];

const MOUSE_GESTURE_LIST = [
  {
    img: '/img/general/tutorial/d01.png',
    tips: 'Left click and drag to pan the map'
  },
  {
    img: '/img/general/tutorial/d02.png',
    tips: 'Scroll up/down to zoom'
  },
  {
    img: '/img/general/tutorial/d03.png',
    tips: 'Right click and slide left/right to rotate'
  },
  {
    img: '/img/general/tutorial/d04.png',
    tips: 'Right click and slide up/down to pitch'
  },
];


const MapSideButtons = ({
  userConfig,
  gpsLocation,
  getUserGpsLocation,
  pitch,
  setPitch,
  bearing,
  setBearing,
  blgOpacity,
  setBlgOpacity
}) => {
  const LEGEND = [
    { label: 'Landed Property', img: '/img/general/house.png', hint: 'Detached House, Semi-Detached, Terrace House' },
    { label: 'Apartment/Condo', img: '/img/general/flat.png', hint: 'Apartment, Condominium' },
    { label: 'Executive Condo', img: '/img/general/condo.png', hint: 'Executive Condominium' },
    { label: 'Condo & Landed', img: '/img/general/mixed.png', hint: 'Mixed landed & non-landed property types' },
    { label: 'New/Upcoming', img: `/img/general/${PROFITABLE_SCHEMES_MAP[userConfig.profitableColor].icons.upcoming}.png`, hint: 'New or upcoming properties' },
    { label: 'Cluster', img: `/img/general/${PROFITABLE_SCHEMES_MAP[userConfig.profitableColor].icons.top}.png`, hint: 'Cluster of properties which has more than 90% profitability on average', sublabel: '>90%', value: 100 },
    { label: 'Cluster', img: `/img/general/${PROFITABLE_SCHEMES_MAP[userConfig.profitableColor].icons.high}.png`, hint: 'Cluster of properties which has more than 70% profitability on average', sublabel: '>70%', value: 80 },
    { label: 'Cluster', img: `/img/general/${PROFITABLE_SCHEMES_MAP[userConfig.profitableColor].icons.mid}.png`, hint: 'Cluster of properties which has more than 50% profitability on average', sublabel: '>50%', value: 60 },
    { label: 'Cluster', img: `/img/general/${PROFITABLE_SCHEMES_MAP[userConfig.profitableColor].icons.low}.png`, hint: 'Cluster of properties which has less than 50% profitability on average', sublabel: '<50%', value: 0 },
    { label: 'Cluster', img: `/img/general/${PROFITABLE_SCHEMES_MAP[userConfig.profitableColor].icons.none}.png`, hint: 'Cluster of properties which has no profitability data', sublabel: 'No Data', value: -1 },
    { label: 'School', img: '/img/general/school.png', hint: 'Preschool, Primary, Secondary, Tertiary School' },
    { label: 'MRT', img: '/img/general/ic_mrt.png', hint: 'MRT Station, Station Exits' },
    { label: 'LRT', img: '/img/general/ic_lrt.png', hint: 'LRT Station' },
  ];

  // const [configPitch, setConfigPitch] = useState(pitch);

  const mediaQuery = checkMediaQuery();

  return (
    <>
      <div className="d-flex flex-column ml-5 noselect">
        <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad-sm">
          <div
            className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-5 py-5 text-12 lh-12"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,10"
            {...(
              mediaQuery
                ? {}
                : {
                  'data-tooltip-id': "map-tooltip",
                  'data-tooltip-html': `
                    <div class="map-tooltip-content">
                    <span class="fw-500">Map Legend</span>
                    </div>
                  `,
                  'data-tooltip-variant': "dark",
                  'data-tooltip-place': "bottom"
                }
            )}
            onClick={() => {
              trackEvent('map_legend_click');
            }}
          >
            <span className="js-dropdown-map map-btn-icon" style={{ height: '20px', width: '20px' }}>
              <ReactSVG
                src="/img/general/help.svg"
                beforeInjection={(svg) => {
                  svg.querySelectorAll('[fill]').forEach((element) => element.removeAttribute('fill'));
                }}
              />
            </span>
          </div>
          <div className="toggle-element -dropdown dropdown-menu legend-menu">
            <div className="text-12 y-gap-5 js-dropdown-list">
              <div className="fw-600 text-blue-1">Map Icons</div>
              {LEGEND.map((item, i) =>
                <div
                  key={i}
                  className="legend-row"
                  data-tooltip-id="map-tooltip"
                  data-tooltip-html={`
                    <div class="map-tooltip-content">
                    <span class="fw-500">${item.hint}</span>
                    </div>
                  `}
                  data-tooltip-variant="dark"
                  data-tooltip-place="bottom"
                >
                  <img src={item.img} alt={item.label} className="legend-img" />
                  <span>{item.label}{item.sublabel ? <span className={getProfitableTextClass(userConfig.profitableColor, item.value)}>{` (${item.sublabel})`}</span> : ''}</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad-sm">
          <div
            className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-5 py-5 text-12 lh-12"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,10"
            {...(
              mediaQuery
                ? {}
                : {
                  'data-tooltip-id': "map-tooltip",
                  'data-tooltip-html': `
                    <div class="map-tooltip-content">
                    <span class="fw-500">Map Pitch</span>
                    <br />
                    Adjust pitch to see 3D map
                    <br />
                    Zoom in with pitch for 3D buildings
                    </div>
                  `,
                  'data-tooltip-variant': "dark",
                  'data-tooltip-place': "bottom"
                }
            )}
            onClick={() => {
              trackEvent('map_3d_click');
            }}
          >
            <span className="js-dropdown-map map-btn-icon text-14 fw-700 text-blue-1">
              3D
            </span>
          </div>
          <div className="toggle-element -dropdown dropdown-menu legend-menu">
            <div className="text-12 y-gap-5 js-dropdown-list">
              <div
                className="button -dark-1 bg-blue-1 text-white text-12 px-10 py-10 mb-10 rounded-100"
                data-bs-toggle="offcanvas"
                data-bs-target="#mobileTutorialPanel"
                onClick={() => {
                  trackEvent(`map_learn_gesture_${mediaQuery ? 'mobile' : 'desktop'}`);
                }}
              >
                Learn gestures
              </div>
              <div className="fw-600 text-blue-1">Set map pitch ({pitch.toFixed(0)}°)</div>
              <input
                type="range"
                min={0}
                max={45}
                value={pitch}
                step={5}
                style={{ width: '100%' }}
                onChange={e => setPitch(parseFloat(e.target.value))}
              />
              <div className="fw-600 text-blue-1">Set map bearing ({bearing.toFixed(0)}°)</div>
              <input
                type="range"
                min={0}
                max={360}
                value={bearing}
                step={1}
                style={{ width: '100%' }}
                onChange={e => setBearing(parseFloat(e.target.value))}
              />
              <div className="fw-600 text-blue-1">Building opacity ({blgOpacity * 100}%)</div>
              <input
                type="range"
                min={0}
                max={1}
                value={blgOpacity}
                step={0.1}
                style={{ width: '100%' }}
                onChange={e => setBlgOpacity(parseFloat(e.target.value))}
              />
              <button
                className="button mr-10 mb-5 -dark-1 bg-blue-1 text-white h-30 px-10 rounded-100 text-12 mt-10"
                onClick={() => {
                  setPitch(0);
                  setBearing(0);
                  setBlgOpacity(0.6);
                }}
              >
                Reset
              </button>
              {/* <div className="row px-5">
                <div className="col-6 px-5">
                  <button
                    className="button mr-10 mb-5 -dark-1 bg-blue-1 text-white h-30 px-10 rounded-100 text-12 mt-10"
                    onClick={() => setPitch(0)}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 px-5">
                  <button
                    className="button mr-10 mb-5 -dark-1 bg-blue-1 text-white h-30 px-10 rounded-100 text-12 mt-10"
                    onClick={() => setPitch(configPitch)}
                  >
                    Apply
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        {'geolocation' in navigator && gpsLocation && !gpsLocation?.err
          && <button
            className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad-sm"
            {...(
              mediaQuery
                ? {}
                : {
                  'data-tooltip-id': "map-tooltip",
                  'data-tooltip-html': `
                    <div class="map-tooltip-content">
                    <span class="fw-500">My GPS Location</span>
                    <br />
                    <span class="map-tooltip-hint">Zoom in to my current location on map</span>
                    </div>
                  `,
                  'data-tooltip-variant': "dark",
                  'data-tooltip-place': "bottom"
                }
            )}
            onClick={() => {
              getUserGpsLocation();

              trackEvent('map_gps_click');
            }}
          >
            <div className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-5 py-5 text-12 lh-12">
              <span className="js-dropdown-map map-btn-icon" style={{ height: '20px', width: '20px' }}>
                <ReactSVG
                  src="/img/general/gps.svg"
                  beforeInjection={(svg) => {
                    svg.querySelectorAll('[fill]').forEach((element) => element.removeAttribute('fill'));
                  }}
                />
              </span>
            </div>
          </button>
        }
      </div>

      {/* Panel */}
      <div
        className="offcanvas offcanvas-end filter-bar-nopad noselect"
        tabIndex="-1"
        id="mobileTutorialPanel"
        onTouchMove={e => {
          e.stopPropagation();
        }}
        onWheel={e => {
          e.stopPropagation();
        }}
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title text-16" id="offcanvasLabel">
            Gestures Tutorial
          </h5>
          <button
            id="bot-close-button"
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body watermark noselect fab-min-pad">
          <aside className="sidebar xl:d-block fab-content">
            {mediaQuery
              && MOBILE_GESTURE_LIST.map((g, i) => (
                <div className="d-flex align-items-center px-10" key={`g_${i}`}>
                  <img className="p-2" src={g.img} />
                  <span className="text-14">{g.tips}</span>
                </div>
              ))
            }
            {!mediaQuery
              && MOUSE_GESTURE_LIST.map((g, i) => (
                <div className="d-flex align-items-center px-10" key={`g_${i}`}>
                  <img className="p-2" src={g.img} />
                  <span className="text-14">{g.tips}</span>
                </div>
              ))
            }
          </aside>
        </div>

      </div>
    </>
  );
};

export default MapSideButtons;
