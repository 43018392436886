import { useCallback, useEffect, useState } from "react";
import ScrollToTop from "@/components/project/ScrollToTop";
import {
  checkMediaQuery
} from "@/utils/user";
import TabButtons from "./property/TabButtons";
import {
  getFile,
  trackEvent
} from "@/utils/api";
import {
  MAP_MODE_HDB,
  PROPERTY_DATA_FILE_PREFIX,
  censorUnit,
  decompressPropertyData,
  getGcsDir,
  getTargetFilename
} from "@/utils/map";
import Loader from "@/components/common/Loader";
import OverviewTab from "./property/OverviewTab";
import { NA } from "@/utils/convert";
import {
  convertDisplayDateMsec,
  formatShortDate
} from "@/utils/time";
import AnnotatedChart from "./chart/AnnotatedChart";
import NearbyTab from "./property/NearbyTab";
import CompareTab from "./property/CompareTab";
import TrendTab from "./property/TrendTab";
import useScreenOrientation from "@/utils/useScreenOrientation";

const TABS = [
  { id: 'overview', label: 'Overview' },
  { id: 'trend', label: 'Transactions' },
  { id: 'compare', label: 'Comparables' },
  { id: 'nearby', label: 'Nearby' },
];

const PropertyView = ({
  user,
  session,
  target,
  isMinimized,
  isMaximized,
  handleExpand,
  handleMaximize,
  closePropertyDetails,
  goToProperty,
  goToHdbBlock,
  goToEstate,
  goToSchool,
  goToStation,
  goToMarker,
  screenDim,
  focus,
  setFocus,
  setFocusOn,
  compareList,
  setCompareList,
  addToCompareList,
  viewComparePro,
  getProjectLabel,
  userConfig,
  lightboxImages,
  setLightboxImages,
  onLikeProperty,
  likedProps,
  onSwitchPropertyTab,
  onCompareSearch,
  unfadedProps,
  setUnfadedProps,
  schoolsData,
  gpsLocation
}) => {
  const orientation = useScreenOrientation();

  const [selectedTab, setSelectedTab] = useState(TABS[0].id);
  const [selectedSubtab, setSelectedSubtab] = useState(null);

  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState(null);

  const [cellDetailsLoading, setCellDetailsLoading] = useState(false);
  const [cellDetails, setCellDetails] = useState(null);
  const [defaultDisplayBlock, setDefaultDisplayBlock] = useState(null);

  const loadPropertyData = () => {
    setErr(null);
    setLoading(true);

    getFile(getGcsDir(target.mode), getTargetFilename(PROPERTY_DATA_FILE_PREFIX, target), txt => {
      const data = decompressPropertyData(target.mode, txt);
      setData(data);
      setLoading(false);
    }, err => {
      setLoading(false);
      setErr('Failed to load property');
    });
  };

  useEffect(() => {
    setData(null);
    setSelectedTab('overview');
    onSwitchTab('overview');
    setUnfadedProps(new Set());
    loadPropertyData();
  }, [target]);

  const onPreSwitchTab = (tab) => {
    // clear unfaded
    setUnfadedProps(new Set());
  };

  const onSwitchTab = (tab, subtab) => {
    setTimeout(() => {
      document.getElementById('project-details-modal')?.scrollTo({ top: 0, behavior: 'smooth' });
    }, 200);
  
    // reset subtab if not specified
    setSelectedSubtab(subtab);

    // callback when switch tab
    onSwitchPropertyTab?.();

    // refocus to property
    if (focus?.target?.id !== target?.id) {
      setFocus({ target });
    }

    if (subtab) {
      trackEvent(`property_tab_${tab}_${subtab}`);
    } else {
      trackEvent(`property_tab_${tab}`);
    }
  };

  const closeSideBar = () => {
    if (document.getElementById('listingDetails').classList.contains('show')) {
      document.getElementById('details-close-button').click();
    }
  };

  const mediaMatches = checkMediaQuery();

  const isHdb = target.mode === MAP_MODE_HDB;

  const hasData = isHdb
    ? data?.brief && data.brief.postal === target.postal
    : data?.brief && data.brief.projectId === target.projectId;

  useEffect(() => {
    loadPropertyData();
    trackEvent(`${target.mode === MAP_MODE_HDB ? 'hdb' : 'private'}_property_view_${target.id}`);
  }, []);

  const escFunc = useCallback((event) => {
    if (event.key === "Escape" && lightboxImages === null) {
      closePropertyDetails();
    }
  }, [lightboxImages]);

  useEffect(() => {
    document.addEventListener("keydown", escFunc, false);

    return () => {
      document.removeEventListener("keydown", escFunc, false);
    };
  }, [escFunc]);

  return (
    <>
      {!isMinimized && (
        <div className={`modal-container ${
          isMaximized ? `modal-full${orientation === 'landscape' ? '-m' : ''} modal-container-fixed` : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-center text-10 text-light-1">
              {/* <div className="p-2 py-0"></div> */}
              <div
                className={`p-2 pt-5 pb-0 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                } mt-0 mb-0`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down px-5 expand-btn-border" />
              </div>
            </div>
          }

          <div className="px-10 mt-10">
            <TabButtons
              isMaximized={isMaximized}
              tabs={TABS}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              onPreSwitchTab={onPreSwitchTab}
              onSwitchTab={onSwitchTab}
              session={session}
              markerName={target.id}
              projectName={target.project}
              closePropertyDetails={closePropertyDetails}
            />
          </div>

          {err
            && <div className="modal-body watermark d-flex">
              {!mediaMatches
                && <div
                  className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                  onClick={() => isMaximized ? handleExpand() : handleMaximize()}
                >
                  <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                    <i className="icon-chevron-sm-down px-5 expand-btn-border" />
                  </div>
                </div>
              }

              <div
                id="project-details-modal"
                className={`p-2 modal-scoll-content text-center fw-500 py-40 ${
                  isMaximized ? 'modal-scroll-media-full' : (mediaMatches ? 'modal-scroll-media' : '')
                }`}
              >
                Failed to get data, please try again later
              </div>
            </div>
          }

          {!err
            && <div
              className={`modal-body ${
                mediaMatches && !isMaximized
                  ? 'modal-body-prop-m'
                  : 'modal-body-prop'
              } watermark d-flex`}
            >
              {!mediaMatches
                && <div
                  className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                  onClick={() => isMaximized ? handleExpand() : handleMaximize()}
                >
                  <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                    <i className="icon-chevron-sm-down px-5 expand-btn-border" />
                  </div>
                </div>
              }

              <div
                id="project-details-modal"
                className={`p-2 modal-scoll-content ${
                  isMaximized ? 'modal-scroll-media-full' : (mediaMatches ? 'modal-scroll-media' : '')
                }`}
              >

                <ScrollToTop />

                {(loading || !hasData)
                  && <div className="loader-container">
                    <Loader />
                  </div>
                }

                {/* detail page tabs */}
                {hasData && selectedTab === 'overview'
                  && <OverviewTab
                    user={user} data={data.brief} marker={target.markerData} isMaximized={isMaximized} screenDim={screenDim}
                    compareList={compareList} setCompareList={setCompareList} addToCompareList={addToCompareList}
                    goToProperty={goToProperty} goToEstate={goToEstate} target={target} userConfig={userConfig}
                    setLightboxImages={setLightboxImages} onLikeProperty={onLikeProperty} likedProps={likedProps}
                    gpsLocation={gpsLocation} badges={data.badges}
                  />
                }

                {hasData && selectedTab === 'trend'
                  && <TrendTab user={user} data={data} isMaximized={isMaximized} screenDim={screenDim}
                    session={session} target={target} goToUnit={(unit) => setCellDetails({ ...unit, showButton: true })}
                    setCellDetails={setCellDetails} setCellDetailsLoading={setCellDetailsLoading}
                    defaultBlock={defaultDisplayBlock} selectedSubtab={selectedSubtab} nearbyTxs={data.nearbyTxs}
                    goToProperty={goToProperty} goToHdbBlock={goToHdbBlock} focus={focus} setFocus={setFocus} setUnfadedProps={setUnfadedProps}
                  />
                }

                {hasData && selectedTab === 'nearby'
                  && <NearbyTab
                    user={user} data={data} nearbyTxs={data.nearbyTxs}
                    isMaximized={isMaximized} goToProperty={goToProperty} goToSchool={goToSchool} goToStation={goToStation} goToHdbBlock={goToHdbBlock}
                    focus={focus} setFocus={setFocus} target={target} session={session} handleExpand={handleExpand}
                    unfadedProps={unfadedProps} setUnfadedProps={setUnfadedProps} schoolsData={schoolsData} goToMarker={goToMarker}
                  />
                }

                {hasData && selectedTab === 'compare'
                  && <CompareTab
                    user={user} data={data} isMaximized={isMaximized} goToProperty={goToProperty} screenDim={screenDim}
                    compareList={compareList} setCompareList={setCompareList} setFocusOn={setFocusOn}
                    viewComparePro={viewComparePro} target={target} getProjectLabel={getProjectLabel}
                    onCompareSearch={onCompareSearch} unfadedProps={unfadedProps} setUnfadedProps={setUnfadedProps}
                    setFocus={setFocus}
                  />
                }

                <div
                  className="offcanvas offcanvas-end filter-bar-nopad"
                  tabIndex="-1"
                  id="listingDetails"
                >
                  <div className={`offcanvas-header block-list-panel ${isMaximized ? 'block-list-max' : ''}`}>
                    <h5 className="offcanvas-title text-16 py-5" id="offcanvasLabel">
                      {
                        `${cellDetails?.mode === MAP_MODE_HDB
                          ? 'BLK '
                          : '' }${cellDetails?.displayBlock ?? ''}`
                      } {
                        cellDetails
                          ? (
                            isHdb
                              ? `(${cellDetails.unit} STOREY ${cellDetails.tx[0].storeyRange})`
                              : (cellDetails.unit !== NA ? censorUnit(cellDetails.unit) : '')
                          )
                          : ''
                      }
                    </h5>
                    <button
                      id="details-close-button"
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>

                  {cellDetails?.showButton
                    && <button
                      className="button -dark-1 bg-blue-1 text-white h-30 px-5 rounded-100 text-12 ml-10 mr-10 mt-5 mb-5"
                      onClick={() => {
                        setDefaultDisplayBlock(cellDetails.displayBlock);
                        setSelectedTab('trend');
                        onSwitchTab('trend', 'units');
                        closeSideBar();
                      }}
                    >
                      Go to Stack View
                    </button>
                  }

                  <div className="offcanvas-body watermark block-list-end">
                    <aside className="sidebar y-gap-20 xl:d-block pl-10 pb-20">
                      {cellDetailsLoading
                        && <div className="loader-container">
                          <Loader />
                        </div>
                      }
                      {!cellDetailsLoading && cellDetails
                        && <>
                          <h2 className="text-15 lh-1 fw-500">Price Trend ($)</h2>
                          <AnnotatedChart
                            label="Price Trend ($)"
                            data={
                              cellDetails.tx.map(row => ({
                                x: row['ts'],
                                y: row.price,
                              }))
                            }
                            tooltipTitle="Price ($)"
                            tooltipLabelCallback={
                              (context) => {
                                const date = new Date(context.raw.x);
                                const formattedDate = formatShortDate(date);
                                return `${formattedDate}: $${context.raw.y.toLocaleString()}`;
                              }
                            }
                            yAxisLabel="Price ($)"
                          />

                          <div className="pt-10"></div>

                          {cellDetails.gains.filter(g => g !== null).length > 0 && <>
                            <h2 className="text-15 lh-1 fw-500">Annualized Profit (%)</h2>
                            <AnnotatedChart
                              label="Annualized Profit (%)"
                              data={
                                cellDetails.gains.filter(g => g !== null).map(row => ({
                                  x: row['ts'],
                                  y: row['gain'],
                                }))
                              }
                              tooltipTitle="Annualized Profit (%)"
                              tooltipLabelCallback={
                                (context) => {
                                  const date = new Date(context.raw.x);
                                  const formattedDate = formatShortDate(date);
                                  return `${formattedDate}: ${context.raw.y.toFixed(2)}%`;
                                }
                              }
                              yAxisLabel="Annualized Profit (%)"
                            />
                          </>}

                          {cellDetails.tx.length > 0 
                            && <h2 className="text-15 lh-1 fw-500">Transaction History</h2>
                          }

                          {cellDetails.tx
                              .sort((a, b) => b['ts'] - a['ts'])
                              .map((row, i) => (
                            <div
                              key={`tx_${i}`}
                              className="px-10 py-5 bg-blue-2 text-12 text-blue-1 mr-20 mt-10 text-10"
                            >
                              <span className="text-14 fw-500">
                                {convertDisplayDateMsec(row['ts'])}
                              </span>
                              <br />
                              <span className="">
                                Type of Sale: {row.type}
                              </span>
                              <br />
                              <span className="">
                                Price: ${row.price.toLocaleString()}
                              </span>
                              <br />
                              <span className="">
                                Price per area: ${row.unitPrice.toLocaleString()} psf
                              </span>
                              {cellDetails.gains.length > 0 && cellDetails.gains[i] !== null
                                && <>
                                  <br />
                                  <span className="">
                                    Annualized Profit (%): {cellDetails.gains[i].gain.toFixed(2)}%
                                  </span>
                                </>
                              }
                            </div>
                          ))}
                        </>
                      }
                    </aside>
                  </div>
                </div>

              </div>
            </div>
          }

        </div>
      )}
    </>
  );
};

export default PropertyView;
