import { useState } from "react";

import Accordion from "@/components/common/sidebar/Accordion";
import CheckboxFilter from "@/components/common/sidebar/CheckboxFilter";
import RangeInput from "@/components/common/sidebar/RangeInput";
import PeriodSearch from "@/components/common/sidebar/PeriodSearch";
import LocationSelector from "@/components/common/sidebar/LocationSelector";
import PlanningAreaFilter from "@/components/common/sidebar/PlanningAreaFilter";
import DistrictFilter from "@/components/common/sidebar/DistrictFilter";
import RegionFilter from "@/components/common/sidebar/RegionFilter";
import RangeSlider from "@/components/common/sidebar/RangeSlider";
import Checkbox from "@/components/common/sidebar/Checkbox";

import {
  FIELD_AREAS,
  FIELD_DISTRICTS,
  FIELD_REGIONS,
  FILTER_TYPE_CHECKBOXES,
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_LOCATION,
  FILTER_TYPE_MULTI_RANGE,
  FILTER_TYPE_RANGE,
  FILTER_TYPE_SINGLE,
  FILTER_TYPE_SLIDER,
  hasFilterSelected,
} from "@/utils/filter";
import SingleSlider from "./sidebar/SingleSlider";


const Sidebar = (props) => {
  const [locationTypeSelect, setLocationTypeSelect] = useState(0);
  const {
    resetLocations,
    filters,
    setFilters,
    options,
  } = props;

  const onLocationTypeChange = (type) => {
    setLocationTypeSelect(type);
    resetLocations?.();
  };

  const setFilterValue = (field, value) => setFilters({
    ...filters,
    [field]: value,
  });

  const setFilterValues = (values) => setFilters({
    ...filters,
    ...values,
  });

  return (
    <>
      {options.map((option, idx) => (
        <div
          className={idx === options.length - 1 ? 'mb-50' : ''}
          key={idx}
        >
          {option.type === FILTER_TYPE_DATE_RANGE
            && <div className={`sidebar__item -no-border px-10 ${idx === 0 ? '' : 'border-top-light'}`}>
              <h5 className="text-16 fw-500 mt-10 mb-10">{option.title ?? 'Search By Period'}</h5>
              <PeriodSearch
                startDate={filters[option.field[0]]}
                endDate={filters[option.field[1]]}
                fields={option.field}
                setDate={d => setFilterValues(d)}
                minDate={option.min}
                yearOnly={!!option.year}
              />
            </div>
          }

          {option.type === FILTER_TYPE_CHECKBOXES
            && <div className="sidebar__item">
              <Accordion title={option.label} show={option.show || hasFilterSelected(option, filters)}>
                <div className="sidebar-checkbox">
                  <div className="row y-gap-5 items-center">
                    <CheckboxFilter
                      col={option.col}
                      options={option.options}
                      values={filters[option.field]}
                      setValues={v => setFilterValue(option.field, v)}
                    />
                  </div>
                </div>
              </Accordion>
            </div>
          }

          {option.type === FILTER_TYPE_RANGE
            && <div className="sidebar__item">
              <Accordion title={option.label} show={!option.hide || hasFilterSelected(option, filters)}>
                <div className="row x-gap-10 y-gap-10 pt-10">
                  <RangeSlider
                    value={filters[option.field]}
                    setValue={v => setFilterValue(option.field, v)}
                    minValue={option.minBound ?? option.min}
                    maxValue={option.maxBound ?? option.max}
                    unit={option.unit}
                    pluralize={option.pluralize}
                    dollarize={option.unit === 'psf'}
                    step={option.step}
                    prefix={!!option.prefix}
                    snapToStepMin={option.snapToStepMin}
                    minify={option.minify}
                    discreteMax={option.discreteMax}
                    allowSameValues={!!option.allowSameValues}
                    hasLimit={option.hasLimit}
                    decimalPlace={option.decimalPlace}
                  />
                </div>
              </Accordion>
            </div>
          }

          {option.type === FILTER_TYPE_SLIDER
            && <div className="sidebar__item">
              <Accordion title={option.label} show={!option.hide || hasFilterSelected(option, filters)}>
                <div className="row x-gap-10 y-gap-10 pt-10">
                  <SingleSlider
                    value={filters[option.field]}
                    setValue={v => setFilterValue(option.field, v)}
                    minValue={option.minBound ?? option.min}
                    maxValue={option.maxBound ?? option.max}
                    unit={option.unit}
                    step={option.step}
                    hasLimit={option.hasLimit}
                    decimalPlace={option.decimalPlace}
                  />
                </div>
              </Accordion>
            </div>
          }

          {option.type === FILTER_TYPE_SINGLE
            && <div className="sidebar__item">
              <Accordion title={option.label} show={option.show || hasFilterSelected(option, filters)}>
                <div className="row x-gap-10 y-gap-10 pt-10">
                  <Checkbox
                    label={option.option}
                    value={filters[option.field]}
                    setValue={v => setFilterValue(option.field, v)}
                  />
                </div>
              </Accordion>
            </div>
          }

          {option.type === FILTER_TYPE_MULTI_RANGE
            && <div className="sidebar__item">
              <Accordion title={option.label} show={option.show || hasFilterSelected(option, filters)}>
                <div className="row x-gap-10 y-gap-30">
                  <div className="col-12">
                    <RangeInput
                      min={filters[option.field[0]]}
                      setMin={v => setFilterValue(option.field[0], v)}
                      max={filters[option.field[1]]}
                      setMax={v => setFilterValue(option.field[1], v)}
                    />
                  </div>
                </div>
              </Accordion>
            </div>
          }

          {option.type === FILTER_TYPE_LOCATION
            && <div className="sidebar__item">
              <Accordion title="Location" show={option.show || hasFilterSelected(option, filters)}>
                {!option.town
                  && <>
                    <div className="row x-gap-10 y-gap-10 pt-10">
                      <LocationSelector
                        onChange={onLocationTypeChange}
                      />
                    </div>
                    <br />
                  </>
                }
                {locationTypeSelect === 0
                  && <div className="sidebar-checkbox px-10">
                    <PlanningAreaFilter
                      isHdb={!!option.hdb}
                      selection={filters[FIELD_AREAS]}
                      setSelection={v => setFilterValues({
                        [FIELD_AREAS]: v,
                        [FIELD_DISTRICTS]: {},
                        [FIELD_REGIONS]: {}
                      })}
                    />
                  </div>
                }
                {locationTypeSelect === 1 && !option.town
                  && <div className="sidebar-checkbox px-10">
                    <DistrictFilter
                      selection={filters[FIELD_DISTRICTS]}
                      setSelection={v => setFilterValues({
                        [FIELD_AREAS]: {},
                        [FIELD_DISTRICTS]: v,
                        [FIELD_REGIONS]: {}
                      })}
                    />
                  </div>
                }
                {locationTypeSelect === 2 && !option.town
                  && <div className="sidebar-checkbox px-10">
                    <RegionFilter
                      selection={filters[FIELD_REGIONS]}
                      setSelection={v => setFilterValues({
                        [FIELD_AREAS]: {},
                        [FIELD_DISTRICTS]: {},
                        [FIELD_REGIONS]: v
                      })}
                    />
                  </div>
                }
              </Accordion>
            </div>
          }
        </div>
      ))}
    </>
  );
};

export default Sidebar;
