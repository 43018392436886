import { useCallback, useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { v4 as uuidv4 } from 'uuid';
import {
  checkMediaQuery,
  getUserClaims,
  isAdminTier,
  isPremium
} from "@/utils/user";
import EditPostTag from "./EditPostTag";
import {
  createPost,
  editPost,
  getPostImgUrl,
  uploadFiles
} from "@/utils/api";
import EditPostProperty from "./EditPostProperty";
import Checkbox from "../common/sidebar/Checkbox";

const EditPost = ({
  user,
  content,
  onClose,
  showSuccess
}) => {
  const textRef = useRef(null);

  const hashCode = (s) => {
    var hash = 0, i, chr;
    if (s.length === 0) return hash;
    for (i = 0; i < s.length; i++) {
      chr = s.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0;
    }
    return hash.toString();
  };

  const isEdit = !!content?.id;
  const parseExistingImgs = (imgs) => imgs?.map(img => ({ img, uploaded: true, existing: true }));
  const postId = content?.id ?? `${hashCode(user.claims.user_id.slice(-6))}${new Date().getTime().toString().slice(-6)}_${uuidv4()}`;

  const [err, setErr] = useState(null);
  const [isPosting, setIsPosting] = useState(false);

  const [editText, setEditText] = useState(content?.text);
  const [editGallery, setEditGallery] = useState(parseExistingImgs((!content?.gallery && content?.img) ? [content.img] : content?.gallery) ?? []);
  const [editTags, setEditTags] = useState(content?.tags ?? []);
  const [propTags, setPropTags] = useState(content?.properties ?? []);

  const [isAdmin, setIsAdmin] = useState(false);
  const [enableAdmin, setEnableAdmin] = useState(false);

  const [uploadProgress, setUploadProgress] = useState({});
  const [vidThumbs, setVidThumbs] = useState({});

  const mediaQuery = checkMediaQuery();

  const upsertPost = () => {
    const data = {
      type: 'post',
      tags: editTags.map(t => t.trimEnd()),
      properties: propTags
    };
    const editText = textRef.current.value;
    if (!editText && editGallery.length === 0) {
      setErr('Please write something or upload image or video before creating post');
      return;
    }
    if (editText) data.text = editText;
    if (editGallery) data.gallery = editGallery.filter(g => !g.err).map(g => g.existing ? g.img : g.id);
    if (enableAdmin) data.admin = true;
    if (isEdit) {
      setIsPosting(true);
      editPost(content.id, user, data, () => {
        showSuccess('Post updated successfully');
        onClose();
      }, err => {
        setErr('Failed to edit post');
        setIsPosting(false);
      });
    } else {
      setIsPosting(true);
      createPost(user, data, postId => {
        if (!postId) {
          setErr('Failed to create post');
          setIsPosting(false);
        } else {
          showSuccess('Post created, will be available in a minute');
          onClose();
        }
      });
    }
  };

  useEffect(() => {
    if (isPremium(user) && isAdminTier(getUserClaims(user))) {
      setIsAdmin(true);
    }
  }, [user]);

  const removePhoto = (idx) => {
    const newGallery = [...editGallery];
    newGallery.splice(idx, 1);
    setEditGallery(newGallery);
  };

  const onAddNewTag = () => {
    const idx = editTags.length;
    setEditTags([...editTags.filter(tag => tag !== ''), '']);
    setTimeout(() => {
      const tagInput = document.getElementById(`edit-tag-${idx}`);
      tagInput.focus();
    }, 100);
  };

  const onChangeTag = (idx, tag) => {
    const newTags = [...editTags];
    newTags[idx] = tag;
    setEditTags(newTags);
  };

  const onRemoveTag = (idx) => {
    const newTags = [...editTags];
    newTags.splice(idx, 1);
    setEditTags(newTags);
  };

  const onAddNewPropertyTag = () => {
    const idx = propTags.length;
    setPropTags([...propTags.filter(tag => tag !== ''), '']);
    setTimeout(() => {
      const tagInput = document.getElementById(`prop-tag-${idx}`);
      tagInput.focus();
    }, 100);
  };

  const onChangePropertyTag = (idx, tag) => {
    const newTags = [...propTags];
    newTags[idx] = tag;
    setPropTags(newTags);
  };

  const onRemovePropertyTag = (idx) => {
    const newTags = [...propTags];
    newTags.splice(idx, 1);
    setPropTags(newTags);
  };

  const alertIfHeic = (files) => {
    if (files.filter(f => f.type === 'image/heic').length > 0) {
      window.alert(`HEIF image not allowed: ${files.map(f => f.name).join(', ')}`);
      return true;
    }
    return false;
  };

  const onFileSelect = async (evt) => {
    const currTs = new Date().getTime();
    const files = Array.from(evt.target.files);
    evt.target.value = "";

    if (alertIfHeic(files)) return;   // TODO: remove after debugging

    const initialProgress = {};

    files.forEach((f, i) => {
      initialProgress[`${f.name}_${editGallery.length + i}_${currTs}`] = 0;
    });

    const namedFiles = files.map((f, i) => ({
      name: `${f.name}_${editGallery.length + i}_${currTs}`,
      file: f,
      video: f.type.startsWith("video/")
    }));

    setEditGallery([...editGallery, ...namedFiles]);
    setUploadProgress((prevProgress) => ({ ...prevProgress, ...initialProgress }));

    uploadFiles(postId, namedFiles, (name, progress) => {
      setUploadProgress(prevProgress => ({
        ...prevProgress,
        [name]: progress
      }));
    }, (name, fileId) => {
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        [name]: 100
      }));
      setEditGallery((prevGallery) => {
        const newGallery = [...prevGallery];
        return newGallery.map(g => g.name === name ? ({ ...g, id: fileId }) : g);
      });
    }, (name, err) => {
      setEditGallery((prevGallery) => {
        const newGallery = [...prevGallery];
        return newGallery.map(g => g.name === name ? ({ ...g, err: 'Upload failed' }) : g);
      });
      setUploadProgress((prevProgress) => {
        const newProgress = {  ...prevProgress };
        delete newProgress[name];
        return newProgress;
      });
    });

    // handle video thumbnails while uploading if possible
    const videos = namedFiles.filter(f => f.video);
    if (videos.length > 0) {
      videos.forEach(v => {
        generateVideoThumbnail(v.file, (thumbnail, doc) => {
          doc.remove();
          setVidThumbs((prev) => ({ ...prev, [v.name]: thumbnail }));
        });
      });
    }
  };

  const escFunc = useCallback((event) => {
    if (event.key === "Escape") {
      onClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunc, false);

    return () => {
      document.removeEventListener("keydown", escFunc, false);
    };
  }, [escFunc]);

  const generateVideoThumbnail = (file, callback) => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(file);
    video.muted = true;
    video.playsInline = true;

    video.addEventListener("loadeddata", () => {
      video.currentTime = 1; // Seek to the first second
  
      video.addEventListener("seeked", () => {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth / 2; // Reduce resolution
        canvas.height = video.videoHeight / 2;
        const ctx = canvas.getContext("2d");
  
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const thumbnail = canvas.toDataURL("image/jpeg"); // Convert to JPEG
        // const isLongVideo = video.duration > 30;
        callback(thumbnail, video);
        });
    });
  };

  return (
    <div className={`newsfeed-content newsfeed-post ${mediaQuery ? 'newsfeed-post-full' : ''} px-15 pt-10`}>
      {/* header */}
      <div className="d-flex justify-content-between">
        <div className="p-2 text-14 fw-600">
          {content ? 'Edit' : 'Create New'} Post
        </div>

        <div className="p-2 flex-grow-1"></div>

        <button
          className="p-2 button h-30 px-10 text-16 py-20"
          onClick={onClose}
        >
          <i className="icon-close py-5" />
        </button>
      </div>

      {/* content body */}
      <div
        className="news-post-ch py-10 watermark"
        style={{
          height: 'calc(100svh - 60px)'
        }}
      >
        {err
          && <div className="text-center mb-10">
            <span className="text-14 rounded-8 px-10 py-5 bg-red-3 text-red-1">{err}</span>
          </div>
        }

        <div className="row px-20">
          <textarea
            ref={textRef}
            className="col-12 newsfeed-input px-10 py-10 text-14"
            placeholder="Write your post content here"
            // value={editText}
            // onChange={evt => setEditText(evt.target.value)}
            style={{
              height: 'calc(100svh - 520px)',
              minHeight: '120px'
            }}
          />
        </div>

        <div className="p-2 mt-10">
          <div className="p-2 text-12 fw-600 py-0 px-0">
            Gallery
          </div>
          <div className="d-flex py-0">
            <input
              type="file"
              accept="image/png,image/jpeg,image/webp,video/mp4,video/quicktime,video/webm"
              multiple
              id="file-upload"
              onChange={onFileSelect}
              hidden
            />
            <label
              htmlFor="file-upload"
              className="p-2 d-flex justify-content-center align-items-center px-10 py-10 cursor-pointer post-btn"
              data-tooltip-id="edit-tooltip"
              data-tooltip-html="Add photo to post"
              data-tooltip-variant="dark"
              data-tooltip-place="bottom"
              style={{
                width: '120px',
                height: '120px',
                background: '#ecf0f1',
                marginTop: '8px',
                marginBottom: '8px'
              }}
            >
              <i className="icon-plus" />
            </label>
            
            <div
              className="p-2 flex-grow-1 photo-gallery d-flex"
              style={{
                display: 'flex',
                overflowX: 'auto',
                whiteSpace: 'nowrap',
              }}
            >
              {editGallery.map((d, i) => (
                d.uploaded
                  ? <div
                      key={`img_upl_${i}`}
                      style={{
                        position: 'relative',
                        display: 'inline-block',
                        flex: '0 0 auto',            
                      }}
                    >
                      <img
                        loading="lazy"
                        src={getPostImgUrl(d.img)}
                        style={{
                          cursor: 'default',
                          display: 'block',
                        }}
                      />
                      <button
                        className="rounded-100 post-btn"
                        data-tooltip-id="edit-tooltip"
                        data-tooltip-html="Remove photo from post"
                        data-tooltip-variant="dark"
                        data-tooltip-place="bottom"
                        style={{
                          position: 'absolute',
                          top: '6px',
                          right: '12px',
                          backgroundColor: 'rgba(52, 73, 94, 0.3)',
                          color: '#fff',
                          border: 'none',
                          padding: '2px 8px 0px 8px',
                          cursor: 'pointer',
                        }}
                        onClick={() => removePhoto(i)}
                      >
                        <i className="icon-trash" />
                      </button>
                    </div>
                  : <div
                      key={`img_prog_${i}`}
                      style={{
                        position: 'relative',
                        display: 'inline-block',
                        flex: '0 0 auto',             
                      }}
                    >
                      {d.video && !(!uploadProgress[d.name] || uploadProgress[d.name] < 100)
                        && <video
                          src={URL.createObjectURL(d.file)}
                          muted
                          playsInline
                          controls
                          style={{
                            cursor: 'default',
                            display: 'block',
                            backgroundColor: 'black'
                          }}
                        />
                      }
                      {d.video && (!uploadProgress[d.name] || uploadProgress[d.name] < 100)
                        && <img
                          loading="lazy"
                          src={(d.name in vidThumbs ? vidThumbs[d.name] : '/img/general/loader.jpg')}
                          style={{
                            cursor: 'default',
                            display: 'block',
                            backgroundColor: 'black'
                          }}
                        />
                      }
                      {!d.video
                        && <img
                          loading="lazy"
                          src={URL.createObjectURL(d.file)}
                          style={{
                            cursor: 'default',
                            display: 'block',
                          }}
                        />
                      }
                      {(!uploadProgress[d.name] || uploadProgress[d.name] < 100) && !d.err
                        && <div className="post-progress-bar">
                          <div
                            className="post-progress"
                            style={{
                              width: `${uploadProgress[d.name] || 0}%`,
                            }}
                          ></div>
                        </div>
                      }
                      {
                        (!uploadProgress[d.name] || uploadProgress[d.name] < 100) && !d.err
                        && <div
                          className=""
                          style={{
                            position: 'absolute',
                            top: '38px',
                            left: '50%',
                            transform: 'translate(-50%, 0)'
                          }}
                        >
                          <div className="img-loader-spin"></div>
                        </div>
                      }
                      {d.err
                        && <div
                          className="post-btn text-white"
                          data-tooltip-id="edit-tooltip"
                          data-tooltip-html={d.err}
                          data-tooltip-variant="dark"
                          data-tooltip-place="bottom"
                          style={{
                            position: 'absolute',
                            top: '6px',
                            right: '6px',
                            padding: '0px',
                            cursor: 'pointer'
                          }}
                        >
                          <img
                            src="/img/general/warning.png"
                            height={25}
                            width={25}
                            style={{
                              width: '25px',
                              height: '25px',
                              padding: '0px',
                              marginRight: '6px'
                            }}
                          />
                        </div>
                      }
                      {!(!uploadProgress[d.name] || uploadProgress[d.name] < 100) && !d.err
                        && <button
                          className="rounded-100 post-btn"
                          data-tooltip-id="edit-tooltip"
                          data-tooltip-html="Remove photo from post"
                          data-tooltip-variant="dark"
                          data-tooltip-place="bottom"
                          style={{
                            position: 'absolute',
                            top: '6px',
                            right: '12px',
                            backgroundColor: 'rgba(52, 73, 94, 0.3)',
                            color: '#fff',
                            border: 'none',
                            padding: '2px 8px 0px 8px',
                            cursor: 'pointer',
                          }}
                          onClick={() => removePhoto(i)}
                        >
                          <i className="icon-trash" />
                        </button>
                      }
                    </div>
              ))}
            </div>
          </div>

        </div>

        <div className="p-2">
          <div className="p-2 text-12 fw-600 px-0">
            Properties
          </div>
          <div className="d-flex flex-wrap">
            <button
              className="p-2 button -dark-1 py-5 px-10 h-30 rounded-100 bg-blue-1 text-white text-12 mr-5"
              onClick={onAddNewPropertyTag}
            >
              <i className="icon-plus" />
            </button>
            {propTags.map((tag, i) =>
              <EditPostProperty
                key={`prop_t${i}`}
                idx={i}
                tag={tag}
                onChange={tag => onChangePropertyTag(i, tag)}
                onRemove={() => onRemovePropertyTag(i)}
                idPrefix="prop"
              />
            )}
          </div>
        </div>

        <div className="p-2">
          <div className="p-2 text-12 fw-600 px-0">
            Post Tags
          </div>
          <div className="d-flex flex-wrap">
            <button
              className="p-2 button -dark-1 py-5 px-10 h-30 rounded-100 bg-blue-1 text-white text-12 mr-5"
              onClick={onAddNewTag}
            >
              <i className="icon-plus" />
            </button>
            {editTags.map((tag, i) =>
              <EditPostTag
                key={`edit_t${i}`}
                idx={i}
                tag={tag}
                onChange={(tag) => onChangeTag(i, tag)}
                onRemove={() => onRemoveTag(i)}
              />
            )}
          </div>
        </div>

        <div
          className="d-flex flex-row-reverse justify-content-between align-items-center"
          style={{
            position: 'absolute',
            bottom: '16px',
            right: '16px'
          }}
        >
          <button
            className="button -dark-1 py-5 px-10 h-30 rounded-100 bg-blue-1 text-white text-12"
            onClick={upsertPost}
            disabled={Object.keys(uploadProgress).length > 0 && Object.keys(uploadProgress).some(k => uploadProgress[k] < 100) && isPosting}
            style={{
              ...(
                Object.keys(uploadProgress).length > 0 && Object.keys(uploadProgress).some(k => uploadProgress[k] < 100) && isPosting
                  ? {
                    opacity: '0.3'
                  } : {
                    opacity: '1'
                  }
              )
            }}
          >
            {
              Object.keys(uploadProgress).length > 0 && Object.keys(uploadProgress).some(k => uploadProgress[k] < 100)
                ? 'Uploading please wait'
                : (
                    isPosting
                      ? `${content ? 'Updating' : 'Creating'} Post`
                      : `${content ? 'Update' : 'Create'} Post`
                  )
            }
          </button>
          
          {isAdmin
            && <div className="mr-10 bg-white px-10">
              <Checkbox
                label="Post as admin"
                value={enableAdmin}
                setValue={setEnableAdmin}
              />
            </div>
          }
        </div>

      </div>

      <Tooltip id="edit-tooltip" />
    </div>
  );
};

export default EditPost;
