import PropTypes from "prop-types";

const Accordion = ({
  children,
  title,
  show,
}) => {
  const id = 'accord-' + title.replaceAll(' ', '-').replaceAll('$', '-').replaceAll('(', '-').replaceAll(')', '-').replaceAll('%', '-').replaceAll('/', '-');

  return (
    <div
      className="accordion -simple row y-gap-20 js-accordion"
      id="Faq1"
    >
      <div className="col-12" key={id}>
        <div className="accordion__item px-10 py-10 rounded-4">
          <div
            className="accordion__button d-flex justify-content-between items-center"
            data-bs-toggle="collapse"
            data-bs-target={`#${id}`}
          >
            <div className="button text-dark-1 text-start text-16">{title}</div>
            <div className="accordion__icon size-20 flex-center rounded-full mr-10 rotate-270">
              <i className="icon-chevron-left" />
              <i className="icon-chevron-right" />
            </div>
          </div>
          <div
            className={"accordion-collapse collapse" + (show ? ' show' : '')}
            id={id}
            data-bs-parent="#Faq1"
          >
            <div className="pt-15">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object, PropTypes.array]),
  title: PropTypes.string,
  show: PropTypes.bool
};

export default Accordion;
