import { useState } from 'react';

import CustomTable from '@/components/transactions/CustomTable';
import { STANDARD_PAGE_CONFIG } from '@/utils/table';
import { MAP_MODE_HDB, MAP_MODE_LANDED } from '@/utils/map';
import {
  convertDisplayDate,
  convertDisplayMonthYear
} from '@/utils/time';

const CompRecentTxTable = ({
  data,
  goToProperty,
  target
}) => {
  const DEFAULT_COLUMNS = target.mode === MAP_MODE_HDB
    ? [
        {
          Header: 'HDB',
          accessor: 'name',
          disableGlobalFilter: true,
          Cell: (row) => <div>{row.data[row.cell.row.index].block} {row.data[row.cell.row.index].street}</div>
        },
        {
          Header: 'Sale Date',
          accessor: 'sale_date',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{convertDisplayMonthYear(new Date(row.cell.value))}</div>
        },
        {
          Header: 'Transacted Price ($)',
          accessor: 'transacted_price',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Unit Price ($psf)',
          accessor: 'unitPrice',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toFixed(2)}</div>
        },
        {
          Header: 'Area (sqft)',
          accessor: 'area',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Flat Type',
          accessor: 'flatType',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Storey',
          accessor: 'storeyRange',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Flat Model',
          accessor: 'flatModel',
          disableGlobalFilter: true,
          width: 120,
          Cell: (row) => <div>{row.cell.value?.toUpperCase()}</div>
        },
        {
          Header: 'Postal Code',
          accessor: 'postal',
          disableGlobalFilter: true,
          width: 80,
        },
      ]
    : [
        {
          Header: 'Project',
          accessor: target.mode === MAP_MODE_LANDED ? 'marker' : 'name',
          disableGlobalFilter: true,
        },
        {
          Header: 'Sale Date',
          accessor: 'sale_date',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{convertDisplayDate(new Date(row.cell.value))}</div>
        },
        {
          Header: 'Type of Sale',
          accessor: 'type',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Transacted Price ($)',
          accessor: 'transacted_price',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Unit Price ($psf)',
          accessor: 'unitPrice',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toFixed(2)}</div>
        },
        {
          Header: 'Area (sqft)',
          accessor: 'area',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Address',
          accessor: 'address',
          disableGlobalFilter: true,
          disableSortBy: true,
        },
        {
          Header: 'Postal Code',
          accessor: 'postal',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Property Type',
          accessor: 'propertyType',
          disableGlobalFilter: true,
          width: 90,
        },
        {
          Header: 'Type of Area',
          accessor: 'areaType',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Purchaser Address Indicator',
          accessor: 'purchaserAddrIndicator',
          disableGlobalFilter: true,
          width: 80,
        },
      ];

  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  return (
    <CustomTable
      data={data}
      tableConfig={STANDARD_PAGE_CONFIG}
      defaultColumns={DEFAULT_COLUMNS}
      fullColumns={fullColumns}
      setFullColumns={setFullColumns}
      columns={columns}
      setColumns={setColumns}
      hideSearchInput
      hidePageButton
    />
  );
};

export default CompRecentTxTable;
