import { useState } from "react";

import EditPost from "@/components/newsfeed/EditPost";
import ProfilePanel from "@/components/newsfeed/ProfilePanel";
import MenuButton from "@/components/newsfeed/MenuButton";
import BotPanel from "@/components/mapv2/BotPanel";
import PostView from "@/components/newsfeed/PostView";
import { useNavigate } from "react-router-dom";
import { logAnalytics } from "@/utils/api";
import { checkMediaQuery, isLoggedIn } from "@/utils/user";


const BottomBar = ({
  user,
  session,
  selectedPost,
  editPost,
  showSharePanel,
  setShowSharePanel,
  onCreatePost,
  onEditPost,
  onClickLikedProps,
  setSelectedPost,
  lightboxImages,
  setLightboxImages,
  showError,
  showSuccess,
  onDeletePost,
  onSearchLocation,
  onClickPost,
  hideNewsfeed,
  isMapMode
}) => {
  const navigate = useNavigate();

  const [showBotPanelTs, setShowBotPanelTs] = useState(null);

  const [display, setDisplay] = useState(null);

  const isSmallScreen = window.innerWidth < 800;

  const mediaQuery = checkMediaQuery();
  
  const goToMap = () => {
    if (isMapMode) return;
    navigate('/map');
    logAnalytics('BOTTOM_BAR_CLICK', session, {
      target: 'map'
    });
  };

  const goToNews = () => {
    navigate('/news');
    logAnalytics('BOTTOM_BAR_CLICK', session, {
      target: 'news'
    });
  };

  const onClickLikedPropsAction = () => {
    if (isLoggedIn(user)) {
      setDisplay('liked_props');
    }
    onClickLikedProps();
    logAnalytics('BOTTOM_BAR_CLICK', session, {
      target: 'profile'
    });
  };
  
  const onEditPostAction = (post) => {
    if (isLoggedIn(user)) {
      setDisplay('edit');
    }
    onEditPost(post);
    onEditPostAction(post);
  };

  const onCreatePostAction = () => {
    if (isLoggedIn(user)) {
      setDisplay('edit');
      logAnalytics('CREATE_POST', session, {});
    }
    onCreatePost();
  };

  const closeDisplay = () => {
    setDisplay(null);
  };

  const goToHome = () => {
    navigate('/');
  };

	return (
		<>
			<div className="user-action-bar">
        {/* <div className={`d-flex justify-content-${isSmallScreen ? 'between' : 'center'} px-${isSmallScreen ? '30' : '5'}`}> */}
				<div className={`d-flex justify-content-${isSmallScreen ? (isMapMode ? 'evenly' : 'between') : 'center'} px-${isSmallScreen ? '30' : '5'}`}>
					<MenuButton img="nav_map.png" label="Map" onClick={goToMap} isSmallScreen={isSmallScreen} hint="View map" fade={isMapMode} />
					{!hideNewsfeed
            && <MenuButton
              img="nav_news.png"
              label="Newsfeed"
              onClick={goToNews}
              isSmallScreen={isSmallScreen}
              hint="View newsfeed"
            />
          }
					<MenuButton label="Create" isSmallScreen={isSmallScreen} maximize />
					{!hideNewsfeed
            && <MenuButton
              img="nav_ai.png"
              label="Assistant"
              onClick={() => {
                setShowBotPanelTs(new Date());
                logAnalytics('BOTTOM_BAR_CLICK', session, {
                  target: 'assistant'
                });
              }}
              isSmallScreen={isSmallScreen}
              hint="Real smart assistant"
            />
          }
					<MenuButton img="nav_user.png" label="Profile" onClick={onClickLikedPropsAction} isSmallScreen={isSmallScreen} hint="View my profile" />
				</div>
			</div>
			<div className="user-main-btn">
				<button
					className={`px-${isSmallScreen ? '0' : '50'} py-0 cursor-pointer home-btn-m`}
					onClick={hideNewsfeed ? goToHome : onCreatePostAction}
					{...(mediaQuery ? {} : {
						'data-tooltip-id': 'social-tooltip',
						'data-tooltip-html': 'Create new post',
						'data-tooltip-variant': "dark",
						'data-tooltip-place': "top"
					})}
				>
					<img
						src={hideNewsfeed ? "/img/general/logo-rs-light.png" : "/img/general/rsic_edit.jpg"}
						height={56}
						width={56}
						style={{
							borderRadius: '20px',
							border: '4px solid #13357b',
              background: 'white',
              ...(
                hideNewsfeed ? {
                  padding: '3px'
                } : {}
              )
						}}
					/>
				</button>
			</div>
			<BotPanel showBotPanelTs={showBotPanelTs} hidden />

			<div
        className={`langMenu langMenuTop js-langMenu newsfeed-view ${mediaQuery ? 'newsfeed-view-full' : ''} ${(selectedPost || display) ? "" : "is-hidden"}`}
        style={{
          paddingTop: '0px',
          transition: 'none'
        }}
      >
        {/* <div className="currencyMenu__bg" onClick={() => {
          setSelectedPost(null);
          setDisplay(null);
        }}></div> */}
        <div
          className="langMenu__content bg-white rounded-4 rel-content"
          style={{
            left: '0',
            height: '100%',
            width: '100%',
            transform: 'none'
          }}
        >

          {/* show post */}
          {selectedPost
            && <PostView
              user={user}
              session={session}
              brief={selectedPost}
              onClose={() => {
                setSelectedPost(null);
                const newUrl = window.location.protocol
                  + "//"
                  + window.location.host
                  + '/news';
                window.history.replaceState({path: newUrl}, '', newUrl);
              }}
              lightboxImages={lightboxImages}
              setLightboxImages={setLightboxImages}
              onEditPost={onEditPostAction}
              showError={showError}
              showSuccess={showSuccess}
              onDeletePost={onDeletePost}
              onSearchLocation={onSearchLocation}
            />
          }

          {/* liked properties */}
          {display === 'liked_props' && isLoggedIn(user)
            && <ProfilePanel
              user={user}
              session={session}
              mediaQuery={mediaQuery}
              closeDisplay={closeDisplay}
              onCreatePost={onCreatePost}
              showError={showError}
              showSuccess={showSuccess}
              selectedPost={selectedPost}
              onClickPost={onClickPost}
              hideNewsfeed={hideNewsfeed}
            />
          }

          {/* edit or create post */}
          {display === 'edit' && isLoggedIn(user)
            && <EditPost
              user={user}
              content={editPost}
              onClose={() => setDisplay(null)}
              showError={showError}
              showSuccess={showSuccess}
            />
          }
        </div>
      </div>

			{showSharePanel
        && <div className="border-bottom-light py-10 px-15">
          <div className="d-flex items-center justify-between">
            <div className="text-12 fw-500">Share Post Link</div>
            <button className="pointer" onClick={() => setShowSharePanel(null)}>
              <i className="icon-close text-10" />
            </button>
          </div>
          <div className="d-flex mt-10 text-12">
            <div
              className="p-2 flex-grow-1 text-light-1 h-30 py-0 newsfeed-input mr-10"
              style={{
                alignContent: 'center'
              }}
            >
              {showSharePanel}
            </div>
            <button
              className="p-2 button -dark-1 py-5 px-10 h-30 rounded-100 bg-blue-1 text-white text-12"
              onClick={() => {
                navigator.clipboard.writeText(showSharePanel);
              }}
            >
              Copy
            </button>
          </div>
        </div>
      }
		</>
	);
};

export default BottomBar;
