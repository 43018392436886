import PropTypes from "prop-types";

const CheckboxFilter = ({ values, setValues, options, col }) => {
  const isTypeSelected = (type) => values && !!values[type.value];

  const onSelectType = (e, type) => {
    const { checked } = e.target;
    setValues({ ...values, [type.value]: checked });
  };

  return (
    <>
      {options.map((type, index) => (
        <div className={`col-auto col-${col ?? 6}`} key={index}>
          <div className="form-checkbox d-flex items-center">
            <input
              type="checkbox"
              checked={isTypeSelected(type)}
              onChange={e => onSelectType(e, type)}
            />
            <div className="form-checkbox__mark">
              <div className="form-checkbox__icon icon-check" />
            </div>
            <div className="text-15 ml-10">{type.label}</div>
          </div>
        </div>
      ))}
    </>
  );
};

CheckboxFilter.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  col: PropTypes.number,
};

CheckboxFilter.defaultProps = {
  col: 6,
};

export default CheckboxFilter;
