import { useCallback, useEffect } from "react";
import PropNotificationRow from "./PropNotificationRow";

const PropNotifications = ({
  data,
  showing,
  onClick,
  onDelete,
  onClose
}) => {
  const escFunc = useCallback((event) => {
    if (event.key === "Escape" && showing) {
      onClose();
    }
  }, [showing]);

  useEffect(() => {
    document.addEventListener("keydown", escFunc, false);

    return () => {
      document.removeEventListener("keydown", escFunc, false);
    };
  }, [escFunc]);

  return (
    <>
      <div
        className="like-props"
      >
        {data.map((r, i) =>
          <PropNotificationRow
            key={`notif_${i}`}
            data={r}
            onClick={onClick}
            onDelete={onDelete}
          />
        )}
        {data.length === 0
          && <div className="text-center py-10 text-14">
            No unread notifications
            <br />
            <span className="text-12">Click on <strong className="text-10 bg-blue-1 text-white px-10 rounded-100">Subscribe</strong> in map to receive updates here</span>
          </div>
        }
      </div>
    </>
  );
};

export default PropNotifications;
