import Aos from "aos";
import { useEffect, useState } from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from 'uuid';
import { ErrorBoundary } from "react-error-boundary";

import SrollTop from "./components/common/ScrollTop";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-cards";
import "aos/dist/aos.css";
import "./styles/index.scss";
import { Provider } from "react-redux";
import { store } from "./store/store";

import "maplibre-gl/dist/maplibre-gl.css";

if (typeof window !== "undefined") {
  import("bootstrap");
}
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollTopBehaviour from "./components/common/ScrollTopBehaviour";

import NotFoundPage from "./pages/not-found";
import LogIn from "./pages/others/login";
import Terms from "./pages/others/terms";
import About from "./pages/others/about";

// import TransactionSearchPage from "./pages/transactions";
// import ProfitSearchPage from "./pages/profit";
// import RentalSearchPage from "./pages/rental";
// import AdminPage from "./pages/admin";
import LandingPage from "./pages/landing";
import HomeMap from "./pages/mapv2";
import NewsfeedPage from "./pages/newsfeed";
import ErrorOccurredPage from "./pages/error";

Sentry.init({
  dsn: "https://2f9b6b613efe149b025cfb3fe9350773@o4507704266326016.ingest.us.sentry.io/4507704270323712",
  integrations: [],
});

function App() {
  const [user, setUser] = useState(null);

  // generate the session id for the device, this will be useful to track guest users if not logged in
  const getSessionId = () => {
    try {
      let sessionId = localStorage.getItem('session_id');
      if (!sessionId) {
        // sessionId = Math.random().toString(36).slice(2);
        sessionId = uuidv4();
      }
      return sessionId;
    } catch(e) {
      return uuidv4();
    }
  };

  const session = getSessionId();

  const saveUserId = (user) => {
    try {
      if (user.claims?.user_id) {
        localStorage.setItem('session_idx', user.claims.user_id);
      }
    } catch(e) {
      // do nothing if fail to save user id
    }
  };

  useEffect(() => {
    Aos.init({
      duration: 1200,
      once: true,
    });
  }, []);

  const refreshUser = (callback) => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            user.getIdTokenResult().then((result) => {
              setUser({
                ...user,
                result,
                claims: result.claims,
              });
              callback?.(result.claims);
              
              saveUserId(user);

              // dynamically update Umami tracking
              if (window?.umami && typeof window.umami.identify === 'function') {
                if (result.claims?.user_id && result.claims?.email) {
                  umami.identify({
                    id: result.claims.user_id,
                    email: result.claims.email
                  });
                }
              }
            });
          })
          .catch(() => {
            setUser({});
          });
      } else {
        setUser({});
      }
    });
    return () => unregisterAuthObserver();
  };

  const getUser = () => firebase.auth().currentUser;

  const forceRefreshUser = (callback) => {
    const user = getUser();
    if (user) {
      user.getIdToken(true).then(() => {
        user.getIdTokenResult().then((result) => {
          callback?.(result.claims);
        });
      });
    }
  };

  const updateTokenResult = (results) => {
    const name = results.claims.name;
    const picture = results.claims.picture;
    const email = results.claims.email;
    setUser({
      multiFactor: {
        ...user?.multiFactor,
        user: {
          ...user?.multiFactor?.user,
          displayName: name,
          email: email,
          photoURL: picture,
        },
      },
      results,
      claims: results.claims,
    })
  };

  // handle user auth state
  useEffect(() => {
    refreshUser();
  }, []);

  return (
    <main>
      <ErrorBoundary
        FallbackComponent={ErrorOccurredPage}
        onReset={() => window?.location.replace('/')}
      >
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path="/">
                <Route index element={<LandingPage user={user} session={session} allowModeSwitch />} />
                <Route path="map" element={<HomeMap user={user} session={session} allowModeSwitch allowBot />} />
                <Route path="news" element={<NewsfeedPage user={user} session={session} />} />

                <Route path="app" element={<HomeMap user={user} session={session} allowModeSwitch allowBot />} />

                <Route path="login" element={<LogIn session={session} updateTokenResult={updateTokenResult} />} />

                {/* <Route path="transactions" element={<TransactionSearchPage user={user} session={session} />} />
                <Route path="profits" element={<ProfitSearchPage user={user} session={session} />} />
                <Route path="rentals" element={<RentalSearchPage user={user} session={session} />} /> */}
                
                <Route path="error" element={<ErrorOccurredPage user={user} session={session} />} />
                <Route path="404" element={<NotFoundPage user={user} session={session} />} />
                <Route path="*" element={<NotFoundPage user={user} session={session} />} />

                <Route path="terms" element={<Terms user={user} session={session} />} />
                <Route path="about" element={<About user={user} session={session} />} />
                <Route path="contact" element={<About user={user} session={session} />} />

                <Route path="start" element={<LandingPage user={user} session={session} />} />
                {/* <Route path="admin" element={<AdminPage user={user} />} /> */}
                
              </Route>
            </Routes>
            <ScrollTopBehaviour />
          </BrowserRouter>

          <SrollTop />
        </Provider>
      </ErrorBoundary>
    </main>
  );
}

export default App;
