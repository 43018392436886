import { NA } from "@/utils/convert";
import { MAP_MODE_HDB } from "@/utils/map";

const PerformancePanel = ({
  data,
  target
}) => {
  const generateRating = (idx) => {
    const base = idx;
    if (data.score >= base) return 2;
    return data.score >= (base - 0.5) ? 1 : 0;
  };

  const hasData = data.score !== null;

  if (!hasData) {
    return <></>
  }

  return (
    <div className="px-5 py-5 mt-5 proj-perf" id="proj-details-perf-box">
      <div className="d-flex items-center text-blue-1 text-12 ml-5">
        <i className="icon-award mr-5"></i>
        <div className="fw-600">Past 1 Year Performance</div>
      </div>

      {!hasData
        && <div className="text-12 ml-10 mt-5 fw-500 text-dark-1">
          Insufficient resale data in past year
        </div>
      }

      <div className="mt-10">
        {hasData && data.score !== null && data.score !== undefined
          && <div className="d-flex flex-row">
            <div className="p-2 proj-perf-line py-0 pb-10">
              <div
                className="text-14 text-dark-1 fw-500 lh-12 d-flex align-items-center cursor-pointer"
                data-tooltip-id="map-tooltip"
                data-tooltip-html={`
                Track record of <strong>profitable transactions</strong>
                <br /> compared with condos across Singapore
                <hr class="mt-5 mb-5" />
                Maximum score of 5.0 for top properties
                `}
                data-tooltip-variant="dark"
                data-tooltip-place="bottom"
              >
                REALSCORE
                <span
                  className="ml-5 text-12 fw-600 text-blue-1"
                >ⓘ</span>
                {/* <span className="ml-10 text-10 lh-10 items-center text-white proj-beta noselect rounded-100 fw-500">BETA</span> */}
              </div>
              <div className="text-10 text-dark-1 lh-12">Profitability rank across Singapore</div>
            </div>
            <div className="p-2 text-14 text-dark-1 py-0 px-0 fw-500">
              <div className="mt-5 d-flex align-items-center">
                <span className="mr-10 text-dark-1">{hasData && data.score && data.score !== null && data.score !== undefined ? data.score.toFixed(1) : NA}</span>
                {hasData ? (
                  <>
                    {[...Array(5).keys()].map(i => (
                      <img
                        key={`rscore_${i}`}
                        className="proj-perf-rate noselect"
                        src={`/img/general/logo_outline_${generateRating(i + 1)}.png`} />
                    ))}
                  </>
                ) : ''}
              </div>
            </div>
          </div>
        }

        <div className="d-flex flex-row">
          <div className="p-2 proj-perf-line">
            <div className="text-14 text-dark-1 fw-500 lh-12">
              Returns
            </div>
            <div className="text-10 text-dark-1 lh-12">{target.mode === MAP_MODE_HDB ? 'Avg annualized price increase' : 'Average annualized profit'} in past year</div>
          </div>
          <div className={`p-2 text-14 py-0 px-0 mt-10 ${
            (!hasData || data.last1yReturns === null) ? 'text-dark-1' : (data.last1yReturns > 0 ? 'text-green-2' : 'text-red-1')
          } fw-500`}>
            {!hasData || data.last1yReturns === null || data.last1yReturns === undefined ? NA : `${data.last1yReturns.toFixed(1)}%`}
          </div>
        </div>

        <div className="d-flex flex-row">
          <div className="p-2 proj-perf-line">
            <div className="text-14 text-dark-1 fw-500 lh-12">
              Track Record
            </div>
            <div className="text-10 text-dark-1 lh-12">Profitable transactions in past year</div>
          </div>
          <div className="p-2 text-14 py-0 px-0 mt-10 text-dark-1 fw-500">
            {!hasData || data.last1yProfitTx === null || data.last1yProfitTx === undefined ? NA : `${data.last1yProfitTx.toFixed(0)}`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformancePanel;
