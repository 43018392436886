import { useEffect, useState } from "react";
import TransactionsTab from "./TransactionTab";
import RentalTab from "./RentalTab";
import TabButtons from "./TabButtons";
import UnitsTab from "./UnitsTab";
import { MAP_MODE_LANDED } from "@/utils/map";

const TREND_OPTIONS = [
  {
    id: 'tx',
    label: 'Transactions'
  },
  {
    id: 'r',
    label: 'Rentals'
  },
  {
    id: 'u',
    label: 'Stack View'
  }
];

const EstateTrendTab = ({
  user,
  session,
  data,
  isMaximized,
  screenDim,
  target,
  selectedSubtab,
  defaultBlock,
  setCellDetails,
  setCellDetailsLoading,
  goToProperty
}) => {
  const [display, setDisplay] = useState(
    TREND_OPTIONS.find(o => o.id === selectedSubtab)
      ? selectedSubtab
      : TREND_OPTIONS[0].id
  );

  useEffect(() => {
    if (selectedSubtab && TREND_OPTIONS.find(o => o.id === selectedSubtab)) {
      setDisplay(selectedSubtab);
    }
  }, [selectedSubtab]);

  const goToPropertyFromRow = (row) => {
    // TODO
    const address = row.address;
    const project = data.projects.find(r => r.project.startsWith(address + ' '));
    if (project) {
      goToProperty(project.marker, project.id, MAP_MODE_LANDED);
    }
  };

  return (
    <>
      <TabButtons
        tabs={TREND_OPTIONS}
        selectedTab={display}
        setSelectedTab={setDisplay}
        session={session}
        markerName={target.id}
        projectName={target.project}
      />

      <div className="px-5">
        {display === 'tx'
          && <TransactionsTab user={user} data={data} isMaximized={isMaximized} screenDim={screenDim}
            target={target} hideProfit
            goToProperty={goToPropertyFromRow}
          />
        }

        {display === 'r'
          && <RentalTab user={user} data={data} isMaximized={isMaximized} screenDim={screenDim} />
        }

        {display === 'u'
          && <UnitsTab
            user={user} data={data} isMaximized={isMaximized} screenDim={screenDim}
            setCellDetails={setCellDetails} setCellDetailsLoading={setCellDetailsLoading}
            defaultBlock={defaultBlock} target={target}
          />
        }
      </div>

    </>
  );
};

export default EstateTrendTab;
