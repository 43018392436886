import PropTypes from "prop-types";
import InputRange from "react-input-range";

const SingleSlider = (props) => {
  const {
    value,
    setValue,
    minValue,
    maxValue,
    unit,
    step,
    hasLimit,
    decimalPlace
  } = props;

  const handleOnChange = (value) => {
    setValue(value);
  };

  const formatDecimalPlaces = (value) => decimalPlace > 0 ? value.toFixed(decimalPlace) : decimalPlace;

  return (
    <div className="js-price-rangeSlider">
      <div className="text-14 fw-500"></div>

      <div className="d-flex justify-between mb-20">
        <div className="text-15 text-dark-1">
          
          <span className="js-upper mx-1">
            {value === maxValue
              ? (hasLimit ? formatDecimalPlaces(maxValue) : 'No limit')
              : `≤ ${formatDecimalPlaces(value)} ${unit}`
            }
          </span>
        </div>
      </div>

      <div className="px-5">
        <InputRange
          formatLabel={() => ``}
          minValue={minValue ?? 0}
          maxValue={maxValue}
          value={value}
          step={step}
          onChange={(value) => handleOnChange(value)}
        />
      </div>
    </div>
  );
};

SingleSlider.propTypes = {
  value: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number.isRequired,
  pluralize: PropTypes.bool,
  unit: PropTypes.string,
  dollarize: PropTypes.bool,
  minify: PropTypes.bool,
  step: PropTypes.number,
  prefix: PropTypes.bool,
  snapToStepMin: PropTypes.func,
  hasLimit: PropTypes.bool,
  decimalPlace: PropTypes.number
};

SingleSlider.defaultProps = {
  minValue: 0,
  pluralize: false,
  dollarize: false,
  minify: false,
  step: 1,
  prefix: false,
  snapToStepMin: null,
  hasLimit: false,
  decimalPlace: 0
};

export default SingleSlider;
