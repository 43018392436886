// import { useEffect, useState } from "react";
import ScrollDownButton from "./ScrollDownButton";
import { useNavigate } from "react-router-dom";
import LandingFilterBox from "./LandingFilterBox";
import { logAnalytics, logNameSearch, trackEvent } from "@/utils/api";
import Carousel from "./Carousel";
import { MAP_MODE_CONDO, MAP_MODE_HDB, MAP_MODE_LANDED, isHdbType } from "@/utils/map";
import BotPanel from "../mapv2/BotPanel";
import {
  LOCATION_PROPERTY,
  LOCATION_SCHOOL,
  LOCATION_STATION,
  LOCATION_UPCOMING
} from "@/utils/areas";
import { useState } from "react";
import Loader from "../common/Loader";

const IntroPage = ({
  onNextPage,
  session,
  allowModeSwitch
}) => {
  const navigate = useNavigate();

  const [click, setClick] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const onClickItem = (item) => {
    if (item.url) {
      navigate(`/map?${item.url}`);
    } else if (item.s) {
      navigate(`/map?s=${encodeURIComponent(item.n)}`);
    }
  };

  const onSearchLocation = (searchTerm) => {
    logNameSearch('LANDING', session, searchTerm.name);

    if (searchTerm.type === LOCATION_PROPERTY) {
      const isHdb = isHdbType(searchTerm.subtype);
      
      trackEvent(`landing_search_${isHdbType ? 'hdb' : 'private'}_${searchTerm.marker}`);

      const url = `/map?id=${
        isHdb
          ? searchTerm.store
          : encodeURIComponent(searchTerm.marker)
      }${
        isHdb
          ? ''
          : `&p=${searchTerm.store}`
      }&mode=${isHdb ? MAP_MODE_HDB : (
        searchTerm.landed ? MAP_MODE_LANDED : MAP_MODE_CONDO
      )}`;
      navigate(url);
    } else if (searchTerm.type === LOCATION_SCHOOL) {
      trackEvent(`landing_search_school_${searchTerm.names[0]}}`);

      const url = `/map?sch=${searchTerm.store}&mode=c`;
      navigate(url);
    } else if (searchTerm.type === LOCATION_STATION) {
      trackEvent(`landing_search_station_${searchTerm.marker}`);

      const url = `/map?stn=${searchTerm.store}&mode=c`;
      navigate(url);
    } else if (searchTerm.type === LOCATION_UPCOMING) {
      trackEvent(`landing_search_upcoming_${searchTerm.marker}`);

      const url = `/map?new=${searchTerm.store}`;
      navigate(url);
    }
  };

  const loadSearchData = () => {
    setLoading(true);

    fetch(
      'https://realsmart.global.ssl.fastly.net/landsrc'
    )
    .then(resp => {
      return resp.json();
    }).then(json => {
      setData(json);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  };

  const handleCarding = () => {
    if (!click) {
      if (data.length === 0) {
        loadSearchData();
      }
      logAnalytics('LANDING_SUGGESTIONS', session, {});
    }
    setClick((prevState) => !prevState);
  };

  return (
    <div className="lp-full lp-intro gif-bg">
      <div className="lp-intro-container watermark">

        <div className="lp-intro-content d-flex justify-content-center flex-column align-items-center">
          
          {/* <div className="intro-type-wrapper">
            <p className="intro-typing">Search for <span className="text-blue-1">Profitable</span> Property in SG</p>
          </div> */}

          <img className="landing-logo mt-10" loading="lazy" src="/img/general/animhome2.gif" alt="REALSMART.SG" />

          <LandingFilterBox
            onSearchLocation={onSearchLocation}
            allowModeSwitch={allowModeSwitch}
            hideClickHint
          />

          <div className="mt-5">
            <button
              className="d-flex items-center text-12 notif-btn fw-600 post-btn"
              onClick={handleCarding}
            >
              <span className="text-blue-1">Jump to <u>popular searches</u></span>
            </button>
          </div>

          <div className="mt-10" />

          {/* {!loading && data && <div className="lp-intro-card-container pt-30 mt-10 mb-40">
            <FlyInOutText
              texts={data}
              onClickItem={onClickItem}
            />
          </div>}

          {loading && <LandingLoader />} */}

          <Carousel
            onClickItem={onClickItem}
          />
          <br />

          {/* <Link
            className="button -sm -dark-1 bg-blue-1 text-white text-16 px-20 py-10 landing-btn mt-40 mb-20"
            to="/search"
          >
            GET STARTED
          </Link> */}
          {/* <div className="text-12">
            <Link
              to="/terms?t=tos"
              className="text-blue-1"
              target="_blank"
            >
              Terms of Service
            </Link> · <Link
              to="/terms?t=privacy"
              className="text-blue-1"
              target="_blank"
            >
              Privacy Policy
            </Link>
          </div> */}
        </div>

        {/* <ScrollDownButton onNextPage={onNextPage} showHint dark /> */}
      </div>

      {/* <BotPanel
        position={{
          bottom: '92px',
          right: '10px'
        }}
      /> */}

      <div className={`langMenu langMenuTop js-langMenu ${click ? "" : "is-hidden"}`}>
        <div className="currencyMenu__bg" onClick={handleCarding}></div>
        <div className="langMenu__content bg-white rounded-4 rel-content">
          <div className="d-flex items-center px-20 py-10 sm:px-15 border-bottom-light">
            <h3 className="text-14">Drill down …</h3>
            <div className="flex-grow-1"></div>
            <button className="pointer px-10" onClick={handleCarding}>
              <i className="icon-close" />
            </button>
          </div>

          {loading
            && <div className="loader-container-sm">
              <Loader />
            </div>
          }

          {!loading
            && <div className="acct-bh watermark">
              <div className="like-props">
                {data.map((row, i) => (
                  <div
                    key={`r${i}`}
                    className="d-flex align-items-center align-self-center border-bottom-light py-10 px-20 text-12 cursor-pointer notif-item text-blue-1 fw-600"
                    onClick={() => {
                      logAnalytics('LANDING_SUGGESTION_CLICK', session, {
                        select: row[0]
                      });
                      trackEvent('landing_suggestion_link', {
                        label: row[0],
                        url: row[1]
                      });
                      window.location.replace(row[1]);
                    }}
                  >
                    {row[0]}
                  </div>
                ))}
              </div>
            </div>
          }

        </div>

      </div>
    </div>
  );
};

export default IntroPage;
