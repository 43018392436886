import { checkMediaQuery } from "@/utils/user";

const MenuButton = ({
  img,
  label,
  onClick,
  isSmallScreen,
  style,
  hint,
  maximize,
  fade
}) => {
  return (
    <button
      className={`px-${isSmallScreen ? '0' : '50'} py-0 cursor-pointer home-btn`}
      onClick={() => {
        onClick?.();
      }}
      {...((checkMediaQuery() || !hint) ? {} : {
        'data-tooltip-id': 'social-tooltip',
        'data-tooltip-html': hint,
        'data-tooltip-variant': "dark",
        'data-tooltip-place': "top"
      })}
      style={{
        ...(
          fade ? {
            opacity: '0.3'
          } : {
            opacity: '1'
          }
        )
      }}
    >
      {img
        && <img
          src={`/img/general/${img}`}
          height={16}
          width={16}
          style={style ?? {}}
        />
      }
      {!img
        && <div
          style={{
            height: '10px',
            width: '50px'
          }}
        />
      }
      {!maximize
        && <div
          className="fw-600"
          style={{
            color: 'white',
            fontSize: '8px'
          }}
        >
          {label}
        </div>
      }
    </button>
  );
};

export default MenuButton;
