import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  PointElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";
import { generateColorsByValue } from "@/utils/colors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
);

const getOptions = (xLabel, yLabel) => ({
  responsive: true,
  maintainAspectRatio: false,

  scales: {      
    x: {
      title: xLabel ? {
        display: true,
        text: xLabel,
        font: {
          size: 10,
        },
        padding: {
          top: 10,
        },
      }: {},
    },
    y: {
      title: yLabel ? {
        display: true,
        text: yLabel,
        font: {
          size: 10,
        },
        padding: {
          right: 10,
        },
      } : {},
    },
  },

  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },

    tooltips: {
      position: "nearest",
      mode: "index",
      intersect: false,
      yPadding: 10,
      xPadding: 10,
      caretSize: 4,
      backgroundColor: "#1967d2",
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 4,
    },
  },
});

const DistributionChart = ({
  label,
  data,
  tooltipTitleCallback,
  tooltipLabelCallback,
  xAxisLabel,
  yAxisLabel,
  height
}) => {
  const chartData = {
    labels: data.labels,
    datasets: [
      {
        type: 'bar',
        label,
        data: data.data,
        backgroundColor: generateColorsByValue(data.labels),
      },
      {
        type: 'line',
        label: 'Number of Transactions',
        data: data.data,
        fill: false,
        backgroundColor: generateColorsByValue(data.labels),
        borderColor: 'rgba(53,84,209,0.3)',
        borderWidth: 2,
        tension: 0.4,
      }
    ],
  };

  const options = getOptions(xAxisLabel, yAxisLabel);

  return (
    <div className="widget-content">
      <Bar
        options={{
          ...options,
          plugins: {
            ...options.plugins,
            tooltip: {
              callbacks: {
                title: (context) => tooltipTitleCallback(context),
                label: (context) => tooltipLabelCallback(context),
              }
            },
          },
        }}
        data={chartData}
        height={height}
      />
    </div>
  );
};

DistributionChart.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  tooltipTitleCallback: PropTypes.func.isRequired,
  tooltipLabelCallback: PropTypes.func.isRequired,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
};

export default DistributionChart;
