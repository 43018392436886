const FollowRow = ({
  idx,
  data,
  onView,
  onUnfollow
}) => {
  const isSmallScreen = window.innerWidth < 1000;

  return (
    <div
      key={idx}
      className={`${idx !== 0 ? 'border-top-light' : ''} px-0 py-5`}
    >
      <div className="px-0">
        <div className="d-flex px-0 align-items-center">
          <img
            className="p-2 noselect"
            src={data.avatar ?? '/img/general/profile.png'}
            height={60}
            width={60}
          />
          <div className="p-2 flex-grow-1">
            <div className="fw-600 text-dark text-16">
              {data.name}
            </div>
          </div>
          <button
            className={`p-2 button ${isSmallScreen ? 'mr-5 px-5' : 'mr-10 px-10'} mb-5 -dark-1 bg-blue-1 text-white h-30 rounded-100 text-12 noselect`}
            onClick={() => onUnfollow(data.id)}
          >
            <i className="icon-close px-5" />
            {!isSmallScreen
              && <span className="">
                Unfollow
              </span>
            }
          </button>
          {/* <button
            className="p-2 button mr-5 mb-5 -dark-1 bg-blue-1 text-white h-30 px-10 rounded-100 text-12 noselect"
            onClick={() => onView?.(data)}
          >
            {!isSmallScreen
              && <i className="icon-user px-5" />
            }
            <span className="">
              View
            </span>
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default FollowRow;
