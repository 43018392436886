import { useEffect, useRef, useState } from "react";
import Loader from "../common/Loader";
import { useNavigate } from "react-router-dom";
import FollowRow from "./FollowRow";
import { getFollows, logAnalytics, unfollowAuthor } from "@/utils/api";


const Follows = ({
  user,
  session,
  style = {}
}) => {
  const PAGE_LIMIT = 20;
  const containerRef = useRef(null);
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [loadIndex, setLoadIndex] = useState(1000);
  const [isFetching, setIsFetching] = useState(false);

  const loadFollows = (showLoader) => {
    setIsFetching(true);
    if (showLoader) {
      setLoading(true);
    }

    getFollows(user, offset, PAGE_LIMIT, follows => {
      if (follows.length < PAGE_LIMIT) {
        setOffset(-1);  // disable load next page
      } else {
        setOffset(offset + PAGE_LIMIT);
      }

      const newData = follows.map(d => ({
        ...d,
        ...d.author
      }));

      const updatedData = [...(data ?? []), ...newData];

      setData(updatedData);
      
      const expectedLoadIndex = updatedData.length - parseInt((PAGE_LIMIT * 0.5));
      setLoadIndex(expectedLoadIndex > 1 ? expectedLoadIndex : 1000);

      if (showLoader) {
        setLoading(false);
      }
      setIsFetching(false);
    });

    logAnalytics('LOAD_USER_FOLLOWS', session, {
      offset
    });
  };

  useEffect(() => {
    loadFollows(true);
  }, []);

  const onListScroll = () => {
    if (containerRef.current && data.length > 0) {
      const scrollTop = containerRef.current.scrollTop;
      const childHeight = containerRef.current.children[0].offsetHeight;
      const index = Math.floor(scrollTop / childHeight);

      // start loading next page if hit the load index
      if (offset > 0 && index >= loadIndex && !isFetching) {
        loadFollows();
      }
    }
  };

  const onUnfollow = (id) => {
    unfollowAuthor(id);
    setData(data.filter(d => d.id !== id));
  };

  return (
    <div
      className="like-props watermark"
      ref={containerRef}
      onScroll={onListScroll}
      style={style}
    >
      {loading
        && <div className="loader-container-sm">
          <Loader />
        </div>
      }

      {!loading
        && data?.map((row, i) => (
          <FollowRow
            key={`follow_${i}`}
            idx={i}
            data={row}
            onView={data => {}}
            onUnfollow={onUnfollow}
          />
        ))
      }

      {!loading
        && data?.length === 0
        && <div className="text-center text-12 py-20">
            You have not followed any users yet
          </div>
      }
    </div>
  );
};

export default Follows;
