import { useEffect, useRef, useState } from "react";
import LikedPropertyRow from "./LikedPropertyRow";
import Loader from "../common/Loader";
import { getPropertyLikes, logAnalytics, unlikeProperty } from "@/utils/api";
import { useNavigate } from "react-router-dom";
import { getPropertyFileName, MAP_MODE_HDB } from "@/utils/map";


const LikedProperties = ({
  user,
  session,
  onViewProperty,
  onUnlikeProperty,
  isMap,
  style = {}
}) => {
  const PAGE_LIMIT = 20;
  const containerRef = useRef(null);
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [loadIndex, setLoadIndex] = useState(1000);
  const [isFetching, setIsFetching] = useState(false);

  const loadProperties = (showLoader) => {
    setIsFetching(true);
    if (showLoader) {
      setLoading(true);
    }

    getPropertyLikes(user, v => {
      if (v.length < PAGE_LIMIT) {
        setOffset(-1);  // disable load next page
      } else {
        setOffset(offset + PAGE_LIMIT);
      }

      const newData = v.map(d => JSON.parse(d.prop.data));
      
      const updatedData = [...(data ?? []), ...newData];
      setData(updatedData);
      const expectedLoadIndex = updatedData.length - parseInt((PAGE_LIMIT * 0.5));
      setLoadIndex(expectedLoadIndex > 1 ? expectedLoadIndex : 1000);

      if (showLoader) {
        setLoading(false);
      }
      setIsFetching(false);
    }, PAGE_LIMIT, offset);

    logAnalytics('LOAD_PROPERTY_LIKES', session, {
      offset
    });
  };

  useEffect(() => {
    loadProperties(true);
  }, []);

  const onListScroll = () => {
    if (containerRef.current && data.length > 0) {
      const scrollTop = containerRef.current.scrollTop;
      const childHeight = containerRef.current.children[0].offsetHeight;
      const index = Math.floor(scrollTop / childHeight);

      // start loading next page if hit the load index
      if (offset > 0 && index >= loadIndex && !isFetching) {
        loadProperties();
      }
    }
  };

  const onUnlike = (prop) => {
    const id = prop.id;
    const mode = prop.mode;
    let pid = id;
    if (mode !== MAP_MODE_HDB) {
      pid = `${getPropertyFileName(prop.marker)}_${prop.id}`;
    } else {
      pid = prop.postal;
    }
    unlikeProperty(pid);
    setData(data.filter(d => d.id !== id));
    onUnlikeProperty?.(data);
  };

  return (
    <div
      className="like-props watermark"
      ref={containerRef}
      onScroll={onListScroll}
      style={style}
    >
      {loading
        && <div className="loader-container-sm">
          <Loader />
        </div>
      }

      {!loading
        && data?.map((row, i) => (
          <LikedPropertyRow
            key={i}
            idx={i}
            data={row}
            onView={data => {
              if (isMap) {
                onViewProperty(data);
              } else {
                if (data.mode === MAP_MODE_HDB) {
                  navigate(`/map?mode=${data.mode}&id=${data.postal}`);
                } else {
                  navigate(`/map?mode=${data.mode}&id=${encodeURIComponent(data.marker)}&p=${data.id}`);
                }
              }
            }}
            onUnlike={onUnlike}
          />
        ))
      }

      {!loading
        && data?.length === 0
        && <div className="text-center text-12 py-20">
            You have not liked any properties yet
          </div>
      }
    </div>
  );
};

export default LikedProperties;
