import {
  isMapboxURL,
  transformMapboxUrl
} from 'maplibregl-mapbox-request-transformer'
import { isDevMode } from './dev';
import { NA } from './convert';

export const LOCATION_PROPERTY = 'property';
export const LOCATION_BLOCK = 'block';
export const LOCATION_SCHOOL = 'school';
export const LOCATION_STATION = 'station';
export const LOCATION_UPCOMING = 'upcoming';
export const LOCATION_HDB = 'hdb';
export const LOCATION_AREA = 'area';
export const LOCATION_MARKER = 'marker';
export const LOCATION_ESTATE = 'estate';
export const LOCATION_COMPARE = 'compare';
export const LOCATION_FILTER = 'filter';

export const REGIONS = [
  {
    label: 'North',
    areas: [
      'Lim Chu Kang', 'Mandai', 'Sembawang', 'Sungei Kadut', 'Woodlands', 'Yishun',
    ],
  },
  {
    label: 'North-East',
    areas: [
      'Ang Mo Kio', 'Hougang', 'Punggol', 'Seletar', 'Sengkang', 'Serangoon',
    ],
  },
  {
    label: 'East',
    areas: [
      'Bedok', 'Changi', 'Pasir Ris', 'Paya Lebar', 'Tampines',
    ],
  },
  {
    label: 'West',
    areas: [
      'Boon Lay', 'Bukit Batok', 'Bukit Panjang', 'Choa Chu Kang', 'Clementi', 'Jurong East', 'Jurong West', 'Pioneer',
      'Tengah', 'Tuas',
    ],
  },
  {
    label: 'Central',
    areas: [
      'Bishan', 'Bukit Merah', 'Bukit Timah', 'Downtown Core', 'Geylang', 'Kallang', 'Marina East', 'Marina South',
      'Marine Parade', 'Museum', 'Newton', 'Novena', 'Orchard', 'Outram', 'Queenstown', 'River Valley', 'Rochor',
      'Singapore River', 'Southern Islands', 'Tanglin', 'Toa Payoh',
    ],
  },
];

export const HDB_REGIONS = [
  {
    label: "North",
    areas: ["Sembawang", "Woodlands", "Yishun"]
  },
  {
    label: "Northeast",
    areas: ["Ang Mo Kio", "Hougang", "Punggol", "Sengkang", "Serangoon"]
  },
  {
    label: "East",
    areas: ["Bedok", "Pasir Ris", "Tampines"]
  },
  {
    label: "West",
    areas: ["Bukit Batok", "Bukit Panjang", "Choa Chu Kang", "Clementi", "Jurong East", "Jurong West"]
  },
  {
    label: "Central",
    areas: ["Bishan", "Bukit Merah", "Bukit Timah", "Central Area", "Geylang", "Kallang/Whampoa", "Marine Parade", "Queenstown", "Toa Payoh"]
  }
];

const AREA_LIST = REGIONS.flatMap(region => region.areas);

export const POSTAL_DISTRICTS = [
  {
    id: 1,
    name: "Raffles Place, Cecil, Marina, People's Park",
    coordinates: { latitude: 1.2839, longitude: 103.8512 }
  },
  {
    id: 2,
    name: "Anson, Tanjong Pagar",
    coordinates: { latitude: 1.2724, longitude: 103.8467 }
  },
  {
    id: 3,
    name: "Queenstown, Tiong Bahru",
    coordinates: { latitude: 1.2883, longitude: 103.8058 }
  },
  {
    id: 4,
    name: "Telok Blangah, HarbourFront",
    coordinates: { latitude: 1.2653, longitude: 103.8237 }
  },
  {
    id: 5,
    name: "Pasir Panjang, Hong Leong Garden, Clementi",
    coordinates: { latitude: 1.2854, longitude: 103.7655 }
  },
  {
    id: 6,
    name: "High Street, Beach Road (City)",
    coordinates: { latitude: 1.2944, longitude: 103.8524 }
  },
  {
    id: 7,
    name: "Middle Road, Golden Mile",
    coordinates: { latitude: 1.3008, longitude: 103.8605 }
  },
  {
    id: 8,
    name: "Little India",
    coordinates: { latitude: 1.3062, longitude: 103.8520 }
  },
  {
    id: 9,
    name: "Orchard, Cairnhill, River Valley",
    coordinates: { latitude: 1.3032, longitude: 103.8353 }
  },
  {
    id: 10,
    name: "Bukit Timah, Holland Road, Tanglin",
    coordinates: { latitude: 1.3224, longitude: 103.8065 }
  },
  {
    id: 11,
    name: "Watten Estate, Novena, Thomson",
    coordinates: { latitude: 1.3240, longitude: 103.8350 }
  },
  {
    id: 12,
    name: "Balestier, Toa Payoh, Serangoon",
    coordinates: { latitude: 1.3349, longitude: 103.8450 }
  },
  {
    id: 13,
    name: "Macpherson, Braddell",
    coordinates: { latitude: 1.3327, longitude: 103.8741 }
  },
  {
    id: 14,
    name: "Geylang, Eunos",
    coordinates: { latitude: 1.3197, longitude: 103.8931 }
  },
  {
    id: 15,
    name: "Katong, Joo Chiat, Amber Road",
    coordinates: { latitude: 1.3013, longitude: 103.9011 }
  },
  {
    id: 16,
    name: "Bedok, Upper East Coast",
    coordinates: { latitude: 1.3201, longitude: 103.9568 }
  },
  {
    id: 17,
    name: "Loyang, Changi",
    coordinates: { latitude: 1.3624, longitude: 103.9802 }
  },
  {
    id: 18,
    name: "Tampines, Pasir Ris",
    coordinates: { latitude: 1.3521, longitude: 103.9456 }
  },
  {
    id: 19,
    name: "Serangoon Garden, Hougang, Punggol",
    coordinates: { latitude: 1.3693, longitude: 103.8926 }
  },
  {
    id: 20,
    name: "Bishan, Ang Mo Kio",
    coordinates: { latitude: 1.3721, longitude: 103.8491 }
  },
  {
    id: 21,
    name: "Upper Bukit Timah, Clementi Park, Ulu Pandan",
    coordinates: { latitude: 1.3387, longitude: 103.7769 }
  },
  {
    id: 22,
    name: "Jurong",
    coordinates: { latitude: 1.3398, longitude: 103.7064 }
  },
  {
    id: 23,
    name: "Bukit Batok, Bukit Panjang",
    coordinates: { latitude: 1.3596, longitude: 103.7644 }
  },
  {
    id: 24,
    name: "Choa Chu Kang, Tengah",
    coordinates: { latitude: 1.3855, longitude: 103.7446 }
  },
  {
    id: 25,
    name: "Kranji, Woodgrove",
    coordinates: { latitude: 1.4317, longitude: 103.7741 }
  },
  {
    id: 26,
    name: "Upper Thomson, Springleaf",
    coordinates: { latitude: 1.4032, longitude: 103.8256 }
  },
  {
    id: 27,
    name: "Yishun, Sembawang",
    coordinates: { latitude: 1.4312, longitude: 103.8366 }
  },
  {
    id: 28,
    name: "Seletar",
    coordinates: { latitude: 1.3944, longitude: 103.8690 }
  }
];


// export const PLANNING_AREAS = [
//   {
//     name: { en: "Ang Mo Kio", zh: "宏茂桥" },
//     coordinates: { latitude: 1.38009, longitude: 103.8421 },
//   },
//   {
//     name: { en: "Bedok", zh: "勿洛" },
//     coordinates: { latitude: 1.32366, longitude: 103.92822 },
//   },
//   {
//     name: { en: "Bishan", zh: "碧山" },
//     coordinates: { latitude: 1.35382, longitude: 103.84327 },
//   },
//   {
//     name: { en: "Boon Lay", zh: "文礼" },
//     coordinates: { latitude: 1.31479, longitude: 103.70222 },
//   },
//   {
//     name: { en: "Bukit Batok", zh: "武吉巴督" },
//     coordinates: { latitude: 1.35566, longitude: 103.75474 },
//   },
//   {
//     name: { en: "Bukit Merah", zh: "红山" },
//     coordinates: { latitude: 1.27549, longitude: 103.82313 },
//   },
//   {
//     name: { en: "Bukit Panjang", zh: "武吉班让" },
//     coordinates: { latitude: 1.36682, longitude: 103.773 },
//   },
//   {
//     name: { en: "Bukit Timah", zh: "武吉知马" },
//     coordinates: { latitude: 1.32999, longitude: 103.7907 },
//   },
//   {
//     name: { en: "Central Water Catchment", zh: "中央集水区" },
//     coordinates: { latitude: 1.37666, longitude: 103.80119 },
//   },
//   {
//     name: { en: "Changi", zh: "樟宜" },
//     coordinates: { latitude: 1.35081, longitude: 103.99782 },
//   },
//   {
//     name: { en: "Changi Bay", zh: "樟宜湾" },
//     coordinates: { latitude: 1.32233, longitude: 104.02495 },
//   },
//   {
//     name: { en: "Choa Chu Kang", zh: "蔡厝港" },
//     coordinates: { latitude: 1.38402, longitude: 103.74719 },
//   },
//   {
//     name: { en: "Clementi", zh: "金文泰" },
//     coordinates: { latitude: 1.3176, longitude: 103.76075 },
//   },
//   {
//     name: { en: "Downtown Core", zh: "市中心" },
//     coordinates: { latitude: 1.28506, longitude: 103.8543 },
//   },
//   {
//     name: { en: "Geylang", zh: "芽笼" },
//     coordinates: { latitude: 1.3209, longitude: 103.88952 },
//   },
//   {
//     name: { en: "Hougang", zh: "后港" },
//     coordinates: { latitude: 1.36364, longitude: 103.8898 },
//   },
//   {
//     name: { en: "Jurong East", zh: "裕廊东" },
//     coordinates: { latitude: 1.32389, longitude: 103.73651 },
//   },
//   {
//     name: { en: "Jurong West", zh: "裕廊西" },
//     coordinates: { latitude: 1.34161, longitude: 103.7049 },
//   },
//   {
//     name: { en: "Kallang", zh: "加冷" },
//     coordinates: { latitude: 1.31034, longitude: 103.86643 },
//   },
//   {
//     name: { en: "Lim Chu Kang", zh: "林厝港" },
//     coordinates: { latitude: 1.43064, longitude: 103.71929 },
//   },
//   {
//     name: { en: "Mandai", zh: "万礼" },
//     coordinates: { latitude: 1.42267, longitude: 103.80607 },
//   },
//   {
//     name: { en: "Marina East", zh: "滨海东" },
//     coordinates: { latitude: 1.28852, longitude: 103.87235 },
//   },
//   {
//     name: { en: "Marina South", zh: "滨海南" },
//     coordinates: { latitude: 1.28053, longitude: 103.86563 },
//   },
//   {
//     name: { en: "Marine Parade", zh: "马林百列" },
//     coordinates: { latitude: 1.30023, longitude: 103.89627 },
//   },
//   {
//     name: { en: "Museum", zh: "博物馆" },
//     coordinates: { latitude: 1.29592, longitude: 103.84671 },
//   },
//   {
//     name: { en: "Newton", zh: "纽顿" },
//     coordinates: { latitude: 1.30866, longitude: 103.83884 },
//   },
//   {
//     name: { en: "Novena", zh: "诺维娜" },
//     coordinates: { latitude: 1.32488, longitude: 103.83588 },
//   },
//   {
//     name: { en: "Orchard", zh: "乌节" },
//     coordinates: { latitude: 1.30381, longitude: 103.83358 },
//   },
//   {
//     name: { en: "Outram", zh: "欧南" },
//     coordinates: { latitude: 1.28286, longitude: 103.84206 },
//   },
//   {
//     name: { en: "Pasir Ris", zh: "巴西立" },
//     coordinates: { latitude: 1.37632, longitude: 103.94849 },
//   },
//   {
//     name: { en: "Paya Lebar", zh: "巴耶利峇" },
//     coordinates: { latitude: 1.3594, longitude: 103.91478 },
//   },
//   {
//     name: { en: "Pioneer", zh: "先驱" },
//     coordinates: { latitude: 1.31259, longitude: 103.67299 },
//   },
//   {
//     name: { en: "Punggol", zh: "榜鹅" },
//     coordinates: { latitude: 1.40536, longitude: 103.91004 },
//   },
//   {
//     name: { en: "Queenstown", zh: "女皇镇" },
//     coordinates: { latitude: 1.29347, longitude: 103.78608 },
//   },
//   {
//     name: { en: "River Valley", zh: "里峇峇利" },
//     coordinates: { latitude: 1.29805, longitude: 103.83397 },
//   },
//   {
//     name: { en: "Rochor", zh: "梧槽" },
//     coordinates: { latitude: 1.30426, longitude: 103.85326 },
//   },
//   {
//     name: { en: "Seletar", zh: "实里达" },
//     coordinates: { latitude: 1.41434, longitude: 103.8771 },
//   },
//   {
//     name: { en: "Sembawang", zh: "三巴旺" },
//     coordinates: { latitude: 1.45575, longitude: 103.81883 },
//   },
//   {
//     name: { en: "Sengkang", zh: "盛港" },
//     coordinates: { latitude: 1.39204, longitude: 103.8877 },
//   },
//   {
//     name: { en: "Serangoon", zh: "实龙岗" },
//     coordinates: { latitude: 1.3652, longitude: 103.86782 },
//   },
//   {
//     name: { en: "Simpang", zh: "新邦" },
//     coordinates: { latitude: 1.44332, longitude: 103.85197 },
//   },
//   {
//     name: { en: "Singapore River", zh: "新加坡河" },
//     coordinates: { latitude: 1.29011, longitude: 103.84121 },
//   },
//   {
//     name: { en: "Southern Islands", zh: "南部群岛" },
//     coordinates: { latitude: 1.248, longitude: 103.83005 },
//   },
//   {
//     name: { en: "Straits View", zh: "海峡景" },
//     coordinates: { latitude: 1.27125, longitude: 103.85877 },
//   },
//   {
//     name: { en: "Sungei Kadut", zh: "双溪加株" },
//     coordinates: { latitude: 1.41813, longitude: 103.75746 },
//   },
//   {
//     name: { en: "Tampines", zh: "淡滨尼" },
//     coordinates: { latitude: 1.34366, longitude: 103.95426 },
//   },
//   {
//     name: { en: "Tanglin", zh: "东陵" },
//     coordinates: { latitude: 1.30837, longitude: 103.81759 },
//   },
//   {
//     name: { en: "Tengah", zh: "登加" },
//     coordinates: { latitude: 1.36384, longitude: 103.72875 },
//   },
//   {
//     name: { en: "Toa Payoh", zh: "大巴窑" },
//     coordinates: { latitude: 1.33668, longitude: 103.86103 },
//   },
//   {
//     name: { en: "Tuas", zh: "大士" },
//     coordinates: { latitude: 1.28383, longitude: 103.63069 },
//   },
//   {
//     name: { en: "Western Water Catchment", zh: "西部集水区" },
//     coordinates: { latitude: 1.38634, longitude: 103.69429 },
//   },
//   {
//     name: { en: "Woodlands", zh: "兀兰" },
//     coordinates: { latitude: 1.44068, longitude: 103.7877 },
//   },
//   {
//     name: { en: "Yishun", zh: "义顺" },
//     coordinates: { latitude: 1.41497, longitude: 103.83695 },
//   },
// ];

export const REGION_LIST = REGIONS.map(region => region.label);

export const isRegion = (area) => REGION_LIST.includes(area);

export const getRegionOfArea = (area) => {
  for (let i = 0; i < REGIONS.length; i += 1) {
    if (REGIONS[i].areas.find(a => a === area)) return REGIONS[i].label;
  }
};

export const MARKET_SEGMENTS = [
  {
    label: 'Core Central Region',
  },
  {
    label: 'Rest of Central Region',
  },
  {
    label: 'Outside Central Region',
  },
];

export const MARKET_SEGMENT_ID_MAP = {
  [MARKET_SEGMENTS[0].label]: 'CCR',
  [MARKET_SEGMENTS[1].label]: 'RCR',
  [MARKET_SEGMENTS[2].label]: 'OCR',
};

export const MARKET_SEGMENT_ID_REVERSE_MAP = {
  'CCR': [MARKET_SEGMENTS[0].label],
  'RCR': [MARKET_SEGMENTS[1].label],
  'OCR': [MARKET_SEGMENTS[2].label],
};

export const TENURE_LIST = [
  {
    label: "30 yrs",
    value: "30",
  },
  {
    label: "60 yrs",
    value: "60",
  },
  {
    label: "70 yrs",
    value: "70",
  },
  {
    label: "99 yrs",
    value: "99",
  },
  {
    label: "100 yrs",
    value: "100",
  },
  {
    label: "102 yrs",
    value: "102",
  },
  {
    label: "103 yrs",
    value: "103",
  },
  {
    label: "999 yrs",
    value: "999",
  },
  {
    label: "Freehold",
    value: "Freehold",
  },
  {
    label: "Leasehold",
    value: "Leasehold",
  },
];

export const getTenureValue = (value) => {
  const tenure = TENURE_LIST.find(t => t.value === value);
  if (!tenure) return null;
  return tenure.value;
};

export const getAllTenureIntValues = () => TENURE_LIST
  .map(tenure => tenure.value === "Freehold" ? null : parseInt(tenure.value));

export const getTenureLabel = (value) => {
  if (value.toLowerCase().includes('Freehold')) return 'Freehold';
  const tenure = TENURE_LIST.find(t => t.value === value);
  if (!tenure) return 'Unknown';
  return tenure.label;
};

export const CONDO_PROPERTY_TYPE_DISPLAY_LIST = [
  { label: "Apartment/Condo", value: "Apt/Condo" },
  { label: "Executive Condo", value: "Executive Condominium" },
];

export const LANDED_PROPERTY_TYPE_DISPLAY_LIST = [
  { label: "Detached House", value: "Detached House" },
  { label: "Semi-Detached", value: "Semi-Detached House" },
  { label: "Terrace House", value: "Terrace House" },
];

export const PROPERTY_TYPE_DISPLAY_LIST = [
  { label: "Apartment/Condo", value: "Apt/Condo" },
  { label: "Detached House", value: "Detached House" },
  { label: "Semi-Detached", value: "Semi-Detached House" },
  { label: "Terrace House", value: "Terrace House" },
  { label: "Executive Condo", value: "Executive Condominium" },
];

export const HDB_PROPERTY_TYPE_DISPLAY_LIST = [
  { label: "1 Room", value: "1 ROOM" },
  { label: "2 Room", value: "2 ROOM" },
  { label: "3 Room", value: "3 ROOM" },
  { label: "4 Room", value: "4 ROOM" },
  { label: "5 Room", value: "5 ROOM" },
  { label: "Executive", value: "EXECUTIVE" },
  // { label: "Multi-Gen", value: "MULTI-GENERATION" },
];

export const PROPERTY_TYPE_LIST = [
  { label: "Apartment", value: "Apartment" },
  { label: "Condo", value: "Condominium" },
  { label: "Detached House", value: "Detached House" },
  { label: "Semi-Detached", value: "Semi-Detached House" },
  { label: "Terrace House", value: "Terrace House" },
  { label: "Executive Condo", value: "Executive Condominium" },
];

export const INT_PROPERTY_TYPE_LIST = [
  ...PROPERTY_TYPE_LIST,
  { label: "Non-Landed", value: "Non-landed Properties" },
];

export const RENTAL_PROPERTY_TYPE_LIST = [
  { label: "Non-Landed", value: "Non-landed Properties" },
  { label: "Detached House", value: "Detached House" },
  { label: "Semi-Detached", value: "Semi-Detached House" },
  { label: "Terrace House", value: "Terrace House" },
  { label: "Executive Condo", value: "Executive Condominium" },
];

export const SALE_TYPE_LIST = [
  // { label: "New Sale", value: "New Sale" },
  { label: "Sub Sale", value: "Sub Sale" },
  { label: "Resale", value: "Resale" },
];

export const SALE_TYPE_FULL_LIST = [
  { label: "New Sale", value: "New Sale" },
  { label: "Sub Sale", value: "Sub Sale" },
  { label: "Resale", value: "Resale" },
];

export const getPropertyFileName = (project, street) => {
  let str = `${project}-${street}`;
  // Replace all non-alphanumeric characters with underscores
  str = str.replace(/\W+/g, '_');
  // Replace multiple underscores with a single underscore
  str = str.replace(/_+/g, '_');
  // Strip leading and trailing underscores
  str = str.replace(/^_+|_+$/g, '');
  return str.toLowerCase();
};

export const transformMapboxStyleRequest = (url, resourceType) => {
  const mapboxKey = isDevMode
    ? "pk.eyJ1IjoieGluaW1pYyIsImEiOiJjbHo5b2wwYXQwZmlyMm1yNndneXRhbzhpIn0.yKlYXtwSwBEAtOFp5diUuA"
    : "pk.eyJ1IjoieGluaW1pYyIsImEiOiJjbHo5b3BjdGMwYm5oMmpwamRja240anh6In0.oNZE1cjnr-0KNaz6CjBdkQ";

  if (isMapboxURL(url)) {
    return transformMapboxUrl(url, resourceType, mapboxKey)
  }
  
  return {url}
}

export const MAP_THEMES = [
  {
    label: 'OneMap',
    style: 'https://www.onemap.gov.sg/maps/json/raster/mbstyle/Default.json',
    icon: '/img/general/map_onemap.png'
  },
  {
    label: 'Minimalist',
    style: 'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json',
    icon: '/img/general/map_default.png'
  },
  {
    label: 'Street',
    style: 'mapbox://styles/mapbox/streets-v12',
    icon: '/img/general/map_street.png'
  },
  {
    label: 'Satellite',
    style: 'mapbox://styles/mapbox/satellite-streets-v12',
    icon: '/img/general/map_satellite.png'
  },
  {
    label: 'Dark',
    style: 'mapbox://styles/mapbox/dark-v11',
    icon: '/img/general/map_dark.png'
  },
  {
    label: 'Outdoors',
    style: 'mapbox://styles/mapbox/outdoors-v12',
    icon: '/img/general/map_outdoors.png'
  },
];

export const getMapStyleFromTheme = (theme) => MAP_THEMES.find(t => t.label === theme)?.style ?? MAP_THEMES[0].style;

export const DEFAULT_MAP_THEME = 'Outdoors';

export const convertIntToPropertyType = (val) => {
  if (!val) return [NA];
  const types = [];
  INT_PROPERTY_TYPE_LIST.forEach((t, i) => {
    const b = (1 << i) & val;
    if (b > 0) {
      types.push(t.value);
    }
  });
  return types.filter(v => !!v);
};

export const convertPropertyTypeToInt = (types) => {
  if (!types || types.length === 0) return 0;
  let val = 0;
  types.forEach(t => {
    for (let i = 0; i < INT_PROPERTY_TYPE_LIST.length; i++) {
      if (INT_PROPERTY_TYPE_LIST[i].value === t) {
        const b = (1 << i);
        val += b;
        return;
      }
    }
  });
  return val;
};

export const convertIntToTenureType = (val) => {
  if (!val) return [NA];
  return val.split(',').map(v => {
    if (v === '-') return NA;
    if (v === '0') return 'Freehold';
    if (v.endsWith('l')) return `${v.slice(0, -1)} yrs leasehold`;
    const chunks = v.split('&');
    const d = chunks[0];
    const y = chunks[1];
    return `${d} yrs from ${y}`;
  }).filter(t => !t.includes('undefined') && t !== NA);
};

export const convertIntToSaleType = (val) => SALE_TYPE_FULL_LIST[val].value;

const ID_REPLACEMENTS = {
  "AVENUE": "*a",
  "APARTMENT": "*x",
  "BUKIT": "*b",
  "BLOCK": "*5",
  "COURT": "*c",
  "CIRCLE": "*y",
  "CENTRE": "*7",
  "CRESCENT": "*9",
  "CONDOMINIUM": "*z",
  "DRIVE": "*d",
  "ESTATE": "*e",
  "GARDEN": "*g",
  "GROVE": "*.",
  "GEYLANG": "*+",
  "HILL": "*h",
  "HOUSE": "*-",
  "HEIGHTS": "*8",
  "HOLLAND": "*?",
  "JALAN": "*j",
  "LORONG": "*l",
  "LANE": "*1",
  "MANSION": "*m",
  "MOUNT": "*2",
  "PARK": "*p",
  "QUAY": "*q",
  "ROAD": "*r",
  "STREET": "*s",
  "SQUARE": "*o",
  "SERANGOON": "*:",
  "SEMBAWANG": "*;",
  "TOWER": "*t",
  "TERRACE": "*3",
  "RISE": "*4",
  "RESIDENCE": "*6",
  "VIEW": "*v",
  "WALK": "*w",
  "PRIMARY": "*P",
  "SCHOOL": "*S",
  "SECONDARY": "*V",
  "HIGH": "*H",
  "PRESCHOOL": "*N",
  "STATION": "*n",
  "EXIT": "*E",
  "RESIDENTIAL": "*R",
  "LANDED HOUSING DEVELOPMENT": "*L",
  "SINGAPORE": "*C",
  "MOE KINDERGARTEN": "*k",
  "KINDERGARTEN": "*K",
  "BUILDING": "*B",
  "URBAN": "*u",
  "JUNCTION": "*J",
  "UNIVERSITY": "*U",
  "DEVELOPMENT": "*D",
  "NORTH": "*f",
  "SOUTH": "*F",
  "CLOSE": "*X",
  "VILLAS": "*Q",
  "CHUAN": "*Y",
  "THOMSON": "*T",
  "EVERGREEN": "*O",
  "JURONG": "*A",
  "LENTOR": "*G",
  "ISLAND": "*i",
  "SUNSET": "*Z",
  "TAMAN": "*M",
  "SAINT": "*[",
  "YIO CHU KANG": "*]",
  "CORONATION": "*'",
  "VILLE": "*<",
  "BRIGHT": "*>",
  "KEMBANGAN": "*/",
  "COLLECTION": "*`",
  "SPRING": "*~",
  "TANJONG": "*I",
  "PASIR": "*!",
  "REGENCY": "*@",
  "WATER": "*W",
  "THE": "%"
}

export const expandId = (id, marker = null) => {
  if (!id) return null;

  // handle same secondary name
  if (id === '***') return marker;

  let newId = id;

  // handle area words
  for (let i = 0; i < AREA_LIST.length; i++) {
    const replacement = `!${i}!`;
    newId = newId.split(replacement).join(AREA_LIST[i].toUpperCase());
  }

  // handle common words
  for (let i = 0; i < Object.keys(ID_REPLACEMENTS).length; i++) {
    const replacement = Object.keys(ID_REPLACEMENTS)[i];
    newId = newId.split(ID_REPLACEMENTS[replacement]).join(replacement);
  }

  return newId;
}

export const unravelId = (id) => {
  const sameStreet = !id.includes(',');
  let newId = id;
  for (let i = 0; i < Object.keys(ID_REPLACEMENTS).length; i++) {
    const replacement = Object.keys(ID_REPLACEMENTS)[i];
    newId = newId.split(ID_REPLACEMENTS[replacement]).join(replacement);
  }
  return sameStreet ? `${newId},${newId}` : newId;
};

export const getHdbUnitFromTransaction = (target, tx) => {
  return {
    unit: tx.flatType,
    block: target.block,
    floor: tx.storeyMin,
    house: tx.flatTypeRaw,
  };
};

export const getBlockUnitFromAddress = (address) => {
  // check for unit number in address and handle all units within a block or street number
  const unitNumberMatch = address.match(/#\d+-\d+/);
  if (unitNumberMatch) {
    const unitNumber = unitNumberMatch[0];
    const blockStreet = address.split(unitNumber)[0].trim();
    const comps = unitNumber.split('-');
    const floor = parseInt(comps[0].replace('#', ''));
    const unit = parseInt(comps[1]);
    return {
      unit: unitNumber,
      block: blockStreet,
      floor,
      house: unit,
    };
  }

  // fallback if no unit number matched is to get the street one instead
  const blockMatch = address.match(/^\d+/);
  const blockStreet = blockMatch ? blockMatch[0] : '';
  return {
    unit: NA,
    block: !!blockStreet ? address : NA,
    floor: 1,
    house: 1,
  };
};

export const calculateAnnualized = (index, transactions) => {
  const total = transactions?.length;
  if (total > 1 && index !== (total - 1)) {
    const innerPrice = transactions[index + 1].price;
    const outerPrice = transactions[index].price;
    const innerDate = new Date(transactions[index + 1]['ts']);
    const outerDate = new Date(transactions[index]['ts']);
    let diff = (outerDate.getFullYear() - innerDate.getFullYear()) * 12;
    diff -= innerDate.getMonth();
    diff += outerDate.getMonth();
    const years = diff / 12;
    return (
      (outerPrice / innerPrice) **
      (1 / Math.max(1.0, years)) - 1
    ) * 100.0;
  }
  return null;
};

export const HDB_TOWNS = [
  { name: 'ANG MO KIO', lat: 1.3691, lng: 103.8454 },
  { name: 'BEDOK', lat: 1.3236, lng: 103.9273 },
  { name: 'BUKIT BATOK', lat: 1.3537, lng: 103.7540 },
  { name: 'BUKIT MERAH', lat: 1.2770, lng: 103.8198 },
  { name: 'BUKIT PANJANG', lat: 1.3840, lng: 103.7710 },
  { name: 'CENTRAL AREA', lat: 1.2903, lng: 103.8519 },
  { name: 'CHOA CHU KANG', lat: 1.3850, lng: 103.7445 },
  { name: 'CLEMENTI', lat: 1.3159, lng: 103.7653 },
  { name: 'GEYLANG', lat: 1.3180, lng: 103.8830 },
  { name: 'HOUGANG', lat: 1.3711, lng: 103.8928 },
  { name: 'JURONG WEST', lat: 1.3404, lng: 103.7074 },
  { name: 'KALLANG/WHAMPOA', lat: 1.3105, lng: 103.8620 },
  { name: 'PASIR RIS', lat: 1.3681, lng: 103.9496 },
  { name: 'PUNGGOL', lat: 1.4032, lng: 103.9020 },
  { name: 'SERANGOON', lat: 1.3561, lng: 103.8700 },
  { name: 'TOA PAYOH', lat: 1.3346, lng: 103.8566 },
  { name: 'WOODLANDS', lat: 1.4360, lng: 103.7865 },
  { name: 'YISHUN', lat: 1.4294, lng: 103.8357 },
  { name: 'JURONG EAST', lat: 1.3330, lng: 103.7420 },
  { name: 'SEMBAWANG', lat: 1.4492, lng: 103.8185 },
  { name: 'TAMPINES', lat: 1.3496, lng: 103.9568 },
  { name: 'QUEENSTOWN', lat: 1.2945, lng: 103.8030 },
  { name: 'BISHAN', lat: 1.3508, lng: 103.8480 },
  { name: 'BUKIT TIMAH', lat: 1.3294, lng: 103.8021 },
  { name: 'MARINE PARADE', lat: 1.3020, lng: 103.9050 },
  { name: 'SENGKANG', lat: 1.3893, lng: 103.8950 }
];
