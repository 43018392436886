import { useEffect, useState } from 'react';
import CustomTable from '@/components/transactions/CustomTable';
import {
  MAP_MODE_HDB,
  MAP_MODE_LANDED,
  getHdbBlockLabel,
  getProjectLabel,
} from '@/utils/map';
import { STANDARD_PAGE_CONFIG } from '@/utils/table';
import {
  convertDisplayDate,
  convertDisplayMonthYear
} from '@/utils/time';
import { noSelectClass } from '@/utils/user';
import { formatDistanceLabel } from '@/utils/convert';
import Checkbox from '@/components/common/sidebar/Checkbox';

const DIST_SELECTIONS = [
  {
    label: '200m',
    value: 200
  },
  {
    label: '400m',
    value: 400
  },
  {
    label: '1 km',
    value: 1000
  },
];

const PERIOD_SELECTIONS = [
  {
    label: 'Past 1 Year',
    value: '12m',
    diff: 31556952000
  },
  {
    label: 'Past 6 Months',
    value: '6m',
    diff: 15778476000
  },
  {
    label: 'Past 3 Months',
    value: '3m',
    diff: 7889238000
  },
  {
    label: 'Past 1 Month',
    value: '1m',
    diff: 2629746000
  },
];

const NearbyTransactions = ({
  user,
  target,
  transactions,
  goToProperty,
  focus,
  setFocus,
  setUnfadedProps
}) => {
  const distSelectable = DIST_SELECTIONS;

  const allDistricts = [...transactions.reduce((s, t) => s.add(t.district), new Set())].filter(v => !!v).sort();

  const [period, setPeriod] = useState(PERIOD_SELECTIONS[0].value);
  const [dist, setDist] = useState(distSelectable.length === 1 ? DIST_SELECTIONS[0].value : DIST_SELECTIONS[1].value);
  const [district, setDistrict] = useState(null);

  const isHdb = target.mode === MAP_MODE_HDB;

  const filter = (transactions) => {
    const selectedPeriod = PERIOD_SELECTIONS.find(s => s.value === period);
    const diff = new Date().getTime() - selectedPeriod.diff
    const data = transactions
      .filter(t => t.sale_date >= diff)
      .filter(t => t.distance <= dist)
      .filter(t => district === null || !!district[t.district]);
    return data;
  };

  const [data, setData] = useState(filter(transactions));

  useEffect(() => {
    const newUnfaded = new Set([isHdb ? target.id : target.key]);
    data.forEach(t => {
      if (isHdb) {
        newUnfaded.add(t.postal);
      } else if (target.mode === MAP_MODE_LANDED) {
        newUnfaded.add(`${t.name}_${t.projectId}`)
      } else {
        newUnfaded.add(`${t.name}_${t.projectId}`)
      }
    });
    setUnfadedProps(newUnfaded);
    setFocus({ target, zoomRadius: dist });
  }, [data]);

  if (focus?.fine !== dist / 1000) {
    setFocus({
      ...focus,
      fine: dist / 1000
    });
  }

  useEffect(() => {
    setData(filter(transactions));
  }, [period, dist, district]);

  const DEFAULT_COLUMNS = isHdb
    ? [
        {
          Header: 'HDB',
          accessor: 'name',
          disableGlobalFilter: true,
          Cell: (row) =>
            <a
              className="text-blue-1 cursor-pointer"
              onClick={() => goToProperty(row)}
            >
              {getHdbBlockLabel(row.data[row.cell.row.index])}
            </a>
        },
        {
          Header: 'Distance',
          accessor: 'distance',
          disableGlobalFilter: true,
          width: 60,
          Cell: (row) => <div>{row.cell.value === 0 ? '-' : formatDistanceLabel(row.cell.value)}</div>
        },
        {
          Header: 'Sale Date',
          accessor: 'sale_date',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{convertDisplayMonthYear(new Date(row.cell.value))}</div>
        },
        {
          Header: 'Transacted Price ($)',
          accessor: 'transacted_price',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Unit Price ($psf)',
          accessor: 'unitPrice',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toFixed(2)}</div>
        },
        {
          Header: 'Area (sqft)',
          accessor: 'area',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Flat Type',
          accessor: 'flatType',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Storey',
          accessor: 'storeyRange',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Flat Model',
          accessor: 'flatModel',
          disableGlobalFilter: true,
          width: 120,
          Cell: (row) => <div>{row.cell.value?.toUpperCase()}</div>
        },
        {
          Header: 'Postal Code',
          accessor: 'postal',
          disableGlobalFilter: true,
          width: 80,
        },
      ]
    : [
        {
          Header: 'Project',
          accessor: 'name',
          disableGlobalFilter: true,
          Cell: (row) =>
            <a
              className="text-blue-1 cursor-pointer"
              onClick={() => goToProperty(row)}
            >
              {target.mode === MAP_MODE_LANDED
                ? row.data[row.cell.row.index].name // row.data[row.cell.row.index].marker
                : getProjectLabel(row.data[row.cell.row.index].marker, row.data[row.cell.row.index].name)
              }
            </a>
        },
        {
          Header: 'Distance',
          accessor: 'distance',
          disableGlobalFilter: true,
          width: 60,
          Cell: (row) => <div>{row.cell.value === 0 ? '-' : formatDistanceLabel(row.cell.value)}</div>
        },
        {
          Header: 'District',
          accessor: 'district',
          disableGlobalFilter: true,
          width: 60
        },
        {
          Header: 'Sale Date',
          accessor: 'sale_date',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{convertDisplayDate(new Date(row.cell.value))}</div>
        },
        {
          Header: 'Type of Sale',
          accessor: 'type',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Transacted Price ($)',
          accessor: 'transacted_price',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Unit Price ($psf)',
          accessor: 'unitPrice',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toFixed(2)}</div>
        },
        {
          Header: 'Area (sqft)',
          accessor: 'area',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Address',
          accessor: 'address',
          disableGlobalFilter: true,
          disableSortBy: true,
        },
        {
          Header: 'Postal Code',
          accessor: 'postal',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Property Type',
          accessor: 'propertyType',
          disableGlobalFilter: true,
          width: 90,
        },
        {
          Header: 'Type of Area',
          accessor: 'areaType',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Purchaser Address Indicator',
          accessor: 'purchaserAddrIndicator',
          disableGlobalFilter: true,
          width: 80,
        },
      ];

  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  return (
    <div className="mb-50">
      <div className={`data-table ${noSelectClass(user)}`}>
        <div className="mb-10 ml-5">
          <h3 className="text-16 fw-500">Recent transactions</h3>
        </div>
        
        <div className="d-flex mb-20">

          <div className="p-2 dropdown js-dropdown js-category-active compare-chart-item proj-comp-spad">
            <div
              className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-12 lh-12"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
              aria-expanded="false"
              data-bs-offset="0,10"
            >
              <span className="js-dropdown-title">
                <span className="text-blue-1 fw-600"></span>
                <span className="js-dropdown-dist">Within {
                  DIST_SELECTIONS.find(v => v.value === dist).label
                }</span>
              </span>
              <i className="icon icon-chevron-sm-down text-7 ml-10" />
            </div>
            <div className="toggle-element -dropdown dropdown-menu">
              <div className="text-13 y-gap-15 js-dropdown-list">
                {distSelectable.map(option => (
                  <div key={`dist-${option.value}`}>
                    <button
                      className={`d-block js-dropdown-link ${
                        dist === option.value ? "text-blue-1 " : "text-dark-1"
                      }`}
                      onClick={() => {
                        setDist(option.value);
                        document.querySelector(".js-dropdown-dist").textContent = option.label;
                      }}
                    >
                      {option.label}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="p-2 dropdown js-dropdown js-category-active compare-chart-item proj-comp-spad">
            <div
              className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-12 lh-12"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
              aria-expanded="false"
              data-bs-offset="0,10"
            >
              <span className="js-dropdown-title">
                <span className="text-blue-1 fw-600"></span>
                <span className="js-dropdown-time">{
                  PERIOD_SELECTIONS.find(v => v.value === period).label
                }</span>
              </span>
              <i className="icon icon-chevron-sm-down text-7 ml-10" />
            </div>
            <div className="toggle-element -dropdown dropdown-menu">
              <div className="text-13 y-gap-15 js-dropdown-list">
                {PERIOD_SELECTIONS.map(option => (
                  <div key={`period-${option.value}`}>
                    <button
                      className={`d-block js-dropdown-link ${
                        period === option.value ? "text-blue-1 " : "text-dark-1"
                      }`}
                      onClick={() => {
                        setPeriod(option.value);
                        document.querySelector(".js-dropdown-time").textContent = option.label;
                      }}
                    >
                      {option.label}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {!isHdb
            && <div className="p-2 dropdown js-dropdown js-category-active compare-chart-item proj-comp-spad">
              <div
                className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-12 lh-12"
                data-bs-toggle="dropdown"
                data-bs-auto-close="true"
                aria-expanded="false"
                data-bs-offset="0,10"
              >
                <span className="js-dropdown-title">
                  <span className="text-blue-1 fw-600"></span>
                  <span className="js-dropdown-d">{
                    district === null
                      ? 'District View'
                      : `District ${Object.keys(district).sort().join(', ')}`
                  }</span>
                </span>
                <i className="icon icon-chevron-sm-down text-7 ml-10" />
              </div>
              <div className="toggle-element -dropdown dropdown-menu">
                <div className="text-13 y-gap-15 js-dropdown-list">
                  {allDistricts.map(d => (
                    <div key={`d-${d}`}>
                      <Checkbox
                        label={d}
                        value={!district || district[d]}
                        setValue={v => {
                          if (!district) {
                            setDistrict({ [d]: true });
                          } else {
                            const newDistrict = { ...district };
                            newDistrict[d] = v;
                            setDistrict(newDistrict);
                          }
                        }}
                        textSize="14"
                      />
                    </div>
                    ))}
                </div>
              </div>
            </div>
          }

        </div>

        <CustomTable
          data={data}
          tableConfig={STANDARD_PAGE_CONFIG}
          defaultColumns={DEFAULT_COLUMNS}
          fullColumns={fullColumns}
          setFullColumns={setFullColumns}
          columns={columns}
          setColumns={setColumns}
          hideSearchInput
          hidePageButton
        />

      </div>
    </div>
  );
};

export default NearbyTransactions;
