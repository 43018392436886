import { useEffect, useState } from 'react';

import CustomTable from '@/components/transactions/CustomTable';
import { STANDARD_PAGE_CONFIG } from '@/utils/table';
import { MAP_MODE_HDB } from '@/utils/map';

const TABLE_FIELDS = [
  /* General */
  { key: 'score', label: 'REALSCORE' },
  { key: 'developer', label: 'Developer' },
  { key: 'propertyTypes', label: 'Property Type'},
  { key: 'completion', label: 'Completion' },
  { key: 'total', label: 'Total Units' },
  { key: 'size', label: 'Unit Size' },
  { key: 'tenuresList', label: 'Tenure' },
  /* Location */
  { key: 'marketSegment', label: 'Market Segment' },
  { key: 'area', label: 'Planning Area' },
  { key: 'region', label: 'Region' },
  { key: 'district', label: 'District' },
  { key: 'sector', label: 'Postal Sector' },
  /* Transactions */
  { key: 'launchSold', label: 'Sold At Launch' },
  { key: 'buyersHdb', label: 'HDB Buyers' },
  { key: 'devUnsold', label: 'Developer Unsold' },
  { key: 'totalTxAmt', label: 'Total Transacted' },
  { key: 'lastTxDate', label: 'Last Transacted' },
  { key: 'fairPsf', label: 'Past 3 month PSF' },
  /* Profitability */
  { key: 'profitablePerc', label: 'Profitable %' },
  { key: 'txProfit', label: '# Profitable' },
  { key: 'txPerc6Profit', label: '> 6% Annualized' },
  { key: 'txUnprofit', label: '# Unprofitable' },
  { key: 'txTotal', label: 'Total Transactions' },
  { key: 'totalProfits', label: 'Total Profits' },
  /* Rental */
  { key: 'last1mAvgRentPsf', label: 'Past Mth Rent PSF' },
  { key: 'last1mTotalRent', label: 'Past Mth # Rentals' },
  { key: 'last6mAvgRentPsf', label: 'Past 6 Mth Rent PSF' },
  { key: 'last6mTotalRent', label: 'Past 6 Mth # Rentals' },
];

const HDB_TABLE_FIELDS = [
  /* General */
  { key: 'completion', label: 'Completion' },
  { key: 'total', label: 'Total Units' },
  { key: 'size', label: 'Unit Size' },
  /* Location */
  { key: 'town', label: 'Town' },
  /* Transactions */
  { key: 'lastTxDate', label: 'Last Transacted' },
  /* Profitability */
  { key: 'profitablePerc', label: 'Profitable %' },
  { key: 'txProfit', label: '# Profitable' },
  { key: 'txUnprofit', label: '# Unprofitable' },
  { key: 'txTotal', label: 'Total Transactions' },
  /* Rental */
  // { key: 'last1mAvgRentPsf', label: 'Past Mth Rent PSF' },
  // { key: 'last1mTotalRent', label: 'Past Mth # Rentals' },
  // { key: 'last6mAvgRentPsf', label: 'Past 6 Mth Rent PSF' },
  // { key: 'last6mTotalRent', label: 'Past 6 Mth # Rentals' },
];

const CompareTable = ({
  data,
  target,
  getProjectLabel,
  // focusOnProperty,
  onHeaderClick
}) => {
  const DEFAULT_COLUMNS = [
    {
      Header: '',
      accessor: 'header',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 100,
    },
    ...Object.keys(data).map(projKey => ({
      Header: getProjectLabel(projKey),
      accessor: projKey,
      disableGlobalFilter: true,
      disableSortBy: true,
    }))
  ];

  const formattedData = {};
  Object.keys(data).forEach(projKey => {
    const d = data[projKey];
    if (target.mode === MAP_MODE_HDB) {
      formattedData[projKey] = {
        ...d,
        size: d.minSize
          ? (d.minSize === d.maxSize ? `${d.minSize.toLocaleString()} sqft` : `${d.minSize?.toLocaleString()} - ${d.maxSize?.toLocaleString()} sqft`)
          : '',
        total: d.totalUnits === -1 ? '-' : d.totalUnits.toLocaleString(),
        profitablePerc: d.profitable !== null ? `${d.profitable.toFixed(0)}%` : '-',
        txProfit: d.profitTx !== null ? d.profitTx.toLocaleString() : '-',
        txUnprofit: d.unprofitTx !== null ? d.unprofitTx.toLocaleString() : '-',
        txTotal: d.totalTx !== null ? d.totalTx.toLocaleString() : '-',
        // last6mAvgRentPsf: d.last6mAvgRentPsf !== null ? `$${d.last6mAvgRentPsf.toFixed(2)} psf` : '-',
        // last6mTotalRent: d.last6mTotalRent !== null ? d.last6mTotalRent.toLocaleString() : '-',
        // last1mAvgRentPsf: d.last1mAvgRentPsf !== null ? `$${d.last1mAvgRentPsf.toFixed(2)} psf` : '-',
        // last1mTotalRent: d.last1mTotalRent !== null ? d.last1mTotalRent.toLocaleString() : '-',
      };
    } else {
      formattedData[projKey] = {
        ...d,
        propertyTypes: d.types ? d.types.join(', ') : '-',
        size: d.minSize
          ? (d.minSize === d.maxSize ? `${d.minSize.toLocaleString()} sqft` : `${d.minSize?.toLocaleString()} - ${d.maxSize?.toLocaleString()} sqft`)
          : '',
        tenuresList: d.tenures ? d.tenures.join(', ') : '-',
        total: d.totalUnits === -1 ? '-' : d.totalUnits.toLocaleString(),
        launchSold: d.soldAtLaunch !== null ? d.soldAtLaunch.toLocaleString() : '-',
        buyersHdb: d.hdbBuyers ? `${d.hdbBuyers.toFixed(0)}%` : '-',
        devUnsold: d.unsoldUnits !== null ? d.unsoldUnits.toLocaleString() : '-',
        profitablePerc: d.profitable !== null ? `${d.profitable.toFixed(0)}%` : '-',
        txProfit: d.profitTx !== null ? d.profitTx.toLocaleString() : '-',
        txPerc6Profit: d.perc6ProfitTx !== null ? d.perc6ProfitTx.toLocaleString() : '-',
        txUnprofit: d.unprofitTx !== null ? d.unprofitTx.toLocaleString() : '-',
        txTotal: d.totalTx !== null ? d.totalTx.toLocaleString() : '-',
        totalProfits: d.totalProfits !== null ? `$${d.totalProfits.toLocaleString()}` : '-',
        last6mAvgRentPsf: d.last6mAvgRentPsf !== null ? `$${d.last6mAvgRentPsf.toFixed(2)} psf` : '-',
        last6mTotalRent: d.last6mTotalRent !== null ? d.last6mTotalRent.toLocaleString() : '-',
        last1mAvgRentPsf: d.last1mAvgRentPsf !== null ? `$${d.last1mAvgRentPsf.toFixed(2)} psf` : '-',
        last1mTotalRent: d.last1mTotalRent !== null ? d.last1mTotalRent.toLocaleString() : '-',
      };
    }
  });

  const tableData = (target.mode === MAP_MODE_HDB ? HDB_TABLE_FIELDS : TABLE_FIELDS).map(f => {
    const rowData = {
      header: f.label
    };
    Object.keys(formattedData).forEach(projKey => {
      const value = formattedData[projKey][f.key] ?? '';
      rowData[projKey] = value;
    });
    return rowData;
  });

  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  useEffect(() => {
    setFullColumns(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
    setColumns(DEFAULT_COLUMNS);
  }, [DEFAULT_COLUMNS]);

  return (
    <CustomTable
      name="comparetab-table"
      data={tableData}
      pageLimit={100}
      tableConfig={{STANDARD_PAGE_CONFIG}}
      defaultColumns={DEFAULT_COLUMNS}
      fullColumns={fullColumns}
      setFullColumns={setFullColumns}
      columns={columns}
      setColumns={setColumns}
      onHeaderClick={onHeaderClick}
      hideSearchInput
      hidePageButton
      hidePage
      disableCustomColumns
    />
  );
};

export default CompareTable;
