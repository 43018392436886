import PropTypes from "prop-types";

const districts = [
  {
    label: 'D1: Boat Quay, Cecil, Marina, Raffles Pl',
    value: '1',
  },
  {
    label: 'D2: 	Anson, Chinatown, Tanjong Pagar',
    value: '2',
  },
  {
    label: 'D3: Alexandra, Queenstown, Tiong Bahru',
    value: '3',
  },
  {
    label: 'D4: Harbourfront, Sentosa, Telok Blangah',
    value: '4',
  },
  {
    label: 'D5: Clementi, Pasir Panjang, Bouna Vista',
    value: '5',
  },
  {
    label: 'D6: City Hall, High Street, North Bridge',
    value: '6',
  },
  {
    label: 'D7: Bugis, Golden Mile, Middle Rd',
    value: '7',
  },
  {
    label: 'D8: Farrer Park, Little India, Serangoon Rd',
    value: '8',
  },
  {
    label: 'D9: Cairnhill, Orchard, River Valley',
    value: '9',
  },
  {
    label: 'D10: Tanglin, Holland, Bt Timah, Ardmore',
    value: '10',
  },
  {
    label: 'D11: Thomson, Watten Estate, Novena',
    value: '11',
  },
  {
    label: 'D12: Balestier, Toa Payoh',
    value: '12',
  },
  {
    label: 'D13: Braddell, Macpherson, Potong Pasir',
    value: '13',
  },
  {
    label: 'D14: Sims, Paya Lebar, Geylang, Eunos',
    value: '14',
  },
  {
    label: 'D15: Katong, Joo Chiat, Amber Rd',
    value: '15',
  },
  {
    label: 'D16: Bedok, Upper East Coast, Eastwood',
    value: '16',
  },
  {
    label: 'D17: Changi, Flora, Loyang',
    value: '17',
  },
  {
    label: 'D18: Pasir Ris, Simei, Tampines',
    value: '18',
  },
  {
    label: 'D19: Hougang, Ponggol, Sengkang',
    value: '19',
  },
  {
    label: 'D20: Ang Mo Kio, Bishan, Braddell',
    value: '20',
  },
  {
    label: 'D21: Upper Bt Timah, Clementi Park',
    value: '21',
  },
  {
    label: 'D22: Boon Lay, Jurong, Tuas',
    value: '22',
  },
  {
    label: 'D23: Hillview, Bt Panjang, Choa Chu Kang',
    value: '23',
  },
  {
    label: 'D24: Lim Chu Kang, Tengah',
    value: '24',
  },
  {
    label: 'D25: Woodgrove, Kranji, Woodlands',
    value: '25',
  },
  {
    label: 'D26: Springleaf, Tagore, Upper Thomson',
    value: '26',
  },
  {
    label: 'D27: Admiralty, Sembawang, Yishun',
    value: '27',
  },
  {
    label: 'D28: Seletar, Yio Chu Kang',
    value: '28',
  },
];

const DistrictFilter = (props) => {
  const { selection, setSelection } = props;

  const onSelectRegion = (e, region) => {
    const checked = e.target.checked;
    const newSelection = { ...selection, [region.value]: checked };
    setSelection(newSelection);
  }

  return (
    <>
      {districts.map((region, index) => (
        <>
          <div className="row y-gap-10 items-center justify-between" key={`region-select-${region.value}`}>
            <div className="col-auto">
              <div className="form-checkbox d-flex items-center">
                <input
                  type="checkbox"
                  name={"region-" + region.value}
                  checked={!!selection?.[region.value]}
                  onChange={e => onSelectRegion(e, region)}
                />
                <div className="form-checkbox__mark">
                  <div className="form-checkbox__icon icon-check" />
                </div>
                <div className="text-15 ml-10">{region.label}</div>
              </div>
            </div>
          </div>
        </>
      ))}
    </>
  );
};

DistrictFilter.propTypes = {
  selection: PropTypes.object.isRequired,
  setSelection: PropTypes.func.isRequired,
};

export default DistrictFilter;
