import { useState } from 'react';

import CustomTable from '@/components/transactions/CustomTable';
import {
  convertDisplayMonthYear,
  dateStrToMsec
} from '@/utils/time';
import { NA, convertFloorAreaRange } from '@/utils/convert';
import { STANDARD_PAGE_CONFIG } from '@/utils/table';

const RentalTable = ({
  data
}) => {
  const DEFAULT_COLUMNS = [
    {
      Header: 'Lease Commence',
      accessor: 'commence_date',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div>{convertDisplayMonthYear(new Date(row.cell.value))}</div>
    },
    {
      Header: 'Address',
      accessor: 'address',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: (row) => <div>
        {row.cell.value}
      </div>
    },
    {
      Header: 'Property Type',
      accessor: 'propertyType',
      disableGlobalFilter: true,
      width: 90,
    },
    {
      Header: 'No of Bedroom',
      accessor: 'bedrooms',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div>{(!row.cell.value || row.cell.value === -1) ? NA : row.cell.value.toFixed(0) }</div>
    },
    {
      Header: 'Monthly Rent ($)',
      accessor: 'monthlyRent',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div>${row.cell.value.toLocaleString()}</div>
    },
    {
      Header: 'Floor Area (sqft)',
      accessor: 'area',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div>{convertFloorAreaRange(row.cell.value).toLocaleString()}</div>
    },
    {
      Header: 'Rental ($psf)',
      accessor: 'estPsf',
      disableGlobalFilter: true,
      width: 80,
      Cell: (row) => <div>${row.cell.value.toFixed(2)}</div>
    }
  ];

  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  return (
    <CustomTable
      data={
        data.map(row => ({
          ...row,
          'commence_date': dateStrToMsec(row.leaseDate),
        })).sort((a, b) => b.commence_date - a.commence_date)
      }
      tableConfig={STANDARD_PAGE_CONFIG}
      defaultColumns={DEFAULT_COLUMNS}
      fullColumns={fullColumns}
      setFullColumns={setFullColumns}
      columns={columns}
      setColumns={setColumns}
      hideSearchInput
      hidePageButton
      showFilterButton
    />
  );
};

export default RentalTable;
