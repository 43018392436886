import { useState } from 'react';

import CustomTable from '@/components/transactions/CustomTable';
import { convertDisplayDate, convertDisplayMonthYear, dateStrToMsec } from '@/utils/time';
import { STANDARD_PAGE_CONFIG } from '@/utils/table';
import { Link } from 'react-router-dom';
import { calculateAnnualized, getBlockUnitFromAddress } from '@/utils/areas';
import { MAP_MODE_HDB } from '@/utils/map';

const TransactionTable = ({
  raw,
  data,
  target,
  onFilterButtonClick,
  goToUnit,
  goToProperty
}) => {
  const DEFAULT_COLUMNS = target.mode === MAP_MODE_HDB
    ? [
        {
          Header: 'Sale Date',
          accessor: 'sale_date',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{convertDisplayMonthYear(new Date(row.cell.value))}</div>
        },
        {
          Header: 'Transacted Price ($)',
          accessor: 'transacted_price',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Unit Price ($psf)',
          accessor: 'unitPrice',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toFixed(2)}</div>
        },
        {
          Header: 'Area (sqft)',
          accessor: 'area',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Flat Type',
          accessor: 'flatType',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Storey',
          accessor: 'storeyRange',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Flat Model',
          accessor: 'flatModel',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{row.cell.value?.toUpperCase()}</div>
        },
      ]
    : [
        {
          Header: 'Sale Date',
          accessor: 'sale_date',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{convertDisplayDate(new Date(row.cell.value))}</div>
        },
        // {
        //   Header: 'Type of Sale',
        //   accessor: 'type',
        //   disableGlobalFilter: true,
        //   width: 80,
        // },
        {
          Header: 'Transacted Price ($)',
          accessor: 'transacted_price',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Unit Price ($psf)',
          accessor: 'unitPrice',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toFixed(2)}</div>
        },
        {
          Header: 'Area (sqft)',
          accessor: 'area',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Address',
          accessor: 'address',
          disableGlobalFilter: true,
          disableSortBy: true,
          Cell: (row) => goToUnit
            ? <Link
                className="text-blue-1"
                data-bs-toggle="offcanvas"
                data-bs-target="#listingDetails"
                onClick={() => {
                  const txs = raw.filter(t => t.address === row.cell.value)
                    .map(t => ({ ...t, ts: dateStrToMsec(t.saleDate) }))
                    .sort((a, b) => b.ts - a.ts);
                  const blockInfo = getBlockUnitFromAddress(row.cell.value);
                  const unit = {
                    displayBlock: blockInfo.block,
                    tx: txs,
                    gains: txs.map((tx, i) => {
                      const gain = calculateAnnualized(i, txs);
                      return gain !== null ? {
                        ts: dateStrToMsec(tx.saleDate),
                        gain,  
                      } : null
                    }),
                    unit: blockInfo.unit
                  };
                  goToUnit(unit);
                }}
              >
                {row.cell.value}
              </Link>
            : (goToProperty
                ? <Link
                    className="text-blue-1"
                    onClick={() => {
                      goToProperty(row.data[row.cell.row.index])
                    }}
                  >
                    {row.cell.value}
                  </Link>
                : <div>{row.cell.value}</div>
              )
        },
        {
          Header: 'Postal Code',
          accessor: 'postal',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Property Type',
          accessor: 'propertyType',
          disableGlobalFilter: true,
          width: 90,
        },
        {
          Header: 'Type of Area',
          accessor: 'areaType',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Purchaser Address Indicator',
          accessor: 'purchaserAddrIndicator',
          disableGlobalFilter: true,
          width: 80,
        },
      ];

  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  const dateStrConvert = (dateStr) => {
    const chunks = dateStr.split('-');
    const day = chunks[0];
    const month = chunks[1];
    const year = chunks[2];
    return new Date(`${year}-${month}-${day}`).getTime();
  };

  return (
    <CustomTable
      name="tx-tb"
      data={
        data.map(row => ({
          ...row,
          'sale_date': dateStrConvert(row.saleDate),
          'transacted_price': parseInt(row.price),
        })).sort((a, b) => b.sale_date - a.sale_date)
      }
      tableConfig={STANDARD_PAGE_CONFIG}
      defaultColumns={DEFAULT_COLUMNS}
      fullColumns={fullColumns}
      setFullColumns={setFullColumns}
      columns={columns}
      setColumns={setColumns}
      hideSearchInput
      hidePageButton
      showFilterButton
      onFilterButtonClick={onFilterButtonClick}
    />
  );
};

export default TransactionTable;
