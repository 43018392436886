import { useState } from "react";

const selections = [
  "Areas",
  "Districts",
  "Market Segment",
];

const LocationSelector = ({ onChange }) => {
  const [activeSelection, setActiveSelection] = useState(selections[0]);

  const handleRatingClick = (selection, idx) => {
    const deselect = selection === activeSelection;
    setActiveSelection(deselect ? null : selection);
    onChange(deselect ? null : idx);
  };

  return (
    <>
      {selections.map((selection, idx) => (
        <div className="col-auto" key={selection}>
          <button
            className={`button -blue-1 bg-blue-1-05 text-blue-1 text-12 py-5 px-20 rounded-100 ${
              selection === activeSelection ? "active" : ""
            }`}
            onClick={() => handleRatingClick(selection, idx)}
          >
            {selection}
          </button>
        </div>
      ))}
    </>
  );
};

export default LocationSelector;
