import PropTypes from "prop-types";

const Checkbox = (props) => {
  const {
    label,
    value,
    setValue,
    textSize,
    colorHints,
    img
  } = props;

  const onCheckToggle = (e) => {
    const { checked } = e.target;
    setValue(checked);
  };

  return (
    <div className="col-auto col-12">
      <div className="form-checkbox d-flex items-center mt-10">
        <input
          type="checkbox"
          checked={!!value}
          onChange={e => onCheckToggle(e)}
        />
        <div className="form-checkbox__mark">
          <div className="form-checkbox__icon icon-check" />
        </div>
        {img
          && <img className="ml-5" src={img} height={15} width={15} />
        }
        <div className={`text-${textSize ?? '15'} ${img ? 'ml-5' : 'ml-10'}`}>{colorHints && colorHints.length > 0
          && <span
            style={{
              display: 'inline-block',
              background: colorHints.length > 1? `linear-gradient(45deg, ${colorHints.join(',')})` : colorHints[0],
              backgroundColor: colorHints.length > 1? `linear-gradient(45deg, ${colorHints.join(',')})` : colorHints[0],
              width: '28px',
              height: '28px',
              borderRadius: '50%',
              marginRight: '8px'
            }}
          >
            &nbsp;
          </span>
        }{label}</div>
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  textSize: PropTypes.string,
  colorHints: PropTypes.array,
  img: PropTypes.string
};

Checkbox.defaultTypes = {
  textSize: null,
  colorHints: null,
  img: null
};

export default Checkbox;
