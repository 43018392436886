import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { debounce } from 'lodash';

import {
  DEBOUNCE_TIMING,
  searchQuery
} from "@/utils/api";
import LocationSearch from "./LocationSearch";

const HeaderFilterBox = ({
  onSearchLocation
}) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const onSearch = (value) => {
    setLoading(true);
    searchQuery(value.trim(), 10, (data) => {
      if (data) {
        setResults(data);
      }
      setLoading(false);
    }, () => {
      setLoading(false);
    }, null, null);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedSearch = useCallback(debounce(onSearch, DEBOUNCE_TIMING), []);

  const onInput = (item) => {
    setSearchValue(item);
    if (item?.length >= 3) onDebouncedSearch(item);
  };

  const onSelect = (item) => {
    onSearchLocation(item);
    setSearchValue('');
  };

  const onKeyDown = () => {
    if (results?.length > 0) {
      onSearchLocation(results[0]);
      setSearchValue('');
    }
  };

  return (
    <>
      <div className="header-search px-5 fade-intro">
        <div className="button-grid items-center d-flex justify-content-between rounded-8 bg-white px-10">
          <i className="icon-search text-16 mr-5" />
          <div
            className="p-2 flex-fill py-0 px-0"
          >
            <LocationSearch
              searchValue={searchValue}
              results={results}
              loading={loading}
              onInput={onInput}
              onSelect={onSelect}
              onKeyDown={onKeyDown}
            />
          </div>
        </div>
      </div>
    </>
  );
};

HeaderFilterBox.propTypes = {
  onSearchLocation: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  hideClickHint: PropTypes.bool,
  buttonIcon: PropTypes.string,
  buttonLabel: PropTypes.string,
};

HeaderFilterBox.defaultProps = {
  buttonIcon: null,
  buttonLabel: null,
};

export default HeaderFilterBox;
