import {
  getProjectLabel,
  getPropertyIconByType,
  MAP_MODE_HDB,
  MAP_MODE_LANDED
} from "@/utils/map";
import {
  DAY_MSEC,
  generateShortDateString,
  generateShortDiffString,
  getTimeLabel
} from "@/utils/time";

const PropNotificationRow = ({
  data,
  onClick,
  onDelete
}) => {
  const generateDateRangeLabel = () => {
    if (data.data.min_ts === data.data.max_ts) {
      return generateShortDateString(new Date().getTime(), data.data.min_ts, true);
    } else {
      const now = new Date().getTime();
      const diff = Math.round((now - data.data.max_ts) / DAY_MSEC);
      if (diff > 7) {
        const maxWeeks = Math.round(diff / 7);
        const minWeeks = Math.round(Math.round((now - data.data.min_ts) / DAY_MSEC) / 7);
        if (minWeeks === maxWeeks) return `${maxWeeks} week${minWeeks > 1 ? 's' : ''} ago`;
        return `${maxWeeks} to ${minWeeks} week${minWeeks > 1 ? 's' : ''} ago`;
      }
      if (diff > 1) {
        const minDays = Math.round((now - data.data.min_ts) / DAY_MSEC);
        if (diff === minDays) return `${diff} days ago`;
        return `${diff} to ${minDays} days ago`;
      }
      return 'recently';
    }
  };
  
  const generatePriceRangeLabel = () => {
    if (data.data.min_price === data.data.max_price) {
      return `$${data.data.min_price.toLocaleString()}`;
    } else {
      return `$${data.data.min_price.toLocaleString()} to $${data.data.max_price.toLocaleString()}`;
    }
  };

  const getPropertyImgName = (sharePlaceId) => sharePlaceId
    .toUpperCase()
    .split('')
    .filter(ch => /[A-Z0-9]/.test(ch))
    .join('')
    .replace(/ /g, '_');
  
  return (
    <div
      className={`d-flex align-items-center align-self-center border-bottom-light py-0 px-10 text-12 cursor-pointer notif-item ${data.read ? 'bg-white' : 'bg-blue-2'}`}
      onClick={() => onClick?.(data)}
      style={{
        minHeight: '61px'
      }}
    >
      <div
        className="p-2 mr-5 px-0"
        style={{
          minWidth: '30px'
        }}
      >
        <img
          className="prop-img-avatar noselect rounded-100"
          src="/img/general/logo-rs-light.png"
        />
      </div>
      <div
        className="p-2 flex-grow-1"
        style={{
          position: 'relative'
        }}
      >
        {data?.data?.type === 't'
          && <div className="">
            <span className="text-blue-1 fw-600">
              {data.data.mode === MAP_MODE_HDB ? data.data.marker : getProjectLabel(data.data.marker, data.data.project)}
            </span> has <span className="fw-600">{data.data.value}</span> transactions {generateDateRangeLabel()} {data.data.min_price === data.data.max_price ? 'at' : 'ranging from'} <span className="fw-600">{generatePriceRangeLabel()}</span>
          </div>
        }
        {data?.data?.type === 'rh'
          && <div className="">
            {data.data.flat} UNIT at <span className="text-blue-1 fw-600">
              {data.data.marker}
            </span> just set a new record resale price of <span className="fw-600">${data.data.price.toLocaleString()}</span> in {data.data.town} {generateShortDateString(new Date().getTime(), data.data.ts, true)} — a <span className="text-green-2 fw-600">{data.data.increase}%</span> jump from previous high
          </div>
        }
        {data?.data?.type === 'rp'
          && <div className="">
            <span className="text-blue-1 fw-600">
            💰 {data.data.mode === MAP_MODE_HDB ? data.data.marker : getProjectLabel(data.data.marker, data.data.project)}
            </span> sets a record PSF of <span className="fw-600">${data.data.psf}</span> {generateShortDateString(new Date().getTime(), data.data.ts, true)}! ⬆️ Up <span className="text-green-2 fw-600">{data.data.increase}%</span> PSF and <span className="text-green-2 fw-600">{data.data.price_inc}%</span> from previous high
          </div>
        }
        {data?.data?.type === 'rt'
          && <div className="">
            <span className="text-blue-1 fw-600">
              {data.data.mode === MAP_MODE_HDB ? data.data.marker : getProjectLabel(data.data.marker, data.data.project)}
            </span> has {data.data.count} transactions {generateDateRangeLabel()}
          </div>
        }
        {data?.data?.type === 'nl'
          && <div className="">
            <span className="text-blue-1 fw-600">
            📈 {data.data.mode === MAP_MODE_HDB ? data.data.marker : getProjectLabel(data.data.marker, data.data.project)}
            </span> recorded <span className="fw-600">{data.data.count}</span> recent transactions, PSF ranging from <span className="text-green-2 fw-600">${data.data.psf[0].toLocaleString()}</span> to <span className="text-green-2 fw-600">${data.data.psf[1].toLocaleString()}</span>
          </div>
        }
        {data?.data?.type === 'u'
          && <div className="">
            <span className="text-blue-1 fw-600">
              {data.data.name}
            </span> {
              data.data.subtype === 's'
                ? ' subscribed to you '
                : (
                  data.data.subtype === 'l'
                    ? ' liked your post '
                    : ''
                )
            } {generateShortDateString(new Date().getTime(), data.data.ts, true)}
          </div>
        }
        <span className="text-grey"
          style={{
            position: 'absolute',
            bottom: '0',
            right: '12px',
            color: '#bdc3c7',
            fontSize: '8px'
          }}
        >
          {getTimeLabel(new Date(data.createdAt).getTime())}
        </span>
      </div>
      <img
        className="p-2 prop-img-icon noselect px-0 py-0 ml-5 mt-5 mb-5"
        src={data.data.img
          ? `https://realsmart.global.ssl.fastly.net/jr/${getPropertyImgName(data.data.img)}.jpg`
          : `/img/general/thumbs/${
            data.data.mode === MAP_MODE_HDB ? 'hdb' : (data.data.mode === MAP_MODE_LANDED ? 'house' : 'flat')}.png`
        }
        style={{
          borderRadius: (data.data.img ? '20%' : '0')
        }}
      />
      <button
        className="p-2 button ml-15 mr-10 -dark-1 bg-blue-1 text-white h-30 px-5 rounded-100 text-12 px-10"
        onClick={evt => {
          evt.stopPropagation();
          onDelete?.(data);
        }}
      >
        <i className="icon-trash" />
      </button>
    </div>
  );
};

export default PropNotificationRow;
