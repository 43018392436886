export const getCurrentYear = () => new Date().getFullYear();

export const getTsYearsAgo = (yr) => {
  const now = new Date();
  now.setFullYear(now.getFullYear() - yr);
  return now.getTime();
};

export const timestampFromYear = (year) => {
  return `01-01-${year}`;
};

export const dateStrToDateObj = (dateStr) => {
  const chunks = dateStr.split('-');
  const day = chunks[0];
  const month = chunks[1];
  const year = chunks[2];
  return new Date(`${year}-${month}-${day}`);
};

export const dateStrToMsec = (dateStr) => {
  return dateStrToDateObj(dateStr).getTime();
};

export const formatShortDate = (date) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return date.toLocaleDateString(undefined, options);
};

const prependDigitZero = (digit) => `0${digit}`.slice(-2);

const SG_UTC_HOUR_OFFSET = 8;

export const convertDisplayDate = (date) => {
  const offset = SG_UTC_HOUR_OFFSET + Math.floor(new Date().getTimezoneOffset() / 60);
  const newDate = new Date(date.getTime());
  newDate.setHours(newDate.getHours() + offset);
  return `${prependDigitZero(newDate.getDate())}-${prependDigitZero(newDate.getMonth() + 1)}-${newDate.getFullYear()}`;
}

export const convertDisplayMonthYear = (date) => {
  const offset = SG_UTC_HOUR_OFFSET + Math.floor(new Date().getTimezoneOffset() / 60);
  const newDate = new Date(date.getTime());
  newDate.setHours(newDate.getHours() + offset);
  return newDate.toLocaleString('en-us',{month:'short', year:'numeric'}).toUpperCase();
}

export const convertDisplayDateMsec = (msec) => {
  return convertDisplayDate(new Date(msec));
};

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const DAY_MSEC = 1000 * 60 * 60 * 24;

export const generateShortDiffString = (now, ts) => {
  const diff = Math.round((now - ts) / DAY_MSEC);
  
  if (diff > 7) {
    const weeks = Math.round(diff / 7);
    return `${weeks}w ago`;
  }

  if (diff >= 1) {
    return `${diff}d ago`;
  }

  return 'recent';
};

export const generateShortDateString = (now, ts, suffix) => {
  const diff = Math.round((now - ts) / DAY_MSEC);
  
  if (diff > 7) {
    const weeks = Math.round(diff / 7);
    return `${weeks} week${weeks > 1 ? 's' : ''}${suffix ? ' ago' : ''}`;
  }

  if (diff > 1) {
    return `${diff} days${suffix ? ' ago' : ''}`;
  }

  if (diff > 0) {
    return 'yesterday';
  }

  return 'today';
};

export const generateDateString = (now, ts) => {
  const diff = Math.round((now - ts) / DAY_MSEC);
  
  if (diff > 7) {
    const date = new Date(ts);
    return `on ${formatShortDate(date)}`;
  }

  if (diff > 1) {
    return `${diff} days ago`;
  }

  if (diff > 0) {
    return 'yesterday';
  }

  return 'today';
};

export const generateDateTimeString = (now, ts) => {
  const date = new Date(ts);
  const timeString = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric'});
  if ((now - ts) >= DAY_MSEC) return generateDateString(now, ts);
  return `${generateDateString(now, ts)} at ${timeString}`;
};

export const trimDateToDay = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
};

export const getSmallestQueryDateStr = () => "1000";

export const getQueryDateString = (date) => {
  const sgDate = new Date(date.toLocaleString("en-US", { timeZone: "Asia/Singapore" }));
  return sgDate.toISOString().slice(0, 19).split('T')[0];
};

export const getCurrentQueryDateStr = () => getQueryDateString(new Date());

export const getTimeLabel = (ts, altNow, suffix = '') => {
  const now = new Date().getTime();
  const diff = now - ts;
  if (diff < 60000)
    return altNow ?? 'Now';
  if (diff < 3600000)
    return `${Math.floor(diff / 60000)}m` + suffix;
  if (diff < 86400000)
    return `${Math.floor(diff / 3600000)}h` + suffix;
  if (diff < 604800000)
    return `${Math.floor(diff / 86400000)}d` + suffix;
  return `${Math.floor(diff / 604800000)}w` + suffix;
};

export const dateStrConvert = (dateStr) => {
  const chunks = dateStr.split('-');
  const day = chunks[0];
  const month = chunks[1];
  const year = chunks[2];
  return new Date(`${year}-${month}-${day}`).getTime();
};

export const dateStrConvertV2 = (dateStr) => {
  const chunks = dateStr.split('-');
  const year = chunks[0];
  const month = chunks[1];
  const day = chunks[2];
  return new Date(`${year}-${month}-${day}`).getTime();
};
