import { useEffect, useState } from "react";
import LoginBox from "./LoginBox";
import {
  randomBool,
  randomize
} from "@/utils/user";

const LoginPopup = ({
  show,
  setShow,
  session,
  onLoggedIn
}) => {
  const [rstate, setRstate] = useState(randomBool());
  const [rtype, setRtype] = useState(randomBool());

  const handleCarding = () => {
    setShow((prevState) => !prevState);
  };

  useEffect(() => {
    setRstate(randomBool());
    setRtype(randomBool());
  }, [show]);

  return (
    <div className={`langMenu langMenuTop js-langMenu ${show ? "" : "is-hidden"} ${randomize(12)}`}>
      {/* <div className="currencyMenu__bg" onClick={handleCarding}></div> */}
      <div className="currencyMenu__bg"></div>{/* disable background close */}
      <div
        className="langMenu__content bg-white rounded-4 rel-content pb-20"
        style={{
          marginRight: `${rstate ? '20' : '0'}px`
        }}
      >
        <div className="d-flex items-center flex-row-reverse px-30 py-10 sm:px-15 text-10">
          <button
            className={randomize(18)}
            onClick={handleCarding}
          >
            {rtype
              ? <i className={`icon-close ${randomize(16)}`} />
              : <span className={`fw-600 ${randomize(10)}`}>CLOSE</span>
            }
            {/* <i className="icon-close" /> */}
          </button>
        </div>
        <LoginBox session={session} onLoggedIn={onLoggedIn} />
      </div>
    </div>
  );
};

export default LoginPopup;
