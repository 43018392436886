import { LOCATION_BLOCK } from "@/utils/areas";
import { calculateDist, formatDistanceLabel } from "@/utils/convert";


const HdbStations = ({
  target,
  stations,
  focus,
  setFocus,
  isMaximized,
  handleExpand
}) => {
  const formattedStations = stations
    .map(s => ({ ...s, distance: calculateDist(target.lat, target.lng, s.lat, s.lng) }))
    .sort((a, b) => a.distance - b.distance);

  return (
    <div className="pt-10 mb-50">
      {stations.length === 0
        && <div className="py-20 text-14 fw-500 text-center">No stations nearby</div>
      }

      {stations.length > 0
        && <div className="row mt-0 mb-10">
          <div className="col-12">
            <div className="">
              <div className="mb-20 md:mb-10 mt-20">
                <div
                  className="d-flex items-center mb-10"
                >
                  <div
                    className="ml-10 text-14 fw-500"
                  >
                    Stations within 1km
                  </div>
                </div>

                <div
                  className="row y-gap-10 x-gap-0 px-10 surrounding-f"
                >
                  {formattedStations.map((s, i) => (
                    <div
                      className="col-12 border-top-light"
                      key={i}
                      style={{
                        opacity: !focus || (focus && focus.links?.length > 0 && focus.links[0].name === s.name) || focus.use !== 'nearby-stn'
                          ? 1 : 0.2
                      }}
                    >
                      <div
                        className="row items-center justify-between"
                      >
                        <div className="col-9">
                          <div
                            className="text-13 hover-blue-row cursor-pointer nodecor"
                            onClick={() => {
                              if (focus && focus.links?.length > 0 && focus.links[0].name === s.name) {
                                setFocus(null);
                              } else {
                                setFocus({
                                  use: 'nearby-stn',
                                  target: { ...target, type: LOCATION_BLOCK },
                                  mode: LOCATION_BLOCK,
                                  links: [s]
                                });
                                if (isMaximized) {
                                  handleExpand();
                                }
                              }
                            }}
                          >
                            {s.name}
                          </div>
                        </div>

                        <div className="col-3">
                          <div className="text-13 text-right">
                            {formatDistanceLabel(s.distance)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default HdbStations;
