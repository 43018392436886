import { useState, useEffect, useRef, useCallback } from "react";
import {
  useNavigate,
  useLocation,
  useSearchParams
} from "react-router-dom";

import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

// import DefaultHeader from "@/components/header/default-header";
import Header12 from "@/components/header/header-12";
import MetaComponent from "@/components/common/MetaComponent";
import Loader from "@/components/common/Loader";
import ErrorContent from "@/components/mapv2/error/ErrorContent";
import {
  checkMediaQuery,
  continueIfAllowedUser,
  getCachedPropLikes,
  isGuest,
  isLoggedIn,
  noSelectClass,
  setCachedPropLikes
} from "@/utils/user";
import MainMap from "@/components/mapv2/MainMap";
import {
  deletePost,
  getFile,
  likeProperty,
  logComparePro,
  logMapMarkerSelect,
  logPageView,
  logReferred,
  trackEvent,
  trackPageView,
  unlikeProperty
} from "@/utils/api";
import {
  CONDO_MAP_FILTER_OPTIONS,
  HDB_MAP_FILTER_OPTIONS,
  LANDED_MAP_FILTER_OPTIONS,
  MAP_MODE_CONDO,
  MAP_MODE_HDB,
  MAP_MODE_LANDED,
  PROPERTY_COMP_FILE_PREFIX,
  decompressAdvMapData,
  decompressBasicMapData,
  decompressSingleCompData,
  getGcsDir,
  getProjectKey,
  getProjectLabel,
  getPropertyFileName,
  isCondoType,
  isHdbType
} from "@/utils/map";
// import FilterBox from "@/components/mapv2/search/FilterBox";
import {
  convertMapUrl,
  resetMapUrl
} from "@/utils/url";
import PropertyView from "@/components/mapv2/PropertyView";
import {
  LOCATION_AREA,
  LOCATION_BLOCK,
  LOCATION_COMPARE,
  LOCATION_ESTATE,
  LOCATION_FILTER,
  LOCATION_HDB,
  LOCATION_MARKER,
  LOCATION_PROPERTY,
  LOCATION_SCHOOL,
  LOCATION_STATION,
  LOCATION_UPCOMING
} from "@/utils/areas";
import {
  initializeFilters,
  requireLoadAdvData
} from "@/utils/filter";
import AreaView from "@/components/mapv2/AreaView";
import AmenityView from "@/components/mapv2/AmenityView";
import CompareProView from "@/components/mapv2/CompareProView";
import UpcomingView from "@/components/mapv2/UpcomingView";
import BotPanel from "@/components/mapv2/BotPanel";
import LoginPopup from "@/components/login/LoginPopup";
import EstateView from "@/components/mapv2/EstateView";
import BottomBar from "@/components/newsfeed/BottomBar";

const DEFAULT_METADATA = {
  title: "REALSMART.SG | Search Properties | Supercharge your property search",
  description: "REALSMART.SG - Supercharge your property search",
};

/*
URL filter cases:
  http://localhost:5173/map?mode=c&prop_type=Detached%20House
  http://localhost:5173/map?mode=h&flat_type=2%20ROOM
*/

const HomeMap = ({
  user,
  session,
  allowModeSwitch = false,
  allowBot = false,
}) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  // const { pathname } = useLocation();
  
  const mapRef = useRef(null);
  const workerRef = useRef(null);

  const referredOrigin = params.get('r');

  const getDefaultTarget = (params) => {
    if (params.get('id')) {
      let queryMode = MAP_MODE_CONDO;
      if (params.get('mode')) {
        queryMode = params.get('mode');
      }
      if (params.get('p')) {
        return {
          id: params.get('id'),
          projectId: params.get('p'),
          key: `${params.get('id')}_${params.get('p')}`,
          type: LOCATION_PROPERTY,
          mode: queryMode
        };
      } else {
        return {
          id: params.get('id'),
          type: queryMode === MAP_MODE_HDB ? LOCATION_PROPERTY : LOCATION_MARKER,
          mode: queryMode
        };
      }
    } else if (params.get('sch')) {
      return {
        id: params.get('sch'),
        type: LOCATION_SCHOOL,
        opt: params.get('m') ?? MAP_MODE_CONDO
      };
    } else if (params.get('stn')) {
      return {
        id: params.get('stn'),
        type: LOCATION_STATION,
        opt: params.get('m') ?? MAP_MODE_CONDO
      };
    } else if (params.get('new')) {
      return {
        id: params.get('new'),
        type: LOCATION_UPCOMING
      };
    } else if (params.get('s')) {
      // this search is to maintain backward compatibility with v1 table pages
      return {
        search: {
          project: params.get('s'),
          street: params.get('t')
        },
        type: 'search',
        mode: MAP_MODE_CONDO
      };
    } else if (params.get('m')) {
      let queryMode = MAP_MODE_CONDO;
      if (params.get('mode')) {
        queryMode = params.get('mode');
      }
      if (params.get('m') === 'compare') {
        return {
          type: LOCATION_COMPARE,
          mode: queryMode
        };
      }
    } else if (params.get('e')) {
      let queryMode = MAP_MODE_LANDED;
      if (params.get('mode') === MAP_MODE_LANDED) {
        return {
          id: params.get('e'),
          type: LOCATION_ESTATE,
          mode: queryMode
        };
      }
    } else if (params.get('f') === '1') {
      let queryMode = MAP_MODE_CONDO;
      if (params.get('mode')) {
        queryMode = params.get('mode');
      }
      return {
        type: LOCATION_FILTER,
        mode: queryMode
      };
    }
    return null;
  };

  const getDefaultMode = (params) => {
    if (params.get('mode')) {
      return params.get('mode');
    }
    return MAP_MODE_CONDO;
  };

  const [userConfig, setUserConfig] = useState({
    // profitableColor: 'red', // either red or green for most profitable
    profitableColor: 'green'
  });

  const [metadata, setMetadata] = useState(DEFAULT_METADATA);

  const [showSharePanel, setShowSharePanel] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [editPost, setEditPost] = useState(null);

  const getGuestUsage = () => {
    try {
      const usage = localStorage.getItem("rsLvl");
      if (usage && !isNaN(parseInt(usage))) return parseInt(usage);
      return 0;
    } catch(e) {
      return 0;
    }
  };

  const persistGuestUsage = (usage) => {
    try {
      localStorage.setItem("rsLvl", usage.toString());
    } catch(e) {
      // do nothing
    }
  };

  const [showLogin, setShowLogin] = useState(false);
  const [guestUse, setGuestUse] = useState(getGuestUsage());

  const [lightboxImages, setLightboxImages] = useState(null);

  const [actionSignal, setActionSignal] = useState(null); // as a quick way to trigger child actions without ref

  // handle liked props in cache
  const defaultLikedProps = getCachedPropLikes() ?? {};
  const [likedProps, setLikedProps] = useState(defaultLikedProps);

  const [linkTarget, setLinkTarget] = useState(getDefaultTarget(params)); // actual target to load data
  const [target, setTarget] = useState(getDefaultTarget(params)); // actual target to load data
  const [focus, setFocus] = useState(null);                       // zoom in on map only
  const [compareList, setCompareList] = useState({});             // data list to use for compare pro

  const [mode, setMode] = useState(getDefaultMode(params)); // default map mode is condo
  
  const [hasSearchResults, setHasSearchResults] = useState(false);

  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(null);

  const filterOptions = mode === MAP_MODE_HDB ? HDB_MAP_FILTER_OPTIONS
    : (mode === MAP_MODE_LANDED ? LANDED_MAP_FILTER_OPTIONS : CONDO_MAP_FILTER_OPTIONS);

  // private condo
  const [mapData, setMapData] = useState(null);
  const [hasAdvMapData, setHasAdvMapData] = useState(false);
  const [filterSelected, setFilterSelected] = useState(initializeFilters(filterOptions, params));
  const [incomingFilter, setIncomingFilter] = useState(null);

  // private landed
  const [landedMapData, setLandedMapData] = useState(null);
  const [hasAdvLandedMapData, sethasAdvLandedMapData] = useState(false);

  // hdb
  const [hdbMapData, setHdbMapData] = useState(null);
  const [hasAdvHdbMapData, setHasAdvHdbMapData] = useState(false);

  const [isMinimized, setIsMinimized] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const isMinimizedRef = useRef(isMinimized);
  const [screenDim, setScreenDim] = useState({ height: window.innerHeight, width: window.innerWidth });

  const [gpsLocation, setGpsLocation] = useState(null);

  const [unfadedProps, setUnfadedProps] = useState(new Set());

  // handle URL search parameters to load correct place
  const loadTarget = (target, mapData) => {
    if (target?.type === LOCATION_PROPERTY) {
      if (target?.mode === MAP_MODE_HDB) {
        if (!mapData) {
          loadMapData(MAP_MODE_HDB, target);
        } else {
          if (target?.type === LOCATION_PROPERTY) {
            if (target.id in mapData.index) {
              const block = mapData.blocks[mapData.index[target.id]];
              if (block) goToHdbUsingData(block);
            }
          }
        }
      } else {
        if (target?.mode === MAP_MODE_LANDED) {
          if (!mapData) {
            loadMapData(MAP_MODE_LANDED, target);
          } else {
            const property = mapData.projects.find(p => p.marker === target.id && p.projectId === target.projectId);
            const marker = mapData.markers.find(m => m.name === target.id);
            if (marker && property) goToPropertyUsingData(marker, property, target.mode);
            else setTarget(null);
          }
        } else {
          const property = mapData.projects.find(p => p.marker === target.id && p.projectId === target.projectId);
          const marker = mapData.markers.find(m => m.name === target.id);
          if (marker && property) goToPropertyUsingData(marker, property, target.mode);
          else setTarget(null);
        }
      }
    } else if (target?.type === LOCATION_MARKER) {
      const marker = mapData.markers.find(m => m.name === target.id);
      if (marker) goToMarkerUsingData(marker);
      else setTarget(null);
    } else if (target?.type === LOCATION_SCHOOL) {
      const schoolData = mapData?.schools?.find(s => s.id === target.id);
      if (schoolData) goToSchool({ ...schoolData, opt: target.opt });
      else setTarget(null);
    } else if (target?.type === LOCATION_STATION) {
      const stationData = mapData?.stations?.find(s => s.id === target.id);
      if (stationData) goToStation({ ...stationData, opt: target.opt });
      else setTarget(null);
    } else if (target?.type === LOCATION_UPCOMING) {
      const chunks = target.id.split('_');
      const targetId = chunks[chunks.length - 1];
      const upcomingData = mapData?.upcomings?.find(s => s.id === targetId);
      if (upcomingData) goToUpcoming(upcomingData);
      else setTarget(null);
    } else if (target?.type === 'search') {
      const projects = mapData.projects.filter(p => p.project === target.search.project);
      if (projects.length > 0) {
        // for now just take the first matching project
        const project = projects[0];
        const markers = mapData.markers.filter(m => m.name === project.marker);
        if (markers.length > 0) {
          const marker = markers[0];
          goToPropertyUsingData(marker, project);
        }
      } else {
        setTarget(null);
      }
    } else if (target?.type === LOCATION_ESTATE) {
      if (!mapData) {
        loadMapData(MAP_MODE_LANDED, target);
      } else {
        goToEstate(target.id);
      }
    } else if (target?.type === LOCATION_FILTER) {
      // will only reach here if target mode and loading mode is the same
      // background load will be ignored by caller function already
      if (requireLoadAdvData(mode, filterSelected, hasAdvMapData, hasAdvLandedMapData, hasAdvHdbMapData)) {
        loadAdvMapData(mode, mapData);
      } else {
        // no need to load adv data, just stop loader
        setLoading(false);
      }
    }
  };

  // handler for history stack pop
  const handleHistoryPop = useCallback(() => {
    const currentUrl = new URL(window.location.href);
    const params = currentUrl.searchParams;
    const target = getDefaultTarget(params);
    if (target) {
      let data = null;
      if (target.mode === MAP_MODE_CONDO) data = mapData;
      if (target.mode === MAP_MODE_LANDED) data = landedMapData;
      if (target.mode === MAP_MODE_HDB) data = hdbMapData;
      loadTarget(target, data);
    }
  }, [mapData, landedMapData, hdbMapData]);

  const onLoadData = (mode, loadedData, target, callback, disableLoader) => {
    if (mode === MAP_MODE_CONDO) {
      setMapData(loadedData);
    } else if (mode === MAP_MODE_LANDED) {
      setLandedMapData(loadedData);
    } else if (mode === MAP_MODE_HDB) {
      setHdbMapData(loadedData);
    }

    if (target && !disableLoader) {
      // disableLoader only happens when load condo map data in background
      // so, to ignore all target links here and let the original map load handle it
      if (mode === target.mode) {
        loadTarget(target, loadedData);
      } else {
        let targetData = loadedData;
        if (target.mode === MAP_MODE_CONDO) targetData = mapData;
        else if (target.mode === MAP_MODE_LANDED) targetData = landedMapData;
        else if (target.mode === MAP_MODE_HDB) targetData = hdbMapData;
        loadTarget(target, targetData);
      }
    }

    if (!disableLoader && target?.type !== LOCATION_FILTER) {
      setLoading(false);
    }

    callback?.(loadedData);
  };

  /* Load map */
  const loadMapData = (mode, target, callback, disableLoader) => {
    if (!disableLoader) setLoading(true);
    setErr(null);

    getFile(getGcsDir(mode), 'm1', txt => {
      // set the default map data
      if (mode === MAP_MODE_LANDED) {
        workerRef.current.onmessage = (event) => {
          onLoadData(mode, event.data, target, callback, disableLoader);
        };
        workerRef.current.postMessage({ txt })
      } else {
        const loadedData = decompressBasicMapData(mode, txt);
        onLoadData(mode, loadedData, target, callback, disableLoader);
      }
    }, err => {
      if (!disableLoader) setLoading(false);
      setErr('Failed to load map');
    });
  };

  const loadAdvMapData = (mode, mapData, callback) => {
    if (mode === MAP_MODE_CONDO && hasAdvMapData) {
      callback?.(mapData.projects);
    } else if (mode === MAP_MODE_LANDED && hasAdvLandedMapData) {
      callback?.(mapData.projects);
    } else if (mode === MAP_MODE_HDB && hasAdvHdbMapData) {
      callback?.(mapData.blocks);
      return;
    }
    if (mode === MAP_MODE_LANDED) {
      setLoading(true);
    }
    getFile(getGcsDir(mode), mapData.adv, txt => {
      const data = decompressAdvMapData(mode, txt);
      const newMapData = { ...mapData };
      if (mode === MAP_MODE_CONDO) {
        data.forEach(r => {
          const idx = newMapData.projectIndex[getProjectKey(r)];
          if (idx === undefined) return;  // skip projects not found
          newMapData.projects[idx] = {
            ...newMapData.projects[idx],
            ...r
          };
        });
        setMapData(newMapData);
        setHasAdvMapData(true);
        callback?.(newMapData.projects);
      } else if (mode === MAP_MODE_LANDED) {
        data.forEach(r => {
          const idx = newMapData.projectIndex[getProjectKey(r)];
          if (idx === undefined) return;  // skip projects not found
          newMapData.projects[idx] = {
            ...newMapData.projects[idx],
            ...r
          };
        });
        setLandedMapData(newMapData);
        sethasAdvLandedMapData(true);
        callback?.(newMapData.projects);
      } else if (mode === MAP_MODE_HDB) {
        data.forEach(r => {
          const idx = newMapData.index[r.postal];
          if (idx === undefined) return;  // skip projects not found
          newMapData.blocks[idx] = {
            ...newMapData.blocks[idx],
            ...r
          };
        });
        setHdbMapData(newMapData);
        setHasAdvHdbMapData(true);
        callback?.(newMapData.blocks);
      }
      setLoading(false);
    });
  };

  const queryGpsLocation = (callback) => {
    // TODO: think of how to show iPhone Safari users who did not allow GPS
    // on Safari to enable it if they need GPS
    // https://whatpwacando.today/geolocation

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          // do a check on gps location to ensure only singapore coords will be persisted
          if (latitude < 1.15
              || latitude >> 1.55
              || longitude < 103.45
              || longitude > 104.2
            ) {
              // not in singapore
              return;
          }
          
          // set new gps location
          const newGpsLocation = {
            lat: latitude,
            lng: longitude
          };
          setGpsLocation(newGpsLocation);
          callback?.(newGpsLocation);
        },
        (error) => {
          switch(error.code) {
            case error.PERMISSION_DENIED:
            case error.POSITION_UNAVAILABLE:
            case error.TIMEOUT:
            case error.UNKNOWN_ERROR:
              setGpsLocation({
                err: true,
                errCode: error.code
              });
              break;
          }
          console.error('Error obtaining gps location:', error.message);
        },
        {
          enableHighAccuracy: true,
          timeout: 6000,    // 6 seconds timeout
          maximumAge: 0     // disable cached location
        }
      );
    }
  };

  const fetchGpsLocation = async (callback) => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          queryGpsLocation(callback);
        }
      });
    } else {
      queryGpsLocation(callback);
    }
  };

  useEffect(() => {
    // load user config from localStorage
    // handle local + remote config in future
    // for now, just handle local
    try {
      if (localStorage.getItem("rsMapPref")) {
        setUserConfig(JSON.parse(localStorage.getItem("rsMapPref")));
      }
    } catch(e) {
      // do nothing
    }

    // handle user gps location if exists
    fetchGpsLocation();

    // page view analytics
    logPageView('MAP', session);
    trackPageView('map');
    if (referredOrigin) {
      logReferred(referredOrigin, session);
    }
  }, []);

  useEffect(() => {
    // TODO
    // if (likedProps && Object.keys(likedProps).length > 0) {
    //   setCachedPropLikes(likedProps);
    // }
  }, [likedProps]);

  useEffect(() => {
    // reset compare list
    setCompareList({});

    // clear unfaded
    setUnfadedProps(new Set());

    if (mode === MAP_MODE_CONDO && !mapData) {
      loadMapData(mode, linkTarget);
      setLinkTarget(null);
    } else if (mode === MAP_MODE_HDB && !hdbMapData) {
      loadMapData(mode, linkTarget);
      setLinkTarget(null);

      // additionally load default map data if not loaded yet in other modes
      if (!mapData) loadMapData(MAP_MODE_CONDO, linkTarget, null, true);
      
      setLinkTarget(null);
    } else if (mode === MAP_MODE_LANDED && !landedMapData) {
      loadMapData(mode, linkTarget);

      // additionally load default map data if not loaded yet in other modes
      if (!mapData) loadMapData(MAP_MODE_CONDO, linkTarget, null, true);
      
      setLinkTarget(null);
    }

    // update the url
    resetMapUrl(mode);
  }, [mode]);

  const setProfitableColorScheme = (setting) => {
    const updatedConfig = {
      ...userConfig,
      profitableColor: setting,
      last_update: new Date().getTime()
    };
    setUserConfig(updatedConfig);
    try {
      localStorage.setItem("rsMapPref", JSON.stringify(updatedConfig));
    } catch(e) {
      // do nothing
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', handleHistoryPop);

    // clean up on unmount of component
    return () => {
      window.removeEventListener('popstate', handleHistoryPop);
    };
  }, [handleHistoryPop]);

  /* Handle screen sizing */
  useEffect(() => { isMinimizedRef.current = isMinimized }, [isMinimized]);

  const handleResize = () => {
    setScreenDim({ height: window.innerHeight, width: window.innerWidth });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    workerRef.current = new Worker('./worker.js');

    return () => {
      window.removeEventListener("resize", handleResize);
      workerRef.current.terminate();
    };
  }, []);

  /* Handle property window */
  const handleMinimize = () => {
    setIsMinimized(true);
    setIsMaximized(false);
  };

  const handleDefaultGivenTarget = (target) => {
    setIsMinimized(false);
    setIsMaximized(false);
  };

  const handleDefault = () => {
    handleDefaultGivenTarget(target);
  };

  const handleMaximize = () => {
    setIsMaximized(true);
    setIsMinimized(false);
  };

  const closePropertyDetails = () => {
    setTarget(null);
    setFocus(null);
    setUnfadedProps(new Set());
    convertMapUrl(null);
  }

  /* Map interaction */
  const goToPropertyUsingData = (marker, property, preferredMode) => {
    const selectedMode = preferredMode ? preferredMode : mode;

    if (!property || !marker) return;
    const markerName = property.marker;
    const projectId = property.projectId;

    // set new target
    const newTarget = {
      ...property,
      id: markerName,
      projectId,
      markerData: marker,
      key: `${markerName}_${projectId}`,
      type: LOCATION_PROPERTY,
      mode: selectedMode
    };
    setTarget(newTarget);

    if (mode !== selectedMode) {
      setMode(selectedMode);
    }

    // set UI layout
    if (isMaximized || isMinimized) handleDefaultGivenTarget(newTarget);

    // set focus target on map
    setFocus({
      target: { ...newTarget }
    });

    // update page URL
    setTimeout(() => {
      convertMapUrl(newTarget);
    }, 800);

    // log for analytics
    logMapMarkerSelect(session, { type: LOCATION_PROPERTY, marker: markerName, project: property.project, id: projectId });

    // handle guest
    showLoginIfGuest();
  };

  const goToProperty = (markerName, projectId, preferredMode) => {
    const selectedMode = preferredMode ? preferredMode : mode;

    if (selectedMode === MAP_MODE_CONDO && !mapData) {
      setLoading(true);
      loadMapData(MAP_MODE_CONDO, {
        id: markerName,
        projectId,
        key: `${markerName}_${projectId}`,
        type: LOCATION_PROPERTY,
        mode: MAP_MODE_CONDO
      });
      return;
    }

    if (selectedMode === MAP_MODE_LANDED && !landedMapData) {
      setLoading(true);
      loadMapData(MAP_MODE_LANDED, {
        id: markerName,
        projectId,
        key: `${markerName}_${projectId}`,
        type: LOCATION_PROPERTY,
        mode: MAP_MODE_LANDED
      });
      return;
    }

    const modeData = selectedMode === MAP_MODE_LANDED ? landedMapData : mapData;

    // do nothing if already selected this property
    if (target?.type === LOCATION_PROPERTY && markerName === target?.id && projectId === target?.projectId) return;
    
    // find property
    let property = modeData.projects.find(p => p.marker === markerName && p.projectId === projectId);
    if (!property) {
      property = modeData.projects.find(p => p.project === markerName && p.projectId === projectId);
      if (!property) {
        return;
      }
    }

    // find marker
    const marker = modeData.markers.find(m => m.name === markerName);
    if (!marker) return;

    goToPropertyUsingData(marker, property, selectedMode);
  };

  const goToMarkerUsingData = (marker) => {
    if (!marker) return;
    const markerName = marker.name;

    // check if only 1 property in marker
    // if so it means that no need to view marker, just jump straight to property
    if (marker.properties.length > 1) {
      // set new target
      const newTarget = {
        ...marker,
        id: markerName,
        projects: marker.properties,
        isMarker: true,
        type: LOCATION_MARKER,
        mode
      };
      setTarget(newTarget);

      // set UI layout
      if (isMaximized || isMinimized) handleDefaultGivenTarget(newTarget);

      // set focus target on map
      setFocus({
        target: { ...newTarget }
      });

      // update page URL
      convertMapUrl(newTarget);

      // log for analytics
      logMapMarkerSelect(session, { type: LOCATION_MARKER, marker: markerName });
    } else {
      goToPropertyUsingData(marker, marker.properties[0]);
    }
  };

  const goToMarker = (markerName) => {
    // do nothing if already selected this marker
    if (target?.type === LOCATION_MARKER && markerName === target?.id) return;

    // find marker
    const marker = (
      mode === MAP_MODE_LANDED
       ? landedMapData
       : mapData
    ).markers.find(m => m.name === markerName);
    if (!marker) return;

    goToMarkerUsingData(marker);
  };

  const goToEstate = (estateName, prevTarget) => {
    // do nothing if already selected this estate
    if (target?.type === LOCATION_ESTATE && estateName === target?.id) return;

    const newTarget = {
      id: estateName,
      type: LOCATION_ESTATE,
      prev: prevTarget,
      mode: MAP_MODE_LANDED
    };
    setTarget(newTarget);

    // set UI layout
    if (isMaximized || isMinimized) handleDefaultGivenTarget(newTarget);

    // update page URL
    convertMapUrl(newTarget);

    // log for analytics
    logMapMarkerSelect(session, { type: LOCATION_ESTATE, marker: estateName });
  };

  const goToBlock = (block) => {
    // set focus target on map
    setFocus({
      target: {
        ...block,
        id: block.address,
        type: LOCATION_BLOCK,
        mode
      },
      links: [target]
    });
  };

  const goToArea = (area) => {
    // set new target
    const newTarget = {
      ...area,
      id: area.id,
      type: LOCATION_AREA,
      mode
    };
    setTarget(newTarget);

    // set UI layout
    if (isMaximized || isMinimized) handleDefaultGivenTarget(newTarget);

    // set focus target on map
    setFocus({
      target: area,
      type: LOCATION_AREA
    });

    // update page URL
    convertMapUrl(null);
  };

  const goToStation = (station) => {
    // check station data
    if (!station) return;

    // set new target
    const newTarget = {
      ...station,
      type: LOCATION_STATION,
      mode
    };
    setTarget(newTarget);

    // set UI layout
    if (isMaximized || isMinimized) handleDefaultGivenTarget(newTarget);
    
    // set focus target on map
    setFocus({
      target: { ...station }
    });

    // log for analytics
    logMapMarkerSelect(session, { type: LOCATION_STATION, name: station.name });

    // handle guest
    showLoginIfGuest();
  };

  const goToSchool = (school) => {
    // check school data
    if (!school) return;

    // set new target
    const newTarget = {
      ...school,
      type: LOCATION_SCHOOL,
      mode
    };
    setTarget(newTarget);

    // set UI layout
    if (isMaximized || isMinimized) handleDefaultGivenTarget(newTarget);

    // set focus target on map
    setFocus({
      target: { ...school }
    });

    // log for analytics
    logMapMarkerSelect(session, { type: LOCATION_SCHOOL, name: school.label });

    // handle guest
    showLoginIfGuest();
  };

  const goToUpcoming = (upcoming) => {
    // check upcoming property data
    if (!upcoming) return;

    if (mode !== MAP_MODE_CONDO) {
      setMode(MAP_MODE_CONDO);
    }

    // set new target
    const newTarget = {
      ...upcoming,
      type: LOCATION_UPCOMING,
      mode
    };
    setTarget(newTarget);

    // set UI layout
    if (isMaximized || isMinimized) handleDefaultGivenTarget(newTarget);

    // set focus target on map
    setFocus({
      target: { ...upcoming }
    });
    
    // update page URL
    convertMapUrl(newTarget);

    // log for analytics
    logMapMarkerSelect(session, { type: LOCATION_UPCOMING, project: upcoming.project, street: upcoming.street });

    // handle guest
    showLoginIfGuest();
  };

  const goToHdbUsingData = (block) => {
    // set new target
    const newTarget = {
      ...block,
      id: block.postal,
      type: LOCATION_PROPERTY,
      mode: MAP_MODE_HDB
    };
    setTarget(newTarget);

    if (mode !== MAP_MODE_HDB) {
      setMode(MAP_MODE_HDB);
    }

    // set UI layout
    if (isMaximized || isMinimized) handleDefaultGivenTarget(newTarget);

    // set focus target on map
    setFocus({
      target: { ...block }
    });

    // update page URL
    setTimeout(() => {
      convertMapUrl(newTarget);
    }, 800);

    // log for analytics
    logMapMarkerSelect(session, { type: LOCATION_HDB, postal: block.postal });

    // handle guest
    showLoginIfGuest();
  };

  const goToHdbBlock = (postal) => {
    // if hdb data not loaded yet, set the target and load data first
    if (!hdbMapData) {
      setLoading(true);
      loadMapData(MAP_MODE_HDB, {
        id: postal,
        type: LOCATION_PROPERTY,
        mode: MAP_MODE_HDB
      });
      return;
    }

    // get hdb block data if available
    if (!(postal in hdbMapData.index)) return;
    const block = hdbMapData.blocks[hdbMapData.index[postal]];
    if (!block) return;

    goToHdbUsingData(block);
  };

  /* Search bar interaction */
  const onSearch = (location) => {
    closePropertyDetails();

    if (location.type === LOCATION_PROPERTY) {
      const isHdb = isHdbType(location.subtype);
      const isCondo = isCondoType(location.subtype);
      if (isHdb) {
        goToHdbBlock(location.postal[0]);
      } else {
        goToProperty(location.marker, location.store, isCondo ? MAP_MODE_CONDO : MAP_MODE_LANDED);
      }

      trackEvent(`map_search_${isHdb ? 'hdb' : 'private'}_${location.marker}`);
    } else if (location.type === LOCATION_SCHOOL) {
      const school = mapData.schools.find(s => s.id === location.store);
      if (school) goToSchool(school);

      trackEvent(`map_search_school_${location.names[0]}`);
    } else if (location.type === LOCATION_STATION) {
      const station = mapData.stations.find(s => s.id === location.store);
      if (station) goToStation(station);

      trackEvent(`map_search_station_${location.marker}`);
    } else if (location.type === LOCATION_UPCOMING) {
      const c = location.store.split('_');
      const upcomingId = c[c.length - 1];
      const upcoming = mapData.upcomings.find(s => s.id === upcomingId);
      if (upcoming) goToUpcoming(upcoming);

      trackEvent(`map_search_upcoming_${location.marker}`);
    }
  };

  const setFocusOn = (projectName) => {
    if (mode === MAP_MODE_HDB) {
      if (!(projectName in hdbMapData.index)) return;
      const block = hdbMapData.blocks[hdbMapData.index[projectName]];
      if (block) {
        setFocus({ target: block });
      }
    } else {
      const modeData = mode === MAP_MODE_LANDED ? landedMapData : mapData;
      const propIndex = modeData?.projectIndex?.[projectName];
      if (propIndex === undefined || propIndex === null) return;
      const prop = modeData.projects[propIndex];
      if (prop) {
        setFocus({ target: prop });
      }
    }
  };

  const getProjectLabelFromCompKey = (compKey) => {
    if (!compKey) return compKey;
    if (mode === MAP_MODE_HDB) {
      const block = hdbMapData.blocks[hdbMapData.index[compKey]];
      return block.name;
    } else {
      const c = compKey.split('_');
      if (c.length !== 2) return compKey;
      const modeData = mode === MAP_MODE_LANDED ? landedMapData : mapData;
      const idx = modeData.projectIndex[getProjectKey({ marker: c[0], projectId: c[1] })];
      const project = modeData.projects[idx];
      return project ? (
        mode === MAP_MODE_LANDED
          ? c[0]
          : getProjectLabel(c[0], project.project)
      ) : compKey;
    }
  };

  const addToCompareList = (projKey) => {
    if (projKey in compareList) return;

    getFile(getGcsDir(mode), PROPERTY_COMP_FILE_PREFIX + getPropertyFileName(projKey), txt => {
      const json = JSON.parse(txt);
      const data = decompressSingleCompData(mode, json);
        setCompareList({
          ...compareList,
          [projKey]: data
        });
    }, err => {
      // do nothing - soft fail
    });
  };

  const viewComparePro = () => {
    // set new target
    const newTarget = {
      type: LOCATION_COMPARE,
      mode
    };
    setTarget(newTarget);

    // set UI layout
    if (isMaximized || isMinimized) handleDefaultGivenTarget(newTarget);

    // update page URL
    convertMapUrl(newTarget);

    // log for analytics
    logComparePro(session, { selection: Object.keys(compareList).map(projKey => compareList[projKey].d.name) });

    // handle guest
    showLoginIfGuest();
  };

  const onLoggedIn = (user) => {
    // do nothing
  };

  const onLikeProperty = (like, target, detailedData, skip) => {
    if (!isLoggedIn(user)) {
      // guest cannot like
      setShowLogin(true);
      return;
    }

    // generate id
    let id = '';
    if (target.projectId) {
      // handle private
      id = `${getPropertyFileName(target.marker)}_${target.projectId}`;
    } else {
      // handle hdb
      id = target.postal;
    }

    // load for initial state only
    if (skip) {
      setLikedProps({
        ...likedProps,
        [id]: like
      });
      return;
    }

    // check if this has been spammed
    if (`clicked-${id}` in likedProps && likedProps[`clicked-${id}`] > 5) {
      // prevent any more spams
      return;
    }

    // on like
    if (like) {
      likeProperty(id);
    } else {
      // on un-like
      unlikeProperty(id);
    }

    setLikedProps({
      ...likedProps,
      [id]: like,
      [`clicked-${id}`]: (likedProps[`clicked-${id}`] ?? 0) + 1
    });
  };

  const onUnlikeProperty = (id) => {
    setLikedProps({
      ...likedProps,
      [id]: false
    });
  };

  const showLoginIfGuest = () => {
    if (isGuest(user)) {
      const usage = guestUse + 1;
      setGuestUse(usage);
      persistGuestUsage(usage);
      if (usage > 10) {
        navigate('/login');
      } else if (usage > 5) {
        setShowLogin(true);
      }
    }
  };

  const onCompareSearch = () => {
    showLoginIfGuest();
  };

  useEffect(() => {
    showLoginIfGuest();
  }, [user]);

  const onEditPost = (post) => {
    if (isLoggedIn(user)) {
      setSelectedPost(null);
      setEditPost(post);
    } else {
      setShowLogin(true);
    }
  };

  const onCreatePost = () => {
    if (isLoggedIn(user)) {
      setSelectedPost(null);
      setEditPost(null);
    } else {
      setShowLogin(true);
    }
  };

  const onClickLikedProps = () => {
    if (!isLoggedIn(user)) {
      setShowLogin(true);
    }
  };

  const onClickPost = (post) => {
    // TODO
    setSelectedPost(post);
  };

  const onDeletePost = (id, callback, errCallback) => {
      deletePost(id, () => {
        callback?.();
      }, err => {
        errCallback?.();
        // TODO
      });
  };

  const showModal = target?.type === LOCATION_PROPERTY
    || target?.type === LOCATION_MARKER
    || target?.type === LOCATION_AREA
    || target?.type === LOCATION_STATION
    || target?.type === LOCATION_SCHOOL
    || target?.type === LOCATION_UPCOMING
    || target?.type === LOCATION_COMPARE
    || target?.type === LOCATION_ESTATE;

  const mediaMatches = checkMediaQuery(); // check is mobile

  const onNotificationClick = (mode, marker, id) => {
    if (mode === MAP_MODE_HDB) {
      goToHdbBlock(marker);
    } else if (mode === MAP_MODE_CONDO || mode === MAP_MODE_LANDED) {
      goToProperty(marker, id, mode);
    }
  };

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="header-margin-xs"></div>
      {/* <DefaultHeader
        user={user}
        session={session}
        onViewProperty={prop => {
          closePropertyDetails();
          setMode(prop.mode);
          if (prop.mode === MAP_MODE_HDB) {
            goToHdbBlock(prop.postal);
          } else {
            goToProperty(prop.marker, prop.id, prop.mode);
          }
        }}
        onUnlikeProperty={onUnlikeProperty}
        isMap
      /> */}

      {!loading && !err
        && <Header12
          user={user}
          session={session}
          onSearchLocation={onSearch}
          onNotificationClick={onNotificationClick}
        />
      }

      {/* loading screen */}
      {loading && <div className="loader-container"><Loader /></div>}

      {/* error screen */}
      {err && <ErrorContent />}

      {!loading && !err
        && <>
          {/* search bar */}
          {/* <div className="py-10 bg-dark-2">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <FilterBox
                    onSearch={onSearch}
                    hasSearchResults={setHasSearchResults}
                    allowModeSwitch={allowModeSwitch}
                  />
                </div>
              </div>
            </div>
          </div> */}

          <section className={noSelectClass(user)}>
            <div className="proj-bg-map">
              <MainMap
                ref={mapRef}
                user={user}
                target={target}
                focus={focus}
                setFocus={setFocus}
                mapData={mapData}
                hdbMapData={hdbMapData}
                landedMapData={landedMapData}
                goToMarker={goToMarker}
                goToArea={goToArea}
                goToStation={goToStation}
                goToSchool={goToSchool}
                goToUpcoming={goToUpcoming}
                goToHdbBlock={goToHdbBlock}
                isMinimized={isMinimized}
                isMaximized={isMaximized}
                loadAdvMapData={loadAdvMapData}
                hasAdvMapData={hasAdvMapData}
                hasAdvLandedMapData={hasAdvLandedMapData}
                hasAdvHdbMapData={hasAdvHdbMapData}
                filterSelected={filterSelected}
                setFilterSelected={setFilterSelected}
                viewComparePro={viewComparePro}
                hasSearchResults={hasSearchResults}
                userConfig={userConfig}
                setProfitableColorScheme={setProfitableColorScheme}
                gpsLocation={gpsLocation}
                fetchGpsLocation={fetchGpsLocation}
                mode={mode}
                setMode={mode => {
                  // reset all filters and states
                  setTarget(null);
                  setFocus(null);
                  setFilterSelected(initializeFilters(filterOptions, new URLSearchParams()));

                  // change mode
                  setMode(mode);
                }}
                allowModeSwitch={allowModeSwitch}
                incomingFilter={incomingFilter}
                actionSignal={actionSignal}
                setActionSignal={setActionSignal}
                unfadedProps={unfadedProps}
                setUnfadedProps={setUnfadedProps}
                closePropertyDetails={closePropertyDetails}
              />

              {!(mediaMatches && showModal)
                && <BottomBar
                  user={user}
                  session={session}
                  selectedPost={selectedPost}
                  editPost={editPost}
                  showSharePanel={showSharePanel}
                  setShowSharePanel={setShowSharePanel}
                  onCreatePost={onCreatePost}
                  onEditPost={onEditPost}
                  onClickLikedProps={onClickLikedProps}
                  setSelectedPost={setSelectedPost}
                  lightboxImages={lightboxImages}
                  setLightboxImages={setLightboxImages}
                  showError={() => {}}
                  showSuccess={() => {}}
                  onDeletePost={onDeletePost}
                  onSearchLocation={onSearch}
                  onClickPost={onClickPost}
                  hideNewsfeed
                  isMapMode
                />
              }

              {target?.type === LOCATION_PROPERTY
                && <PropertyView
                  user={user}
                  session={session}
                  target={target}
                  focus={focus}
                  setFocus={setFocus}
                  setFocusOn={setFocusOn}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                  goToProperty={goToProperty}
                  goToHdbBlock={goToHdbBlock}
                  goToEstate={goToEstate}
                  goToSchool={postal => {
                    const school = mapData.schools.find(s => s.postal === postal);
                    if (school) goToSchool(school);
                  }}
                  goToStation={name => {
                    const station = mapData.stations.find(s => s.name === name);
                    if (station) goToStation(station);
                  }}
                  goToMarker={goToMarker}
                  screenDim={screenDim}
                  compareList={compareList}
                  setCompareList={setCompareList}
                  addToCompareList={addToCompareList}
                  viewComparePro={viewComparePro}
                  getProjectLabel={getProjectLabelFromCompKey}
                  userConfig={userConfig}
                  lightboxImages={lightboxImages}
                  setLightboxImages={setLightboxImages}
                  onLikeProperty={onLikeProperty}
                  likedProps={likedProps}
                  // onSwitchPropertyTab={showLoginIfGuest}
                  onCompareSearch={onCompareSearch}
                  unfadedProps={unfadedProps}
                  setUnfadedProps={setUnfadedProps}
                  schoolsData={mapData?.schools}
                  gpsLocation={gpsLocation}
                />
              }

              {target?.type === LOCATION_ESTATE
                && <EstateView
                  user={user}
                  session={session}
                  target={target}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                  screenDim={screenDim}
                  userConfig={userConfig}
                  landedMapData={landedMapData}
                  unfadedProps={unfadedProps}
                  setUnfadedProps={setUnfadedProps}
                  setFocus={setFocus}
                  goToProperty={goToProperty}
                />
              }

              {(target?.type === LOCATION_AREA || target?.type === LOCATION_MARKER)
                && <AreaView
                  user={user}
                  mode={mode}
                  session={session}
                  target={target}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                  goToProperty={goToProperty}
                  goToHdbBlock={goToHdbBlock}
                  userConfig={userConfig}
                  unfadedProps={unfadedProps}
                  setUnfadedProps={setUnfadedProps}
                />
              }

              {(target?.type === LOCATION_STATION || target?.type === LOCATION_SCHOOL)
                && <AmenityView
                  user={user}
                  userConfig={userConfig}
                  session={session}
                  mapData={mapData}
                  target={target}
                  setTarget={setTarget}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                  goToProperty={goToProperty}
                  goToHdbBlock={goToHdbBlock}
                  goToBlock={goToBlock}
                  mode={mode}
                  setMode={setMode}
                  filterSelected={filterSelected}
                  unfadedProps={unfadedProps}
                  setUnfadedProps={setUnfadedProps}
                />
              }

              {target?.type === LOCATION_UPCOMING
                && <UpcomingView
                  user={user}
                  session={session}
                  target={target}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                  unfadedProps={unfadedProps}
                  setUnfadedProps={setUnfadedProps}
                />
              }

              {target?.type === LOCATION_COMPARE
                && <CompareProView
                  user={user}
                  target={target}
                  screenDim={screenDim}
                  isMaximized={isMaximized}
                  isMinimized={isMinimized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  goToProperty={goToProperty}
                  setFocusOn={setFocusOn}
                  compareList={compareList}
                  setCompareList={setCompareList}
                  closeDetails={closePropertyDetails}
                  getProjectLabel={getProjectLabelFromCompKey}
                  onCompareSearch={onCompareSearch}
                  unfadedProps={unfadedProps}
                  setUnfadedProps={setUnfadedProps}
                  setFocus={setFocus}
                />
              }
            </div>
          </section>

          <Lightbox
            open={!!lightboxImages}
            close={() => setLightboxImages(null)}
            slides={lightboxImages?.gallery ?? []}
            index={lightboxImages?.idx ?? 0}
            portal={{ target: document.body }}
            plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
          />
        </>
      }

      {(allowBot && false)
        && <BotPanel
          hidden={!!target}
          onGoToCondo={(marker, id) => {
            goToProperty(marker, id, MAP_MODE_CONDO);
          }}
          onGoToLanded={(marker, id) => {
            goToProperty(marker, id, MAP_MODE_LANDED);
          }}
          onGoToHdb={postal => {
            setMode(MAP_MODE_HDB);
            goToHdbBlock(postal);
          }}
          onGoToCondoMap={(tenure) => {
            setMode(MAP_MODE_CONDO);
            const filter = {
              // prop_type: {
              //   'Apt/Condo': true,
              //   'Executive Condominium': true
              // }
            };
            if (tenure) {
              filter.tenure = {};
              tenure.forEach(t => filter.tenure[t] = true);
            }
            setIncomingFilter({
              data: mapData.projects,
              filter,
              mode: MAP_MODE_CONDO
            });
          }}
          onGoToLandedMap={(tenure) => {
            setMode(MAP_MODE_LANDED);
            const filter = {
              // prop_type: {
              //   'Detached House': true,
              //   'Semi-Detached House': true,
              //   'Terrace House': true
              // }
            };
            if (tenure) {
              filter.tenure = {};
              tenure.forEach(t => filter.tenure[t] = true);
            }
            setIncomingFilter({
              data: landedMapData.projects,
              filter,
              mode: MAP_MODE_LANDED
            });
          }}
          onGoToHdbMap={(flatTypes) => {
            setMode(MAP_MODE_HDB);
            const filter = {
              mode: MAP_MODE_HDB
            };
            if (flatTypes) {
              filter.flat_type = {};
              flatTypes.forEach(t => filter.flat_type[t] = true);
            }
            if (!hdbMapData) {
              loadMapData(MAP_MODE_HDB, null, (hdbMapData) => {
                setIncomingFilter({
                  data: hdbMapData.blocks,
                  filter,
                  mode: MAP_MODE_HDB
                });
              });
            } else {
              setIncomingFilter({
                data: hdbMapData.blocks,
                filter,
                mode: MAP_MODE_HDB
              });
            }
          }}
          onRestart={() => {
            setActionSignal('resetFilter');
          }}
        />
      }

      {user !== null && !isLoggedIn(user)
        && <LoginPopup
          session={session}
          show={showLogin}
          setShow={setShowLogin}
          onLoggedIn={onLoggedIn}
        />
      }

    </>
  );
};

export default HomeMap;
