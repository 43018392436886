import Social from "../../common/social/Social";

const Copyright = () => {
  const startYear = 2024;
  const currYear = new Date().getFullYear();

  return (
    <div className="row justify-between items-center y-gap-10 text-12">
      <div className="col-auto">
        <div className="row x-gap-30 y-gap-10">
          <div className="col-auto">
            <div className="d-flex items-center">
              © {currYear === startYear ? startYear : `${startYear} - ${currYear}`} by
              <a
                href="https://realsmart.sg"
                className="mx-2 text-blue-1 fw-600"
                // target="_blank"
                // rel="noopener noreferrer"
              >
                REALSMART.SG
              </a>
              <span>All rights reserved.</span>
            </div>
          </div>
          {/* End .col */}

          
          {/* End .col */}
        </div>
        {/* End .row */}
      </div>
      {/* End .col */}

      <div className="col-auto">
        <div className="row y-gap-10 items-center">
          <div className="col-auto text-12 fw-600">
            <div className="d-flex x-gap-15">
              <a href="/about">About Us</a>
              <a href="/terms?t=privacy">Privacy</a>
              <a href="/terms?t=tos">Terms</a>
            </div>
          </div>
          <div className="col-auto">
            <div className="d-flex x-gap-20 items-center">
              <Social />
            </div>
          </div>
          {/* End .col */}
        </div>
      </div>
      {/* End .col */}
    </div>
  );
};

export default Copyright;
