import PropTypes from "prop-types";

const RangeInput = ({ min, setMin, max, setMax }) => {
  return (
    <div className="row y-gap-10 items-center justify-between">
      <div className="col-auto col-6">
        <div className="single-field relative d-flex items-center py-10">
          <input
            className="pl-50 border-light text-dark-1 h-40 rounded-8"
            type="number"
            placeholder="Min"
            value={min ?? ''}
            onChange={e => setMin(e.target.value)}
            min={0}
            max={max}
          />
          <button className="absolute d-flex items-center h-full">
            <i className="icon-chevron-right text-20 px-15 text-dark-1" />
          </button>
        </div>
      </div>
      <div className="col-auto col-6">
        <div className="single-field relative d-flex items-center py-10">
          <input
            className="pl-50 border-light text-dark-1 h-40 rounded-8"
            type="number"
            placeholder="Max"
            value={max ?? ''}
            onChange={e => setMax(e.target.value)}
            min={Math.min(0, min)}
          />
          <button className="absolute d-flex items-center h-full">
            <i className="icon-chevron-left text-20 px-15 text-dark-1" />
          </button>
        </div>
      </div>
    </div>
  );
};

RangeInput.propTypes = {
  min: PropTypes.number,
  setMin: PropTypes.func.isRequired,
  max: PropTypes.number,
  setMax: PropTypes.func.isRequired,
};

export default RangeInput;
