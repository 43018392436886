import { LOCATION_BLOCK } from "@/utils/areas";
import { calculateDist, formatDistanceLabel } from "@/utils/convert";


const HdbSchools = ({
  target,
  schools,
  focus,
  setFocus,
  isMaximized,
  handleExpand,
  schoolsData,
  goToSchool
}) => {
  const formattedSchools = schools
    .map(s => {
      const dist = calculateDist(target.lat, target.lng, s.lat, s.lng);
      const d = {
        ...s,
        distance: Math.min(1000, dist)
      };
      if (dist > 1000) {
        d.radius = dist;
      }
      return d;
    })
    .sort((a, b) => a.distance - b.distance);

  const getSchoolRank = (postal) => {
    const sch = schoolsData.find(s => s.postal === postal);
    return sch?.rank ?? 0;
  };

  const isTopSchool = (postal) => {
    const rank = getSchoolRank(postal);
    if (!rank) return false;
    return true;
  };

  const isHotSchool = (postal) => {
    const sch = schoolsData.find(s => s.postal === postal);
    return !!sch?.popularity;
  };

  const getSchoolOverapplyRatio = (postal) => {
    const sch = schoolsData.find(s => s.postal === postal);
    return sch?.ratio;
  };

  const isOverAppliedSchool = (postal) => {
    const ratio = getSchoolOverapplyRatio(postal);
    return ratio;
  };

  const showSchDetails = (postal) => {
    return isTopSchool(postal) || isHotSchool(postal) || isOverAppliedSchool(postal);
  };

  return (
    <div className="pt-10 mb-50">
      {schools.length === 0
        && <div className="py-20 text-18 fw-500 text-center">No schools nearby or waiting for official guidance</div>
      }

      {schools.length > 0
        && <div className="row mt-0 mb-10">
          <div className="col-12">
            <div className="">
              <div className="mb-20 md:mb-10 mt-20">
                <div
                  className="d-flex items-center mb-10"
                >
                  <div
                    className="ml-10 text-14 fw-500"
                  >
                    Schools within 1km
                  </div>
                </div>

                <div
                  className="row y-gap-10 x-gap-0 px-10 surrounding-f"
                >
                  {formattedSchools.map((s, i) => (
                    <div
                      className="col-12 border-top-light"
                      key={i}
                      style={{
                        opacity: !focus || focus.mode !== LOCATION_BLOCK || (focus && focus.links?.length > 0 && focus.links[0].name === s.name) || focus.use !== 'nearby-sch'
                          ? 1 : 0.2
                      }}
                    >
                      <div className="d-flex items-center justify-between">
                        <div className="p-2 flex-grow-1 px-0 py-0">
                          <div
                            className="text-13 hover-blue-row cursor-pointer nodecor"
                            onClick={() => {
                              if (focus && focus.links?.length > 0 && focus.links[0].name === s.name) {
                                setFocus(null);
                              } else {
                                const focusTarget = {
                                  ...target,
                                  type: LOCATION_BLOCK
                                };
                                if (s.radius) {
                                  focusTarget.radius = s.radius;
                                }
                                setFocus({
                                  use: 'nearby-sch',
                                  target: focusTarget,
                                  mode: LOCATION_BLOCK,
                                  links: [s]
                                });
                                if (isMaximized) {
                                  handleExpand();
                                }
                              }
                            }}
                          >
                            {s.name}
                          </div>
                        </div>

                        <div className="p-2">
                          <div className="text-13 text-right">
                            {formatDistanceLabel(s.distance)}
                          </div>
                        </div>

                        <div className="p-2">
                          <button
                            className="button mr-5 -dark-1 bg-blue-1 text-white h-20 px-10 rounded-100 text-10"
                            onClick={() => {
                              goToSchool(s.postal);
                            }}
                            style={{
                              margin: '0 auto',
                              display: 'block'
                            }}
                          >
                            DETAILS
                          </button>
                        </div>
                      </div>
                      {
                        showSchDetails
                          ? <div className="mb-10 pt-0 mt-0 d-flex flex-wrap">
                              {isTopSchool(s.postal)
                                && <span className="text-10 mr-5 lh-10 items-center text-white proj-beta noselect rounded-100 fw-500 noselect">
                                  Top {getSchoolRank(s.postal)} (Ranked by ChatGPT)
                                </span>
                              }
                              {isOverAppliedSchool(s.postal)
                                && <span className="text-10 mr-5 lh-10 items-center text-white proj-beta-2 noselect rounded-100 fw-500 noselect">
                                  {getSchoolOverapplyRatio(s.postal)}x Overapplied
                                </span>
                              }
                              {isHotSchool(s.postal)
                                && <span className="text-10 mr-5 lh-10 items-center text-white proj-beta-3 noselect rounded-100 fw-500 noselect">
                                  HOT
                                </span>
                              }
                            </div>
                          : ''
                      }
                    </div>
                  ))}
                </div>

              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default HdbSchools;
