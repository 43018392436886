import { useCallback, useEffect, useState } from "react";
import ScrollToTop from "@/components/project/ScrollToTop";
import {
  checkMediaQuery
} from "@/utils/user";
import TabButtons from "./property/TabButtons";
import {
  getFile,
  trackEvent
} from "@/utils/api";
import {
  MAP_MODE_LANDED,
  PROPERTY_DATA_FILE_PREFIX,
  censorUnit,
  decompressEstateData,
  getPropertyFileName
} from "@/utils/map";
import Loader from "@/components/common/Loader";
import EstateOverviewTab from "./property/EstateOverviewTab";
import EstateTrendTab from "./property/EstateTrendTab";
import PropertyRow from "./PropertyRow";
import { NA } from "@/utils/convert";
import AnnotatedChart from "./chart/AnnotatedChart";
import { convertDisplayDateMsec, formatShortDate } from "@/utils/time";

const TABS = [
  { id: 'overview', label: 'Overview' },
  { id: 'trend', label: 'Trend' },
  { id: 'projects', label: 'Properties' }
];

const EstateView = ({
  user,
  session,
  target,
  isMinimized,
  isMaximized,
  handleExpand,
  handleMaximize,
  landedMapData,
  closePropertyDetails,
  screenDim,
  userConfig,
  setUnfadedProps,
  setFocus,
  goToProperty
}) => {
  const [selectedTab, setSelectedTab] = useState(TABS[0].id);
  const [selectedSubtab, setSelectedSubtab] = useState(null);

  const [cellDetailsLoading, setCellDetailsLoading] = useState(false);
  const [cellDetails, setCellDetails] = useState(null);
  const [defaultDisplayBlock, setDefaultDisplayBlock] = useState(null);

  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState(null);

  const showEstatePropertyOnMap = (projects) => {
    const newUnfaded = new Set();
    const latLngs = [];
    projects.forEach(p => {
      const key = `${p.marker}_${p.id}`;
      newUnfaded.add(key);
      if (key in landedMapData.projectIndex) {
        const project = landedMapData.projects[landedMapData.projectIndex[key]];
        latLngs.push({ lat: project.lat, lng: project.lng });
      }
    });
    setUnfadedProps(newUnfaded);
    setFocus({
      use: 'comparables',
      multiTargets: latLngs
    });
  };

  const loadEstateData = () => {
    setErr(null);
    setLoading(true);

    getFile("e", PROPERTY_DATA_FILE_PREFIX + getPropertyFileName(target.id), txt => {
      const data = decompressEstateData(txt);
      showEstatePropertyOnMap(data.projects);
      setData(data);
      setLoading(false);
      
    }, err => {
      setLoading(false);
      setErr('Failed to load property');
    });
  };

  useEffect(() => {
    setUnfadedProps(new Set());
    setData(null);
    setSelectedTab('overview');
    onSwitchTab('overview');
    loadEstateData();
    trackEvent(`estate_view_${target.id}`);
  }, [target]);

  const onSwitchTab = (tab, subtab) => {
    document.getElementById('project-details-modal')?.scrollTo({ top: 0, behavior: 'smooth' });
  
    // reset subtab if not specified
    setSelectedSubtab(subtab);

    if (subtab) {
      trackEvent(`property_tab_${tab}_${subtab}`);
    } else {
      trackEvent(`property_tab_${tab}`);
    }
  };

  const mediaMatches = checkMediaQuery();

  const hasData = data && data?.brief?.name === target?.id;

  useEffect(() => {
    loadEstateData();
    trackEvent(`estate_view_${target.id}`);
  }, []);

  const closeSideBar = () => {
    if (document.getElementById('listingDetails').classList.contains('show')) {
      document.getElementById('details-close-button').click();
    }
  };

  const escFunc = useCallback((event) => {
    if (event.key === "Escape") {
      closePropertyDetails();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunc, false);

    return () => {
      document.removeEventListener("keydown", escFunc, false);
    };
  }, [escFunc]);

  return (
    <>
      {!isMinimized && (
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-center text-10 text-light-1">
              {/* <div className="p-2 py-0"></div> */}
              <div
                className={`p-2 pt-5 pb-0 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                } mt-0 mb-0`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down px-5 expand-btn-border" />
              </div>
            </div>
          }

          <div className="px-10 mt-10">
            <TabButtons
              tabs={TABS}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              onSwitchTab={onSwitchTab}
              session={session}
              markerName={target.id}
              projectName={target.project}
              closePropertyDetails={closePropertyDetails}
            />
          </div>

          {err
            && <div className="modal-body watermark d-flex">
              {!mediaMatches
                && <div
                  className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                  onClick={() => isMaximized ? handleExpand() : handleMaximize()}
                >
                  <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                    <i className="icon-chevron-sm-down px-5 expand-btn-border" />
                  </div>
                </div>
              }

              <div
                id="project-details-modal"
                className={`p-2 modal-scoll-content text-center fw-500 py-40 ${
                  isMaximized ? 'modal-scroll-media-full' : (mediaMatches ? 'modal-scroll-media' : '')
                }`}
              >
                Estate not found
              </div>
            </div>
          }

          {!err
            && <div
              className={`modal-body ${
                mediaMatches && !isMaximized
                  ? 'modal-body-prop-m'
                  : 'modal-body-prop'
              } watermark d-flex`}
            >
              {!mediaMatches
                && <div
                  className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                  onClick={() => isMaximized ? handleExpand() : handleMaximize()}
                >
                  <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                    <i className="icon-chevron-sm-down px-5 expand-btn-border" />
                  </div>
                </div>
              }

              <div
                id="project-details-modal"
                className={`p-2 modal-scoll-content ${
                  isMaximized ? 'modal-scroll-media-full' : (mediaMatches ? 'modal-scroll-media' : '')
                }`}
              >

                <ScrollToTop />

                {(loading || !hasData)
                  && <div className="loader-container">
                    <Loader />
                  </div>
                }

                {/* detail page tabs */}
                {hasData && selectedTab === 'overview'
                  && <EstateOverviewTab
                    user={user} data={data.brief} isMaximized={isMaximized} screenDim={screenDim}
                    target={target} userConfig={userConfig}
                  />
                }

                {hasData && selectedTab === 'trend'
                  && <EstateTrendTab user={user} data={data} isMaximized={isMaximized} screenDim={screenDim}
                    session={session} target={target} selectedSubtab={selectedSubtab}
                    setCellDetails={setCellDetails} setCellDetailsLoading={setCellDetailsLoading}
                    defaultBlock={defaultDisplayBlock} goToProperty={goToProperty}
                  />
                }

                {hasData && selectedTab === 'projects'
                  && <div className="">
                    {data.projects.map((p, i) => (
                      <PropertyRow
                        key={`project_${i}`}
                        id={i}
                        mode={MAP_MODE_LANDED}
                        data={p}
                        onView={(data) => goToProperty(data.marker, data.id)}
                      />
                    ))}
                  </div>
                }

                <div
                  className="offcanvas offcanvas-end filter-bar-nopad"
                  tabIndex="-1"
                  id="listingDetails"
                >
                  <div className={`offcanvas-header block-list-panel ${isMaximized ? 'block-list-max' : ''}`}>
                    <h5 className="offcanvas-title text-16" id="offcanvasLabel">
                      {cellDetails?.displayBlock ?? ''} {
                        cellDetails ? (cellDetails.unit !== NA ? censorUnit(cellDetails.unit) : '') : ''
                      }
                    </h5>
                    <button
                      id="details-close-button"
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div className="offcanvas-body watermark block-list-end">
                    <aside className="sidebar y-gap-20 xl:d-block pl-10 pb-20">
                      {cellDetailsLoading
                        && <div className="loader-container">
                          <Loader />
                        </div>
                      }
                      {!cellDetailsLoading && cellDetails
                        && <>
                          <h2 className="text-15 lh-1 fw-500">Price Trend ($)</h2>
                          <AnnotatedChart
                            label="Price Trend ($)"
                            data={
                              cellDetails.tx.map(row => ({
                                x: row['ts'],
                                y: row.price,
                              }))
                            }
                            tooltipTitle="Price ($)"
                            tooltipLabelCallback={
                              (context) => {
                                const date = new Date(context.raw.x);
                                const formattedDate = formatShortDate(date);
                                return `${formattedDate}: $${context.raw.y.toLocaleString()}`;
                              }
                            }
                            yAxisLabel="Price ($)"
                          />

                          <div className="pt-10"></div>

                          {cellDetails.gains.filter(g => g !== null).length > 0 && <>
                            <h2 className="text-15 lh-1 fw-500">Annualized Profit (%)</h2>
                            <AnnotatedChart
                              label="Annualized Profit (%)"
                              data={
                                cellDetails.gains.filter(g => g !== null).map(row => ({
                                  x: row['ts'],
                                  y: row['gain'],
                                }))
                              }
                              tooltipTitle="Annualized Profit (%)"
                              tooltipLabelCallback={
                                (context) => {
                                  const date = new Date(context.raw.x);
                                  const formattedDate = formatShortDate(date);
                                  return `${formattedDate}: ${context.raw.y.toFixed(2)}%`;
                                }
                              }
                              yAxisLabel="Annualized Profit (%)"
                            />
                          </>}

                          {cellDetails.tx.length > 0 
                            && <h2 className="text-15 lh-1 fw-500">Transaction History</h2>
                          }

                          {cellDetails.tx
                              .sort((a, b) => b['ts'] - a['ts'])
                              .map((row, i) => (
                            <div
                              key={`tx_${i}`}
                              className="px-10 py-5 bg-blue-2 text-12 text-blue-1 mr-20 mt-10 text-10"
                            >
                              <span className="text-14 fw-500">
                                {convertDisplayDateMsec(row['ts'])}
                              </span>
                              <br />
                              <span className="">
                                Type of Sale: {row.type}
                              </span>
                              <br />
                              <span className="">
                                Price: ${row.price.toLocaleString()}
                              </span>
                              <br />
                              <span className="">
                                Price per area: ${row.unitPrice.toLocaleString()} psf
                              </span>
                              {cellDetails.gains.length > 0 && cellDetails.gains[i] !== null
                                && <>
                                  <br />
                                  <span className="">
                                    Annualized Profit (%): {cellDetails.gains[i].gain.toFixed(2)}%
                                  </span>
                                </>
                              }
                            </div>
                          ))}
                        </>
                      }
                    </aside>
                  </div>
                </div>

              </div>
            </div>
          }

        </div>
      )}
    </>
  );
};

export default EstateView;
