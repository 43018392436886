export const sortAsc = (array) => array.sort((a, b) => a - b);

export const getCount = (array) => array?.length ?? 0;

export const getSum = (array) => array?.reduce((a, b) => a + b, 0);

export const getMean = (array) => array?.length > 0 ? getSum(array) / array.length : 0;

export const getQuantile = (array, quantile) => {
  const sorted = sortAsc(array);
  const pos = (sorted.length - 1) * quantile;
  const base = Math.floor(pos);
  const rest = pos - base;
  return sorted[base + 1] !== undefined
    ? (sorted[base] + rest * (sorted[base + 1] - sorted[base]))
    : sorted[base];
};

export const getMedian = (array) => getQuantile(array, 0.5);

export const getQuantileRange = (array, quantiles) => {
  const sortedQuantiles = sortAsc(quantiles);
  return sortedQuantiles.map(q => getQuantile(array, q));
};

const STANDARD_PERCENTILES = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9];

export const STANDARD_PERCENTILES_LABEL = STANDARD_PERCENTILES.map(q => `${(q * 100).toFixed(0)}th`);

export const getStandardPercentiles = (array) => {
  return getQuantileRange(array, STANDARD_PERCENTILES);
};

export const round0 = (value) => Math.ceil(value);

export const round1 = (value) => Math.ceil(value * 10) / 10;

export const round2 = (value) => Math.ceil(value * 100) / 100;

export const getHistogram = (array) => {
  if (array.length < 2) {
    return array.map(v => ({ x: v, y: 1 }));
  }

  const sortedArray = sortAsc(array);
  const minValue = sortedArray[0];
  const maxValue = sortedArray[sortedArray.length - 1];
  const interval = (maxValue - minValue) / Math.min(10, array.length);

  const bins = [
    {
      x: round1(minValue + interval),
      y: 0
    }
  ];

  let binIndex = 0;

  for (let i = 0; i < sortedArray.length; i += 1) {
    let limit = bins[binIndex].x;
    const value = sortedArray[i];

    while (value > limit) {
      bins.push({
        x: round1(limit + interval),
        y: 0
      });
      binIndex += 1;
      limit = bins[binIndex].x;
    }

    bins[binIndex].y += 1;
  }

  return bins;
};
