import { isAdminTier, isPremium } from "@/utils/user";
import { useNavigate } from "react-router-dom";
import firebase from 'firebase/compat/app';
import PostsPage from "./PostsPage";

const ProfilePage = ({
    user,
    session,
    onCreatePost,
    onClickPost,
    hideNewsfeed
}) => {
    const navigate = useNavigate();

    const userDisplayName = user?.claims?.name;
    const userId = user?.claims?.user_id;
    const displayImgUrl = user?.claims?.picture;

    const isProUser = isPremium(user);
    const isAdminUser = isAdminTier(user?.claims);

    const onLogout = () => {
        firebase.auth().signOut().then(() => {
            try {
                localStorage.removeItem('session_idx');
            } catch(e) {
                // do nothing
            }

            // clear user tracking on Umami when logged out
            if (window.umami && typeof window.umami.reset === 'function') {
                window.umami.reset();
            }
          
            // navigate to login page
            navigate('/login');
        });
    };

    return (
        <div
            className="d-flex flex-column"
            style={{
                height: '100%'
            }}
        >
            <div
                className="d-flex px-20"
                style={{...(
                    hideNewsfeed
                        ? {}
                        : {
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)'
                        }
                )}}
            >
                <img
                    className="p-2"
                    src={displayImgUrl ? displayImgUrl : '/img/general/icons-xl/house.png'}
                    height={100}
                    width={100}
                    style={{ borderRadius: '50%' }}
                />
                <div className="p-2 px-10">
                    <span className="text-18 fw-600">{userDisplayName}</span>
                    <br />
                    <span className="text-12 fw-500 text-white bg-green-2 px-10 py-5 rounded-100">
                        <i className="icon-user-2 mr-5" />
                        {
                            isAdminUser
                                ? 'Administrator'
                                : (isProUser ? 'VIP Member' : 'Basic Member')
                        }
                    </span>
                </div>
            </div>

            {hideNewsfeed
                && <div
                    className="like-props watermark"
                >
                    <div className="px-10 py-30 fw-600 text-14 text-center">
                        New features coming soon
                    </div>
                </div>
            }

            {!hideNewsfeed
                && <PostsPage
                    user={user}
                    session={session}
                    onCreatePost={onCreatePost}
                    onClickPost={onClickPost}
                />
            }

            <div className="d-flex justify-content-end pt-10 pb-10 ml-10 mr-10 border-top-light">
                <button
                    className="button ml-auto p-2 px-30 fw-400 text-12 border-blue-1 -outline-blue -blue-1-05 bg-white h-40 text-blue-1"
                    onClick={onLogout}
                >
                    <i className="icon-bed text-18 mr-10" />
                    Logout
                </button>
            </div>
        </div>
    );
};

export default ProfilePage;
