import {
  checkMediaQuery,
  noSelectClass
} from "@/utils/user";
import ScrollToTop from "../project/ScrollToTop";
import AmenityTable from "./property/Table/AmenityTable";
import { useCallback, useEffect, useState } from "react";
import {
  calculateDist,
  formatDistanceLabel
} from "@/utils/convert";
import {
  getFile,
  getNearbyCondos,
  getNearbyHdbs,
  getNearbyLanded,
  trackEvent
} from "@/utils/api";
import {
  MAP_MODE_CONDO,
  MAP_MODE_HDB,
  MAP_MODE_LANDED,
  SCHOOL_DATA_FILENAME_PREFIX,
  decompressAmenityData,
  getGcsDir,
  getPropTypeLabel
} from "@/utils/map";
import Loader from "../common/Loader";
import {
  LOCATION_SCHOOL,
  getBlockUnitFromAddress
} from "@/utils/areas";
import MaxDistInput from "./filter/MaxDistInput";
import { convertMapUrl } from "@/utils/url";

const PROP_OPTIONS = [
  {
    id: MAP_MODE_CONDO,
    label: 'Condo / Apt / EC'
  },
  {
    id: MAP_MODE_LANDED,
    label: 'Landed'
  },
  {
    id: MAP_MODE_HDB,
    label: 'HDB'
  }
];

const DEFAULT_MAX_DIST = 1000;

const AmenityView = ({
  user,
  userConfig,
  target,
  setTarget,
  mapData,
  isMinimized,
  isMaximized,
  handleMaximize,
  handleExpand,
  closePropertyDetails,
  goToProperty,
  goToBlock,
  goToHdbBlock,
  mode,
  setMode,
  filterSelected,
  setUnfadedProps
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // filters
  const [filterProp, setFilterProp] = useState(mode);
  const [filterMaxDist, setFilterMaxDist] = useState(DEFAULT_MAX_DIST);

  const basicData = target?.type === LOCATION_SCHOOL ? mapData.schools.find(s => s.id === target.id) : null;

  const getSchoolRank = () => {
    return basicData?.rank ?? 0;
  };

  const isTopSchool = () => {
    const rank = getSchoolRank();
    if (!rank) return false;
    return true;
  };

  const isHotSchool = () => {
    return !!basicData?.popularity;
  };

  const getSchoolOverapplyRatio = () => {
    return basicData?.ratio;
  };

  const isOverAppliedSchool = () => {
    const ratio = getSchoolOverapplyRatio();
    return ratio;
  };

  const showSchDetails = () => {
    return isTopSchool() || isHotSchool() || isOverAppliedSchool();
  };

  const loadData = (filterProp) => {
    setLoading(true);
    setData([]);
    const onData = (data) => {
      const formattedData = data
        .map(r => {
          const lat = r.coords[0];
          const lng = r.coords[1];
          const d = {
            ...r,
            lat,
            lng,
            distance: r.dist ? r.dist : calculateDist(target.lat, target.lng, lat, lng)
          };
          if (filterProp !== MAP_MODE_HDB) {
            d.name = r.names[0];
            d.addr = r.street[0];
          }
          return d;
        })
        .filter(r => r.distance <= DEFAULT_MAX_DIST)
        .sort((a, b) => a.distance - b.distance);
      setData(formattedData);
      setLoading(false);
    };
    const onErr = (err) => {
      setLoading(false);
    };
    const isPriSch = basicData?.type === 'school' && basicData?.types.indexOf('Primary School') >= 0;
    if (isPriSch) {
      getFile(getGcsDir('sch'), SCHOOL_DATA_FILENAME_PREFIX + basicData.postal, txt => {
        const data = decompressAmenityData(txt);
        setData(data[filterProp].sort((a, b) => a.distance - b.distance));
        setLoading(false);
      }, onErr);
    } else if (filterProp === MAP_MODE_CONDO) {
      getNearbyCondos(target.lat, target.lng, '1 km', 200, onData, onErr);
    } else if (filterProp === MAP_MODE_LANDED) {
      getNearbyLanded(target.lat, target.lng, '1 km', 200, onData, onErr);
    } else if (filterProp === MAP_MODE_HDB) {
      getNearbyHdbs(target.lat, target.lng, '1 km', 200, onData, onErr);
    }
  };

  useEffect(() => {
    setUnfadedProps(new Set());
    loadData(mode);

    // link
    convertMapUrl(target, null, null, filterProp);

    // check if target has option to view preferred property mode
    setTimeout(() => {
      if (target?.opt) {
        if (target.opt !== filterProp) {
          setFilterProp(target.opt);
          if (mode !== target.opt) setMode(target.opt);
          loadData(target.opt);
        }
      }
    }, 600);
  }, [target]);

  useEffect(() => {
    // analytics
    trackEvent(`${target.type === LOCATION_SCHOOL ? 'school' : 'station'}_view_${target.id}`);
  }, []);

  useEffect(() => {
    // link
    convertMapUrl(target, null, null, filterProp);
  }, [filterProp]);

  const filterProps = (r) => {
    // filter by max distance
    if (r.distance > filterMaxDist) return false;
    return true;
  };

  const mediaMatches = checkMediaQuery();

  const goToPropertyFromRow = (data) => {
    const idx = data.cell.row.index;
    if (filterProp === MAP_MODE_HDB) {
      goToHdbBlock(data.data[idx].store);
    } else {
      goToProperty(data.data[idx].marker, data.data[idx].store);
    }
  };

  const goToBlockFromRow = (data) => {
    const idx = data.cell.row.index;
    let block = getBlockUnitFromAddress(data.data[idx].address);
    const match = block.block.match(/^\S+/);
    block = match ? match[0] : block.block;
    goToBlock({
      ...data.data[idx],
      block,
    });
  };

  const getDisplayLabel = (target) => {
    if (target.name) {
      return target.name;
    }
    if (target.names) {
      const names = target.names.filter(n => !n.includes('MOE KINDERGARTEN'));
      if (names.length > 0) {
        return names[0];
      }
    }
    return '';
  };

  const getLabelHint = (label) => {
    if (!label) return [];
    return label.split(',');
  };

  const escFunc = useCallback((event) => {
    if (event.key === "Escape") {
      closePropertyDetails();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunc, false);

    return () => {
      document.removeEventListener("keydown", escFunc, false);
    };
  }, [escFunc]);

  return (
    <>
      {loading
        && <div className="loader-container">
          <Loader />
        </div>
      }

      {!isMinimized && !loading && (
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-center text-10 text-light-1">
              {/* <div className="p-2 py-0"></div> */}
              <div
                className={`p-2 pt-5 pb-0 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                } mt-0 mb-0`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down px-5 expand-btn-border" />
              </div>
            </div>
          }

          <div className="d-flex align-items-center justify-content-start py-0 mt-15 mb-5 modal-menu noselect">
            <i className="p-2 icon-location-2 text-18" />
            <h3
              className="p-2 text-15 fw-500 flex-grow-1 text-truncate"
              data-tooltip-id="map-tooltip"
              data-tooltip-html={`
                <div class="map-tooltip-content">
                  ${getLabelHint(target.name ?? target.names?.join(',')).join('<br />')}
                </div>
              `}
              data-tooltip-variant="dark"
              data-tooltip-place="bottom"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {getDisplayLabel(target)}
            </h3>

            <button
              className="p-2 button h-30 px-10 text-16"
              onClick={() => closePropertyDetails()}
            >
              <i className="icon-close py-5" />
            </button>
          </div>

          {target?.type === LOCATION_SCHOOL && showSchDetails()
            && <div className="px-30 md:px-10 d-flex flex-wrap">
              {isTopSchool()
                && <span className="text-10 mr-5 lh-10 items-center text-white proj-beta noselect rounded-100 fw-500 noselect">
                  Top {getSchoolRank()} (Ranked by ChatGPT)
                </span>
              }
              {isOverAppliedSchool()
                && <span className="text-10 mr-5 lh-10 items-center text-white proj-beta-2 noselect rounded-100 fw-500 noselect">
                  {getSchoolOverapplyRatio()}x Overapplied
                </span>
              }
              {isHotSchool()
                && <span className="text-10 mr-5 lh-10 items-center text-white proj-beta-3 noselect rounded-100 fw-500 noselect">
                  HOT
                </span>
              }
            </div>
          }

          {(!data || data.length === 0)
            && <div className="modal-body watermark d-flex">
              {!mediaMatches
                && <div
                  className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                  onClick={() => isMaximized ? handleExpand() : handleMaximize()}
                >
                  <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                    <i className="icon-chevron-sm-down px-5 expand-btn-border" />
                  </div>
                </div>
              }

              <div
                id="project-details-modal"
                className={`p-2 modal-scoll-content text-center text-14 py-40 ${
                  isMaximized ? `modal-scroll-am-full${target?.type === LOCATION_SCHOOL && showSchDetails() ? '-s' : ''} ` : (mediaMatches ? `modal-scroll-am${target?.type === LOCATION_SCHOOL && showSchDetails() ? '-s' : ''}` : 'modal-scoll-content-am')
                }`}
              >
                No data yet
              </div>
            </div>
          }

          {data && data.length > 0
            && <div className="modal-body watermark d-flex">
              {!mediaMatches
                && <div
                  className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                  onClick={() => isMaximized ? handleExpand() : handleMaximize()}
                >
                  <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                    <i className="icon-chevron-sm-down px-5 expand-btn-border" />
                  </div>
                </div>
              }

              <div
                id="project-details-modal"
                className={`p-2 modal-scoll-content ${
                  isMaximized
                    ? (
                        mediaMatches
                          ? `modal-scroll-am-full${target?.type === LOCATION_SCHOOL && showSchDetails() ? '-s' : ''}`
                          : `modal-scroll-am-full${target?.type === LOCATION_SCHOOL && showSchDetails() ? '-ls' : ''}`
                      )
                    : (
                        mediaMatches
                          ? `modal-scroll-am${target?.type === LOCATION_SCHOOL && showSchDetails() ? '-s' : ''}`
                          : `modal-scoll-content-am${target?.type === LOCATION_SCHOOL && showSchDetails() ? '-s' : ''}`
                      )
                }`}
              >

                <ScrollToTop />
              
                <section className={`modal-table${isMaximized ? '-full' : ''}`}>
                  <div className={`data-table ${noSelectClass(user)} mb-50`}>

                    <div className="d-flex noselect mt-10">
                      <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
                        <div
                          className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5 text-12 lh-12 "
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="true"
                          aria-expanded="false"
                          {...(mediaMatches ? {} :
                            {
                              'data-bs-offset': "0,10",
                              'data-tooltip-id': "map-tooltip",
                              'data-tooltip-html': `
                                <div class="map-tooltip-content">
                                <span class="fw-500">Filter by Property Type</span>
                                <br />
                                <span class="map-tooltip-hint">This filter overrides the map's filter</span>
                                </div>
                              `,
                              'data-tooltip-variant': "dark",
                              'data-tooltip-place': "bottom"
                            }
                          )}
                        >
                          <span className="js-dropdown-title text-12">{
                            PROP_OPTIONS.find(o => o.id === filterProp).label
                          }</span>
                          <i className="icon icon-chevron-sm-down text-7 ml-5" />
                        </div>
                        <div className="toggle-element -dropdown dropdown-menu overlay-menu">
                          <div className="text-12 y-gap-5 js-dropdown-list">
                          {PROP_OPTIONS.map((option, index) => (
                            <div key={index}>
                              <button
                                className={`d-block js-dropdown-link ${
                                  filterProp === option.id ? "text-blue-1 " : "text-dark-1"
                                }`}
                                onClick={() => {
                                  setFilterProp(option.id);
                                  if (mode !== option.id) {
                                    setMode(option.id);
                                    setTarget({ ...target, opt: option.id });
                                  }
                                  loadData(option.id);
                                  trackEvent(`amenity_tab_switch_proptype_${getPropTypeLabel(option.id)}`);
                                }}
                              >
                                <span>{option.label}</span>
                              </button>
                            </div>
                          ))}
                          </div>
                        </div>
                      </div>

                      <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad ml-5">
                        <div
                          className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5 text-12 lh-12 "
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="true"
                          aria-expanded="false"
                          {...(mediaMatches ? {} :
                            {
                              'data-bs-offset': "0,10",
                              'data-tooltip-id': "map-tooltip",
                              'data-tooltip-html': `
                                <div class="map-tooltip-content">
                                <span class="fw-500">Filter by Distance</span>
                                <br />
                                <span class="map-tooltip-hint">Set the maximum distance of property to filter</span>
                                </div>
                              `,
                              'data-tooltip-variant': "dark",
                              'data-tooltip-place': "bottom"
                            }
                          )}
                        >
                          <span className="js-dropdown-title text-12">Max distance: {formatDistanceLabel(filterMaxDist)}</span>
                          <i className="icon icon-chevron-sm-down text-7 ml-5" />
                        </div>
                        <div className="toggle-element -dropdown dropdown-menu overlay-menu">
                          <div className="text-12 y-gap-5 js-dropdown-list">
                            <MaxDistInput
                              defaultDist={filterMaxDist}
                              setMaxDist={setFilterMaxDist}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="p-2 flex-grow-1"></div>

                    </div>
                    
                    <AmenityTable
                      data={data.filter(filterProps)}
                      userConfig={userConfig}
                      goToProperty={goToPropertyFromRow}
                      goToBlock={goToBlockFromRow}
                      mode={filterProp}
                    />
                  </div>
                </section>
              </div>
            </div>
          }
        </div>
      )}
    </>
  );
};

export default AmenityView;
