import {
  CONDO_PROPERTY_TYPE_DISPLAY_LIST,
  HDB_PROPERTY_TYPE_DISPLAY_LIST,
  LANDED_PROPERTY_TYPE_DISPLAY_LIST,
  PROPERTY_TYPE_DISPLAY_LIST,
  RENTAL_PROPERTY_TYPE_LIST,
  SALE_TYPE_FULL_LIST,
  SALE_TYPE_LIST,
  TENURE_LIST,
  getAllTenureIntValues,
  getTenureValue,
} from "./areas";
import { MAP_MODE_CONDO, MAP_MODE_HDB, MAP_MODE_LANDED } from "./map";
import { formatShortDate } from "./time";

export const hasValidInput = str => str !== null && str !== undefined
  && (typeof str === 'number' || str.replace(/^\s+|\s+$/g, '') !== '');

export const hasCheckedItem = obj => Object.keys(obj).filter(t => obj[t]).length > 0

export const hasChangedMinMaxSlider = (obj, min, max)=> obj.value?.min !== min || obj.value?.max !== max;

export const FILTER_TYPE_DATE_RANGE = 'date';
export const FILTER_TYPE_CHECKBOXES = 'checkboxes';
export const FILTER_TYPE_RANGE = 'range';
export const FILTER_TYPE_SLIDER = 'slider';
export const FILTER_TYPE_SINGLE = 'single';
export const FILTER_TYPE_MULTI_RANGE = 'multi';
export const FILTER_TYPE_NAME_INPUT = 'search';
export const FILTER_TYPE_LOCATION = 'location';

const UNIT_TYPE_YEAR = 'year';
const UNIT_TYPE_PERC = '%';
const UNIT_TYPE_SQFT = 'sqft';
const UNIT_TYPE_PRICE = '$';
const UNIT_TYPE_PSF = 'psf';

export const FIELD_START_DATE = 'start_date';
export const FIELD_END_DATE = 'end_date';
export const FIELD_MAP_START_DATE = 'map_start_date';
export const FIELD_MAP_END_DATE = 'map_end_date';
export const FIELD_MIN_SIZE = 'min_size';
export const FIELD_MAX_SIZE = 'max_size';
export const FIELD_MIN_PRICE = 'min_price';
export const FIELD_MAX_PRICE = 'max_price';
export const FIELD_MIN_UNIT_PRICE = 'min_unit_price';
export const FIELD_MAX_UNIT_PRICE = 'max_unit_price';
export const FIELD_AREAS = 'areas';
export const FIELD_DISTRICTS = 'districts';
export const FIELD_REGIONS = 'regions';
export const FIELD_NAME = 'name';

export const FILTER_DATE = { label: 'Date', field: [FIELD_START_DATE, FIELD_END_DATE], type: FILTER_TYPE_DATE_RANGE };
export const FILTER_COMPLETION_DATE = { ...FILTER_DATE, title: 'Completion Date', year: true };
export const FILTER_TRANSACTION_DATE = { ...FILTER_DATE, title: 'Transaction Date', min: new Date('1995-01-01') };
export const FILTER_LEASE_DATE = { ...FILTER_DATE, title: 'Lease Commencement Date', min: new Date('1999-01-01') };
export const FILTER_PROPERTY_TYPE = { label: 'Property Types', field: 'prop_type', type: FILTER_TYPE_CHECKBOXES, options: PROPERTY_TYPE_DISPLAY_LIST, col: 6 };
export const FILTER_CONDO_PROPERTY_TYPE = { label: 'Property Types', field: 'prop_type', type: FILTER_TYPE_CHECKBOXES, options: CONDO_PROPERTY_TYPE_DISPLAY_LIST, col: 6 };
export const FILTER_LANDED_PROPERTY_TYPE = { label: 'Property Types', field: 'prop_type', type: FILTER_TYPE_CHECKBOXES, options: LANDED_PROPERTY_TYPE_DISPLAY_LIST, col: 6 };
export const FILTER_HDB_FLAT_TYPE = { label: 'Flat Types', field: 'flat_type', type: FILTER_TYPE_CHECKBOXES, options: HDB_PROPERTY_TYPE_DISPLAY_LIST, col: 6 };
export const FILTER_RENTAL_PROPERTY_TYPE = { label: 'Property Types', field: 'prop_type', type: FILTER_TYPE_CHECKBOXES, options: RENTAL_PROPERTY_TYPE_LIST, col: 6 };
export const FILTER_TENURE = { label: 'Tenure', field: 'tenure', type: FILTER_TYPE_CHECKBOXES, options: TENURE_LIST, col: 4 };
export const FILTER_LEASE = { label: 'Remaining Lease', field: 'lease', type: FILTER_TYPE_RANGE, min: 0, max: 100, unit: UNIT_TYPE_YEAR, pluralize: true };
export const FILTER_UNCOMPLETED = { label: 'Completion', field: 'uncompleted', type: FILTER_TYPE_SINGLE, option: 'Include uncompleted', default: true };
export const FILTER_AGE = { label: 'Age', field: 'age', type: FILTER_TYPE_RANGE, min: 0, max: 100, unit: UNIT_TYPE_YEAR, pluralize: true };
export const FILTER_PROPERTY_AGE = { ...FILTER_AGE, label: 'Property Age', show: true };
export const FILTER_TOP_SCHOOL = { label: 'Near Top Schools (1km)', field: 'schools', type: FILTER_TYPE_RANGE, min: 1, max: 100, unit: "Top", prefix: true, pluralize: false, step: 10, snapToStepMin: (val) => Math.round((val - 1) / 10) * 10 + 1 };
export const FILTER_NEAREST_STATION = { label: 'Nearest Station', field: 'station', type: FILTER_TYPE_SLIDER, min: 1, max: 1000, unit: "m", pluralize: false, step: 50 };
export const FILTER_REALSCORE = { label: 'Realscore', field: 'realscore', type: FILTER_TYPE_RANGE, min: 0, max: 5, unit: "", pluralize: false, step: 0.5, hasLimit: true, decimalPlace: 1 };
export const FILTER_GAIN = { label: 'Annualized Gain', field: 'gain', type: FILTER_TYPE_RANGE, min: 0, max: 10, unit: UNIT_TYPE_PERC, minBound: -1 };
export const FILTER_SIZE = { label: 'Unit Size (sqft)', field: [FIELD_MIN_SIZE, FIELD_MAX_SIZE], type: FILTER_TYPE_MULTI_RANGE, unit: UNIT_TYPE_SQFT };
export const FILTER_SALE_TYPE = { label: 'Sale Types', field: 'sale_type', type: FILTER_TYPE_CHECKBOXES, options: SALE_TYPE_LIST, col: 4 };
export const FILTER_PRICE = { label: 'Price', field: [FIELD_MIN_PRICE, FIELD_MAX_PRICE], type: FILTER_TYPE_MULTI_RANGE, prefixUnit: UNIT_TYPE_PRICE, onFormat: (v) => parseInt(v).toLocaleString() };
export const FILTER_AVG_PRICE = { ...FILTER_PRICE, label: 'Past Transaction Price', show: true };
export const FILTER_UNIT_PRICE = { label: 'Price per area ($psf)', field: [FIELD_MIN_UNIT_PRICE, FIELD_MAX_UNIT_PRICE], type: FILTER_TYPE_MULTI_RANGE, unit: UNIT_TYPE_PSF, prefixUnit: UNIT_TYPE_PRICE };
export const FILTER_AVG_UNIT_PRICE = { ...FILTER_UNIT_PRICE, label: 'Average PSF (Past 3 mths)', show: true };
export const FILTER_RENT_PRICE = { label: 'Monthly Rent', field: [FIELD_MIN_PRICE, FIELD_MAX_PRICE], type: FILTER_TYPE_MULTI_RANGE, prefixUnit: UNIT_TYPE_PRICE };
export const FILTER_RENT_PSF = { label: 'Rent PSF ($psf)', field: [FIELD_MIN_UNIT_PRICE, FIELD_MAX_UNIT_PRICE], type: FILTER_TYPE_MULTI_RANGE, unit: UNIT_TYPE_PSF, prefixUnit: UNIT_TYPE_PRICE };
export const FILTER_RENT_AREA = { label: 'Floor Area (sqft)', field: [FIELD_MIN_SIZE, FIELD_MAX_SIZE], type: FILTER_TYPE_MULTI_RANGE, unit: UNIT_TYPE_SQFT };
export const FILTER_FULL_SALE_TYPE = { label: 'Sale Types', field: 'sale_type', type: FILTER_TYPE_CHECKBOXES, options: SALE_TYPE_FULL_LIST, col: 4 };
export const FILTER_PROPERTY_INPUT = { type: FILTER_TYPE_NAME_INPUT, subtype: 'PROPERTY' };
export const FILTER_SCHOOL_INPUT = { type: FILTER_TYPE_NAME_INPUT, subtype: 'SCHOOL' };
export const FILTER_LOCATION = { type: FILTER_TYPE_LOCATION };
export const FILTER_HDB_LOCATION = { type: FILTER_TYPE_LOCATION, town: true, hdb: true };
export const FILTER_PROFITABLE = { label: 'Profitable % ', field: 'profitable', type: FILTER_TYPE_RANGE, min: 0, max: 100, unit: UNIT_TYPE_PERC, show: true };
export const FILTER_HDB_BUYERS = { label: 'HDB Buyers %', field: 'hdb', type: FILTER_TYPE_RANGE, min: 0, max: 100, unit: UNIT_TYPE_PERC, show: false };
export const FILTER_TOTAL_UNITS = { label: 'Total Units', field: 'total_units', type: FILTER_TYPE_RANGE, min: 0, max: 1000, step: 10 };
export const FILTER_LAUNCH_PERC = { label: 'Sold on Launch %', field: 'launch_sold', type: FILTER_TYPE_RANGE, min: 0, max: 100, unit: UNIT_TYPE_PERC, show: false };
export const FILTER_PSF_RENTAL_6M = { label: 'Past 6 Mth PSF Rental Price', field: 'rental_psf', type: FILTER_TYPE_RANGE, min: 0, max: 10, unit: UNIT_TYPE_PSF };
export const FILTER_MAP_TRANSACTION_DATE = { label: 'Transaction Date', title: 'Last Transacted Date', field: [FIELD_MAP_START_DATE, FIELD_MAP_END_DATE], type: FILTER_TYPE_DATE_RANGE };
export const FILTER_CONDO_FLOOR = { label: 'Floors', field: 'floors', type: FILTER_TYPE_RANGE, unit: "Floor", pluralize: false, discreteMax: true, step: 1, allowSameValues: true };

export const hasAnyValue = (obj) => obj && Object.keys(obj).filter(a => !!obj[a]).length > 0;
export const hasMinRangeSelected = (filter, obj) => (filter.minBound !== undefined && obj.value.min !== filter.minBound) || (filter.minBound === undefined && obj.value.min !== filter.min);
export const hasMaxRangeSelected = (filter, obj) => obj.value.max !== filter.max;

/**
 * Generate human readable string of specific filter and filter value, return null if is in default state
 */
export const generateFilterString = (type, filter) => {
  // handle date range
  if (type.type === FILTER_TYPE_DATE_RANGE) {
    const startField = type.field[0];
    const endField = type.field[1];
    if (filter[startField] || filter[endField]) {
      if (filter[startField] && filter[endField])
        return `${type.label}: ${formatShortDate(new Date(filter[startField]))} - ${formatShortDate(new Date(filter[endField]))}`
      else if (filter[startField])
        return `${type.label}: From ${formatShortDate(new Date(filter[startField]))}`;
      else if (filter[endField])
        return `${type.label}: Until ${formatShortDate(new Date(filter[endField]))}`;
    }
    return null;
  }

  // handle multiple checkboxes
  if (type.type === FILTER_TYPE_CHECKBOXES) {
    if (filter[type.field]) {
      const values = filter[type.field];
      if (values.length > 0)
        return `${type.label}: ${values.join(', ')}`;
    }
    return null;
  }

  // handle min-max range
  if (type.type === FILTER_TYPE_RANGE) {
    const min = type.min ?? 0;
    const max = type.max ?? 100;
    const minField = `min_${type.field}`;
    const maxField = `max_${type.field}`;
    const minVal = filter[minField] ?? min;
    const maxVal = filter[maxField] ?? max;
    const suffix = (type.unit ?? '') + (type.pluralize ? 's' : '');
    if (minVal !== min && maxVal !== max) {
      const startVal = type.onFormat ? type.onFormat(minVal) : minVal;
      const endVal = type.onFormat ? type.onFormat(maxVal) : maxVal;
      return `${type.label}: ${type.prefixUnit ?? ''}${startVal} - ${type.prefixUnit ?? ''}${endVal} ${suffix}`;
    } else if (minVal !== min) {
      const startVal = type.onFormat ? type.onFormat(minVal) : minVal;
      return `${type.label}: ${type.prefixUnit ?? ''}${startVal} ${suffix} and above`;
    } else if (maxVal !== max) {
      const endVal = type.onFormat ? type.onFormat(maxVal) : maxVal;
      return `${type.label}: Up to ${type.prefixUnit ?? ''}${endVal} ${suffix}`;
    }
    return null;
  }

  // handle single range
  if (type.type === FILTER_TYPE_SLIDER) {
    const max = type.max ?? 100;
    const maxVal = filter[type.field];
    if (maxVal !== max) {
      return `Up to ${maxVal}${type.unit ?? ''}`;
    }
    return null;
  }

  // handle input range
  if (type.type === FILTER_TYPE_MULTI_RANGE) {
    const startField = type.field[0];
    const endField = type.field[1];
    const suffix = (type.unit ?? '') + (type.pluralize ? 's' : '');
    if (filter[startField] && filter[endField]) {
      const startVal = type.onFormat ? type.onFormat(filter[startField]) : filter[startField];
      const endVal = type.onFormat ? type.onFormat(filter[endField]) : filter[endField];
      return `${type.label}: ${type.prefixUnit ?? ''}${startVal} - ${type.prefixUnit ?? ''}${endVal} ${suffix}`;
    } else if (filter[startField]) {
      const startVal = type.onFormat ? type.onFormat(filter[startField]) : filter[startField];
      return `${type.label}: ${type.prefixUnit ?? ''}${startVal} ${suffix} and above`;
    } else if (filter[endField]) {
      const endVal = type.onFormat ? type.onFormat(filter[endField]) : filter[endField];
      return `${type.label}: Up to ${type.prefixUnit ?? ''}${endVal} ${suffix}`;
    }
      return null;
  }

  // handle single checkbox
  if (type.type === FILTER_TYPE_SINGLE) {
    const defaultVal = type.default;
    if (filter[type.field] !== undefined && filter[type.field] !== null && filter[type.field] !== defaultVal) {
      return `${type.option}: ${filter[type.field] === '1' ? 'Yes' : 'No'}`;
    }
    return null;
  }

  // handle location type
  if (type.type === FILTER_TYPE_LOCATION) {
    const limit = 5;
    if (filter['areas'] && filter['areas'].length > 0) {
      const areas = filter['areas'];
      return `Areas: ${areas.slice(0, limit).join(', ')}${areas.length > limit ? `+${areas.length - 5} more` : ''}`
    } else if (filter['districts'] && filter['districts'].length > 0) {
      const districts = filter['districts'];
      return `Districts: ${districts.slice(0, limit).join(', ')}${districts.length > limit ? ` +${districts.length - limit} more` : ''}`;
    } else if (filter['regions'] && filter['regions'].length > 0) {
      return `Regions: ${filter['regions'].join(', ')}`;
    }
    return null;
  }

  // handle name input
  if (type.type === FILTER_TYPE_NAME_INPUT) {
    if (filter['name']) {
      return `Project/Street: ${filter['name'].toUpperCase()}`;
    }
    return null;
  }
};

export const generateAllFilterString = (types, filter) => types.map(t => generateFilterString(t, filter)).filter(s => !!s);

const convertStrToInt = (str, defaultVal) => {
  if (!str) return defaultVal;
  const i = parseInt(str);
  return isNaN(i) ? defaultVal : i;
};

const convertStrToBool = (str, defaultVal) => {
  if (!str) return defaultVal;
  return str === '1';
};

const convertStrToObj = (str) => {
  if (!str) return {};
  const decodedStr = decodeURIComponent(str);
  const vals = decodedStr.split(',');
  return vals
    ? vals.reduce((obj, item) => Object.assign(obj, { [item]: true }), {})
    : {};
};

const convertStrToIntDate = (str) => {
  const i = convertStrToInt(str, null);
  return i ? new Date(i) : null;
};

/**
 * Create a state filter for UI from given query params
 */
export const getFilterFromParams = (type, params) => {
  const filter = {};
  
  // handle date range
  if (type.type === FILTER_TYPE_DATE_RANGE) {
    const startField = type.field[0];
    const endField = type.field[1];
    filter[startField] = convertStrToIntDate(params.get(startField));
    filter[endField] = convertStrToIntDate(params.get(endField));
  }

  // handle multiple checkboxes
  if (type.type === FILTER_TYPE_CHECKBOXES) {
    filter[type.field] = convertStrToObj(params.get(type.field));
  }

  // handle min-max range
  if (type.type === FILTER_TYPE_RANGE) {
    const min = type.min ?? 0;
    const max = type.max ?? 100;
    const startField = `min_${type.field}`;
    const endField = `max_${type.field}`;
    const minVal = convertStrToInt(params.get(startField), min);
    const maxVal = convertStrToInt(params.get(endField), max);
    filter[type.field] = { value: { min: minVal, max: maxVal } };
  }

  // handle single range
  if (type.type === FILTER_TYPE_SLIDER) {
    const max = type.max ?? 100;
    const maxVal = convertStrToInt(params.get(type.field), max);
    filter[type.field] = maxVal;
  }

  // handle input range
  if (type.type === FILTER_TYPE_MULTI_RANGE) {
    const startField = type.field[0];
    const endField = type.field[1];
    filter[startField] = convertStrToInt(params.get(startField), null);
    filter[endField] = convertStrToInt(params.get(endField), null);
  }

  // handle single checkbox
  if (type.type === FILTER_TYPE_SINGLE) {
    filter[type.field] = convertStrToBool(params.get(type.field), type.default);
  }

  // handle location type
  if (type.type === FILTER_TYPE_LOCATION) {
    if (params.get('areas')) {
      filter['areas'] = convertStrToObj(params.get('areas'));
      filter['districts'] = {};
      filter['regions'] = {};
    } else if (params.get('districts')) {
      filter['areas'] = {};
      filter['districts'] = convertStrToObj(params.get('districts'));
      filter['regions'] = {};
    } else if (params.get('regions')) {
      filter['areas'] = {};
      filter['districts'] = {};
      filter['regions'] = convertStrToObj(params.get('regions'));
    } else {
      filter['areas'] = {};
      filter['districts'] = {};
      filter['regions'] = {};
    }
  }

   // handle name input
  if (type.type === FILTER_TYPE_NAME_INPUT) {
    filter['name'] = params.get('name') ? decodeURIComponent(params.get('name')) : '';
  }

  return filter;
};

export const initializeFilters = (types, params, ignoreId) => {
  const filters = types.reduce((obj, item) => Object.assign(obj, getFilterFromParams(item, params)), {});
  if (!ignoreId && params.get('id')) {
    filters['id'] = decodeURIComponent(params.get('id'));
  }
  return filters;
};

export const clearFilters = (types) => initializeFilters(types, new URLSearchParams(), true);

export const convertFilterToParams = (type, filter) => {
  const params = {};

  // handle date range
  if (type.type === FILTER_TYPE_DATE_RANGE) {
    const startField = type.field[0];
    const endField = type.field[1];
    if (filter[startField])
      params[startField] = filter[startField].getTime();
    if (filter[endField])
      params[endField] = filter[endField].getTime();
  }

  // handle multiple checkboxes
  if (type.type === FILTER_TYPE_CHECKBOXES) {
    if (filter[type.field]) {
      const values = Object.keys(filter[type.field]).filter(p => !!filter[type.field][p]);
      if (values.length > 0)
        params[type.field] = values;
    }
  }

  // handle min-max range
  if (type.type === FILTER_TYPE_RANGE) {
    const min = type.min ?? 0;
    const max = type.max ?? 100;
    if (filter[type.field]?.value) {
      const minVal = filter[type.field].value.min;
      const maxVal = filter[type.field].value.max;
      if (minVal !== min)
        params[`min_${type.field}`] = minVal;
      if (maxVal !== max)
        params[`max_${type.field}`] = maxVal;
    }
  }

  // handle single range
  if (type.type === FILTER_TYPE_SLIDER) {
    const max = type.max ?? 100;
    if (filter[type.field] !== max) {
      params[type.field] = filter[type.field];
    }
  }

  // handle input range
  if (type.type === FILTER_TYPE_MULTI_RANGE) {
    const startField = type.field[0];
    const endField = type.field[1];
    if (filter[startField])
      params[startField] = filter[startField];
    if (filter[endField])
      params[endField] = filter[endField];
  }

  // handle single checkbox
  if (type.type === FILTER_TYPE_SINGLE) {
    const defaultVal = type.default;
    if (filter[type.field] !== undefined && filter[type.field] !== null && filter[type.field] !== defaultVal) {
      const newVal = !defaultVal;
      params[type.field] = newVal ? '1' : '0';
    }
  }

  // handle location type
  if (type.type === FILTER_TYPE_LOCATION) {
    if (filter['areas'] && Object.keys(filter['areas']).length > 0) {
      const areas = Object.keys(filter['areas']).filter(a => !!filter['areas'][a]);
      params['areas'] = areas;
    } else if (filter['districts'] && Object.keys(filter['districts']).length > 0) {
      const districts = Object.keys(filter['districts']).filter(a => !!filter['districts'][a]);
      params['districts'] = districts;
    } else if (filter['regions'] && Object.keys(filter['regions']).length > 0) {
      const regions = Object.keys(filter['regions']).filter(a => !!filter['regions'][a]);
      params['regions'] = regions;
    }
  }

  // handle name input
  if (type.type === FILTER_TYPE_NAME_INPUT) {
    if (filter['name']) {
      params['name'] = filter['name'];
    }
  }

  return params;
};

export const createReadableFilter = (types, filter) => types.reduce((obj, item) => Object.assign(obj, convertFilterToParams(item, filter)), {});

export const createUrlFilterFromReadableFilter = (readableFilter) => {
  const urlFilter = {};
  Object.keys(readableFilter).forEach(key => {
    const value = readableFilter[key];
    if (Array.isArray(value))
      urlFilter[key] = encodeURIComponent(value.join(','));
    else if (typeof value === 'string')
      urlFilter[key] = encodeURIComponent(value);
    else
      urlFilter[key] = value;
  });
  return urlFilter;
};

export const createUrlParamFromReadableFilter = (filter) => {
  const params = new URLSearchParams();
  const urlFilter = createUrlFilterFromReadableFilter(filter);
  Object.keys(urlFilter).forEach(key => {
    params.set(key, urlFilter[key]);
  });
  return params;
};

export const createURLFilter = (types, filter) => {
  const readableFilter = createReadableFilter(types, filter);
  return createUrlFilterFromReadableFilter(readableFilter);
};

export const hasFilterSelected = (type, filter) => {
  // handle date range
  if (type.type === FILTER_TYPE_DATE_RANGE) {
    const startField = type.field[0];
    const endField = type.field[1];
    return !!isValidDate(filter[startField]) || !!isValidDate(filter[endField]);
  }

  // handle multiple checkboxes
  if (type.type === FILTER_TYPE_CHECKBOXES) {
    if (filter[type.field]) {
      const values = Object.keys(filter[type.field]).filter(p => !!filter[type.field][p]);
      return values.length > 0;
    }
    return false;
  }

  // handle min-max range
  if (type.type === FILTER_TYPE_RANGE) {
    const min = type.min ?? 0;
    const max = type.max ?? 100;
    if (filter[type.field]?.value) {
      const minVal = filter[type.field].value.min;
      const maxVal = filter[type.field].value.max;
      return minVal > min || maxVal < max;
    }
    return false;
  }

  // handle single range
  if (type.type === FILTER_TYPE_SLIDER) {
    const max = type.max ?? 100;
    return filter[type.field] !== max;
  }

  // handle input range
  if (type.type === FILTER_TYPE_MULTI_RANGE) {
    const startField = type.field[0];
    const endField = type.field[1];
    return !!filter[startField] || !!filter[endField];
  }

  // handle single checkbox
  if (type.type === FILTER_TYPE_SINGLE) {
    const defaultVal = type.default;
    return filter[type.field] !== undefined && filter[type.field] !== null && filter[type.field] !== defaultVal;
  }

  // handle location type
  if (type.type === FILTER_TYPE_LOCATION) {
    if (filter['areas'] && Object.keys(filter['areas']).length > 0) {
      return true;
    } else if (filter['districts'] && Object.keys(filter['districts']).length > 0) {
      return true;
    } else if (filter['regions'] && Object.keys(filter['regions']).length > 0) {
      return true;
    }
    return false;
  }

  // handle name input
  if (type.type === FILTER_TYPE_NAME_INPUT) {
    return filter['name'];
  }
};

export const hasAnyFilterSelected = (types, filter) => types.some(t => hasFilterSelected(t, filter));

export const getTenureValues = (type, filter) => hasFilterSelected(type, filter)
  ? Object.keys(filter[type.field])
      .filter(o => !!filter[type.field][o])
      .map(o => getTenureValue(o))
      .map(v => v !== "Freehold" ? parseInt(v) : null)
  : getAllTenureIntValues();

export const initializeSortOrder = (params, defaultOrder) => {
  const orderStr = params.get('sort')
  if (!orderStr) return defaultOrder;
  const orders = decodeURIComponent(orderStr).split(',');
  return orders.map(o => {
      const chunks = o.split(':');
      return chunks.length === 2 ? { field: chunks[0], dir: chunks[1] } : null;
    })
    .filter(o => !!o)
    .reduce((obj, item) => Object.assign(obj, { [item.field]: item.dir }), {});
};

export const convertSortOrderToParam = (order) => {
  const orderStr = Object.keys(order).map(o => `${o}:${order[o]}`).join(',');
  return encodeURIComponent(orderStr);
};

export const getCheckValue = (type, filter) => {
  if (type.type === FILTER_TYPE_SINGLE) {
    const defaultVal = type.default;
    return type.field in filter && filter[type.field] !== null
      ? filter[type.field] : defaultVal;
  }
  return false;
};

export const isValidDate = (date) => !!date && !isNaN(date.getTime());

const hasAdvDataByMode = (mode, hasAdvMapData, hasAdvLandedMapData, hasAdvHdbMapData) => {
  if (mode === MAP_MODE_CONDO) return hasAdvMapData;
  if (mode === MAP_MODE_LANDED) return hasAdvLandedMapData;
  if (mode === MAP_MODE_HDB) return hasAdvHdbMapData;
};

export const requireLoadAdvData = (mode, filterSelected, hasAdvMapData, hasAdvLandedMapData, hasAdvHdbMapData) => {
  const requireAdvData = mode === MAP_MODE_HDB
    ? (
        hasFilterSelected(FILTER_COMPLETION_DATE, filterSelected)
        || hasFilterSelected(FILTER_PROPERTY_AGE, filterSelected)
        || hasFilterSelected(FILTER_SIZE, filterSelected)
        || hasFilterSelected(FILTER_TOTAL_UNITS, filterSelected)
        || hasFilterSelected(FILTER_TOP_SCHOOL, filterSelected)
        || hasFilterSelected(FILTER_NEAREST_STATION, filterSelected)
      )
    : (
        hasFilterSelected(FILTER_COMPLETION_DATE, filterSelected)
        || hasFilterSelected(FILTER_TENURE, filterSelected)
        || hasFilterSelected(FILTER_PROPERTY_AGE, filterSelected)
        || hasFilterSelected(FILTER_AVG_PRICE, filterSelected)
        || hasFilterSelected(FILTER_SIZE, filterSelected)
        || hasFilterSelected(FILTER_MAP_TRANSACTION_DATE, filterSelected)
        || hasFilterSelected(FILTER_HDB_BUYERS, filterSelected)
        || hasFilterSelected(FILTER_TOTAL_UNITS, filterSelected)
        || hasFilterSelected(FILTER_LAUNCH_PERC, filterSelected)
        || hasFilterSelected(FILTER_PSF_RENTAL_6M, filterSelected)
        || hasFilterSelected(FILTER_AVG_UNIT_PRICE, filterSelected)
        || hasFilterSelected(FILTER_TOP_SCHOOL, filterSelected)
        || hasFilterSelected(FILTER_NEAREST_STATION, filterSelected)
      );
  return requireAdvData && !hasAdvDataByMode(mode, hasAdvMapData, hasAdvLandedMapData, hasAdvHdbMapData);
};
