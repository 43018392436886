import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import UserPanel from "../UserPanel";

import { getUserPhotoUrl, isGuest, isLoggedIn } from "@/utils/user";
import NotificationPanel from "@/components/account/NotificationPanel";
import HeaderFilterBox from "./HeaderFilterBox";
import MobileMenu from "../MobileMenu";

const Header1 = ({
  user,
  session,
  onSearchLocation,
  showProfileButton,
  onNotificationClick
}) => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  const isUserLoggedIn = isLoggedIn(user);
  const isUserGuest = isGuest(user);

  return (
    <>
      <header className={`header front-header bg-dark-3 ${navbar ? "is-sticky" : ""} slim-header-xs noselect`}>
        <div className="header__container px-20 md:px-10">
          <div className="d-flex justify-between items-center">
            <div className="p-2 py-0 px-0">
              <div className="d-flex items-center">
                <Link to="/" className="header-logo">
                  <img src="/img/general/logo-rs.png" height={60} width={60} alt="logo icon" />
                </Link>

                <Link to="/" className="text-white xl:d-none fw-600 text-14 ml-20 noselect">
                  REALSMART.SG
                </Link>
              </div>
            </div>

            <div className="p-2 flex-grow-1 py-0">
              <HeaderFilterBox
                onSearchLocation={onSearchLocation}
                hideClickHint
              />
            </div>

            <div className="p-2 py-0 px-0">
              <div className="d-flex items-center">
                <div className="d-flex sm:px-0 items-center is-menu-opened-hide">
                  {isUserLoggedIn && !showProfileButton
                    && <NotificationPanel
                      user={user}
                      session={session}
                      onNotificationClick={onNotificationClick}
                      enableNotification
                    />
                  }
                </div>

                {isUserGuest && showProfileButton
                  && <>
                    <div className="d-flex xl:d-none">
                      <Link
                        to="/login"
                        className="button px-30 fw-400 text-14 border-white -outline-white text-white ml-20"
                        style={{
                          height: '35px'
                        }}
                      >
                        Login / Register
                      </Link>
                    </div>

                    <div className="d-none xl:d-flex text-white">
                      <Link
                        to="/login"
                        className="d-flex items-center icon-user text-inherit text-22"
                      />
                    </div>
                  </>
                }

                {isUserLoggedIn && showProfileButton
                  && <>
                    <div className="xl:d-none">
                      <UserPanel
                        textClass="text-white"
                        user={user}
                      />
                    </div>

                    <div className="d-none xl:d-flex">
                      <img
                        src={getUserPhotoUrl(user)}
                        alt="image"
                        referrerPolicy="no-referrer"
                        className="rounded-full"
                        height={30}
                        width={30}
                        data-bs-toggle="offcanvas"
                        aria-controls="mobile-sidebar_menu"
                        data-bs-target="#mobile-sidebar_menu"
                      />

                      <div
                        className="offcanvas offcanvas-start mobile_menu-contnet"
                        tabIndex="-1"
                        id="mobile-sidebar_menu"
                        aria-labelledby="offcanvasMenuLabel"
                        data-bs-scroll="true"
                      >
                        <MobileMenu user={user} />
                      </div>
                    </div>
                  </>
                }

              </div>
            </div>

          </div>
        </div>
      </header>
    </>
  );
};

Header1.propTypes = {
  user: PropTypes.object,
  session: PropTypes.string,
  onSearchLocation: PropTypes.func
};

export default Header1;
