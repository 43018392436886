import { useEffect, useState } from "react";

import {
  NOTIFICATION_OPTIONS,
  checkMediaQuery,
  isLoggedIn
} from "@/utils/user";
import PropNotifications from "./PropNotifications";
import Loader from "../common/Loader";
import { deleteNotifications, getAllNotifications, getUnreadNotifications, readNotifications } from "@/utils/api";
import { useNavigate } from "react-router-dom";
import { MAP_MODE_CONDO, MAP_MODE_HDB } from "@/utils/map";
import { Tooltip } from "react-tooltip";


const NotificationPanel = ({
  user,
  session,
  onClick,
  onClose,
  onNotificationClick,
  enableNotification = false
}) => {
  const navigate = useNavigate();

  const PAGE_MAX = 20;

  const [click, setClick] = useState(false);
  const [display, setDisplay] = useState(NOTIFICATION_OPTIONS[0].id);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [err, setErr] = useState(null);

  let fetching = false;

  const loadUnreadData = () => {
    if (fetching) return;
    fetching = true;
    setLoading(true);
    setErr(null);
    getUnreadNotifications(user.claims.user_id, 0, 10, data => {
      fetching = false;
      setData(data.map(r => ({
        ...r,
        data: JSON.parse(r.data),
        createdTs: new Date(r.createdAt).getTime()
      })));
      setLoading(false);
    });
  };

  const readAllUnreads = (unreadIds) => {
    if (unreadIds.length > 0) {
      readNotifications(unreadIds, () => {
        // do nothing
      }, err => {
        setErr(err);
      });
    }
  };

  const loadData = (excludedId) => {
    if (fetching) return;
    fetching = true;
    setLoading(true);
    setErr(null);
    getAllNotifications(user.claims.user_id, 0, PAGE_MAX, data => {
      fetching = false;
      const newData = data.map(r => ({
        ...r,
        data: JSON.parse(r.data),
        createdTs: new Date(r.createdAt).getTime(),
        marked: true
      }));
      if (excludedId) {
        setData(newData.filter(r => r.id !== excludedId));
      } else {
        setData(newData);
      }
      setLoading(false);

      // set all unread as read
      const unreadIds = newData.filter(d => !d.read).map(d => d.id);
      if (unreadIds.length > 0) {
        readAllUnreads(unreadIds);
      }
    });
  };

  const clearAll = () => {
    setLoading(true);
    setData([]);
    deleteNotifications(data.map(d => d.id), () => {
      loadData();
    }, err => {
      setErr(err);
    });
  };

  const onClickNotification = (data) => {
    readNotifications([data.id]);
    if (data.data.type === 't' || data.data.type === 'nl' || data.data.type === 'rp' || data.data.type === 'rt') {
      if (data.data.mode === MAP_MODE_HDB) {
        if (onNotificationClick) {
          onNotificationClick(MAP_MODE_HDB, data.data.id);
          handleCarding();  
        } else {
          navigate(`/map?mode=${MAP_MODE_HDB}&id=${data.data.id}`);
        }
      } else if (data.data.mode) {
        if (onNotificationClick) {
          onNotificationClick(data.data.mode, data.data.marker.toUpperCase(), data.data.id);
          handleCarding();
        } else {
          navigate(`/map?mode=${data.data.mode}&id=${encodeURIComponent(data.data.marker.toUpperCase())}&p=${data.data.id}`);
        }
      } else {
        if (onNotificationClick) {
          onNotificationClick(MAP_MODE_CONDO, data.data.marker.toUpperCase(), data.data.id);
          handleCarding();
        } else {
          navigate(`/map?mode=${MAP_MODE_CONDO}&id=${encodeURIComponent(data.data.marker.toUpperCase())}&p=${data.data.id}`);
        }
      }
    } else if (data.data.type === 'rh') {
      if (onNotificationClick) {
        onNotificationClick(MAP_MODE_HDB, data.data.postal);
        handleCarding();
      } else {
        navigate(`/map?mode=${MAP_MODE_HDB}&id=${data.data.postal}`);
      }
    }
  };

  const onDeleteNotification = (notification) => {
    deleteNotifications([notification.id]);
    const newData = data.filter(d => d.id !== notification.id);
    if (newData.length < 10) {
      loadData(notification.id);
    } else {
      setData(newData);
    }
  };

  const handleCarding = () => {
    if (!click) {
      if (isLoggedIn(user) && user.claims?.user_id) {
        loadData();
      }
      onClick?.();
    } else {
      onClose?.();
      setDisplay(NOTIFICATION_OPTIONS[0].id);
    }
    setClick((prevState) => !prevState);
  };

  useEffect(() => {
    if (isLoggedIn(user) && user.claims?.user_id) {
      loadUnreadData();
    }
  }, [user]);

  const unreadCount = data.filter(d => !d.read && !d.marked).length;

  return (
    <>
      {enableNotification
        && <div className="p-2 py-0 px-0">
          <button
            className={`d-flex items-center text-white notif-btn`}
            onClick={handleCarding}
            {...(checkMediaQuery() ? {} : {
              'data-tooltip-id': 'notif-tooltip',
              'data-tooltip-html': 'View my notifications',
              'data-tooltip-variant': "dark",
              'data-tooltip-place': "left"
            })}
            style={{
              fontSize: '30px'
            }}
          >
            <i className="icon-notification" />
            {unreadCount > 0
              && <span className="notif-badge">
                {unreadCount > 9 ? '∞' : unreadCount}
              </span>
            }
          </button>
        </div>
      }

      <div className={`langMenu langMenuTop js-langMenu ${click ? "" : "is-hidden"}`}>
        <div className="currencyMenu__bg" onClick={handleCarding}></div>
        <div className="langMenu__content bg-white rounded-4 rel-content">
          <div className="d-flex items-center px-30 py-10 sm:px-15 border-bottom-light">
            <h3 className="text-14">Notifications</h3>
            <div className="flex-grow-1"></div>
            <button className="pointer px-10 mr-20" onClick={clearAll}>
              <i className="icon-trash" />
            </button>
            <button className="pointer px-10" onClick={handleCarding}>
              <i className="icon-close" />
            </button>
          </div>

          {loading
            && <div className="loader-container-sm">
              <Loader />
            </div>
          }

          {!loading && isLoggedIn(user)
            && <div className="acct-bh watermark">
              {err
                && <div className="text-center text-red-1 bg-red-3 text-14 py-10">Error handling notifications</div>
              }
              {display === 'prop'
                && <PropNotifications
                  user={user}
                  data={data}
                  showing={click}
                  onClick={onClickNotification}
                  onDelete={onDeleteNotification}
                  onClose={handleCarding}
                />
              }
            </div>
          }

        </div>

      </div>

      <Tooltip id="notif-tooltip" style={{ zIndex: 800 }} />
    </>
  );
};

export default NotificationPanel;
