import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

const MenuLinks = [
  // {
  //   label: 'Property Search',
  //   url: '/search',
  //   // default: true,
  // },
  {
    label: 'Map',
    url: '/map',
  },
  // {
  //   label: 'Compare',
  //   url: '/compare',
  // },
  // {
  //   label: 'Profits',
  //   url: '/profits',
  // },
  // {
  //   label: 'Transactions',
  //   url: '/transactions',
  // },
  // {
  //   label: 'Rentals',
  //   url: '/rentals',
  // },
  // {
  //   label: 'Units View',
  //   url: '/units',
  // },
  // {
  //   label: 'Upcoming',
  //   url: '/upcoming',
  // },
  {
    label: 'About Us',
    url: '/about',
  }
];

const Menu = ({ style }) => {
  const { pathname } = useLocation();

  const isSelected = (link) => pathname === link.url || (pathname === '/' && link.default);

  const menuType = style === 'text-dark-1' ? 'menu-selected-dark' : 'menu-selected';

  return (
    <nav className="menu js-navList">
      <ul className={`menu__nav ${style} -is-active`}>
        {MenuLinks.map(link => (
          <li key={link.label}>
            <a
              className={`${
                isSelected(link) ? `${menuType}` : `un-${menuType}`
              } ml-5 mr-5`}
              href={link.url}
            >
              <span>{link.label}</span>
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

Menu.propTypes = {
  style: PropTypes.string,
};

Menu.defaultProps = {
  style: '',
};

export default Menu;
