import { useEffect, useState } from "react";
import { checkMediaQuery, isLoggedIn, noSelectClass } from "@/utils/user";
import PerformancePanel from "./Overview/PerformancePanel";
import PropertyMap from "./Overview/PropertyMap";
import Factsheet from "./Overview/Factsheet";
import PropertyButtons from "./Overview/PropertyButtons";
import {
  MAP_MODE_CONDO,
  MAP_MODE_HDB,
  getHdbBlockLabel,
  getPropertyFileName,
  getPropertyTextColor
} from "@/utils/map";
import { LOCATION_PROPERTY } from "@/utils/areas";
import SharePanel from "../modal/SharePanel";
import {
  getPropertyLike,
  hasLikedProperty,
  trackEvent
} from "@/utils/api";
import BadgeView from "./BadgeView";

const OverviewTab = ({
  user,
  data,
  badges,
  target,
  marker,
  isMaximized,
  screenDim,
  compareList,
  setCompareList,
  addToCompareList,
  goToProperty,
  goToEstate,
  userConfig,
  setLightboxImages,
  onLikeProperty,
  likedProps,
  gpsLocation
}) => {

  const PROPERTY_BUTTONS = [
    // {
    //   id: 'compare',
    //   label: 'Compare',
    //   altLabel: 'Compare',
    //   icon: 'icon-plus',
    //   altIcon: 'icon-minus'
    // },
    {
      id: 'share',
      label: 'Share',
      icon: 'icon-share'
    },
    {
      id: 'nav',
      label: 'Navigate',
      icon: 'icon-location-2'
    },
    {
      id: 'like',
      label: 'Subscribe',
      altLabel: 'Unsubscribe',
      icon: 'icon-heart',
      nonNull: true,
      userOnly: true
    },
  ];

  const targetCompKey = target && target.type === LOCATION_PROPERTY ? (
    target.mode === MAP_MODE_HDB
      ? target.id
      : `${target.id}_${target.projectId}`
  ) : '';

  const propKey = target.mode === MAP_MODE_HDB
    ? target.postal
    : `${getPropertyFileName(target.id)}_${target.projectId}`

  const [selection, setSelection] = useState({
    compare: targetCompKey in compareList,
    like: propKey in likedProps ? !!likedProps[propKey] : null
  });

  const [showSharePanel, setShowSharePanel] = useState(false);

  const isHdb = target.mode === MAP_MODE_HDB;

  const shareUrl = isHdb
    ? `https://realsmart.sg/share?m=${target.mode}&id=${encodeURIComponent(target.id)}&name=${encodeURIComponent(target.name)}${data.sharePlaceId && data.gallery?.length > 0 ? `&i=${data.sharePlaceId}` : ''}${user?.claims?.user_id ? `&r=${user.claims.user_id}` : ''}`
    : `https://realsmart.sg/share?m=${target.mode}&id=${encodeURIComponent(target.id)}&project=${encodeURIComponent(target.projectId)}&name=${encodeURIComponent(target.project)}${data.sharePlaceId && data.gallery?.length > 0 ? `&i=${data.sharePlaceId}` : ''}${user?.claims?.user_id ? `&r=${user.claims.user_id}` : ''}`;

  // const chartColGrid = (screenDim.width > screenDim.height)
  //   && (isMaximized ? screenDim.width > 1200 : screenDim.width > 1600) ? 6 : 12;

  useEffect(() => {
    if (!(propKey in likedProps)) {
      if (isLoggedIn(user)) {
        // fetch from database since unknown state for this property
        hasLikedProperty(propKey, isLiked => {
          onLikeProperty(isLiked, target, data, true);
          setSelection({
            ...selection,
            like: isLiked
          });
        });

        // getPropertyLike(propKey, data => {
        //   const isLiked = !!data;
        //   onLikeProperty(isLiked, target, data, true);
        //   setSelection({
        //     ...selection,
        //     like: isLiked
        //   });
        // });
      }
    }
  }, [user]);

  const getStreetsLabel = (streets) => streets?.length > 3 ? (
    <div
      className="d-flex items-center text-12 text-light-1 cursor-pointer"
      data-tooltip-id="map-tooltip"
      data-tooltip-html={`<span class="text-10">${data.streets.map((s, i) => i % 5 === 4 ? `${s},<br />` : (i !== data.streets.length - 1 ? `${s}, `: s)).join('')}</span>`}
      data-tooltip-variant="dark"
      data-tooltip-place="bottom"
    >
      <i className="icon-location-2 text-14 mr-5" />
      {data.streets.slice(0, 3).join(', ')} +{data.streets.length - 3} more
    </div>
  ) : (
    <div className="d-flex items-center text-12 text-light-1">
      <i className="icon-location-2 text-14 mr-5" />
      {data.streets?.join(', ')}
    </div>
  );

  const getTenuresLabel = (tenures) => tenures?.length > 3 ? (
    <div className="col-auto d-flex align-content-start flex-wrap py-0 px-0">
      {data.tenures?.slice(0, 3).map(tenure => (
        <div
          key={`ten_${tenure}`}
          className="p-2 d-flex items-center text-10 text-white proj-tag px-10 rounded-100 fw-500 mr-5 mt-5 mb-5"
        >
          {tenure}
        </div>
      ))}
      <div
        className="p-2 d-flex items-center text-10 text-white proj-tag px-10 rounded-100 fw-500 mr-5 mt-5 mb-5 cursor-pointer"
        data-tooltip-id="map-tooltip"
        data-tooltip-html={`<span class="text-10">${data.tenures.slice(3).join('<br />')}</span>`}
        data-tooltip-variant="dark"
        data-tooltip-place="bottom"
      >
        +{data.tenures.length - 3} more
      </div>
    </div>
  ) : (
    <div className="col-auto d-flex align-content-start flex-wrap py-0">
      {data.tenures?.map(tenure => (
        <div
          key={`ten_${tenure}`}
          className="p-2 d-flex items-center text-10 text-white proj-tag px-10 rounded-100 fw-500 mr-10 mt-5 mb-5"
        >
          {tenure}
        </div>
      ))}
    </div>
  );

  const onSelectButton = (option) => {
    if (option === 'share') {
      if (window.navigator.share) {
        window.navigator.share({
          title: `REALSMART.SG - ${data.name}`,
          text: `I think you'll be interested in this property - ${data.name}. Brought to you by REALSMART.SG`,
          url: shareUrl
        });
      } else {
        setShowSharePanel(true);
      }

      trackEvent('map_overview_share_url');
    } else if (option === 'compare') {
      if (selection.compare) {
        const newCompareList = { ...compareList };
        delete newCompareList[targetCompKey];
        setCompareList(newCompareList);
        setSelection({...selection, compare: false});

        trackEvent('map_overview_compare_remove_1', {
          key: targetCompKey
        });
      } else {
        addToCompareList(targetCompKey);
        setSelection({...selection, compare: true});

        trackEvent('map_overview_compare_add_1', {
          key: targetCompKey
        });
      }
    } else if (option === 'nav') {
      // const url = isHdb
      //   ? `https://www.google.com/maps?q=${target.postal}`
      //   : `https://www.google.com/maps?q=${encodeURIComponent(data.name)}+${target.lat},${target.lng}`;

      let url = isHdb
        ? `https://www.google.com/maps/dir/?api=1&destination=${target.postal}`
        : `https://www.google.com/maps/dir/?api=1&destination=${target.lat},${target.lng}`;
      
      if (gpsLocation) {
        url = isHdb
          ? `https://www.google.com/maps/dir/?api=1&origin=${gpsLocation.lat},${gpsLocation.lng}&destination=${target.postal}`
          : `https://www.google.com/maps/dir/?api=1&origin=${gpsLocation.lat},${gpsLocation.lng}&destination=${target.lat},${target.lng}`
      }

      // `https://www.google.com/maps/dir/?api=1&origin=${}&destination=${}`

      trackEvent('map_overview_navigate_gmap', {
        key: targetCompKey
      });

      window.open(url, '_blank');
    } else if (option === 'like') {
      onLikeProperty(!selection.like, target, data);

      trackEvent('map_overview_like_property', {
        key: targetCompKey
      });
    }
  };

  useEffect(() => {
    setSelection({
      ...selection,
      like: propKey in likedProps ? !!likedProps[propKey] : null
    });
  }, [likedProps]);

  const mediaMatches = checkMediaQuery();

  return (
    <>
      <section className="pt-0" id="reviews">
        <div className={`px-5 ${noSelectClass(user)}`}>
          <div className="row y-gap-10 justify-between">
            <div className="col-12">
              <PropertyButtons
                user={user}
                options={PROPERTY_BUTTONS}
                selection={selection}
                onSelect={onSelectButton}
              />
              {data.estate
                && <button
                  className="p-2 button mt-10 mr-5 mb-10 text-white h-30 px-15 rounded-100 text-12 filter-button"
                  onClick={() => goToEstate(data.estate, target)}
                >
                  {/* <i className="icon-search text-10" /> */}
                  <img
                    src="/img/general/houses.png"
                    height={20}
                    width={20}
                  />
                  <span className="ml-10">
                    VIEW {data.estate} ESTATE
                  </span>
                </button>
              }
            </div>

            <div className="col-12 row x-gap-50 y-gap-10 py-0">
              <div className="row justify-between items-end py-0">
                <div className="col-auto">
                  <div className="row x-gap-20  items-center">
                    <div className="col-auto">
                      <h1 className={`text-16 sm:text-20 fw-600 ${isHdb || marker?.properties.length === 1
                          ? 'mt-5 mb-5'
                          : 'mt-5'
                        }`}
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        {!isHdb && marker.properties.length === 1
                          && <>
                            <span>
                              {data.name}
                            </span>
                            {/* {
                              data.score >= 0
                                && <span
                                  className="rs-tag px-5 ml-10 rounded-100 cursor-pointer"
                                  {...(mediaMatches ? {} : {
                                    'data-tooltip-id': "map-tooltip",
                                    'data-tooltip-html': `
                                      <strong>REALSCORE</strong>
                                      <br />
                                      Track record of <strong>profitable transactions</strong>
                                      <br /> compared with condos across Singapore
                                      <hr class="mt-5 mb-5" />
                                      Maximum score of 5.0 for top properties
                                    `,
                                    'data-tooltip-variant': "dark",
                                    'data-tooltip-place': "bottom"
                                  })}
                                >
                                  {data.score}
                                </span>
                            } */}
                          </>
                        }

                        {isHdb
                          && <span>
                            BLK {getHdbBlockLabel(target)}
                          </span>
                        }

                        {!isHdb && marker.properties.length !== 1
                          && <div className="dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector">
                            <div
                              className="dropdown__button d-flex items-center align-items-center bg-white border-light rounded-3 px-15 py-10 text-14 lh-12"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="true"
                              aria-expanded="false"
                              data-bs-offset="0,10"
                            >
                              <span
                                className="js-dropdown-title"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <span className="js-dropdown-pri">{data.name}</span>
                                {/* {
                                  data.score >= 0
                                    && <span
                                      className="rs-tag px-5 ml-10 rounded-100 cursor-pointer"
                                      {...{
                                        'data-tooltip-id': "map-tooltip",
                                        'data-tooltip-html': `
                                          <strong>REALSCORE</strong>
                                          <br />
                                          Track record of <strong>profitable transactions</strong>
                                          <br /> compared with condos across Singapore
                                          <hr class="mt-5 mb-5" />
                                          Maximum score of 5.0 for top properties
                                        `,
                                        'data-tooltip-variant': "dark",
                                        'data-tooltip-place': "bottom"
                                      }}
                                    >
                                      {data.score}
                                    </span>
                                } */}
                              </span>
                              <i className="icon icon-chevron-sm-down text-7 ml-10" />
                            </div>
                            <div className="toggle-element -dropdown dropdown-menu" style={{ minWidth: '200px' }}>
                              <div className="text-13 y-gap-15 js-dropdown-list">
                                {marker.properties.sort((a, b) => a.lastTxDate > b.lastTxDate).map(p => (
                                  <div key={p.projectId}>
                                    <button
                                      className={`d-block js-dropdown-link ${
                                        data.projectId === p.projectId ? "text-blue-1 " : "text-dark-1"
                                      }`}
                                      onClick={() => {
                                        goToProperty(p.marker, p.projectId);
                                        document.querySelector(".js-dropdown-pri").textContent = p.project;
                                      }}
                                    >
                                      {p.project} {p.profitable !== null && data.projectId !== p.projectId
                                        ? <span className={`${getPropertyTextColor(userConfig.profitableColor, p, 'profitable')}`}>&nbsp;{`${p.profitable.toFixed(0)}%`}</span>
                                        : ''
                                      }
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        }

                      </h1>
                    </div>
                  </div>

                  {/* {!isHdb
                    && <div className="row x-gap-20 y-gap-0 items-center">
                      <div className="col-auto">
                        {getStreetsLabel(data.streets)}
                      </div>
                      {getTenuresLabel(data.tenures)}
                    </div>
                  } */}

                  {/* {isHdb
                    && <div className="row x-gap-20 y-gap-0 items-center">
                      <div className="col-auto">
                        <div className="d-flex items-center text-12 text-light-1">
                          <i className="icon-location-2 text-14 mr-5" />
                          {target.street} [{target.postal.length < 6 ? '0': ''}{target.postal}]
                        </div>
                      </div>
                      <div className="col-auto d-flex align-content-start flex-wrap py-0">
                        <div className="p-2 d-flex items-center text-10 text-white proj-tag px-10 rounded-100 fw-500 mr-10 mt-5 mb-5">
                          99 yrs from {data.tenureDate}
                        </div>
                      </div>
                    </div>
                  } */}

                </div>
              </div>
              
            </div>

            {badges?.length > 0 && target.mode === MAP_MODE_CONDO
              && <BadgeView
                badges={badges}
              />
            }

            <div className="col-12 text-blue-1">
              <PerformancePanel
                data={data}
                target={target}
              />
            </div>

            {/* <div className={`col-${chartColGrid} text-blue-1`}>
              <PerformancePanel
                data={data}
                target={target}
              />
            </div>

            {chartColGrid === 6
              && <div className={`col-${chartColGrid} text-blue-1`}>
                <div className="mt-5" style={{ height: data.score !== null ? 173 : 201 } }>
                  <PropertyMap data={data} zoom={8.8} />
                </div>
              </div>
            }

            {chartColGrid !== 6 && data?.gallery?.length === 0
              && <div className="col-12">
                <div className="proj-gallery">
                  <PropertyMap data={data} />
                </div>
              </div>
            } */}

            {data?.gallery?.length > 0
              && <div className="col-12">
                <div className="photo-gallery d-flex">
                  {data.gallery.map((d, i) => (
                    <img
                      key={`im-${i}`}
                      loading="lazy"
                      src={d.url}
                      onClick={() => setLightboxImages({
                        idx: i,
                        gallery: data.gallery.map(i => ({ src: i.fullsize }))
                      })}
                    />
                  ))}
                </div>
              </div>
            }

            <Factsheet
              data={data}
              isMaximized={isMaximized}
              screenDim={screenDim}
              target={target}
            />
          </div>
        </div>
      </section>

      <SharePanel
        url={shareUrl}
        clicked={showSharePanel}
        onClose={() => setShowSharePanel(false)}
      />
    </>
  );
};

export default OverviewTab;
