import { isValidDate } from "@/utils/filter";
import { trimDateToDay } from "@/utils/time";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";

const options = [
  { label: 'Past year', value: 1 },
  { label: 'Past 2 years', value: 2 },
  { label: 'Past 5 years', value: 5 },
  { label: 'Past 10 years', value: 10 },
  { label: 'Past 20 years', value: 20 },
  { label: 'All Time', value: 0 },
  { label: 'Custom', value: -1 },
];

const setInitialRange = (startDate, endDate, yearOnly) => {
  if (!startDate) return !endDate ? 0 : -1;
  const now = endDate ?? new Date();
  if (startDate.getDate() === now.getDate()
      && startDate.getMonth() === now.getMonth()) {
    if (yearOnly && endDate && endDate.getFullYear() === now.getFullYear()) {
      return -1;
    }
    if (!yearOnly && endDate && startDate.getTime() === endDate.getTime()) {
      return -1;
    }
    const yearDiff = now.getFullYear() - startDate.getFullYear();
    const option = options.find(v => v.value === yearDiff);
    return option ? option.value : -1;
  }
  return -1;
};

const SearchBox = (props) => {
  const { setDate, startDate, endDate, minDate, fields, yearOnly } = props;
  const initialRange = setInitialRange(startDate, endDate, yearOnly);
  const initialDisplayRange = initialRange < 0;
  const [rangeSelect, setRangeSelect] = useState(initialRange);
  const [showCustomRange, setShowCustomRange] = useState(initialDisplayRange);

  const defaultPastYearOffset = 10;
  const hasDateSet = startDate !== null || endDate !== null;
  const now = trimDateToDay(new Date());

  useEffect(() => {
    const newRange = setInitialRange(startDate, endDate, yearOnly);
    const newDisplayRange = (yearOnly && endDate) || newRange < 0;
    setRangeSelect(newRange);
    setShowCustomRange(newDisplayRange);
  }, [startDate, endDate]);

  const clearDate = () => {
    setDate({
      [fields[0]]: null,
      [fields[1]]: null,
    });
  };

  const setPastYear = (years) => {
    if (years) {
      const past = new Date();
      past.setFullYear(past.getFullYear() - years);
      return trimDateToDay(past);
    }
    return null;
  };

  const autoDate = () => {
    const past = setPastYear(defaultPastYearOffset);
    setDate({
      [fields[0]]: past,
      [fields[1]]: now,
    });
  };

  const onRangeChange = (range) => {
    setRangeSelect(range);
    if (range < 0) {
      setShowCustomRange(true);
    } else if (range > 0) {
      setShowCustomRange(false);
      const years = range === 0 ? null : range;
      const past = setPastYear(years);
      setDate({
        [fields[0]]: past,
        [fields[1]]: null,
      });
    } else {
      setShowCustomRange(false);
      setDate({
        [fields[0]]: null,
        [fields[1]]: null,
      });
    }
  };

  const onSetStartDate = (date) => {
    setDate({
      [fields[0]]: date ? trimDateToDay(new Date(parseInt(JSON.stringify(date)))) : date,
      [fields[1]]: endDate,
    });
  };

  const onSetEndDate = (date) => {
    setDate({
      [fields[0]]: startDate,
      [fields[1]]: date ? trimDateToDay(new Date(parseInt(JSON.stringify(date)))) : date,
    });
  };

  return (
    <div className="mt-10">
      <div className="dropdown js-dropdown js-price-1-active mt-10 mb-15 mr-5">
        <select
          className="form-select dropdown__button d-flex items-center rounded-4 border-light h-40 text-14"
          value={rangeSelect}
          onChange={e => onRangeChange(e.target.value)}
        >
          {options.map(option => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {showCustomRange
        && <>
          <p className="text-12">Leave blank for no start/end date limit</p>
          <div className="ml-15 mt-5">
            <div className="row py-10 pl-15 mr-5 text-15 border-light rounded-8 text-dark-1 ls-2 lh-16">
              <div className="col-auto col-4">
                <DatePicker
                  inputClass="custom_input-picker"
                  containerClassName="custom_container-picker"
                  value={startDate}
                  onChange={onSetStartDate}
                  format={yearOnly ? "YYYY" : "D MMM YY"}
                  placeholder="Start Date"
                  maxDate={endDate ?? now}
                  minDate={minDate}
                  onlyYearPicker={yearOnly}
                />
              </div>
              <div className="col-auto col-2">-</div>
              <div className="col-auto col-4">
                <DatePicker
                  inputClass="custom_input-picker"
                  containerClassName="custom_container-picker"
                  value={endDate}
                  onChange={onSetEndDate}
                  format={yearOnly ? "YYYY" : "D MMM YY"}
                  placeholder="End Date"
                  minDate={isValidDate(startDate) ? startDate : minDate}
                  maxDate={now}
                  onlyYearPicker={yearOnly}
                />
              </div>
              {hasDateSet
                && <div className="col-auto col-2">
                  <i className="icon-close text-10 text-dark-1 cursor-pointer" onClick={clearDate} />
                </div>
              }
              {!hasDateSet
                && <div className="col-auto col-2">
                  <i className="icon-menu text-10 text-dark-1 cursor-pointer" onClick={autoDate} />
                </div>
              }
            </div>
          </div>
        </>
      }
    </div>
  );
};

SearchBox.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  setDate: PropTypes.func.isRequired,
};

SearchBox.defaultProps = {
  startDate: null,
  endDate: null,
};

export default SearchBox;
