import {
  LOCATION_COMPARE,
  LOCATION_ESTATE,
  LOCATION_MARKER,
  LOCATION_PROPERTY,
  LOCATION_SCHOOL,
  LOCATION_STATION,
  LOCATION_UPCOMING
} from "./areas";
import { convertSortOrderToParam, createURLFilter, hasAnyFilterSelected } from "./filter";
import { MAP_MODE_CONDO, MAP_MODE_HDB } from "./map";

export const FILTER_FIELD_START_DATE = 'start_date';
export const FILTER_FIELD_END_DATE = 'end_date';
export const FILTER_FIELD_PROP_TYPE = 'prop_type';
export const FILTER_FIELD_TENURE_TYPE = 'tenure';
export const FILTER_FIELD_LEASE = 'lease';
export const FILTER_FIELD_UNCOMPLETED = 'uncompleted';
export const FILTER_FIELD_AGE = 'age';
export const FILTER_FIELD_GAIN = 'gain';
export const FILTER_FIELD_MIN_SIZE = 'min_size';
export const FILTER_FIELD_MAX_SIZE = 'max_size';
export const FILTER_FIELD_UNIT_SIZE = 'unit_size';
export const FILTER_FIELD_AREAS = 'areas';
export const FILTER_FIELD_DISTRICTS = 'districts';
export const FILTER_FIELD_REGIONS = 'regions';
export const FILTER_FIELD_PROP_NAME = 'prop_name';
export const FILTER_FIELD_UNITS_SFT = 'sft_unit';
export const FILTER_FIELD_SALE_TYPE = 'sale_type';
export const FILTER_FIELD_MIN_PRICE = 'min_price';
export const FILTER_FIELD_MAX_PRICE = 'max_price';
export const FILTER_FIELD_MIN_UNIT_PRICE = 'min_unit_price';
export const FILTER_FIELD_MAX_UNIT_PRICE = 'max_unit_price';
export const FILTER_FIELD_UNIT_PRICE = 'price_unit';

export const convertToUnitsUrl = (id, filters) => {
  if (history.pushState) {
    let searchParams = new URLSearchParams();

    if (id) {
      searchParams.set('id', id);
    } else {
      return;
    }

    if (filters && Object.keys(filters).length > 0) {
      Object.entries(filters).forEach(e => {
        searchParams.set(e[0], e[1]);
      });
    }

    let newUrl = window.location.protocol
      + "//"
      + window.location.host
      + window.location.pathname
      + '?' + searchParams.toString();

    window.history.replaceState({path: newUrl}, '', newUrl);
  }
};

export const convertToCompareUrl = (ids) => {
  if (history.pushState) {
    let searchParams = new URLSearchParams();

    if (ids && ids.length > 0) {
      searchParams.set('ids', ids.join(';'));
    } else {
      return;
    }

    let newUrl = window.location.protocol
      + "//"
      + window.location.host
      + window.location.pathname
      + '?' + searchParams.toString();

    window.history.replaceState({path: newUrl}, '', newUrl);
  }
};

export const convertToUrl = (filters, sorts, page, offset) => {
  if (history.pushState) {
    let searchParams = new URLSearchParams();
    
    if (filters && Object.keys(filters).length > 0) {
      Object.keys(filters).forEach(f => {
        if (f === 'id') {
          // do it hacky for now
          searchParams.set(f, decodeURIComponent(filters[f]));
        } else {
          searchParams.set(f, filters[f]);
        }
      });
    }

    if (sorts && Object.keys(sorts).length > 0)
      searchParams.set('sort', convertSortOrderToParam(sorts));

    if (page)
      searchParams.set('page', page);

    if (offset)
      searchParams.set('offset', offset);

    let newUrl = window.location.protocol
      + "//"
      + window.location.host
      + window.location.pathname
      + '?' + searchParams.toString();

    window.history.replaceState({path: newUrl}, '', newUrl);
  }
}

export const convertToProjectUrl = (project, street) => {
  if (history.pushState) {
    let newUrl = window.location.protocol
      + "//"
      + window.location.host
      + '/project/' + encodeURIComponent(`${project},${street}`);

    // window.history.replaceState({path: newUrl}, '', newUrl);
    window.history.pushState({}, '', newUrl);
  }
};

export const resetMapUrl = (mode) => {
  const newUrl = window.location.protocol
      + "//"
      + window.location.host
      + '/map' + `?mode=${mode}`;

    // append url to history instead of replace
    window.history.pushState({}, '', newUrl);
};

export const convertMapUrl = (target, filters, options, filterMode) => {
  setTimeout(() => {
    if (history.pushState) {
    
      // set query parameters
      let suffix = '';
      let mode = MAP_MODE_CONDO;
      if (target?.mode)
        mode = target.mode;
      if (target?.type === LOCATION_PROPERTY)
        suffix = `?id=${encodeURIComponent(target.id)}${
          mode === MAP_MODE_HDB
            ? ''
            : `&p=${encodeURIComponent(target.projectId)}`  
        }&mode=${mode}`;
      else if (target?.type === LOCATION_MARKER)
        suffix = `?id=${encodeURIComponent(target.id)}`;
      else if (target?.type === LOCATION_SCHOOL)
        suffix = `?sch=${encodeURIComponent(target.id)}`;
        // suffix = `?sch=${encodeURIComponent(target.id)}&m=${filterMode ?? mode}`;
      else if (target?.type === LOCATION_STATION)
        suffix = `?stn=${encodeURIComponent(target.id)}`;
        // suffix = `?stn=${encodeURIComponent(target.id)}&m=${filterMode ?? mode}`;
      else if (target?.type === LOCATION_UPCOMING)
        suffix = `?new=${encodeURIComponent(target.id)}`;
      else if (target?.type === LOCATION_COMPARE)
        suffix = `?m=compare&mode=${mode}`;
      else if (target?.type === LOCATION_ESTATE)
        suffix = `?e=${encodeURIComponent(target.id)}&mode=l`;
      else if (!target && filters && options) {
        if (hasAnyFilterSelected(options, filters)) {
          let searchParams = new URLSearchParams();
          const params = createURLFilter(options, filters);
          Object.keys(params).forEach(f => searchParams.set(f, params[f]));
          suffix = `?f=1&${searchParams.toString()}&mode=${filterMode}`;
        }
      }
  
      // set full url
      const newUrl = window.location.protocol
        + "//"
        + window.location.host
        + '/map' + suffix;
  
      // append url to history instead of replace
      // window.history.pushState({}, '', newUrl);
      
      window.history.replaceState({path: newUrl}, '', newUrl);
    }
  }, 600);
};

export const resetUrl = () => {
  let newUrl = window.location.protocol
    + "//"
    + window.location.host
    + window.location.pathname

    window.history.replaceState({path: newUrl}, '', newUrl);
};

export const formatNumberFromParam = (rawValue, defaultValue) => {
  if (rawValue) {
    const value = parseInt(rawValue);
    if (isNaN(value)) return defaultValue;
    return Math.max(defaultValue, value);
  }
  return defaultValue;
};

export const retrieveParam = (params, field, defaultValue, dataType) => {
  if (params && field in params) {
    let value = params[field];
    if (value === null || value === undefined) return defaultValue;

    if (typeof value === 'string') {
      value = value.replace(/^\s+|\s+$/g, '');
      if (value === '') return defaultValue;
    }

    if (dataType === 'date') value = new Date(parseInt(value));

    return value;
  }
  return defaultValue;
};

export const getProjectParamsUrlComponent = (item) => {
  return encodeURIComponent(`${item['Project Name']},${item['Street']}`);
};
