import { useEffect, useState } from "react";
import { getTimeLabel } from "@/utils/time";
import {
  createComment,
  getComments,
  getPostImgUrl
} from "@/utils/api";
import { isLoggedIn } from "@/utils/user";

const Post = ({
  id,
  user,
  content,
  onClick,
  onSeenPost,
  onLikePost,
  setShowSharePanel,
  hideAction,
  suffix = ''
}) => {
  const PAGE_MAX = 3;

  const [replies, setReplies] = useState(null);
  const [onCommenting, setOnCommenting] = useState(false);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [commented, setCommented] = useState(false);
  const [commentInput, setCommentInput] = useState('');
  const [commentInputErr, setCommentInputErr] = useState(null);

  const [showComments, setShowComments] = useState(false);

  const hasBgId = content.bgId !== null && content.bgId !== undefined;

  const onSeen = () => {
    onSeenPost?.(id, content);
  };

  const imgHeight = window.innerWidth > window.innerHeight
    ? window.innerHeight / 3 * 2
    : window.innerHeight / 4;

  useEffect(() => {
    if (onSeenPost) {
      const element = document.getElementById(`post-${id}${suffix}`);
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            onSeen();
            observer.unobserve(entry.target); // Stop observing after it's visible
          }
        },
        { root: null, threshold: 0.1 }
      );

      if (element) {
        observer.observe(element);
      }

      return () => {
        if (element) observer.disconnect();
      };
    }
  }, [id]);

  const onShare = () => {
    const shareUrl = `https://realsmart.sg/post?id=${content.id}`;
    if (window.navigator.share) {
      window.navigator.share({
        title: `REALSMART.SG - Post by ${content.author.name}`,
        text: content.text ?? 'Click to view post',
        url: shareUrl
      });
    } else {
      setShowSharePanel(shareUrl);
    }
  };

  const getTimeQuery = (date) => date.toISOString().replace('Z', '000000Z');

  const onComment = () => {
    if (commentInput && commentInput.length > 2) {
      setOnCommenting(true);
      setCommentInputErr(null);
      createComment(user, {
        post: content.id,
        text: commentInput
      }, commentId => {
        const creationDate = getTimeQuery(new Date());
        setReplies([{
          id: `${user.claims.user_id}_${commentId}`,
          author: {
            id: user.claims.user_id
          },
          data: {
            text: commentInput
          },
          votes: 0,
          createdAt: creationDate,
          updatedAt: creationDate
        }, ...replies]);
        setOnCommenting(false);
        setCommented(true);
        setCommentInput('');

        setShowComments(true);
        setShowCommentInput(false);
      });
    } else {
      setCommented(false);
      setCommentInputErr('Your comment must be at least 10 characters long');
    }
  };

  const onLoadComments = () => {
    if (!replies) {
      getComments(content.id, new Date().toISOString().replace('Z', '000000Z'), PAGE_MAX, data => {
        setReplies(data.map(d => ({
          ...d,
          data: JSON.parse(d.data),
          ts: new Date(d.updatedAt).getTime()
        })));
      });
    }
  };

  return (
    <div
      id={`post-${id}${suffix}`}
      key={id}
      className="noselect"
      style={{
        width: '100%',
        position: 'relative',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
        borderRadius: '8px',
        overflow: 'hidden',
        cursor: 'pointer',
        display: "block",
      }}
      onClick={() => onClick?.(content)}
    >
      {content.img
        && <div
          className=""
          style={{
            position: 'relative'
          }}
        >
          <img
            className="noptr"
            src={getPostImgUrl(content.img, true)}
            style={{
              display: "block",
              width: "100%",
              height: imgHeight,
              maxHeight: "360px",
              objectPosition: "center",
              objectFit: "cover",
              backgroundSize: "cover",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
            onError={e => e.currentTarget.src = '/img/general/pending.jpg'}
          />
          <div
            className="text-white text-10 d-flex"
            style={{
              position: "absolute",
              top: "6px",
              right: "6px",
            }}
          >
            <div
              className="rounded-100 d-flex align-items-center ml-5"
              style={{
                backgroundColor: "#e84393",
                padding: '0px 6px'
              }}
            >
              <i className="icon-heart mr-5" />
              {content.likes ?? 0}
            </div>
            {!!content.comments
              && <div
                className="rounded-100 d-flex align-items-center ml-5"
                style={{
                  backgroundColor: "#6c5ce7",
                  padding: '0px 6px'
                }}
              >
                <i className="icon-newsletter mr-5" />
                {content.comments}
              </div>
            }
            {!!content.views
              && <div
                className="rounded-100 d-flex align-items-center ml-5"
                style={{
                  backgroundColor: "#6c5ce7",
                  padding: '0px 6px'
                }}
              >
                <i className="icon-eye mr-5" />
                {content.views}
              </div>
            }
          </div>
        </div>
      }
      {content.text && !content.img
        && <div
          className="text-white text-10 d-flex justify-content-end"
          style={{
            marginTop: '6px',
            marginRight: '6px'
          }}
        >
          <div
            className="rounded-100 d-flex align-items-center ml-5"
            style={{
              backgroundColor: "#e84393",
              padding: '0px 6px'
            }}
          >
            <i className="icon-heart mr-5" />
            {content.likes ?? 0}
          </div>
          {!!content.comments
            && <div
              className="rounded-100 d-flex align-items-center ml-5"
              style={{
                backgroundColor: "#6c5ce7",
                padding: '0px 6px'
              }}
            >
              <i className="icon-newsletter mr-5" />
              {content.comments}
            </div>
          }
          {!!content.views
            && <div
              className="rounded-100 d-flex align-items-center ml-5"
              style={{
                backgroundColor: "#6c5ce7",
                padding: '0px 6px'
              }}
            >
              <i className="icon-eye mr-5" />
              {content.views}
            </div>
          }
        </div>
      }
      {content.text && !content.img
        && <div
            className={`text-16 lh-15 post-prev-line-max-s ${`post-txt-bg-${hasBgId ? content.bgId : 'none'} ${hasBgId ? 'text-white' : ''}`} text-center px-20 fw-600`}
            style={{
              whiteSpace: "pre-line"
            }}
          >
            {content.text}{content.text.length >= 100 ? '…' : ''}
          </div>
      }
      {content.text && content.img
        && <div
            className={`text-12 lh-15 post-prev-line${content.img ? '' : '-max'} ${content.img ? '' : `post-txt-bg-${hasBgId ? content.bgId : 'none'} ${hasBgId ? 'text-white' : ''}`}`}
            style={{
              padding: '3px 8px',
              whiteSpace: "pre-line"
            }}
          >
            {content.text}{content.text.length >= 100 ? '…' : ''}
          </div>
      }
      <div
        className="d-flex"
        style={{
          backgroundColor: "white",
          color: "#13357b",
          padding: "6px 8px",
          boxSizing: "border-box",
          alignItems: "center",
        }}
      >
        <img
          className="p-2 px-0 py-0"
          src={content.author.avatar ?? '/img/general/profile.png'}
          style={{
            width: "20px",
            height: "20px",
            objectFit: "cover",
            borderRadius: "50%",
            marginRight: "12px",
          }}
        />
        <span
          className="p-2 flex-grow-1 text-12 py-0"
          style={{ fontWeight: "bold" }}
        >
          {content.author.name}
        </span>
        <span
          className="p-2 text-10 py-0"
        >
          {getTimeLabel(new Date(content.createdAt).getTime())}
        </span>
      </div>
      
      {!hideAction && isLoggedIn(user)
        && <div
          className="d-flex justify-content-around text-white px-10"
          style={{
            backgroundColor: "#13357b",
            color: "#13357b",
            padding: "6px 8px",
            boxSizing: "border-box",
            alignItems: "center",
          }}
        >
          <button
            className="text-white fw-600 text-12 px-5"
            data-tooltip-id="social-tooltip"
            data-tooltip-html="Like"
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
            onClick={e => {
              e.stopPropagation();
              onLikePost?.(content?.id);
            }}
          >
            <i className="icon-heart" />
          </button>
          <button
            className="text-white fw-600 text-12 px-5"
            data-tooltip-id="social-tooltip"
            data-tooltip-html="Share"
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
            onClick={e => {
              e.stopPropagation();
              onShare();
            }}
          >
            <i className="icon-share" />
          </button>
          <button
            className="text-white fw-600 text-12 px-5"
            data-tooltip-id="social-tooltip"
            data-tooltip-html="View comments"
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
            onClick={e => {
              e.stopPropagation();
              setShowCommentInput(false);
              if (!showComments) {
                onLoadComments();
              }
              setShowComments(!showComments);
            }}
          >
            <i className="icon-newsletter" />
          </button>
          <button
            className="text-white fw-600 text-12 px-5"
            data-tooltip-id="social-tooltip"
            data-tooltip-html="Reply"
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
            onClick={e => {
              e.stopPropagation();
              setShowComments(false);
              onLoadComments();
              setShowCommentInput(!showCommentInput);
            }}
          >
            <i className="icon-email-2" />
          </button>
        </div>
      }

      {showCommentInput
        && <div
          className="pt-10 px-10 text-12 bg-white"
          onClick={e => e.stopPropagation()}
          style={{
            cursor: 'default'
          }}
        >
          {commented && <span className="pl-5 text-green-2">You have replied to this post</span>}
          {commentInputErr && <span className="pl-5 text-red-1">{commentInputErr}</span>}
          <textarea
            className="newsfeed-input mt-5 px-10 py-10 text-14"
            placeholder="Enter your comment here"
            value={commentInput}
            onChange={evt => setCommentInput(evt.target.value)}
          />
          {commentInput
            && <div className="d-flex flex-row-reverse mb-10">
              <button
                className="p-2 button -dark-1 py-5 px-10 h-30 rounded-100 bg-blue-1 text-white text-12"
                onClick={onComment}
                disabled={onCommenting}
              >
                Send
              </button>
            </div>
          }
        </div>
      }

      {showComments
        && <div
          className="pt-10 pb-10 px-10 text-12 bg-white"
          onClick={e => e.stopPropagation()}
          style={{
            cursor: 'default'
          }}
        >
          {!replies
            && <div className="text-center pt-5 pb-10 text-12 fw-600">
              Loading
            </div>
          }
          {replies?.length === 0
            && <div className="text-center pt-5 pb-10 text-12">
              No comments yet
            </div>
          }
          {replies?.length > 0 && replies.map((c, i) => (
            <div
              key={`rp_${i}`}
              className=""
            >
              <span className="">
                <strong>{
                    c.author.id === user?.claims?.user_id
                      ? <span className="text-green-2">{user?.claims?.name}</span>
                      : c.author.name
                  } <span className="text-blue-1 text-10">{getTimeLabel(new Date(c.createdAt).getTime())}</span>: </strong>{
                    c.data.text.length > 120
                      ? `${c.data.text.slice(0, 120)}…`
                      : c.data.text
                  }
                  {c.data.text.length > 120
                    && <span
                        className="text-blue-1 cursor-pointer fw-600"
                        onClick={() => onClick?.(content)}
                      > see more</span>
                  }
              </span>
            </div>
          ))}
          {replies?.length >= PAGE_MAX
            && <div
              className="text-center text-blue-1 fw-600 pt-10 post-btn cursor-pointer"
              onClick={() => onClick?.(content)}
            >
              See more comments
            </div>
          }
        </div>
      }
    </div>
  );
};

export default Post;
