import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import DefaultHeader from "@/components/header/default-header";
import DefaultFooter from "@/components/footer/default";
import TermsContent from "@/components/common/TermsContent";

import MetaComponent from "@/components/common/MetaComponent";
import { useEffect } from "react";
import { logPage } from "@/utils/api";

const metadata = {
  title: "Terms of Service | REALSMART.SG | Supercharge your property search",
  description: "REALSMART.SG - Supercharge your property search",
};

const Terms = ({ user, session }) => {
  const [params] = useSearchParams();
  const tab = params.get('t');

  useEffect(() => {
    logPage('TERMS_SERVICE', session, {});
  }, []);

  return (
    <>
      <MetaComponent meta={metadata} />
      {/* End Page Title */}

      <div className="header-margin"></div>
      {/* header top margin */}

      <DefaultHeader user={user} />
      {/* End Header 1 */}

      <section className="layout-pt-lg layout-pb-lg noselect">
        <div className="container">
          <div className="tabs js-tabs">
            <TermsContent tab={tab} />
          </div>
        </div>
      </section>
      {/* End terms section */}

      <DefaultFooter />
      {/* End Call To Actions Section */}
    </>
  );
};

Terms.propTypes = {
  user: PropTypes.object,
  session: PropTypes.string.isRequired,
};

Terms.defaultProps = {
  user: null,
};

export default Terms;
