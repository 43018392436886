import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

import PagePiling from "@/components/common/pagepiling/PagePiling";
import PageSection from "@/components/common/pagepiling/PageSection";
import FinalPage from "@/components/landing/FinalPage";
import IntroPage from "@/components/landing/IntroPage";
import MapPage from "@/components/landing/MapPage";
import Header11 from "@/components/header/header-11";
import MetaComponent from "@/components/common/MetaComponent";
import { isGuest } from "@/utils/user";
import LoginPage from "@/components/landing/LoginPage";
import { logLanding, logPageView, logReferred, trackPageView } from "@/utils/api";
import { useSearchParams } from "react-router-dom";

const metadata = {
  title: "REALSMART.SG | Supercharge your property search",
  description: "REALSMART.SG - Supercharge your property search",
};

const getInitialView = () => {
  const params = new URLSearchParams(window.location.search);
  const initialView = parseInt(params.get('view'));
  return !isNaN(initialView) ? initialView : 0;
}

const LandingPage = ({
  user,
  session,
  allowModeSwitch = false
}) => {
  const [params] = useSearchParams();
  const referredOrigin = params.get('r');

  const pageRef = useRef(null);
  const initialView = getInitialView();
  const onViewChange = (view) => {}

  const onPrevPage = (view) => {
    pageRef?.current?.gotoPrevPage();
  };

  const onNextPage = (view) => {
    pageRef?.current?.gotoNextPage();
  };

  // useEffect(() => {
  //   const preventPullToRefresh = (event) => {
  //     if (window.scrollY === 0 && event.touches[0].clientY > event.touches[0].clientX) {
  //       event.preventDefault();
  //     }
  //   };

  //   document.addEventListener('touchmove', preventPullToRefresh, { passive: false });

  //   if (referredOrigin) {
  //     logReferred(referredOrigin, session);
  //   }

  //   return () => {
  //     document.removeEventListener('touchmove', preventPullToRefresh);
  //   };
  // }, []);

  useEffect(() => {
    if (user) {
      logLanding(session, user);
    }
    trackPageView('landing');
  }, [user]);

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="header-margin"></div>
      <Header11 user={user} />

      <div className="landing-container noselect">
        <PagePiling
          ref={pageRef}
          initialView={initialView}
          onViewChange={onViewChange}
        >
          <PageSection>
            {/* {isGuest(user) && <LoginPage onNextPage={onNextPage} session={session} />}
            {!isGuest(user) && <IntroPage onNextPage={onNextPage} session={session} allowModeSwitch={allowModeSwitch} />} */}
            <IntroPage onNextPage={onNextPage} session={session} allowModeSwitch={allowModeSwitch} />
          </PageSection>
          {/* <PageSection style={{ background: 'white' }}>
            <MapPage onNextPage={onNextPage} onPrevPage={onPrevPage} />
          </PageSection>
          <PageSection style={{ background: 'white' }}>
            <FinalPage onPrevPage={onPrevPage} />
          </PageSection> */}
        </PagePiling>
      </div>
    </>
  );
};

LandingPage.propTypes = {
  user: PropTypes.object,
  session: PropTypes.string.isRequired,
};

LandingPage.defaultProps = {
  user: null,
};

export default LandingPage;
