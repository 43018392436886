import { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";

const BadgeView = ({
  badges
}) => {
	const divRef = useRef(null);
	const [width, setWidth] = useState(0);

	useEffect(() => {
		const observer = new ResizeObserver(entries => {
			for (let entry of entries) {
				setWidth(entry.contentRect.width);
			}
		});
	
		if (divRef.current) {
			observer.observe(divRef.current);
		}
	
		return () => observer.disconnect();
	}, []);

	const isLgWidth = width > 1000;

	return (
		<div
			ref={divRef}
			className="col-12 d-flex flex-wrap px-15 py-0 mt-5 noselect"
		>
			{badges.map(badge => (
				<div
					key={badge.label}
					className="d-flex text-12 fw-600 cursor-pointer"
					style={{
						color: badge.enable ? '#27ae60' : '#bdc3c7',
						...(
							isLgWidth
								? {
									padding: '3px 8px',
								}
								: {
									padding: '3px 3px',
									minWidth: '170px'
								}
						)
					}}
					data-tooltip-id="badge-tooltip"
					data-tooltip-html={badge.hint}
					data-tooltip-variant="dark"
					data-tooltip-place="bottom"
				>
					<img
						src={`/img/general/badge_${badge.enable ? 'y' : 'n'}.png`}
						style={{
							marginTop: '3px',
							marginRight: '6px',
							height: '16px',
							width: '16px'
						}}
					/>
					{badge.label}
				</div>
			))}
			<Tooltip id="badge-tooltip" />
		</div>
	);
};

export default BadgeView;
