import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from 'lodash';
import { DEBOUNCE_TIMING, searchQuery } from "@/utils/api";
import { LOCATION_PROPERTY } from "@/utils/areas";
import { getProjectLabel, MAP_MODE_CONDO, MAP_MODE_HDB, MAP_MODE_LANDED } from "@/utils/map";

const EditPostProperty = ({
  idx,
  tag,
  onChange,
  onRemove
}) => {
  const inputRef = useRef(null);

  const [editTag, setEditTag] = useState(tag?.label);
  const [selectedProp, setSelectedProp] = useState(tag);
  const [suggestions, setSuggestions] = useState([]);

  const onSearch = (value) => {
    searchQuery(value, 3, data => {
      setSuggestions(data);
    }, err => {
      // TODO
    }, LOCATION_PROPERTY);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedSearch = useCallback(debounce(onSearch, DEBOUNCE_TIMING), []);

  useEffect(() => {
    if (editTag !== tag?.label && editTag?.length >= 3) onDebouncedSearch(editTag);
  }, [onDebouncedSearch, tag, editTag]);

  useEffect(() => {
    if (tag) {
      const span = document.getElementById('text-measure');
      const inputValue = editTag ?? '';
      span.innerHTML = inputValue.trim();
      if (inputRef?.current) {
        inputRef.current.style.width = `${span.offsetWidth}px`;
      }
    }
  }, [tag]);

  const onSelectSuggestion = (option) => {
    const mode = option.subtype.some(t => t === 'HDB') ? MAP_MODE_HDB : (option.landed ? MAP_MODE_LANDED : MAP_MODE_CONDO);
    const name = mode === MAP_MODE_HDB
      ? option.marker
      : (
          option.landed
            ? option.marker
            : getProjectLabel(option.marker, option.names[0])
        );
    const selected = {
      label: name,
      mode,
      id: mode === MAP_MODE_HDB
        ? option.postal[0]
        : `${option.marker}#${option.store}`
    };
    setSelectedProp(selected);
    setEditTag(name);
    onChange(selected);
  };

  const onInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (suggestions.length > 0) {
        onSelectSuggestion(suggestions[0]);
      }
    }
  };

  return (
    <div className="relative">
      <button
        key={tag}
        className="p-2 mr-5 button -dark-1 py-5 pl-15 pr-0 h-30 rounded-100 bg-blue-1 text-white text-12 mb-5"
      >
        {editTag
          && <span className="fw-600 mr-5">
            <i className="icon-home" />
          </span>
        }
        <span
          id="text-measure"
          style={{
            visibility: 'hidden',
            position: 'absolute',
            whiteSpace: 'nowrap',
          }}
          data-tooltip-id="edit-tooltip"
          data-tooltip-html="Edit tag"
          data-tooltip-variant="dark"
          data-tooltip-place="bottom"
        >
          {editTag}
        </span>
        <input
          ref={inputRef}
          id={`prop-tag-${idx}`}
          className="text-white"
          placeholder="tag name"
          tabIndex="0"
          style={{
            width: '1px',
            minWidth: '52px',
          }}
          value={editTag}
          onChange={e => setEditTag(e.target.value?.toUpperCase())}
          onInput={e => {
            const span = document.getElementById('text-measure');
            const inputValue = e?.target?.value ?? '';
            span.innerHTML = inputValue.trim() || e.target.placeholder;
            e.target.style.width = `${span.offsetWidth}px`;
          }}
          onBlur={e => {
            if (!selectedProp) {
              onRemove();
            }
          }}
          onFocus={e => {
            e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
          }}
          onKeyDown={onInputKeyDown}
          autoComplete="off"
        />
        {tag
          && <i
            className="icon-close pl-15 pr-10 py-10"
            data-tooltip-id="edit-tooltip"
            data-tooltip-html="Remove tag"
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
            onClick={onRemove}
          />
        }
      </button>

      {suggestions.length > 0 && editTag !== selectedProp?.label
        && <ul
          className="absolute w-full left-0 top-full bg-white border rounded shadow-lg max-h-[6rem] overflow-y-auto z-10 text-12"
          style={{ width: '240px' }}
        >
          {suggestions.map((option, i) => (
            <li
              key={`stag_${i}`}
              className="p-2 cursor-pointer hover:bg-gray-100 px-10 py-5 notif-item"
              onMouseDown={evt => evt.preventDefault()}
              onClick={() => onSelectSuggestion(option)}
            >
              {
                option.subtype === 'HDB'
                  ? option.marker
                  : getProjectLabel(option.marker, option.names[0])
              }
            </li>
          ))}
        </ul>
      }
    </div>
  );
};

export default EditPostProperty;
