import { useCallback, useEffect, useState } from "react";
import TabButtons from "../mapv2/property/TabButtons";
import LikedProperties from "../account/LikedProperties";
import Follows from "../account/Follows";
import ProfilePage from "./ProfilePage";
import Block1 from "@/components/about/Block1";
import PostsPage from "./PostsPage";
import { logAnalytics } from "@/utils/api";

const ProfilePanel = ({
    user,
    session,
    mediaQuery,
    closeDisplay,
    onCreatePost,
    selectedPost,
    onClickPost,
    hideNewsfeed
}) => {
    const [display, setDisplay] = useState('profile');

    const escFunc = useCallback((event) => {
        if (event.key === "Escape" && !selectedPost) {
          closeDisplay();
        }
      }, [selectedPost]);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunc, false);
    
        return () => {
          document.removeEventListener("keydown", escFunc, false);
        };
      }, [escFunc]);

    return (
        <div
            className={`newsfeed-content newsfeed-post ${mediaQuery ? 'newsfeed-post-full' : ''} noselect d-flex flex-column`}
        >
            <div className="d-flex items-center justify-between px-30 py-5 sm:px-15 border-bottom-light">
                <div className="mt-5">
                    <TabButtons
                        tabs={
                            hideNewsfeed
                                ? [
                                    { id: 'profile', label: 'Profile' },
                                    { id: 'properties', label: 'Subscribed Properties' },
                                    { id: 'about', label: 'About Realsmart' }
                                ]
                                : [
                                    { id: 'profile', label: 'Profile' },
                                    { id: 'properties', label: 'Subscribed Properties' },
                                    { id: 'follows', label: 'Follows' },
                                    { id: 'likedposts', label: 'Liked Posts' },
                                    { id: 'about', label: 'About Realsmart' }
                                ]
                        }
                        selectedTab={display}
                        setSelectedTab={selected => {
                            setDisplay(selected);
                        }}
                        session={session}
                        logger={tabId => logAnalytics(`PROFILE_BUTTON_${tabId}`, session, {})}
                    />
                </div>
                <button className="pointer" onClick={() => closeDisplay()}>
                    <i className="icon-close" />
                </button>
            </div>

            <div className="me-profile-panel flex-grow-1">
                {display === 'profile'
                    && <ProfilePage
                        user={user}
                        session={session}
                        onCreatePost={onCreatePost}
                        onClickPost={onClickPost}
                        hideNewsfeed={hideNewsfeed}
                    />
                }
                {display === 'properties'
                    && <LikedProperties
                        user={user}
                        session={session}
                        style={{
                            height: '100%',
                            padding: '10px'
                        }}
                    />
                }
                {display === 'follows'
                    && <Follows
                        user={user}
                        session={session}
                        style={{
                            height: '100%',
                            padding: '10px'
                        }}
                    />
                }
                {display === 'likedposts'
                    && <PostsPage
                        user={user}
                        session={session}
                        onCreatePost={onCreatePost}
                        onClickPost={onClickPost}
                        isLikedMode
                    />
                }
                {display === 'about'
                    && <div className="">
                        <section className="layout-pt-md">
                            <div className="container">
                                <div className="row y-gap-30 justify-between items-center">
                                    <Block1 />
                                </div>
                            </div>
                        </section>
                    </div>
                }
            </div>
        </div>
    );
};

export default ProfilePanel;
