import { useCallback, useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { debounce } from 'lodash';
import PostActionButton from "./PostActionButton";
import {
  checkMediaQuery,
  getUserClaims,
  isAdminTier
} from "@/utils/user";
import { getTimeLabel } from "@/utils/time";
import {
  createComment,
  deleteComment,
  followAuthor,
  getComments,
  getPost,
  getPostImgUrl,
  hasLikedAuthor,
  hasLikedPost,
  likePost,
  logAnalytics,
  reportPost,
  unlikePost,
  voteComment
} from "@/utils/api";
import Loader from "../common/Loader";
import { LOCATION_PROPERTY } from "@/utils/areas";
import { MAP_MODE_HDB, MAP_MODE_LANDED } from "@/utils/map";
import Comment from "./Comment";

const PostView = ({
  user,
  session,
  brief,
  onClose,
  lightboxImages,
  setLightboxImages,
  onEditPost,
  onDeletePost,
  onSearchLocation
}) => {
  const bmRef = useRef(null);
  const commentsRef = useRef(null);

  const COMMENT_PAGE_SIZE = 10;

  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(brief);
  const [hasEditRights, setHasEditRights] = useState(false);
  const [commentOffset, setCommentOffset] = useState(null);
  const [loadedComments, setLoadedComments] = useState(new Set());
  const [noCommentsLeft, setNoCommentsLeft] = useState(false);

  const [onReporting, setOnReporting] = useState(false);
  const [onCommenting, setOnCommenting] = useState(false);

  const [showReportInput, setShowReportInput] = useState(false);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showSharePanel, setShowSharePanel] = useState(false);

  const [reported, setReported] = useState(false);
  const [commented, setCommented] = useState(false);

  const [reportInput, setReportInput] = useState('');
  const [reportInputErr, setReportInputErr] = useState(null);
  const [commentInput, setCommentInput] = useState('');
  const [commentInputErr, setCommentInputErr] = useState(null);

  const [liked, setLiked] = useState(null);
  const [followedAuthor, setFollowedAuthor] = useState(null);
  const [votedComments, setVotedComments] = useState({});

  const [replies, setReplies] = useState([]);

  const [deleting, setDeleting] = useState(false);
  const [deletingComment, setDeletingComment] = useState(new Set());

  const mediaQuery = checkMediaQuery();

  const shareUrl = `https://realsmart.sg/post?id=${content.id}`;

  let fetching = false;

  const onLike = () => {
    if (liked) {
      setLiked(false);
      unlikePost(content.id, () => {
        // do nothing
      }, err => {
        setLiked(true);
      });
    } else {
      const prevLike = liked;
      setLiked(true);
      likePost(content.id, () => {
        // do nothing
      }, err => {
        setLiked(prevLike);
      });
    }
  };

  const onReport = () => {
    if (reportInput && reportInput.length > 10) {
      setOnReporting(true);
      setReportInputErr(null);
      reportPost(content.id, reportInput, () => {
        setReportInput('');
        setReported(true);
      }, err => {
        setReportInputErr(err);
        setOnReporting(false);
      });
    } else {
      setReportInputErr('Your report must be at least 10 characters long');
    }
  };

  const onComment = () => {
    if (commentInput && commentInput.length > 2) {
      setOnCommenting(true);
      setCommentInputErr(null);
      createComment(user, {
        post: content.id,
        text: commentInput
      }, commentId => {
        const creationDate = getTimeQuery(new Date());
        setReplies([{
          id: `${user.claims.user_id}_${commentId}`,
          author: {
            id: user.claims.user_id
          },
          data: {
            text: commentInput
          },
          votes: 0,
          createdAt: creationDate,
          updatedAt: creationDate
        }, ...replies]);
        setOnCommenting(false);
        setCommented(true);
        setCommentInput('');
        scrollInputComments();
      });
    } else {
      setCommented(false);
      setCommentInputErr('Your comment must be at least 10 characters long');
    }
  };

  const onShare = () => {
    if (window.navigator.share) {
      window.navigator.share({
        title: `REALSMART.SG - Post by ${content.author.name}`,
        text: content.text ?? 'Click to view post',
        url: shareUrl
      });
    } else {
      setShowSharePanel(true);
    }
  };

  const onCopyUrl = () => {
    navigator.clipboard.writeText(shareUrl);
  };

  const hasTextImgNoGallery = content.text && content.img && !content.gallery;

  const toggleReport = () => {
    setShowCommentInput(false);
    setShowSharePanel(false);
    const isShown = showReportInput;
    setShowReportInput(!isShown);
    setCommented(false);
    if (!isShown) {
      scrollIntoInput();
    }
  };

  const toggleComment = () => {
    setShowReportInput(false);
    setShowSharePanel(false);
    const isShown = showCommentInput;
    setShowCommentInput(!isShown);
    setCommented(false);
    if (!isShown) {
      scrollIntoInput();
    }
  };

  const scrollIntoInput = () => {
    setTimeout(() => {
      bmRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100);
  };

  const scrollInputComments = () => {
    setTimeout(() => {
      commentsRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100);
  };

  const onEdit = () => {
    if (confirm('Edit post?')) {
      onClose();
      onEditPost?.(content);
    }
  };

  const onDelete = () => {
    if (confirm('Delete post?')) {
      setDeleting(true);
      onDeletePost(content.id,
        () => {
          onClose();
        }, err => {
          setDeleting(false);
        }
      );
    }
  };

  const onFollowAuthor = () => {
    const prevFollow = followAuthor;
    setFollowedAuthor(true);
    followAuthor(content.author.id, () => {
      // do nothing
    }, err => {
      setFollowedAuthor(prevFollow);
    });
  };

  const onVoteComment = (id, value) => {
    if (votedComments[id] === value) return;
    const prevValue = votedComments[id];
    setVotedComments((votes) => ({ ...votes, [id]: id in votedComments ? (prevValue + value) : value }));
    voteComment(id, content.id, value, () => {
      // do nothing
    }, err => {
      setVotedComments((votes) => ({ ...votes, [id]: prevValue }));
    });
    setReplies(replies.map(d => d.id === id ? ({ ...d, votes: d.votes + value }): d))
  };

  const escFunc = useCallback((event) => {
    if (event.key === "Escape" && lightboxImages === null) {
      onClose();
    }
  }, [lightboxImages]);

  useEffect(() => {
    document.addEventListener("keydown", escFunc, false);

    return () => {
      document.removeEventListener("keydown", escFunc, false);
    };
  }, [escFunc]);

  const getTimeQuery = (date) => date.toISOString().replace('Z', '000000Z');

  const getLatestQueryTime = () => {
    const now = new Date();
    return getTimeQuery(now);
  };

  const loadComments = (end) => {
    if (fetching || noCommentsLeft) return;
    fetching = true;
    getComments(content.id, end, COMMENT_PAGE_SIZE, data => {
      const formattedData = data.filter(d => !loadedComments.has(d.id)).map(d => ({
        ...d,
        data: JSON.parse(d.data),
        ts: new Date(d.updatedAt).getTime()
      }));
      if (formattedData.length < COMMENT_PAGE_SIZE) {
        setNoCommentsLeft(true);
      }
      const minTs = Math.min(...formattedData.map(d => new Date(d.createdAt).getTime()));
      setCommentOffset(!commentOffset ? minTs : Math.min(commentOffset, minTs));
      const newReplies = [
        ...replies,
        ...formattedData
      ].sort((a, b) => b - a);
      setReplies(newReplies);
      setLoadedComments(new Set(newReplies.map(d => d.id)));
      fetching = false;
    });
  };

  const onDebouncedLoad = useCallback(debounce(loadComments, 200),
    [noCommentsLeft, commentOffset, loadedComments, replies, fetching]);

  const onSeenComment = (idx) => {
    // handle scroll down behavior
    if (!idx || replies.length < COMMENT_PAGE_SIZE) return;
    if (idx >= (replies.length - (COMMENT_PAGE_SIZE / 2))) {
      if (!noCommentsLeft) {
        onDebouncedLoad(getTimeQuery(new Date(commentOffset)));
      }
    }
  };

  const onCommentDelete = (id, errCallback) => {
    setDeletingComment(prev => {
      const newDeletes = new Set(prev);
      newDeletes.add(id);
      return newDeletes;
    });
    deleteComment(id, content.id, () => {
      setReplies(replies.filter(r => r.id !== id));
      setDeletingComment(prev => {
        const newDeletes = new Set(prev);
        newDeletes.delete(id);
        return newDeletes;
      });
    }, err => {
      errCallback(err);
      setDeletingComment(prev => {
        const newDeletes = new Set(prev);
        newDeletes.delete(id);
        return newDeletes;
      });
    });
  };

  useEffect(() => {
    getPost(content.id, data => {
      const jsonData = JSON.parse(data.data);
      const newContent = {
        ...content,
        ...data,
        ...jsonData,
        author: content.author ?? {
          id: jsonData.uid,
          name: jsonData.author,
          avatar: jsonData.avatar
        }
      };
      if (newContent.gallery?.length > 0) {
        newContent.gallery = newContent.gallery.filter(i => !!i);
        if (newContent.gallery.length > 0) {
          newContent.img = newContent.gallery[0];
        }
      }
      setContent(newContent);
      setLoading(false);

      setHasEditRights(user?.claims && (user.claims.user_id === newContent.author.id || isAdminTier(getUserClaims(user))));
      // load comments if any
      if (newContent.comments) {
        loadComments(getLatestQueryTime());
      }

      if (content.author.id !== '0' && content.author.id !== user?.claims?.user_id) {
        hasLikedAuthor(content.author.id, subscribed => {
          setFollowedAuthor(subscribed);
        }, err => {
          setFollowedAuthor(false);
        });
      }

      hasLikedPost(content.id, (liked, votes) => {
        if (content.author.id !== user?.claims?.user_id) {
          setLiked(liked);
        }
        const commentVotes = {};
        votes.forEach(a => commentVotes[a[0]] = a[1]);
        setVotedComments(commentVotes);
      }, err => {
        setLiked(false);
      });
    });

    setTimeout(() => {
      const newUrl = window.location.protocol
        + "//"
        + window.location.host
        + '/news?id=' + content.id;
      window.history.replaceState({path: newUrl}, '', newUrl);
    }, 100);

    logAnalytics('VIEW_POST', session, {
      id: content.id
    });
  }, []);

  const goToProperty = (prop) => {
    onSearchLocation?.({
      isFromTag: true,
      isHdb: prop.mode === MAP_MODE_HDB,
      type: LOCATION_PROPERTY,
      marker: prop.mode === MAP_MODE_HDB ? prop.label : prop.id.split('#')[0],
      store: prop.mode === MAP_MODE_HDB ? prop.id : prop.id.split('#')[1],
      landed: prop.mode === MAP_MODE_LANDED
    });
  };

  const goToTag = (prop) => {
    // TODO
  };

  return (
    <div className="newsfeed-post noselect">
      {/* header */}
      {content.author
        && <div className="d-flex justify-content-between newsfeed-sticky px-15 py-10">
          <div
            className="d-flex cursor-pointer"
            style={{
              boxSizing: "border-box",
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
              alignItems: "center",
            }}
          >
            <img
              src={content.author.avatar ?? '/img/general/profile.png'}
              style={{
                width: "30px",
                height: "30px",
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: "12px",
              }}
            />
            <span className="text-14 fw-600">
              {content.author.name}
            </span>
            {followedAuthor === false
              && <button
                className="p-2 ml-15 button -dark-1 py-5 px-10 h-30 rounded-100 bg-blue-1 text-white text-12"
                onClick={onFollowAuthor}
              >
                Subscribe
              </button>
            }
          </div>

          <div className="p-2 flex-grow-1"></div>

          {hasEditRights
            && <button
              className="p-2 button h-30 px-10 text-16 py-20"
              onClick={onEdit}
            >
              <i className="icon-edit py-5" />
            </button>
          }

          {hasEditRights
            && <button
              className="p-2 button h-30 px-10 text-16 py-20"
              onClick={onDelete}
              disabled={deleting}
              style={{...(
                deleting ? {
                  opacity: '0.3'
                } : {
                  opacity: '1'
                }
              )}}
            >
              <i className="icon-trash py-5" />
            </button>
          }

          <button
            className="p-2 button h-30 px-10 text-16 py-20"
            onClick={onClose}
          >
            <i className="icon-close py-5" />
          </button>
        </div>
      }

      {loading
        && <div className="loader-container"><Loader /></div>
      }

      {!loading
        && <div className="news-post-ch">
          {/* content body */}
          <div className="px-15 py-10 watermark">
            <div className="d-flex justify-content-end px-5 pt-5 pb-15">
              <div
                className="text-10 fw-600 text-white px-10 rounded-100"
                data-tooltip-id="post-tooltip"
                data-tooltip-html={content.likes ? `${content.likes} users liked this post` : 'No likes yet, be the first to like'}
                data-tooltip-variant="dark"
                data-tooltip-place="bottom"
                style={{
                  backgroundColor: "#e84393"
                }}
              >
                <i className="icon-heart mr-5" />
                {content.likes ?? 0}
              </div>
            </div>

            <div className="row">
              <div className={`col-12 ${hasTextImgNoGallery ? 'col-md-6' : ''}`}>
                {/* text */}
                {content.text
                  && <div
                    className="text-14 px-10"
                    style={{
                      whiteSpace: "pre-line"
                    }}
                  >
                    {content.text}
                  </div>
                }

                {/* properties */}
                {content.properties && content.properties.length > 0
                  && <div className="mt-20 px-10">
                    {content.properties.map(tag => (
                      <span
                        key={tag}
                        className="text-blue-1 text-12 fw-500 cursor-pointer mr-15"
                        onClick={() => goToProperty(tag)}
                      >
                        <i className="icon-home mr-5" />
                        {tag.label}
                      </span>
                    ))}
                  </div>
                }

                {/* tags */}
                {content.tags && content.tags.length > 0
                  && <div className="mt-20 px-10">
                    {content.tags.map(tag => (
                      <span
                        key={tag}
                        className="text-blue-1 text-12 fw-500 cursor-pointer mr-15"
                        onClick={() => goToTag(tag)}
                      >
                        #{tag}
                      </span>
                    ))}
                  </div>
                }
              </div>

              {/* single image no gallery */}
              {content.gallery?.length < 2 && content.img
                && <img
                  className={`col-12 ${hasTextImgNoGallery ? 'col-md-6' : ''} cursor-pointer`}
                  loading="lazy"
                  src={getPostImgUrl(content.img, true)}
                  onClick={() => {
                    setLightboxImages({
                      idx: 0,
                      gallery: [{ src: getPostImgUrl(content.img) }]
                    });
                  }}
                  onError={e => e.currentTarget.src = '/img/general/pending.jpg'}
                  style={{
                    maxHeight: '380px',
                    width: 'auto',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  }}
                />
              }
            </div>

            {/* gallery */}
            {content.gallery?.length > 1
              && <div className="p-2 mt-5">
                <div className="photo-gallery d-flex">
                  {content.gallery.map((d, i) => (
                    <img
                      key={`g${i}`}
                      loading="lazy"
                      src={getPostImgUrl(d)}
                      onClick={() => {
                        setLightboxImages({
                          idx: i,
                          gallery: content.gallery.map(p => ({ src: getPostImgUrl(p) }))
                        });
                      }}
                      onError={e => e.currentTarget.src = '/img/general/pending.jpg'}
                    />
                  ))}
                </div>
              </div>
            }

            <div className="p-2 text-end text-12 fw-600">
              <span>{
                content.createdAt !== content.updatedAt ? 'Edited' : 'Posted'
              } {getTimeLabel(new Date(content.updatedAt).getTime(), 'just now', ' ago')}</span>
            </div>
          </div>

          {/* action bar */}
          <div className="d-flex justify-content-end border-bottom-light">
            {content.author.id !== '0' && content.author.id !== user?.claims?.user_id
              && <PostActionButton label="Report" light={showReportInput} onClick={toggleReport} />
            }
            {liked !== null && content.author.id !== user?.claims?.user_id
              && <PostActionButton icon="heart" label={liked ? 'Unlike' : 'Like'} light={liked} onClick={onLike} />
            }
            <PostActionButton label="Share" light={showSharePanel} onClick={onShare} />
            <PostActionButton label="Comment" light={showCommentInput} onClick={toggleComment} />
          </div>

          <div ref={bmRef} className="">
            {showReportInput
              && <div className="border-bottom-light py-10 px-15">
                <div className="d-flex items-center justify-between">
                  <div className="text-12 fw-500">Report this post</div>
                  <button className="pointer" onClick={() => setShowReportInput(false)}>
                    <i className="icon-close text-10" />
                  </button>
                </div>
                <div className="mt-10 text-12">
                  {reported
                    && <span>You have reported this post</span>
                  }
                  {!reported
                    && <>
                      {reportInputErr && <span className="pl-5 text-red-1">{reportInputErr}</span>}
                      <textarea
                        className="newsfeed-input px-10 py-10 text-14"
                        placeholder="Describe what is wrong or offensive about this post"
                        value={reportInput}
                        onChange={evt => setReportInput(evt.target.value)}
                      />
                      {reportInput
                        && <div className="d-flex flex-row-reverse">
                          <button
                            className="p-2 button -dark-1 py-5 px-10 h-30 rounded-100 bg-blue-1 text-white text-12"
                            onClick={onReport}
                            disabled={onReporting}
                          >
                            Send
                          </button>
                        </div>
                      }
                    </>
                  }
                </div>
              </div>
            }

            {showCommentInput
              && <div className="border-bottom-light py-10 px-15">
                <div className="d-flex items-center justify-between">
                  <div className="text-12 fw-500">Reply to this post</div>
                  <button className="pointer" onClick={() => setShowCommentInput(false)}>
                    <i className="icon-close text-10" />
                  </button>
                </div>
                <div className="mt-10 text-12">
                  {commented && <span className="pl-5 text-green-2">You have replied to this post</span>}
                  {commentInputErr && <span className="pl-5 text-red-1">{commentInputErr}</span>}
                  <textarea
                    className="newsfeed-input mt-5 px-10 py-10 text-14"
                    placeholder="Enter your comment here"
                    value={commentInput}
                    onChange={evt => setCommentInput(evt.target.value)}
                  />
                  {commentInput
                    && <div className="d-flex flex-row-reverse">
                      <button
                        className="p-2 button -dark-1 py-5 px-10 h-30 rounded-100 bg-blue-1 text-white text-12"
                        onClick={onComment}
                        disabled={onCommenting}
                      >
                        Send
                      </button>
                    </div>
                  }
                </div>
              </div>
            }
          </div>

          {showSharePanel
            && <div className="border-bottom-light py-10 px-15">
              <div className="d-flex items-center justify-between">
                <div className="text-12 fw-500">Share Post Link</div>
                <button className="pointer" onClick={() => setShowSharePanel(false)}>
                  <i className="icon-close text-10" />
                </button>
              </div>
              <div className="d-flex mt-10 text-12">
                <div
                  className="p-2 flex-grow-1 text-light-1 h-30 py-0 newsfeed-input mr-10"
                  style={{
                    alignContent: 'center'
                  }}
                >
                  {shareUrl}
                </div>
                <button
                  className="p-2 button -dark-1 py-5 px-10 h-30 rounded-100 bg-blue-1 text-white text-12"
                  onClick={() => onCopyUrl()}
                >
                  Copy
                </button>
              </div>
            </div>
          }

          {/* replies */}
          {replies.length === 0
            && <div className="text-center py-20 text-14">
              No comments yet
            </div>
          }
          <div ref={commentsRef} className="mt-10 noselect watermark px-15 py-10 d-flex flex-column">
            {replies.map((row, idx) =>
              <Comment
                key={row.id}
                id={idx}
                row={row}
                user={user}
                onVoteComment={onVoteComment}
                votedComments={votedComments}
                onSeenComment={onSeenComment}
                onCommentDelete={onCommentDelete}
                isDeleting={deletingComment.has(row.id)}
              />
            )}
          </div>
        </div>
      }

      <Tooltip id="post-tooltip" />
    </div>
  );
};

export default PostView;
