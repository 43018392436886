import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import Loader from "@/components/common/Loader";
import StyledFirebaseAuth from "./StyledFirebaseAuth";
import { logLogin, logSignup } from "@/utils/api";
import { FIREBASE_UI_AUTH_CONFIG } from "@/utils/user";


const LoginComponent = ({
  disableRedirectOnLogin,
  onLogin,
  session
}) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [autoLogin, setAutoLogin] = useState(true);
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(null);
  const paramErr = params.get('err');
  const next = params.get('next');

  const redirectHome = () => {
    setTimeout(() => {
      navigate('/map');
    }, 300);
  };

  const redirectNext = () => {
    setTimeout(() => {
      navigate(next);
    }, 300);
  };

  const redirect = () => {
    if (next) {
      redirectNext();
    } else {
      redirectHome();
    }
  };

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase.auth()
          .setPersistence(autoLogin
              ? firebase.auth.Auth.Persistence.LOCAL
              : firebase.auth.Auth.Persistence.SESSION)
          .then(() => {
            setLoading(true);
            logLogin(session, user);
            if (user?.multiFactor?.user?.metadata?.createdAt
                && user?.multiFactor?.user?.metadata?.createdAt === user?.multiFactor?.user?.metadata?.lastLoginAt) {
              logSignup(session, user, user?.multiFactor?.user?.metadata?.createdAt);
            }
            onLogin?.(user);
            if (!disableRedirectOnLogin) {
              redirect();
            }
            
            // getUserInfo((data) => {
            //   // loaded user
            //   if (data?.reload) {
            //     // reload user for latest claims
            //     user.getIdToken(true).then(() => {
            //       user.getIdTokenResult().then((result) => {
            //         updateTokenResult(result);
            //         redirect();
            //       });
            //     });
            //   } else {
            //     redirect();
            //   }
            // }, (err) => {
            //   // soft failure, can just ignore error
            //   redirect();
            // });
          })
          .catch((err) => {
            setErr(err);
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    });
    return () => unregisterAuthObserver();
  }, []);

  if (err === null && paramErr !== err) {
    setErr(paramErr);
  }

  return (
    <section className="layout-pt-lg layout-pb-lg bg-blue-1">
      <div className="container">
        
        <div className="row justify-center">
          <div className="col-xl-6 col-lg-7 col-md-9">
            <div className="px-50 py-50 sm:px-20 sm:py-20 bg-white shadow-4 rounded-4">
              {err
                && <div className="alert alert-danger" role="alert">
                  {err}
                </div>
              }

              {/* <LoginForm />

              <div className="row y-gap-20 pt-30">
                <div className="col-12">
                  <div className="text-center">or sign in with</div>
                </div>
                <LoginWithSocial />
                <div className="col-12">
                  <div className="text-center px-30">
                    By creating an account, you agree to our Terms of Service
                    and Privacy Statement.
                  </div>
                </div>
              </div> */}
              {/* End .row */}

              {!loading
                && <div className="row y-gap-10">
                  <div className="col-12 text-center">
                    <h1 className="text-22 fw-800">REALSMART.SG</h1>
                    <h2 className="text-16 fw-500">Get Superpowered Now</h2>
                    <img className="mt-10 mb-10" height={78} width={319} src="/img/general/skyline.png" />
                    <h2 className="text-12 fw-600 mt-10">
                      Logging in helps us prevent misuse and keeps Realsmart FREE for all
                    </h2>
                  </div>

                  <div className="col-12 d-flex justify-content-center">
                    {/* <button className="" onClick={onSubmit}>
                      <img src="https://developers.google.com/static/identity/images/branding_guideline_sample_lt_sq_lg.svg" />
                    </button> */}

                    <StyledFirebaseAuth uiConfig={FIREBASE_UI_AUTH_CONFIG} firebaseAuth={firebase.auth()} />
                  </div>

                  <div className="col-12">
                    <div className="d-flex justify-content-center">
                      <div className="form-checkbox mt-5">
                        <input
                          type="checkbox"
                          name="name"
                          checked={autoLogin}
                          onChange={e => setAutoLogin(e.target.checked)}
                        />
                        <div className="form-checkbox__mark">
                          <div className="form-checkbox__icon icon-check" />
                        </div>
                      </div>
                      <div className="text-15 lh-15 text-light-1 ml-10">
                        Log me in automatically
                      </div>
                    </div>
                  </div>
                </div>
              }

              {loading
                && <Loader />
              }

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginComponent;
