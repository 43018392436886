import { useEffect, useState } from "react";
import TransactionsTab from "./TransactionTab";
import RentalTab from "./RentalTab";
import TabButtons from "./TabButtons";
import UnitsTab from "./UnitsTab";
import {
  MAP_MODE_HDB,
  MAP_MODE_LANDED
} from "@/utils/map";
import NearbyTransactions from "./Nearby/NearbyTransactions";
import { dateStrConvert } from "@/utils/time";

const LANDED_TREND_OPTIONS = [
  {
    id: 'tx',
    label: 'Transactions'
  },
  {
    id: 'nearby',
    label: 'Nearby Transactions'
  }
];

const HDB_TREND_OPTIONS = [
  {
    id: 'tx',
    label: 'Sales'
  },
  {
    id: 'units',
    label: 'Stack View'
  },
  {
    id: 'nearby',
    label: 'Nearby Transactions'
  }
];

const CONDO_TREND_OPTIONS = [
  {
    id: 'tx',
    label: 'Sales'
  },
  {
    id: 'r',
    label: 'Rental'
  },
  {
    id: 'units',
    label: 'Stack View'
  },
  {
    id: 'nearby',
    label: 'Nearby Transactions'
  }
];

const TrendTab = ({
  user,
  session,
  data,
  isMaximized,
  screenDim,
  goToUnit,
  goToProperty,
  goToHdbBlock,
  target,
  setCellDetails,
  setCellDetailsLoading,
  defaultBlock,
  selectedSubtab,
  nearbyTxs,
  focus,
  setFocus,
  setUnfadedProps
}) => {
  const options = target.mode === MAP_MODE_LANDED ? LANDED_TREND_OPTIONS : (
    target.mode === MAP_MODE_HDB ? HDB_TREND_OPTIONS : CONDO_TREND_OPTIONS
  );

  const [display, setDisplay] = useState(
    options.find(o => o.id === selectedSubtab)
      ? selectedSubtab
      : options[0].id
  );

  useEffect(() => {
    if (selectedSubtab && options.find(o => o.id === selectedSubtab)) {
      setDisplay(selectedSubtab);
    }
  }, [selectedSubtab]);

  const goToPropertyFromRow = (data) => {
    const idx = data.cell.row.index;
    if (target.mode === MAP_MODE_HDB) {
      goToHdbBlock(data.data[idx].postal);
    } else {
      goToProperty(target.mode === MAP_MODE_LANDED
          ? data.data[idx].name
          : data.data[idx].marker,
        data.data[idx].projectId, target.mode);
    }
  };

  return (
    <>
      <TabButtons
        tabs={options}
        selectedTab={display}
        setSelectedTab={setDisplay}
        session={session}
        markerName={target.id}
        projectName={target.project}
      />

      <div className="px-5">
        {display === 'tx'
          && <TransactionsTab user={user} data={data} isMaximized={isMaximized} screenDim={screenDim}
            goToUnit={goToUnit} target={target}
          />
        }

        {display === 'r'
          && <RentalTab user={user} data={data} isMaximized={isMaximized} screenDim={screenDim}
            target={target}
          />
        }

        {display === 'units'
          && <UnitsTab
            user={user} data={data} isMaximized={isMaximized} screenDim={screenDim}
            setCellDetails={setCellDetails} setCellDetailsLoading={setCellDetailsLoading}
            defaultBlock={defaultBlock} target={target}
          />
        }

        {display === 'nearby'
          && <section className={`mb-20 pt-10 modal-table${isMaximized ? '-full' : ''}`}>
            <NearbyTransactions
              user={user}
              target={target}
              transactions={nearbyTxs?.map(row => ({
                ...row,
                'sale_date': dateStrConvert(row.saleDate),
                'transacted_price': parseInt(row.price),
              })).sort((a, b) => b.sale_date - a.sale_date)}
              goToProperty={goToPropertyFromRow}
              focus={focus}
              setFocus={setFocus}
              setUnfadedProps={setUnfadedProps}
            />
          </section>
        }
      </div>

    </>
  );
};

export default TrendTab;
