import { useCallback, useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { debounce } from 'lodash';
import Loader from "../common/Loader";
import { getLikedPosts, getUserPosts, logAnalytics } from "@/utils/api";
import Post from "./Post";

const PostsPage = ({
    user,
    session,
    isLikedMode,
    onCreatePost,
    onClickPost
}) => {
    const PAGE_LIMIT = 20;
    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [noPastPosts, setNoPastPosts] = useState(false);

    let fetching = false;

    const loadData = (offset) => {
        if (fetching) return;
        fetching = true;
        const func = isLikedMode ? getLikedPosts : getUserPosts;
        func(user.claims.user_id, offset, PAGE_LIMIT, data => {
            const formattedData = data
                .map(d => ({ ...(isLikedMode ? d.post : d), ...JSON.parse((isLikedMode ? d.post : d).brief), type: 'post' }));
            if (formattedData.length < PAGE_LIMIT) {
                setNoPastPosts(true);
            }
            setOffset(offset + data.length);
            setData(prev => [
                ...prev,
                ...formattedData
            ]);
            setLoading(false);
            fetching = false;
        });

        logAnalytics('LOAD_MY_POSTS', session, {
            offset
        });
    };

    useEffect(() => {
        loadData(0);
    }, []);

    const onDebouncedLoad = useCallback(debounce(loadData, 200), [noPastPosts, fetching, offset]);

    const onSeenPost = (idx) => {
        // handle scroll down behavior
        if (!idx || data.length < PAGE_LIMIT) return;
        if (idx >= (data.length - (PAGE_LIMIT / 2))) {
            if (!noPastPosts) {
                onDebouncedLoad(offset);
            }
        }
    };

    return (
        <div
            className="like-props watermark"
        >
            {loading
                && <div className="loader-container-sm">
                <Loader />
                </div>
            }

            <div
                className="mb-10 container pt-5 pb-20"
                style={{
                    overflowX: 'hidden'
                }}
              >
                <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 2, 750: 2 }}
                >
                    <Masonry gutter="8px">
                        {data.map((post, idx) => (
                            post.type === 'post'
                                ? <Post
                                    key={`p_${idx}`}
                                    id={idx}
                                    content={post}
                                    onClick={onClickPost}
                                    onSeenPost={onSeenPost}
                                    suffix="-my"
                                    hideAction
                                />
                                : <></>
                        ))}
                    </Masonry>
                </ResponsiveMasonry>

            </div>

            {!loading
                && data?.length === 0
                && <div className="text-center text-12 py-20">
                    <div>You have not posted anything yet</div>
                    <div className="d-flex justify-content-center">
                        <button
                            className="button -dark-1 bg-blue-1 text-white px-10 py-5 rounded-100 mt-15 text-10 fw-600"
                            onClick={onCreatePost}
                        >Create post</button>
                    </div>
                </div>
            }
            </div>
    );
};

export default PostsPage;
