import { ICON } from "@/utils/map";
import { Marker } from "react-map-gl/maplibre";

const BlockMarker = ({
  idx,
  target
}) => {
  return (
    <Marker
      key={`pbm-${idx}`}
      latitude={target.lat}
      longitude={target.lng}
      anchor="bottom"
    >
      <div
        data-bs-toggle="offcanvas"
        data-bs-target="#propSidebar"
        data-tooltip-id="block-tooltip"
        data-tooltip-html={`
          <div class="map-tooltip-content">
          <span class="fw-500">${target.address ?? target.street}</span>
          </div>
        `}
        data-tooltip-variant="dark"
        data-tooltip-place="bottom"
        style={{
          transform: 'scale(0.8)'
        }}
      >
        <svg
          height={60}
          viewBox="0 0 24 24"
          style={{
            cursor: 'pointer',
            fill: '#13357b',
            stroke: 'none',
          }}
        >
          <path d={ICON} />
          <text x='12' y='12.5' textAnchor="middle" fill="#fff" style={{ fontSize: '7px', fontWeight: '600' }} >
            {target.block}
          </text>
        </svg>
      </div>
    </Marker>
  );
};

export default BlockMarker;
