import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { debounce } from 'lodash';

import LocationSearch from "@/components/mapv2/search/LocationSearch";
import {
  DEBOUNCE_TIMING,
  searchQuery
} from "@/utils/api";
import { LOCATION_PROPERTY } from "@/utils/areas";

const LandingFilterBox = ({
  onSearchLocation,
  allowModeSwitch = false
}) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const onSearch = (value) => {
    setLoading(true);
    searchQuery(value.trim(), 10, (data) => {
      if (data) {
        setResults(data);
      }
      setLoading(false);
    }, () => {
      setLoading(false);
    }, null, allowModeSwitch ? null : 'subtype:!=HDB');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedSearch = useCallback(debounce(onSearch, DEBOUNCE_TIMING), []);

  const onInput = (item) => {
    setSearchValue(item);
    if (item?.length >= 3) onDebouncedSearch(item);
  };

  const onSelect = (item) => {
    onSearchLocation(item);
  };

  const onKeyDown = () => {
    if (results?.length > 0) {
      onSearchLocation(results[0]);
    }
  };

  return (
    <>
      <div className="land-search mt-10 px-20 fade-intro">
        <div className="button-grid items-center d-flex justify-content-between rounded-8 border-blue-1">
          <div className="p-2 flex-fill">
            <LocationSearch
              searchValue={searchValue}
              results={results}
              loading={loading}
              onInput={onInput}
              onSelect={onSelect}
              onKeyDown={onKeyDown}
              shortHeight
            />
          </div>

          <div className="button-item h-full mr-10 bg-blue-1 text-white px-10 rounded-100">
            <i className="icon-search text-12" />
          </div>
        </div>
      </div>
    </>
  );
};

LandingFilterBox.propTypes = {
  onSearchLocation: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  hideClickHint: PropTypes.bool,
  buttonIcon: PropTypes.string,
  buttonLabel: PropTypes.string,
};

LandingFilterBox.defaultProps = {
  buttonIcon: null,
  buttonLabel: null,
};

export default LandingFilterBox;
