const ErrorContent = () => {
  return (
    <section className="layout-pt-lg layout-pb-lg noselect">
      <div className="container">
        <div className="row y-gap-30 justify-between items-center">
          <div className="col-lg-6">
            <img src="/img/general/404.svg" alt="image" />
          </div>
          <div className="col-lg-5">
            <div className="no-page">
              <h2 className="text-30 fw-600"><span className="text-blue-1">ERROR</span> LOADING MAP</h2>
              <div className="pr-30 mt-5 fw-500">Please try again later</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErrorContent;
