import { useState, useEffect, useCallback } from "react";
import {
  useNavigate,
  useLocation,
  useSearchParams
} from "react-router-dom";
import { debounce } from 'lodash';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Header12 from "@/components/header/header-12";
import MetaComponent from "@/components/common/MetaComponent";
import Loader from "@/components/common/Loader";
import Carousel from "@/components/newsfeed/Carousel";
import Post from "@/components/newsfeed/Post";
import { checkMediaQuery, isLoggedIn } from "@/utils/user";

import LoginPopup from "@/components/login/LoginPopup";

import { Tooltip } from "react-tooltip";
import {
  deletePost,
  getGuestFeeds,
  getLatestGuestFeeds,
  likePost,
  logAnalytics,
  logNameSearch,
  trackEvent
} from "@/utils/api";
import {
  isHdbType,
  MAP_MODE_CONDO,
  MAP_MODE_HDB,
  MAP_MODE_LANDED
} from "@/utils/map";
import {
  LOCATION_PROPERTY,
  LOCATION_SCHOOL,
  LOCATION_STATION,
  LOCATION_UPCOMING
} from "@/utils/areas";
import ReactPullToRefresh from "react-pull-to-refresh";
import BottomBar from "@/components/newsfeed/BottomBar";


const DEFAULT_METADATA = {
  title: "REALSMART.SG | Latest Property News | Supercharge your property search",
  description: "REALSMART.SG - Supercharge your property search",
};

// generate random background gradients if necessary
const generateRandomBgId = () => Math.floor(Math.random() * 8) + 1;

const NewsfeedPage = ({
  user,
  session
}) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const queryPostId = params.get('id');

  const MAX_PAGE_SIZE = 20;

  const [err, setErr] = useState(null);
  const [succ, setSucc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(null);
  const [latestOffset, setLatestOffset] = useState(null);
  const [loadedPosts, setLoadedPosts] = useState(new Set());
  const [noPastPosts, setNoPastPosts] = useState(false);

  const [selectedPost, setSelectedPost] = useState(null);
  const [editPost, setEditPost] = useState(null);
  const [showSharePanel, setShowSharePanel] = useState(null);

  const [showLogin, setShowLogin] = useState(false);

  const [lightboxImages, setLightboxImages] = useState(null);

  let fetching = false;
  let isPulling = false;
  let seen = new Set();
  let seenSent = new Set();
  let markingPosts = false;

  const markPostsAsSeen = () => {
    if (markingPosts) return;
    markingPosts = true;
    const ids = [...seen].filter(id => !seenSent.has(id));
    // TODO
  };

  const handlePostSeen = (id) => {
    if (seen.has(id) || seenSent.has(id)) return;
    seen.add(id);
  };

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const getUserId = () => {
    try {
      return localStorage.getItem('session_idx');
    } catch(e) {
      return null;
    }
  };

  useEffect(() => {
    // load newsfeed
    onLoadFeed(getLatestQueryTime());

    if (queryPostId) {
      setSelectedPost({
        id: queryPostId
      });
    }

    // handle screen size change
    const updateScreenSize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", updateScreenSize);
    window.addEventListener("orientationchange", updateScreenSize);

    return () => {
      window.removeEventListener("resize", updateScreenSize);
      window.removeEventListener("orientationchange", updateScreenSize);
    };
  }, []);

  useEffect(() => {
    // TODO
  }, [user]);

  const showError = (err) => {
    setErr(err);
    setTimeout(() => {
      setErr(null);
    }, 8000);
  };

  const showSuccess = (err) => {
    setSucc(err);
    setTimeout(() => {
      setSucc(null);
    }, 8000);
  };

  const onClickPost = (post) => {
    // TODO
    setSelectedPost(post);
  };

  const onEditPost = (post) => {
    if (isLoggedIn(user)) {
      setSelectedPost(null);
      setEditPost(post);
    } else {
      setShowLogin(true);
    }
  };

  const onCreatePost = () => {
    if (isLoggedIn(user)) {
      setSelectedPost(null);
      setEditPost(null);
    } else {
      setShowLogin(true);
    }
  };

  const onClickLikedProps = () => {
    if (!isLoggedIn(user)) {
      setShowLogin(true);
    }
  };

  const getTimeQuery = (date) => date.toISOString().replace('Z', '000000Z');

  const getLatestQueryTime = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() - 1); // give 1 minute buffer for media processing
    // now.setSeconds(now.getSeconds() - 30); // give 30s buffer for media processing
    return getTimeQuery(now);
  };

  const onLoadFeed = (end) => {
    // return;
    if (fetching || noPastPosts) return;
    fetching = true;
    getGuestFeeds(end, MAX_PAGE_SIZE, data => {
      const formattedData = data
        .filter(d => !loadedPosts.has(d.id))
        .map(d => ({ ...d.post, ...JSON.parse(d.post.brief), type: 'post' }));
      if (formattedData.length < MAX_PAGE_SIZE) {
        setNoPastPosts(true);
      }
      setData(prev => [
        ...prev,
        ...formattedData
      ]);
      if (formattedData.length > 0) {
        const minTs = Math.min(...formattedData.map(d => new Date(d.createdAt).getTime()));
        setOffset(offset ? Math.min(offset, minTs) : minTs);

        const maxTs = Math.max(...formattedData.map(d => new Date(d.createdAt).getTime()));
        setLatestOffset(latestOffset ? Math.max(latestOffset, maxTs) : maxTs);
      }
      const newLoadedPosts = new Set(loadedPosts);
      formattedData.forEach(d => newLoadedPosts.add(d.id));
      setLoadedPosts(newLoadedPosts);
      fetching = false;
      if (loading) {
        setLoading(false);
      }
    });
    logAnalytics('LOAD_NEWSFEED', session, {
      until: end
    });
  };

  const onDebouncedLoad = useCallback(
    debounce(onLoadFeed, 200),
    [noPastPosts, fetching, offset, loadedPosts, loading, latestOffset]
  );

  const onSeenPost = (idx, content) => {
    // handle scroll down behavior
    if (!idx || data.length < MAX_PAGE_SIZE) return;
    if (idx >= (data.length - (MAX_PAGE_SIZE / 2))) {
      if (!noPastPosts) {
        onDebouncedLoad(getTimeQuery(new Date(offset)));
      }
    }
    if (content?.id) {
      handlePostSeen(content.id);
    }
  };

  const onLikePost = (id) => {
    if (id) {
      likePost(id);
    }
  };

  const onRefreshFeed = (start) => {
    getLatestGuestFeeds(start, MAX_PAGE_SIZE, data => {
      const formattedData = data
        .filter(d => !loadedPosts.has(d.id))
        .map(d => ({ ...d.post, ...JSON.parse(d.post.brief), type: 'post' }));
      setData(prev => [
        ...formattedData,
        ...prev
      ]);
      if (formattedData.length > 0) {
        const maxTs = Math.max(...formattedData.map(d => new Date(d.createdAt).getTime()));
        setLatestOffset(latestOffset ? Math.max(latestOffset, maxTs) : maxTs);
      }
      const newLoadedPosts = new Set(loadedPosts);
      formattedData.forEach(d => newLoadedPosts.add(d.id));
      setLoadedPosts(newLoadedPosts);
      fetching = false;
    });
    logAnalytics('LOAD_NEWSFEED', session, {
      from: start
    });
  };

  const onRefresh = () => {
    if (isPulling) return;
    isPulling = true;
    // onRefreshFeed();
    // latestOffset
    setTimeout(() => {
      isPulling = false;
    }, 800);
  };

  const onLoggedIn = (user) => {
    // do nothing
  };

  const mediaQuery = checkMediaQuery();

  const onDeletePost = (id, callback, errCallback) => {
    deletePost(id, () => {
      callback?.();
      showSuccess('Post deleted successfully');
      setData(data.filter(d => d.id !== id));
    }, err => {
      errCallback?.();
      showError('Failed to delete post');
    });
  };

  const onSearchLocation = (searchTerm) => {
    if (!searchTerm.isFromTag) {
      logNameSearch('NEWSFEED', session, searchTerm.name);
    }

    if (searchTerm.type === LOCATION_PROPERTY) {
      const isHdb = searchTerm.isFromTag ? searchTerm.isHdb : isHdbType(searchTerm.subtype);

      if (searchTerm.isFromTag) {
        trackEvent(`post_tag_${isHdbType ? 'hdb' : 'private'}_${searchTerm.marker}`);
      } else {
        trackEvent(`news_search_${isHdbType ? 'hdb' : 'private'}_${searchTerm.marker}`);
      }

      const url = `/map?id=${
        isHdb
          ? searchTerm.store
          : encodeURIComponent(searchTerm.marker)
      }${
        isHdb
          ? ''
          : `&p=${searchTerm.store}`
      }&mode=${isHdb ? MAP_MODE_HDB : (
        searchTerm.landed ? MAP_MODE_LANDED : MAP_MODE_CONDO
      )}`;

      navigate(url);
    } else if (searchTerm.type === LOCATION_SCHOOL) {
      trackEvent(`landing_search_school_${searchTerm.names[0]}}`);

      const url = `/map?sch=${searchTerm.store}&mode=c`;
      navigate(url);
    } else if (searchTerm.type === LOCATION_STATION) {
      trackEvent(`landing_search_station_${searchTerm.marker}`);

      const url = `/map?stn=${searchTerm.store}&mode=c`;
      navigate(url);
    } else if (searchTerm.type === LOCATION_UPCOMING) {
      trackEvent(`landing_search_upcoming_${searchTerm.marker}`);

      const url = `/map?new=${searchTerm.store}`;
      navigate(url);
    }
  };

  return (
    <>
      <MetaComponent meta={DEFAULT_METADATA} />
      <div className="header-margin-xs"></div>
      <Header12
        user={user}
        session={session}
        onSearchLocation={onSearchLocation}
      />

      {loading && <div className="loader-container"><Loader /></div>}

      {err
        && <div
          className="user-notification text-center px-20 py-5 cursor-pointer text-12"
          style={{ background: 'red' }}
          onClick={() => setErr(null)}
          {...(mediaQuery ? {} : {
            'data-tooltip-id': 'social-tooltip',
            'data-tooltip-html': 'Click to close notification',
            'data-tooltip-variant': "dark",
            'data-tooltip-place': "top"
          })}
        >
          {err}
          <i className="icon-close text-12 text-white ml-20" />
        </div>
      }

      {succ
        && <div
          className="user-notification text-center px-20 py-5 cursor-pointer text-12"
          style={{ background: 'green' }}
          onClick={() => setSucc(null)}
          {...(mediaQuery ? {} : {
            'data-tooltip-id': 'social-tooltip',
            'data-tooltip-html': 'Click to close notification',
            'data-tooltip-variant': "dark",
            'data-tooltip-place': "top"
          })}
        >
          {succ}
          <i className="icon-close text-12 text-white ml-20" />
        </div>
      }

      {!loading
        && <div className="">

          <div className="newsfeed-content">
            {/* carousel */}
            <Carousel
              onClickItem={(item) => {
                if (item.s) {
                  navigate(`/map?s=${encodeURIComponent(item.n)}`);
                }
              }}
            />

            {/* feeds */}
            <div
              className="d-flex justify-content-center"
            >
              {/* <ReactPullToRefresh
                className="mb-10 container pt-5 pb-20"
                style={{
                  overflowX: 'hidden'
                }}
                onRefresh={onRefresh}
              > */}
              <div
                className="mb-10 container pt-5 pb-20"
                style={{
                  overflowX: 'hidden'
                }}
              >
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 2, 750: 2 }}
                >
                  <Masonry gutter="8px">
                    {data.map((post, idx) => (
                      post.type === 'post'
                        ? <Post
                            key={`p_${idx}`}
                            id={idx}
                            user={user}
                            content={post}
                            onClick={content => {
                              if (!isPulling) {
                                onClickPost(content);
                              }
                            }}
                            onSeenPost={onSeenPost}
                            onLikePost={onLikePost}
                            setShowSharePanel={setShowSharePanel}
                          />
                        : <></>
                    ))}
                  </Masonry>
                </ResponsiveMasonry>

              {/* </ReactPullToRefresh> */}
              </div>
            </div>

          </div>

          <BottomBar
            user={user}
            session={session}
            selectedPost={selectedPost}
            editPost={editPost}
            showSharePanel={showSharePanel}
            setShowSharePanel={setShowSharePanel}
            onCreatePost={onCreatePost}
            onEditPost={onEditPost}
            onClickLikedProps={onClickLikedProps}
            setSelectedPost={setSelectedPost}
            lightboxImages={lightboxImages}
            setLightboxImages={setLightboxImages}
            showError={showError}
            showSuccess={showSuccess}
            onDeletePost={onDeletePost}
            onSearchLocation={onSearchLocation}
            onClickPost={onClickPost}
          />

        </div>
      }

      {user !== null && !isLoggedIn(user)
        && <LoginPopup
          session={session}
          show={showLogin}
          setShow={setShowLogin}
          onLoggedIn={onLoggedIn}
        />
      }

      <Lightbox
        open={!!lightboxImages}
        close={() => setLightboxImages(null)}
        slides={lightboxImages?.gallery ?? []}
        index={lightboxImages?.idx ?? 0}
        portal={{ target: document.body }}
        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
        carousel={{ finite: true }}
      />

      <Tooltip id="social-tooltip" style={{ zIndex: 900 }} />
    </>
  );
};

export default NewsfeedPage;
