import { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import firebase from 'firebase/compat/app';

// import PlanList from "./profile/PlanList";
import {
  PROFILE_OPTIONS,
  getUserDisplayName,
  getUserPhotoUrl,
  isLoggedIn
} from "@/utils/user";
import TabButtons from "../mapv2/property/TabButtons";
import UserProfile from "../account/UserProfile";
import LikedProperties from "../account/LikedProperties";
import LikedPosts from "../account/LikedPosts";
import UserHistory from "../account/UserHistory";
import { trackEvent } from "@/utils/api";


const UserMenu = ({
  textClass,
  user,
  session,
  onClick,
  onClose,
  onUnlikeProperty,
  onViewProperty,
  isMap = false
}) => {
  const navigate = useNavigate();

  const [click, setClick] = useState(false);
  const [display, setDisplay] = useState(PROFILE_OPTIONS[0].id);

  const handleCarding = () => {
    if (!click) {
      onClick?.();
    } else {
      onClose?.();
      setDisplay(PROFILE_OPTIONS[0].id);
    }
    setClick((prevState) => !prevState);
  };

  const onLogout = () => {
    firebase.auth().signOut().then(() => {
      try {
        localStorage.removeItem('session_idx');
      } catch(e) {
        // do nothing
      }

      // clear user tracking on Umami when logged out
      if (window.umami && typeof window.umami.reset === 'function') {
        window.umami.reset();
      }
      
      // navigate to login page
      navigate('/login');
    });
  };

  // const onAdmin = () => {
  //   navigate('/admin');
  // };

  return (
    <>
      <div className="col-auto">
        <button
          className={`d-flex items-center text-14 ${textClass}`}
          onClick={handleCarding}
        >
          <img
            src={getUserPhotoUrl(user)}
            alt="image"
            referrerPolicy="no-referrer"
            className="rounded-full mr-10"
            height={20}
            width={20}
          />
          <span className="js-language-mainTitle">
            {" "}
            {getUserDisplayName(user)}
          </span>
          <i className="icon-chevron-sm-down text-7 ml-15" />
        </button>
      </div>

      <div className={`langMenu langMenuTop js-langMenu ${click ? "" : "is-hidden"}`}>
        <div className="currencyMenu__bg" onClick={handleCarding}></div>
        <div className="langMenu__content bg-white rounded-4 rel-content">
          <div className="d-flex items-center justify-between px-30 py-10 sm:px-15 border-bottom-light">
            <TabButtons
              tabs={PROFILE_OPTIONS}
              selectedTab={display}
              setSelectedTab={selected => {
                setDisplay(selected);

                trackEvent(`profile_tab_${selected}`);
              }}
              session={session}
            />
            <button className="pointer" onClick={handleCarding}>
              <i className="icon-close" />
            </button>
          </div>

          {/* <div className="text-18 fw-500 lh-15 ml-35 mt-20">My Subscription</div>
          <PlanList user={user} /> */}

          {isLoggedIn(user)
            && <div className="acct-bh py-10 px-10">
              {display === 'bio'
                && <UserProfile
                  user={user}
                />
              }
              {display === 'likes'
                && <LikedProperties
                  user={user}
                  session={session}
                  onViewProperty={prop => {
                    handleCarding();
                    onViewProperty(prop);
                  }}
                  onUnlikeProperty={onUnlikeProperty}
                  isMap={isMap}
                />
              }
              {display === 'posts'
                && <LikedPosts
                  user={user}
                />
              }
              {display === 'history'
                && <UserHistory
                  user={user}
                />
              }
            </div>
          }

          <div className="d-flex justify-content-end is-menu-opened-hide md:d-none px-20 py-20 border-top-light">
            {/* {isAdminTier(user?.claims)
              && <button
                className="button ml-auto p-2 px-30 fw-400 text-14 border-blue-1 -outline-blue -blue-1-05 bg-white h-50 mt-20 text-blue-1 mr-20"
                onClick={onAdmin}
              >
                <i className="icon-user text-20 mr-10" />
                Admin
              </button>
            } */}
            <button
              className="button ml-auto p-2 px-30 fw-400 text-14 border-blue-1 -outline-blue -blue-1-05 bg-white h-50 text-blue-1"
              onClick={onLogout}
            >
              <i className="icon-bed text-20 mr-10" />
              Logout
            </button>
          </div>
        </div>

      </div>
    </>
  );
};

UserMenu.propTypes = {
  textClass: PropTypes.string.isRequired,
  user: PropTypes.object,
};

export default UserMenu;
