import { useEffect, useState } from "react";
import { getTimeLabel } from "@/utils/time";


const Comment = ({
	id,
	row,
	user,
	onVoteComment,
	votedComments,
	onSeenComment,
	onCommentDelete,
	isDeleting
}) => {
	const [pendingDelete, setPendingDelete] = useState(false);
	const [deleteFailed, setDeleteFailed] = useState(null);

	const onSeen = () => {
		onSeenComment?.(id);
	};

	const onDeleteFail = () => {
		setPendingDelete(false);
		setDeleteFailed('delete failed');
	};

	useEffect(() => {
		if (onSeenComment) {
			const element = document.getElementById(`comment-${id}`);
			const observer = new IntersectionObserver(
				([entry]) => {
					if (entry.isIntersecting) {
						onSeen();
						observer.unobserve(entry.target); // Stop observing after it's visible
					}
				},
				{ root: null, threshold: 0.1 }
			);

			if (element) {
				observer.observe(element);
			}

			return () => {
				if (element) observer.disconnect();
			};
		}
	}, [id]);

	if (row.author.id === user?.claims?.user_id) {
		return (
			<div
				id={`comment-${id}`}
			>
				<div className="d-flex flex-row-reverse mb-5">
					<div className="fab-chat fab-user-chat">
						<div className="d-flex mt-5">
							<div className="p-2 d-flex flex-column px-0 py-0 mr-10 text-center">
								<div
									className="p-2 px-0 py-0 text-white rotate-180 text-10 post-btn"
									style={{ opacity: '0.3' }}
								>
									<i className="icon-chevron-sm-down" />
								</div>
								<div className="p-2 px-0 py-0 text-10 fw-600">
									{row.votes}
								</div>
								<div
									className="p-2 px-0 py-0 text-white text-10 post-btn"
									style={{ opacity: '0.3' }}
								>
									<i className="icon-chevron-sm-down" />
								</div>
							</div>
							<div className="p-2 py-0 px-0">
								<div
									className="d-flex justify-content-between"
								>
									<span className="fw-600 text-12">
										You commented {getTimeLabel(new Date(row.createdAt).getTime(), 'just now', ' ago')}:<br />
									</span>
									{
										!pendingDelete && <button
											className={`${deleteFailed ? 'text-red-1' : 'text-white'} text-12 post-btn pl-10`}
											data-tooltip-id="post-tooltip"
											data-tooltip-html="Delete comment"
											data-tooltip-variant="dark"
											data-tooltip-place="bottom"
											onClick={() => {
												setPendingDelete(true);
												setDeleteFailed(null);
												onCommentDelete(row.id, onDeleteFail);
											}}
											disabled={isDeleting}
										>
											{deleteFailed
												&& <span className="text-red-1 mr-5 fw-600">
													{deleteFailed}
												</span>
											}
											<i className="icon-trash" />
										</button>
									}
								</div>
								<span
									style={{
										whiteSpace: "pre-line"
									}}
								>
									{row.data.text}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div
			id={`comment-${id}`}
		>
			<div className="fab-chat mb-5">
				<div className="d-flex mt-5">
					<div className="p-2 d-flex flex-column px-0 py-0 mr-10 text-center">
						<button
							className="p-2 px-0 py-0 text-white rotate-180 text-10 post-btn"
							data-tooltip-id="post-tooltip"
							data-tooltip-html="Upvote"
							data-tooltip-variant="dark"
							data-tooltip-place="bottom"
							onClick={() => onVoteComment(row.id, 1)}
							style={votedComments[row.id] === 1 ? {
								opacity: '0.3'
							} : {}}
						>
							<i className="icon-chevron-sm-down" />
						</button>
						<div className="p-2 px-0 py-0 text-10 fw-600">
							{row.votes}
						</div>
						<button
							className="p-2 px-0 py-0 text-white text-10 post-btn"
							data-tooltip-id="post-tooltip"
							data-tooltip-html="Downvote"
							data-tooltip-variant="dark"
							data-tooltip-place="bottom"
							style={votedComments[row.id] === -1 ? {
								opacity: '0.3'
							} : {}}
							onClick={() => onVoteComment(row.id, -1)}
						>
							<i className="icon-chevron-sm-down" />
						</button>
					</div>
					<img
						className="p-2 px-0 py-0"
						src={row.avatar ?? '/img/general/profile.png'}
						style={{
							width: "30px",
							height: "30px",
							objectFit: "cover",
							borderRadius: "50%",
							marginRight: "6px",
							marginTop: '12px'
						}}
					/>
					<span
						className="p-2 fw-600 text-12"
						style={{alignItems: 'center', textAlign: 'center', display: 'inline-grid'}}
					>
						{row.author.name} commented {getTimeLabel(new Date(row.createdAt).getTime(), 'just now', ' ago')}:
					</span>
				</div>
				<div
					className="mt-5 py-5"
					style={{
						whiteSpace: "pre-line"
					}}
				>
					{row.data.text}
				</div>
			</div>
		</div>
	);
};

export default Comment;
