import { values } from "lodash";
import PropTypes from "prop-types";
import InputRange from "react-input-range";

const RangeSlider = (props) => {
  const {
    value,
    setValue,
    minValue,
    maxValue,
    pluralize,
    unit,
    dollarize,
    minify,
    step,
    prefix,
    snapToStepMin,
    discreteMax,
    allowSameValues,
    hasLimit
  } = props;

  const handleOnChange = (value) => {
    setValue({ value });
  };

  const pluralizeUnit = () => {
    if (prefix) return '';
    if (!pluralize) return unit;
    const suffix = value?.value?.max > 1 ? 's' : '';
    return `${unit}${suffix}`;
  };

  const dollarizeUnit = (value, snapToStepMin) => {
    if (!dollarize) return snapToStepMin ? snapToStepMin(value) : value;
    return `$${(value ?? 0).toLocaleString()}`
  };

  const prefixUnit = () => {
    if (!prefix) return '';
    return unit + ' ';
  };

  return (
    <div className="js-price-rangeSlider">
      <div className="text-14 fw-500"></div>

      <div className="d-flex justify-between mb-20">
        <div className="text-15 text-dark-1">
          <span className="js-lower mx-1">
            {prefixUnit()}{value?.value?.min === -1 ? `< ${dollarizeUnit(0)}`: dollarizeUnit(value?.value?.min, snapToStepMin)}
          </span>to
          <span className="js-upper mx-1">
            {discreteMax
              && <>
                {prefixUnit()}{dollarizeUnit(value?.value?.max)} {pluralizeUnit()}
              </>
            }
            {!discreteMax
              && <>
                {value?.value?.max === maxValue && !minify ? (hasLimit ? '' : '> ') : ''}{prefixUnit()}{dollarizeUnit(value?.value?.max)}{value?.value?.max === maxValue && minify ? '+' : ''} {pluralizeUnit()}
              </>
            }
          </span>
        </div>
      </div>

      <div className="px-5">
        <InputRange
          formatLabel={() => ``}
          minValue={minValue ?? 0}
          maxValue={maxValue}
          value={value?.value}
          step={step}
          onChange={(value) => handleOnChange(value)}
          allowSameValues={allowSameValues}
        />
      </div>
    </div>
  );
};

RangeSlider.propTypes = {
  value: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number.isRequired,
  pluralize: PropTypes.bool,
  unit: PropTypes.string,
  dollarize: PropTypes.bool,
  minify: PropTypes.bool,
  step: PropTypes.number,
  prefix: PropTypes.bool,
  snapToStepMin: PropTypes.func,
  decimalPlace: PropTypes.number
};

RangeSlider.defaultProps = {
  minValue: 0,
  pluralize: false,
  dollarize: false,
  minify: false,
  step: 1,
  prefix: false,
  snapToStepMin: null,
  decimalPlace: 0
};

export default RangeSlider;
