import { useCallback, useEffect, useRef, useState } from "react";
import { useVirtualizer } from '@tanstack/react-virtual'
import { checkMediaQuery, noSelectClass } from "@/utils/user";
import ScrollToTop from "../project/ScrollToTop";
import AreaTable from "./table/AreaTable";
import { MAP_MODE_HDB } from "@/utils/map";
import { LOCATION_AREA } from "@/utils/areas";
import PropertyRow from "./PropertyRow";
import { Tooltip } from "react-tooltip";
import { trackEvent } from "@/utils/api";

const AreaView = ({
  user,
  mode,
  err,
  target,
  isMinimized,
  isMaximized,
  handleMaximize,
  handleExpand,
  closePropertyDetails,
  goToProperty,
  goToHdbBlock,
  userConfig,
  setUnfadedProps
}) => {
  const getProperties = (target) => ((target.isCluster || target.isMarker) ? target.projects : (
    target.mapMode === MAP_MODE_HDB
      ? target.projects
      : target.projects.flatMap(p => p.properties)
  )).sort((a, b) => {
    if (target.mapMode === MAP_MODE_HDB) {
      return (b.profitable ?? 0) - (a.profitable ?? 0)
    } else {
      return (b.score ?? 0) - (a.score ?? 0)
    }
  });

  const [properties, setProperties] = useState(getProperties(target));

  const parentRef = useRef(null)

  useEffect(() => {
    setUnfadedProps(new Set());
    setProperties(getProperties(target));
    trackEvent('area_view')
  }, [target]);

  // virtualize all the properties so that the ui performance will not be too laggy when there are too many properties
  const rowVirtualizer = useVirtualizer({
    count: properties.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 180,
    overscan: 3,
    measureElement: true
  })

  const mediaMatches = checkMediaQuery();

  const escFunc = useCallback((event) => {
    if (event.key === "Escape") {
      closePropertyDetails();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunc, false);

    return () => {
      document.removeEventListener("keydown", escFunc, false);
    };
  }, [escFunc]);

  return (
    <>
      {!isMinimized && (
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-center text-10 text-light-1">
              {/* <div className="p-2 py-0"></div> */}
              <div
                className={`p-2 pt-5 pb-0 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                } mt-0 mb-0`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down px-5 expand-btn-border" />
              </div>
            </div>
          }

          <div className="d-flex align-items-center justify-content-start py-0 mt-15 mb-5 modal-menu noselect">
            <i className="p-2 icon-city text-18" />
            <h3
              className="p-2 text-15 fw-500 flex-grow-1 text-truncate"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {
                target.isCluster
                  ? `${properties.length} properties`
                  : (
                    target.isMarker
                      ? `${target.name} (${properties.length} properties)`
                      : `${
                          target.mapMode === MAP_MODE_HDB
                            ? ''
                            : 'District '  
                        }${target.id} (${properties.length} properties)`
                  )
              }
            </h3>

            <button
              className="p-2 button h-30 px-10 text-16"
              onClick={() => closePropertyDetails()}
            >
              <i className="icon-close py-5" />
            </button>
          </div>

          {err
            && <div className="modal-body watermark d-flex">
              {!mediaMatches
                && <div
                  className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                  onClick={() => isMaximized ? handleExpand() : handleMaximize()}
                >
                  <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                    <i className="icon-chevron-sm-down px-5 expand-btn-border" />
                  </div>
                </div>
              }

              <div
                id="project-details-modal"
                className={`p-2 modal-scoll-content text-center fw-500 py-40 ${
                  isMaximized ? 'modal-scroll-am-full' : (mediaMatches ? 'modal-scroll-am' : 'modal-scoll-content-am')
                }`}
              >
                {target.type === LOCATION_AREA ? 'Planning area' : 'Data'} not found
              </div>
            </div>
          }

          {!err
            && <div className="modal-body watermark d-flex">
              {!mediaMatches
                && <div
                  className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                  onClick={() => isMaximized ? handleExpand() : handleMaximize()}
                >
                  <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                    <i className="icon-chevron-sm-down px-5 expand-btn-border" />
                  </div>
                </div>
              }

              <div
                id="project-details-modal"
                className={`p-2 modal-scoll-content ${
                  isMaximized ? 'modal-scroll-am-full' : (mediaMatches ? 'modal-scroll-am' : 'modal-scoll-content-am')
                } ${noSelectClass(user)}`}
              >

                <ScrollToTop />
              
                {/* {target.type === LOCATION_AREA
                  && <section className={`modal-table${isMaximized ? '-full' : ''}`}>
                    <div className={`data-table ${noSelectClass(user)}`}>
                      <AreaTable
                        data={properties.map(p => ({
                          ...p,
                          profitable: p.profitable === null ? -1 : p.profitable
                        })).sort((a, b) => (b.profitable - a.profitable) || ((b.totalTx ?? 0) - (a.totalTx ?? 0)))}
                        goToProperty={data => {
                          const idx = data.cell.row.index;
                          if (target.mapMode === MAP_MODE_HDB) {
                            goToHdbBlock(data.data[idx].postal);
                          } else {
                            goToProperty(data.data[idx].marker, data.data[idx].projectId);
                          }
                        }}
                        userConfig={userConfig}
                        mapMode={target.mapMode}
                      />
                    </div>
                  </section>
                } */}

                <div className="py-5" ref={parentRef}>
                  {rowVirtualizer.getVirtualItems().map(r => properties[r.index]).map((p, i) => (
                      <PropertyRow
                        key={i}
                        id={i}
                        mode={mode}
                        data={p}
                        onView={(data) => {
                            if (target.mapMode === MAP_MODE_HDB) {
                              goToHdbBlock(data.postal);
                            } else {
                              goToProperty(data.marker ?? target.id, data.projectId);
                            }
                          }
                        }
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          }
        </div>
      )}

      <Tooltip id="bot-tooltip" />
    </>
  );
};

export default AreaView;
