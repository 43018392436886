import { useState, useEffect } from "react";

const useScreenOrientation = () => {
  const getOrientation = () =>
    window.matchMedia("(orientation: portrait)").matches ? "portrait" : "landscape";

  const [orientation, setOrientation] = useState(getOrientation());

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(getOrientation());
    };

    const mediaQuery = window.matchMedia("(orientation: portrait)");
    mediaQuery.addEventListener("change", handleOrientationChange);

    return () => {
      mediaQuery.removeEventListener("change", handleOrientationChange);
    };
  }, []);

  return orientation;
};

export default useScreenOrientation;
