import PropTypes from "prop-types";

import DefaultHeader from "@/components/header/default-header";
import DefaultFooter from "@/components/footer/default";
import Block1 from "@/components/about/Block1";

import MetaComponent from "@/components/common/MetaComponent";
import { useEffect } from "react";
import { logPage } from "@/utils/api";
// import TeamProfile from "@/components/team/TeamProfile";

const metadata = {
  title: "About Us | REALSMART.SG | Supercharge your property search",
  description: "REALSMART.SG - Supercharge your property search",
};

const About = ({ user, session }) => {
  useEffect(() => {
    logPage('ABOUT_US', session, {});
  }, []);

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="header-margin"></div>
      <DefaultHeader user={user} />

      <div className="about-content">
        <section className="layout-pt-md">
          <div className="container">
            <div className="row y-gap-30 justify-between items-center">
              <Block1 />
            </div>
          </div>
        </section>

        <DefaultFooter />
      </div>

      {/* <section className="section-bg layout-pt-lg layout-pb-lg noselect">
        <div className="section-bg__item -mx-20 bg-light-2" />
        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">
                  Our Team
                </h2>
              </div>
            </div>
          </div>

          <div className="overflow-hidden pt-80 js-section-slider">
            <div className="item_gap-x30">
              <TeamProfile />
            </div>
          </div>
          
        </div>
      </section> */}
    </>
  );
};

About.propTypes = {
  user: PropTypes.object,
  session: PropTypes.string.isRequired,
};

About.defaultProps = {
  user: null,
};

export default About;
